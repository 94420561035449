import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const SocialIcon = ({ icon }) => (
  <i className={`bi bi-${icon.name} text-white text-base lg:text-xl`}></i>
);

const FooterColumn = ({ title, items, isOpen, toggleOpen, screenSize }) => (
  <div className="footer-column sm:pl-14 md:pl-20 lg:pl-0">
    <div
      className="flex justify-between items-center cursor-pointer sm:cursor-default"
      onClick={screenSize.width < 640 ? toggleOpen : null}
    >
      <h3
        className="text-xl md:text-2xl lg:text-[25px] text-white"
        style={{ fontFamily: "Playfair Display", fontWeight: "700" }}
      >
        {title}
      </h3>
      <i
        className={`bi ${
          isOpen ? "bi-chevron-up" : "bi-chevron-down"
        } text-white text-lg sm:hidden`}
      ></i>
    </div>
    <div
      className={`flex flex-col space-y-3 mt-4 ${isOpen ? "block" : "hidden"}`}
    >
      {items?.map((item, index) => (
        <Link
          to={item.url}
          key={index}
          className="w-fit text-sm md:text-base lg:text-[17px] text-gray-200 hover:text-white"
        >
          {item.name}
        </Link>
      ))}
    </div>
  </div>
);

function Footer() {
  const [isInfoOpen, setInfoOpen] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const [isLegalOpen, setLegalOpen] = useState(false);
  const [isUsefulLinksOpen, setUsefulLinksOpen] = useState(false);
  const screenSize = useWindowSize();

  const data = useSelector(state => state?.pages?.data?.activeFooters);

  const columns = [
    {
      title: data?.title2,
      items: data?.quick_links
    },
    {
      title: data?.title3,
      items: data?.legal_links
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 640) {
        setInfoOpen(true);
        setNavOpen(true);
        setLegalOpen(true);
        setUsefulLinksOpen(true);
      } else {
        setInfoOpen(false);
        setNavOpen(false);
        setLegalOpen(false);
        setUsefulLinksOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <footer className="p-10 pt-12 bg-black">
      <div className="flex flex-col gap-y-8 w-full mx-auto justify-between sm:grid sm:grid-cols-2 lg:flex lg:flex-row lg:w-11/12">
        
        <div className="sm:pl-14 md:pl-20 lg:pl-0">
          <div
            className="flex justify-between items-center cursor-pointer sm:cursor-default"
            onClick={
              screenSize.width < 640 ? () => setInfoOpen(!isInfoOpen) : null
            }
          >
            <h3
              className="text-xl md:text-2xl lg:text-[25px] text-white"
              style={{ fontFamily: "Playfair Display", fontWeight: "700" }}
            >
              Informations
            </h3>
            <i
              className={`bi ${
                isInfoOpen ? "bi-chevron-up" : "bi-chevron-down"
              } text-white text-lg sm:hidden`}
            ></i>
          </div>

          <div
            className={`space-y-3 mt-4 ${
              isInfoOpen ? "block" : "hidden"
            } sm:block`}
          >
            <div className="flex items-center gap-x-3 justify-start">
              <span className="icon envelope">
                <i className="bi bi-envelope-fill"></i>
              </span>
              <Link
                to={`mailTo:contact@spaprivatif.com`}
                className="text-white text-sm md:text-base lg:text-[17px]"
              >
                {data?.email}
              </Link>
            </div>
            <div className="flex items-center gap-x-3">
              <span className="icon phone-square">
                <i className="bi bi-telephone-fill"></i>
              </span>
              <Link
                to={`tel:+3312345678`}
                className="text-white text-sm md:text-base lg:text-[17px]"
              >
                {data?.phone}
              </Link>
            </div>
            <div className="flex items-center gap-3">
              {data?.social_links?.map((icon) => (
                <Link to={icon.url} key={icon.name}>
                  <SocialIcon icon={icon} />
                </Link>
              ))}
            </div>
          </div>
        </div>

        {columns?.map((column, index) => (
          <FooterColumn
            key={index}
            title={column.title}
            items={column.items}
            isOpen={index === 0 ? isNavOpen : isLegalOpen}
            toggleOpen={() =>
              index === 0 ? setNavOpen(!isNavOpen) : setLegalOpen(!isLegalOpen)
            }
            screenSize={screenSize}
          />
        ))}

        <div className="footer-column sm:pl-14 md:pl-20 lg:pl-0">
          <div
            className="flex justify-between items-center cursor-pointer sm:cursor-default"
            onClick={
              screenSize.width < 640
                ? () => setUsefulLinksOpen(!isUsefulLinksOpen)
                : null
            }
          >
            <h3
              className="text-xl md:text-2xl lg:text-[25px] text-white"
              style={{ fontFamily: "Playfair Display", fontWeight: "700" }}
            >
              {data?.title4}
            </h3>
            <i
              className={`bi ${
                isUsefulLinksOpen ? "bi-chevron-up" : "bi-chevron-down"
              } text-white text-lg sm:hidden`}
            ></i>
          </div>
          <div
            className={`flex flex-col gap-2 mt-4 ${
              isUsefulLinksOpen ? "block" : "hidden"
            }`}
          >
            {data?.useful_links?.map((item, index) => (
              <Link
                to={item.url}
                key={index}
                className="w-fit text-sm md:text-base lg:text-[17px] text-gray-200 hover:text-white"
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      </div>

      <p className="py-3 mt-5 border-t-2 text-center text-gray-200 text-sm lg:text-base">
        © 2024 FIVE SPA PRIVATIF. Tout droits réservés.
      </p>
    </footer>
  );
}

export default Footer;
