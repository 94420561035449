import AddButton from "../../AddButton";

const EspaceOccupation = ({ espaceDetailsData, handleChange, valider }) => {
  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">
        Section d'Ocupation
      </h1>
      <form onSubmit={valider} className="space-y-2">
        <div className="flex flex-col space-y-1">
          <label htmlFor="occupation">Occupation</label>
          <input
            type="number"
            name="occupation"
            id="occupation"
            value={espaceDetailsData?.occupation}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="flex justify-end">
            <AddButton/>
        </div>
      </form>
    </div>
  );
};

export default EspaceOccupation;
