const getCurrentDateFormattedYMD = (requestDate = null) => {
  const date = requestDate === null ? new Date() : new Date(requestDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export default getCurrentDateFormattedYMD;
