import { Helmet } from "react-helmet";
import { AdvantagesP1, BannerSectionP1, BlogSection, ConceptSection, ContentTwoP1, Footer, PrestationsP1, SpaceSectionP1, TestimonialSection } from "../../components";
import { useSelector } from "react-redux";
import { BASEIMAGEURL } from "../../constants/actions";


export default function MainPage() {
  const data = useSelector(state => state?.pages?.data?.accueil);
  return (
    <div className="space-y-20">
      <Helmet>
        <title>{data?.titreSeo}</title>
        <meta name="description" content={data?.descriptionSeo} />
        <meta name="keywords" content={data?.motsClesSeo} />
        <meta property="og:image" content={BASEIMAGEURL + data?.imageSeo} />
      </Helmet>
        <BannerSectionP1 data={data} />
        <ConceptSection data={data} />
        <SpaceSectionP1 data={data} />
        <PrestationsP1 data={data} />
        <AdvantagesP1 data={data} />
        <ContentTwoP1 data={data} />
        <TestimonialSection data={data} />
        <BlogSection />
        <Footer />
    </div>
  );
}

