import { useEffect, useState } from "react";
import { apiClient } from "../../../actions/api";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Img9 } from "../../../assets/images";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState();
  const [enteredCode, setEnteredCode] = useState();
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state?.fromLogin) {
      navigate('/login');
    }
  }, [location, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await apiClient().post("/forgot-password", { email });

      if (response.data.success) {
        setShow(true);
        toast.success("Un code de réinitialisation a été envoyé à votre adresse email !");
        // setCode(response?.data?.code);
      } else {
        toast.error(
          response.data.error ||
            "Erreur lors de l'envoi du code de réinitialisation !"
        );
      }
    } catch (err) {
      console.error(err);
      toast.error("L'adresse e-mail n'existe pas !");
    }
  };

  const validateCode = async(e) => {
    e.preventDefault();
    try{
    const response = await apiClient().post("/check-code", {
      "email": email,
      "resetNumber": enteredCode
    });

    if (response.data.success) {
      navigate("/reset", { state: { email: email } });
    } else {
      toast.error(
        response.data.error ||
          "Le code de réinitialisation est incorrect !"
      );
    }
  } catch (err) {
    console.error(err);
    toast.error("Le code de réinitialisation est incorrect !");
  }
  };

  return (
    <div>
      <div
        className="min-h-screen flex justify-center items-center relative"
        style={{
          backgroundImage: `url(${Img9})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
    >
      <Helmet>
        <title>Réinitialiser le mot de passe</title>
        <meta name="description" content="Entrez votre adresse e-mail pour recevoir les instructions de réinitialisation de votre mot de passe." />
      </Helmet>
        <div className="absolute inset-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }} />
        <div className="w-11/12 sm:w-10/12 md:max-w-xl mx-auto border rounded-lg p-5 md:p-10 bg-white space-y-4 z-50">
          {!show ? (
            <form onSubmit={handleSubmit} className="space-y-2">
              <div className="text-xl md:text-2xl font-semibold mb-5">
                Entrez votre adresse email pour recevoir un code de réinitialisation
              </div>
              <div className="flex flex-col gap-y-2">
                <label
                  htmlFor="email"
                  className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-900 focus-within:ring-1 focus-within:ring-gray-900"
                >
                  <span className="text-xs font-medium text-gray-700">Email</span>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Votre email"
                    className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                    required
                    autoComplete="off"
                  />
                </label>
              </div>
              <div className="flex justify-end">
                <button type="submit" className="bg-gray-800 hover:bg-gray-700 py-1 px-3 text-white font-medium">
                  Envoyer le code de réinitialisation
                </button>
              </div>
            </form>
          ) : (
            <form onSubmit={validateCode} className="space-y-2">
              <div className="text-xl md:text-2xl font-semibold mb-5">
                Entrez le code de réinitialisation que vous avez reçu par email
              </div>
              <div className="flex flex-col gap-y-2">
                <label
                  htmlFor="code"
                  className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-900 focus-within:ring-1 focus-within:ring-gray-900"
                >
                  <span className="text-xs font-medium text-gray-700">Code de réinitialisation</span>
                  <input
                    type="number"
                    name="code"
                    id="code"
                    placeholder="Code de réinitialisation"
                    value={enteredCode || ""}
                    onChange={(e) => setEnteredCode(e.target.value)}
                    className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                    required
                    autoComplete="off"
                  />
                </label>
              </div>
              <div className="flex justify-end">
                <button type="submit" className="bg-gray-800 hover:bg-gray-700 py-1 px-3 text-white font-medium">
                  Valider le code
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
