import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { apiClient } from '../../../../actions/api';
import uploadImage from '../../../../helpers/uploadImages';
import { getData } from '../../../../actions/pages';
import { AddButton, BasicSEO, BoLayout } from '../../../../components';
import { LoadingGif } from '../../../../assets/images';
import { BASEIMAGEURL } from '../../../../constants/actions';
import removeImage from '../../../../helpers/removeImage';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const EditDecore = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const decores = useSelector((state) => state?.pages?.data?.decores);
  const [decore, setDecore] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formState, setFormState] = useState({
    titre: '',
    description: '',
    price: '',
    photo: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (decores) {
      const existingDecore = decores.find((decor) => decor?.id === parseInt(id));
      if (existingDecore) {
        setDecore(existingDecore);
        setFormState({
          titre: existingDecore?.titre || '',
          description: existingDecore?.description || '',
          price: existingDecore?.price || '',
          photo: existingDecore?.photo || '',
        });
        setSelectedImage(existingDecore?.photo ? BASEIMAGEURL + existingDecore?.photo : null);
      }
    }
  }, [decores, id]);

  useEffect(() => {
    dispatch(getData("/decores"));
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setFormState({ ...formState, photo: file });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let newPath = formState.photo;
      if (formState.photo instanceof File) {
        newPath = await uploadImage(
          "photo",
          { photo: formState.photo },
          { photo: decore?.photo },
          "decores-images"
        );
      }
      await apiClient().put(`/decores/${id}`, {
        photo: newPath ? newPath : formState.photo,
        titre: formState.titre,
        description: formState.description,
        price: formState.price,
      });
      dispatch(getData("/decores"));
      navigate('/gestion/decos-formules#decores');
    } catch (err) {
      toast.error('Erreur lors de la modification du décor');
      console.error(err);
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const deleteDecore = async () => {
    try {
      await removeImage(decore?.photo);
      await apiClient().delete(`/decores/${id}`);
      navigate('/gestion/decos-formules#decores');
    } catch (err) {
      toast.error('Impossible de supprimer ce décor');
      console.error(err);
    }
    closeModal();
  };

  return (
    <BoLayout>
      <BasicSEO />
      <div className="space-y-6">
        <header className='flex items-center gap-x-5 px-4 py-2'>
          <button onClick={() => navigate('/gestion/decos-formules#decores')} className='text-2xl text-gray-700 hover:text-gray-900'>
            <i className="bi bi-arrow-left"></i>
          </button>
          <h1 className='text-lg font-medium font-poppins text-gray-800'>
            Modifier le Décor <span className='font-bold text-gold'>{decore?.titre}</span>
          </h1>
        </header>
        <div className='m-3 p-4 border rounded-lg shadow-lg bg-white'>
          <div className="flex justify-end pr-3 pt-3">
            <button onClick={openModal} className='py-2 px-4 bg-red-600 text-white font-medium rounded-md hover:bg-red-500 transition-colors duration-300'>
              Supprimer
            </button>
          </div>
          {decore ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col gap-2">
                  <label htmlFor="titre" className="font-medium text-gray-700">Titre</label>
                  <input
                    type="text"
                    name="titre"
                    id="titre"
                    value={formState.titre}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 outline-none"
                    required
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="price" className="font-medium text-gray-700">Prix</label>
                  <input
                    type="number"
                    name="price"
                    id="price"
                    value={formState.price}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 outline-none"
                    required
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="description" className="font-medium text-gray-700">Description</label>
                  <textarea
                    name="description"
                    id="description"
                    value={formState.description}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 outline-none"
                    required
                    rows={3}
                  ></textarea>
                </div>
                <div className="flex items-center gap-x-5">
                  <label
                    htmlFor="photo"
                    className="relative cursor-pointer w-32 h-32 bg-gray-300 text-gray-800 rounded-lg flex justify-center items-center overflow-hidden"
                  >
                    <input
                      type="file"
                      name="photo"
                      id="photo"
                      className="sr-only"
                      onChange={handleImageChange}
                    />
                    {selectedImage ? (
                      <img
                        src={selectedImage}
                        alt={`img decor`}
                        className="object-cover w-full h-full"
                      />
                    ) : (
                      <i className="bi bi-card-image text-6xl"></i>
                    )}
                  </label>
                  <label
                    htmlFor="photo"
                    className="cursor-pointer bg-gray-100 py-2 px-4 border rounded-md text-gray-700 hover:bg-gray-200 transition-colors duration-300"
                  >
                    Choisir une image
                  </label>
                </div>
              </div>
              <div className="flex justify-end mt-4">
                <AddButton />
              </div>
            </form>
          ) : (
            <div className="flex justify-center items-center h-32">
              <img src={LoadingGif} alt="loading gif" width={40} height={40} />
            </div>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed z-50 inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto">
            <h2 className="text-lg font-semibold mb-4">Confirmer la Suppression</h2>
            <p className="mb-4 text-gray-700">Êtes-vous sûr de vouloir supprimer ce décor ? Cette action est irréversible.</p>
            <div className="flex justify-end gap-2">
              <button
                onClick={deleteDecore}
                className="bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-500 transition-colors duration-300"
              >
                Oui, Supprimer
              </button>
              <button
                onClick={closeModal}
                className="bg-gray-300 py-2 px-4 rounded-md hover:bg-gray-200 transition-colors duration-300"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </BoLayout>
  );
};

export default EditDecore;