import { Link, useNavigate } from "react-router-dom"
import { AddBoBlogSection, BasicSEO, BoLayout } from "../../../../components"


const AddBoBlog = () => {
  const navigate = useNavigate();
  return (
    <BoLayout>
      <BasicSEO/>
      <div className="flex items-center gap-x-1 px-3">
        <button onClick={()=>navigate('/gestion/blog')}>
          <i className="bi bi-arrow-left text-2xl cursor-pointer"></i>
        </button>
        <h1 className='text-xl md:text-2xl font-semibold py-2 px-4 text-lightOrange'>Ajouter un blog</h1>
      </div>
      <div className="bg-gray-100 pb-32">
        <div className="py-3 px-4 space-y-5">
          <AddBoBlogSection/>
        </div>
      </div>
    </BoLayout>
  )
}

export default AddBoBlog