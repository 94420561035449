// export const BASEIMAGEURL = 'http://127.0.0.1:8000/'
export const BASEIMAGEURL = 'https://www.five-spa-privatif.fr/welldone/public/'


//GLOBAL
export const TOGGLE_LOADING ="TOGGLE_LOADING";

// auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const STORE_TOKEN = 'STORE_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const STORE_USER = 'STORE_USER';

export const STORE_CLIENT = 'STORE_CLIENT';
export const STORE_CLIENT_TOKEN = 'STORE_CLIENT_TOKEN';
export const REMOVE_CLIENT_TOKEN = 'REMOVE_CLIENT_TOKEN';
export const LOGOUT_CLIENT = 'LOGOUT_CLIENT';


// reservation details
export const SET_PERIOD = 'SET_PERIOD';
export const SET_DATE = 'SET_DATE';
export const SET_NBR_PERSONNE = 'SET_NBR_PERSONNE';
export const SET_PRICE_NBR_PERSONNE = 'SET_PRICE_NBR_PERSONNE'; // for adding price of personne
export const SET_DECOR = 'SET_DECOR';
export const REMOVE_DECOR = 'REMOVE_DECOR';
export const SET_FORMULE = 'SET_FORMULE';
export const REMOVE_FORMULE = 'REMOVE_FORMULE';
export const SET_RESERVATION = 'SET_RESERVATION';
export const SET_PROMOTION_CODE = 'SET_PROMOTION_CODE';
export const CLIENT_NAME = 'CLIENT_NAME';
export const STORE_GUEST = 'STORE_GUEST';



//PAGES GLOBAL
export const SET_HEADER_DATA = "SET_HEADER_DATA"
export const SET_EXEMPLE = "SET_EXEMPLE"
 
// data of form multiple account
export const FORM_DATA = "FORM_DATA"

export const HEADER_DATA = 'HEADER_DATA'
export const FOOTER_DATA = 'FOOTER_DATA'
export const ACCUEIL_DATA = 'ACCUEIL_DATA'
export const ESPACE_DATA = 'ESPACE_DATA'
export const PRESTATION_DATA = 'PRESTATION_DATA'
export const ESPACE_CARDS_DATA = 'ESPACE_CARDS_DATA'
export const PRESTATION_CARDS_DATA = 'PRESTATION_CARDS_DATA'
export const CONATCT_DATA = 'CONATCT_DATA'
export const ACTIVE_FOOTER_DATA = 'ACTIVE_FOOTER_DATA'
export const BLOG_DATA = 'BLOG_DATA'
export const BLOG_CARDS_DATA = 'BLOG_CARDS_DATA'
export const PARAMETRES_DATA = 'PARAMETRES_DATA'
export const DECORES_DATA = 'DECORES_DATA'
export const FORMULES_DATA = 'FORMULES_DATA'
export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE'
export const CLEAR_RESERVATION_DATA = 'CLEAR_RESERVATION_DATA'
export const EMAILS_DATA = 'EMAILS_DATA'
export const CLIENT_RESERVATIONS = 'CLIENT_RESERVATIONS'
export const CLIENT_DATA = 'CLIENT_DATA'
export const GUESTS_DATA = 'GUESTS_DATA'
export const PAIMENTS_DATA = 'PAIMENTS_DATA'
export const PROMO_CODES = 'PROMO_CODES'
export const CONTACT_MAILS = 'CONTACT_MAILS'
export const POLITIQUE_DATA = 'POLITIQUE_DATA'
export const CONDITIONS_DATA = 'CONDITIONS_DATA'
export const RGPD_DATA = 'RGPD_DATA'

export const RESERVATIONS_DATA = 'RESERVATIONS_DATA';
export const STOTE_RESERVATIONS_BY_DATE = 'STOTE_RESERVATIONS_BY_DATE'
export const STOTE_RESERVATIONS_BY_SEMAINE = 'STOTE_RESERVATIONS_BY_SEMAINE'



