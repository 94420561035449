import React from 'react';
import { Helmet } from 'react-helmet';
import { BannerSectionP10, ConditionsSection2, Footer } from '../../components';
import { useSelector } from 'react-redux';

const Conditions = () => {
  const data = useSelector(state => state?.pages?.data?.conditions);

  return (
    <>
      <Helmet>
        <title>{data?.titre}</title>
        <meta name="description" content="Consultez les conditions générales de FIVE SPA PRIVATIVE. Retrouvez toutes les informations essentielles concernant nos services, politiques de réservation, annulation et autres termes importants pour garantir une expérience sans souci." />
      </Helmet>
      <div className='space-y-20'>
        <BannerSectionP10 data={data} />
        <ConditionsSection2 data={data} />
        <Footer />
      </div>
    </>
  );
}

export default Conditions;
