import './style.scss';
import { Img11 } from "../../assets/images";
import { BASEIMAGEURL } from '../../constants/actions';


const ContentTwoP6 = ({ espace = null }) => {
  return (
    <section>
      <div className="grid lg:grid-cols-2 gap-x-20 w-10/12 mx-auto">

        <div className="hidden lg:block">
          <img src={BASEIMAGEURL + espace?.imageConcept} className="rounded-2xl h-full object-cover" />
        </div>

        <div className="space-y-5 flex flex-col justify-center">
          <div className="flex justify-center lg:justify-start">
            <h1 className="text-4xl lg:text-5xl text-gray-900 capitalize" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{espace?.titreConcept}</h1>
          </div>
          <div className="text-justify text-gray-600">
            <p dangerouslySetInnerHTML={{ __html: espace?.descriptionConcept }}/>
          </div>
          <div className="flex lg:hidden my-4 h-72 w-full">
            <img src={BASEIMAGEURL + espace?.imageConcept} className="rounded-2xl h-full object-cover w-full" />
          </div>
        </div>

      </div>
    </section>
  );
};

export default ContentTwoP6;
