import { LOGIN_SUCCESS, LOGOUT, LOGOUT_CLIENT, STORE_CLIENT, STORE_USER } from "../constants/actions";

const initialState = {
    user: null,
    client: null,
}

export default (state = initialState, action) => {
    switch(action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
            };
        case LOGOUT:
            return {
                ...state,
                user: null,
            };
        case STORE_USER:
            return {
                ...state,
                user: action.payload,
            };
        case STORE_CLIENT:
            return {
                ...state,
                client: action.payload,
            };
        case LOGOUT_CLIENT:
            return {
                ...state,
                client: null,
            };
        default:
            return state;
    }
}
