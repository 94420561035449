import React, { useEffect, useState } from "react";
import './style.scss'

const FeatureCard = ({ title, description, index }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let bgClass;
  if (isSmallScreen) {
    bgClass = index % 2 === 0 ? 'bg-orangeavantage' : 'bg-grayavantage';
  } else {
    bgClass = index === 0 || index === 3 ? 'bg-grayavantage' : 'bg-orangeavantage';
  }
    return(
      <div className={`p-3 sm:p-4 md:p-5 lg:p-6 rounded-2xl lg:rounded-3xl text-white space-y-3 ${bgClass}`}>
        <h3 className="font-serif lg:text-xl" style={{ fontWeight: '700' }}>{title}</h3>
        <p className="text-sm lg:text-[17px]" dangerouslySetInnerHTML={{ __html: description }}/>
      </div>
  );
}

function AdvantagesP1({ data }) {
  const featureData = [
    {
      title: data?.titreAvantage2,
      description: data?.descriptionAvantage2,
    },
    {
      title: data?.titreAvantage3,
      description: data?.descriptionAvantage3,
    },
    {
      title: data?.titreAvantage4,
      description: data?.descriptionAvantage4,
    },
    {
      title: data?.titreAvantage5,
      description: data?.descriptionAvantage5,
    },
  ];
  
  return (
    <>
     {/* p-12 sm:p-16 lg:p-20 */}
      <section className="spa-section py-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 w-11/12 mx-auto text-justify">
          <article className="hidden lg:block min-h-96">
            <div className="image-container h-80 w-10/12 lg:w-10/12 lg:h-full">
              <div className="overlay-text space-y-5 w-full lg:w-full  left-11 lg:-left-24 lg:bottom-30">
                <h2 className="text-base lg:text-[25px] text-white capitalize" style={{ fontWeight: '700', fontFamily: 'Playfair Display' }}>{data?.titreAvantage1}</h2>
                <p className="text-white text-sm lg:text-[17px]" dangerouslySetInnerHTML={{ __html: data?.descriptionAvantage1 }}/>
              </div>
            </div>
          </article>
          
          <article className="flex flex-col">
            <h2 className="text-4xl lg:text-5xl text-gray-900 capitalize" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{data?.titreAvantages}</h2>
            <p className="main-description my-3 lg:my-5 text-justify" dangerouslySetInnerHTML={{ __html: data?.descriptionAvantages }}/>
            {/* phone */}
            <article className="block my-4 w-full lg:hidden">
              <div className="image-container h-80 w-full lg:h-full">
                <div className="overlay-text space-y-5 w-11/12 sm:w-10/12 lg:w-full left-4 sm:left-11 lg:-left-24 bottom-7 sm:bottom-14">
                  <h2 className="text-xl lg:text-[25px] font-serif text-white" style={{ fontWeight: '700' }}>{data?.titreAvantage1}</h2>
                  <p className="text-white text-[rgba(255, 255, 255, 0.75)] text-[15px] lg:text-[17px]" dangerouslySetInnerHTML={{ __html: data?.descriptionAvantage1 }}/>
                </div>
              </div>
            </article>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
              {featureData.map((feature, index) => (
                <FeatureCard key={index} index={index} {...feature} />
              ))}
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default AdvantagesP1;
