import "./style.scss";
import Header from "../header";


function BannerSectionP3({ data }) {

  return (
    <div className="relative h-[500px]">
      <section className="hr-sec h-full">
        <Header />
        <div className=" absolute inset-0 flex items-center justify-center mt-32">
          <div className="px-4 flex flex-col justify-center items-center max-w-2xl text-center gap-y-7">
            <h1 className="text-4xl lg:text-5xl text-white" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }} >{data?.titre}</h1>
            <p className="text-gray-300 text-sm lg:text-base" dangerouslySetInnerHTML={{ __html: data?.description }}/>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BannerSectionP3;
