import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Import toast
import "./test.css";

const ReservationBar = ({ from }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const reduxData = useSelector((state) => state?.detailsreservation);
  const spaces = useSelector((state) => state?.pages?.data?.espaceCards);
  const additional_price = useSelector(
    (state) => state?.pages?.data?.parametres?.additional_price
  );
  const navigate = useNavigate();
  const [reservedSpace, setReservedSpace] = useState();
  const [total_price, setTotal_price] = useState("0.00");
  const dispatch = useDispatch();

  useEffect(() => {
    if (reduxData?.reservation?.roomId) {
      const reservedSpace = spaces?.find(
        (space) => space?.id === parseInt(reduxData?.reservation?.roomId)
      );
      setReservedSpace(reservedSpace);
    }
  }, [reduxData?.reservation, spaces]);

  useEffect(() => {
    const calculateTotal = () => {
      let total = 0;

      // nbr personnes prices
      if (reduxData?.nbrpersonne > 2) {
        total += parseFloat(additional_price) * (reduxData?.nbrpersonne - 2);
      }

      if (Array.isArray(reduxData?.formules)) {
        total += reduxData.formules.reduce(
          (sum, formule) => sum + (parseFloat(formule.price) || 0),
          0
        );
      }

      if (reduxData?.decor && reduxData.decor.price) {
        total += parseFloat(reduxData.decor.price);
      }

      if (!!reduxData?.promotionCode) {
        total = total * (parseFloat(reduxData?.promotionCode) / 100);
      }

      return total.toFixed(2);
    };

    setTotal_price(calculateTotal());
  }, [reservedSpace, reduxData, dispatch]);

  const handleNext = () => {
    if (reduxData?.formules?.length === 0 && from === "formule") {
      toast.error("Il est nécessaire de choisir une formule"); // Show toast message
    } else {
      const nextPath =
        from === "res"
          ? "/reservation/formules"
          : from === "formule"
          ? "/reservation/decos"
          : "/reservation-details";
      navigate(nextPath);
    }
  };

  return (
    <div className="px-2 lg:px-4 bg-gray-800 text-white">
      {/* large screen */}
      <div className="hidden lg:flex flex-row items-center justify-between py-3">
        <div className="text-2xl font-bold">
          <h1>Votre réservation</h1>
        </div>

        <div className="text-center flex items-center gap-x-4">
          <div className="space-y-1">
            <div className="max-w-48 text-base font-outfit uppercase font-bold">
              {reservedSpace?.titre}
            </div>
            {reduxData?.nbrpersonne > 2 && (
              <div className="text-xs font-outfit uppercase font-bold text-yellow-600">
                ( {reduxData?.nbrpersonne} personnes )
              </div>
            )}
            {reduxData?.nbrpersonne > 2 && (
              <div className="font-bold w-fit mx-auto font-poppins">
                <span>
                  {parseFloat(additional_price) * (reduxData?.nbrpersonne - 2)}
                </span>
                <i className="bi bi-currency-euro"></i>
              </div>
            )}
          </div>
          {reduxData?.formules?.length > 0 && (
            <div className="">
              <i className="bi bi-plus-circle text-lg"></i>
            </div>
          )}
          {/* of formule */}
          {reduxData?.formules?.length > 0 &&
            [...(reduxData?.formules !== null && reduxData?.formules)]?.map(
              (formule, i) => (
                <React.Fragment key={i}>
                  <div className="space-y-1">
                    <div className="max-w-48 text-base font-outfit uppercase font-bold">
                      {formule?.titre}
                    </div>
                    <div className="font-bold w-fit mx-auto font-poppins">
                      <span>{formule?.price}</span>
                      <i className="bi bi-currency-euro"></i>
                    </div>
                  </div>
                </React.Fragment>
              )
            )}
          {/* of decos */}
          {reduxData?.decor && (
            <>
              <div className="">
                <i className="bi bi-plus-circle text-lg"></i>
              </div>
              <div className="space-y-1">
                <div className="max-w-48 text-base font-outfit uppercase font-bold">
                  {reduxData?.decor?.titre}
                </div>
                <div className="font-bold w-fit mx-auto font-poppins">
                  <span>{reduxData?.decor?.price}</span>
                  <i className="bi bi-currency-euro"></i>
                </div>
              </div>
            </>
          )}

          {[
            ...reduxData?.formules,
            reduxData?.decor !== null && reduxData?.decor,
          ]?.length > 0 && (
            <div className="">
              <i className="fa-solid fa-equals text-lg"></i>
            </div>
          )}
        </div>

        <div className="space-y-1">
          <div className="font-bold text-xl">Total</div>
          <div className="font-bold w-fit mx-auto font-poppins">
            <span>{total_price}</span>
            <i className="bi bi-currency-euro"></i>
          </div>
        </div>

        <div className="">
          <button
            className={`px-8 py-4 w-full h-full font-bold bg-niceYellow text-black uppercase ${
              reduxData?.formules?.length === 0 && from === "formule"
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            onClick={handleNext}
            disabled={reduxData?.formules?.length === 0 && from === "formule"}
          >
            {from === "res" && "Suivant"}
            {from === "formule" && "Suivant"}
            {from === "deco" && "Valider"}
          </button>
        </div>
      </div>

      {/* small screen */}
      <div className="block lg:hidden space-y-2 py-2">
        <div className="">
          <h1 className="text-center font-bold text-xl">
            Coût total de votre réservation
          </h1>
          <div className="font-bold w-fit mx-auto text-center text-xl font-poppins">
            <span>{total_price}</span>
            <i className="bi bi-currency-euro"></i>
          </div>
        </div>

        <div
          className={`transition-max-height duration-500 ease-in-out ${
            detailsVisible ? "max-h-96" : "max-h-0 overflow-hidden"
          }`}
        >
          <table className="w-full border-separate font-bold font-poppins uppercase">
            <tr>
              {reduxData?.nbrpersonne > 2 ? (
                <>
                  <td className="p-2 bg-darkBrown max-w-20 ellipsis-td">
                    {reservedSpace?.titre}
                    <div className="text-xs font-outfit uppercase font-bold text-black">
                      ( {reduxData?.nbrpersonne} personnes )
                    </div>
                  </td>
                  <td className="p-2 bg-darkBrown w-1/6 min-w-28">
                    <div className="font-bold w-fit mx-auto font-poppins">
                      <span>
                        {parseFloat(additional_price) *
                          (reduxData?.nbrpersonne - 2)}
                      </span>
                      <i className="bi bi-currency-euro"></i>
                    </div>
                  </td>
                </>
              ) : (
                <td colSpan="2" className="p-2 bg-darkBrown text-center">
                  {reservedSpace?.titre}
                </td>
              )}
            </tr>

            {(reduxData?.formules || []).map((deco, i) => (
              <tr key={i}>
                <td className="p-2 bg-darkBrown text-left">{deco.titre}</td>
                <td className="p-2 bg-darkBrown text-center">
                  {deco.price}
                  <i className="bi bi-currency-euro"></i>
                </td>
              </tr>
            ))}
            {reduxData?.decor && (
              <tr>
                <td className="p-2 bg-darkBrown text-left">{reduxData.decor.titre}</td>
                <td className="p-2 bg-darkBrown text-center">
                  {reduxData.decor.price}
                  <i className="bi bi-currency-euro"></i>
                </td>
              </tr>
            )}
            <tr className="">
              <td className="p-2 bg-darkBrown w-5/6">Total</td>
              <td className="p-2 bg-darkBrown w-1/6 min-w-28 text-center">
                {total_price}
                <i className="bi bi-currency-euro"></i>
              </td>
            </tr>
          </table>
        </div>

        <div className="grid grid-cols-2 gap-2">
          <div
            className="text-center py-1.5 px-3 cursor-pointer bg-darkBrown"
            onClick={() => setDetailsVisible(!detailsVisible)}
          >
            <span className="font-semibold">Voir le détail</span>
            <span className="float-end">
              <i
                className={`bi bi-chevron-${detailsVisible ? "down" : "up"}`}
              ></i>
            </span>
          </div>

          {/* <div
            className="bg-niceYellow text-black cursor-pointer uppercase font-bold text-center py-1.5 px-3"
            onClick={handleNext}
          >
            <span>{from === "deco" ? "Valider" : "Suivant"}</span>
            <span className="float-end">
              <i className="bi bi-chevron-right"></i>
            </span>
          </div> */}
          
          <button
            className={`bg-niceYellow text-black cursor-pointer uppercase font-bold text-center py-1.5 px-3 ${
              reduxData?.formules?.length === 0 && from === "formule"
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            onClick={handleNext}
            disabled={reduxData?.formules?.length === 0 && from === "formule"}
          >
            {from === "res" && "Suivant"}
            {from === "formule" && "Suivant"}
            {from === "deco" && "Valider"}
          </button>

        </div>
      </div>
    </div>
  );
};

export default ReservationBar;
