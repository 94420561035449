import * as React from "react";
import './style.scss';
import { BannerImg, Img5 } from "../../assets/images";
import { BASEIMAGEURL } from "../../constants/actions";
import { useNavigate } from "react-router-dom";

const ContentTwoP1 = ({ data }) => {
  const navigate = useNavigate()
  return (
    <section className="w-11/12 sm:w-9/12 md:w-10/12 lg:w-10/12 mx-auto">
      <div className="flex flex-col lg:flex-row items-center lg:items-start gap-44">
        <div className=" lg:w-1/2 space-y-6">
          <div className="flex justify-center lg:justify-start">
            <h1 className="text-4xl lg:text-5xl text-gray-900 capitalize" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{data?.titreConcept2}</h1>
          </div>
          <div className="space-y-3 mx-auto text-justify w-11/12 sm:w-10/12 lg:w-full">
            <p dangerouslySetInnerHTML={{ __html: data?.descriptionConcept2 }}/>
          </div>
          <div className="lg:hidden flex justify-center gap-4">
            <img src={BASEIMAGEURL + data?.image1Concept2} alt="Luxury bathroom" className="w-36 h-52 sm:w-52 md:w-72 lg:w-48 sm:h-64 object-cover rounded-3xl shadow-lg" />
            <img src={BASEIMAGEURL + data?.image2Concept2} alt="Modern bathroom" className="w-36 h-52 sm:w-52 md:w-72 lg:w-48 sm:h-64 object-cover rounded-3xl shadow-lg mt-12" />
          </div>
          <div className="flex justify-center lg:justify-start">
            <button onClick={()=>navigate(data?.lienButtonConcept2)} className="button primary uppercase py-3 px-6 rounded-full transition duration-300 flex items-center gap-2">
              {data?.buttonConcept2} <i className="bi bi-arrow-right text-lg"></i>
            </button>
          </div>
        </div>
        <div className="hidden lg:w-1/2 lg:flex lg:justify-end gap-4">
          <img src={BASEIMAGEURL + data?.image1Concept2} alt="Luxury bathroom" className="w-56 h-80 object-cover rounded-3xl shadow-lg" />
          <img src={BASEIMAGEURL + data?.image2Concept2} alt="Modern bathroom" className="w-56 h-80 object-cover rounded-3xl shadow-lg mt-12" />
        </div>
      </div>
    </section>
  );
};

export default ContentTwoP1;