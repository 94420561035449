import { BasicSEO, BoFooterSection2, BoLayout } from '../../../../components'
const BoFooter = () => {
  return (
    <BoLayout>
      <BasicSEO/>
      <h1 className='text-xl md:text-2xl font-poppins py-2 px-4 text-lightOrange' style={{ fontWeight: 600 }}>Footer</h1>
      <div className="bg-gray-100 min-h-screen ">
        <div className="py-3 px-4 space-y-5">
          <BoFooterSection2/>
        </div>
      </div>
    </BoLayout>
  )
}

export default BoFooter