import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BasicSEO, BoLayout, BoPrestationDetailsSection1, BoPrestationDetailsSection2, BoPrestationDetailsSection3, BoPrestationDetailsSection4, BoPrestationDetailsSection5, SeoBoPrestationDeatails } from '../../../../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { apiClient } from '../../../../../actions/api';
import uploadImage from '../../../../../helpers/uploadImages';

const PrestationDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [prestationDetailsData, setPrestationDetailsData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient().get(`/prestation-cards/${id}`);
        setPrestationDetailsData(response.data);
        setInitialData(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [id, refresh]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrestationDetailsData({ ...prestationDetailsData, [name]: value });
  };

  const valider = async (e) => {
    e.preventDefault();
    const fieldsToCheck = [
      'bgimageDetail1', 'image1Detail1', 'image2Detail1', 'image3Detail1', 'image4Detail1',
      'bgimageDetail2', 'image1Detail2', 'image2Detail2', 'image3Detail2', 'image4Detail2',
      'bgimageConcept1', 'bgimageConcept2', 'imageSeo'
    ];

    try {
      const updatedData = { ...prestationDetailsData };

      for (const field of fieldsToCheck) {
        if (prestationDetailsData[field] !== initialData[field]) {
          const newPath = await uploadImage(field, prestationDetailsData, initialData, 'prestation-cards');
          updatedData[field] = newPath;
        }
      }
      await apiClient().put(`/prestation-cards/${id}`, updatedData);
      toast.success('Détails de la prestation modifiés', { autoClose: 600 });
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
      toast.error('Erreur lors de la modification des détails de la prestation', { autoClose: 1000 });
    }
  };

  return (
    <BoLayout>
      <BasicSEO/>
      <ToastContainer />
      <div className="flex items-center gap-x-5 py-2 px-4">
        <button onClick={() => navigate('/gestion/pages/prestations')}>
          <i className="bi bi-arrow-left text-2xl cursor-pointer"></i>
        </button>
        <h1 className='text-xl md:text-2xl font-poppins text-lightOrange' style={{ fontWeight: 600 }}>
          Page « Détails Prestation "{id}" »
        </h1>
      </div>
      <div className="bg-gray-100 min-h-screen">
        <div className="py-3 px-4 space-y-5">
          <BoPrestationDetailsSection1 prestationDetailsData={prestationDetailsData} handleChange={handleChange} valider={valider} />
          <BoPrestationDetailsSection2 prestationDetailsData={prestationDetailsData} handleChange={handleChange} valider={valider} />
          <BoPrestationDetailsSection3 prestationDetailsData={prestationDetailsData} handleChange={handleChange} valider={valider} />
          <BoPrestationDetailsSection4 prestationDetailsData={prestationDetailsData} handleChange={handleChange} valider={valider} />
          <BoPrestationDetailsSection5 prestationDetailsData={prestationDetailsData} handleChange={handleChange} valider={valider} />
          <SeoBoPrestationDeatails prestationDetailsData={prestationDetailsData} handleChange={handleChange} valider={valider} />
        </div>
      </div>
    </BoLayout>
  );
};

export default PrestationDetails;
