import { BasicSEO, BoLayout, LastReservations } from "../../../components";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./style.scss";
import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { apiClient } from "../../../actions/api";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr"; // Import the French locale
import "react-datepicker/dist/react-datepicker.css";
registerLocale("fr", fr);

const Dashboard = () => {
  const [tab, setTab] = useState("price");
  const [filter, setFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false); // Loading state
  const [currentTab, setCurrentTab] = useState("price"); // Track current tab

  useEffect(() => {
    fetchChartData();
  }, [tab, filter, startDate, endDate]);

  useEffect(() => {
    // Reset value when tab changes
    setValue(0);
    fetchChartData();
  }, [tab]);

  const fetchChartData = async () => {
    setLoading(true); // Start loading
    const params = { tab };
    if (filter) {
      params.filter = filter;
    }
    if (startDate && endDate) {
      params.start_date = startDate.toISOString().split("T")[0];
      params.end_date = endDate.toISOString().split("T")[0];
    }
    try {
      const response = await apiClient().get("/chart-data", { params });
      setData(response.data);
      calculateTotals(response.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données du graphique :",
        error
      );
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const calculateTotals = (data) => {
    if (tab === "price") {
      setValue(data.reduce((acc, curr) => acc + parseFloat(curr.value), 0));
    } else if (tab === "average") {
      setValue(
        data.length > 0
          ? data.reduce((acc, curr) => acc + parseFloat(curr.value), 0) /
              data.length
          : 0
      );
    } else if (tab === "reservations") {
      setValue(data.reduce((acc, curr) => acc + parseFloat(curr.value), 0));
    } else if (tab === "visitors") {
      setValue(data.reduce((acc, curr) => acc + parseFloat(curr.value), 0));
    } else if (tab === "profit") {
      setValue(
        data.reduce((acc, curr) => acc + parseFloat(curr.value) * 0.8, 0)
      );
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setStartDate(null);
    setEndDate(null);
  };

  const handleDatePickerSubmit = () => {
    setFilter("");
  };

  const buttonStyle = (currentTab) => `
    px-4 py-2 rounded ${
      tab === currentTab
        ? "border-2 border-black bg-lightyellow text-black font-semibold"
        : "border-2 border-grey bg-white"
    }
  `;

  return (
    <BoLayout>
      <BasicSEO />
      <div className="bg-gray-50 p-3 font-poppins text-sm">
        <div className="flex w-full flex-col gap-4">
          <div className="statistics rounded-lg bg-white p-4 shadow-md">
            <div className="overflow-x-auto mb-4">
              <div className="flex space-x-2 min-w-max">
                {["price", "reservations", "average", "visitors", "profit"].map(
                  (key) => (
                    <button
                      key={key}
                      onClick={() => {
                        setTab(key);
                        setCurrentTab(key); // Track current tab
                      }}
                      className={buttonStyle(key)}
                    >
                      {key === "price" && "Prix de toutes les réservations"}
                      {key === "reservations" && "Nombre de réservations"}
                      {key === "average" && "Moyenne de réservations"}
                      {key === "visitors" && "Nombre de visiteurs"}
                      {key === "profit" && "Bénéfice"}
                      <br />
                      {tab === key && !loading && (
                        <span className="text-darckyellow">
                          {value.toFixed(key === "average" ? 2 : 0)}{" "}
                          {key === "price" || key === "profit" ? <i className="bi bi-currency-euro"></i> : ""}
                        </span>
                      )}
                      {loading && tab === key && (
                        <CircularProgress size={24} color="inherit" />
                      )}
                    </button>
                  )
                )}
              </div>
            </div>

            <div className="flex flex-wrap items-center gap-2 mb-4">
              <select
                onChange={handleFilterChange}
                value={filter}
                className="p-2 border border-gray-300 rounded flex-grow"
              >
                <option value="">Sélectionner un filtre</option>
                <option value="day">Jour</option>
                <option value="month">Mois</option>
                <option value="year">Année</option>
                <option value="day-1">Jour-1</option>
                <option value="month-1">Mois-1</option>
                <option value="year-1">Année-1</option>
              </select>

              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="p-2 border border-gray-300 rounded flex-grow"
                placeholderText="Date de début"
                locale="fr" // Set the locale to French
                dateFormat="dd/MM/yyyy" // Format date to French style
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="p-2 border border-gray-300 rounded flex-grow"
                placeholderText="Date de fin"
                locale="fr" // Set the locale to French
                dateFormat="dd/MM/yyyy" // Format date to French style
              />
              <button
                onClick={handleDatePickerSubmit}
                className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
              >
                Appliquer
              </button>
            </div>

            <div className="min-h-96 w-full flex items-center justify-center">
              {loading ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress color="inherit" />
                </Box>
              ) : data.length > 0 ? (
                <LineChart
                  width={800}
                  height={400}
                  data={data}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  className="bg-white shadow-md rounded"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#bf9000" />
                </LineChart>
              ) : (
                <p className="text-gray-500 text-center w-full">
                  Aucune donnée disponible pour cette période.
                </p>
              )}
            </div>
          </div>

          <div className="w-full">
            <LastReservations />
          </div>
        </div>
      </div>
    </BoLayout>
  );
};

export default Dashboard;
