import { useEffect, useState } from "react";
import { apiClient } from "../../../../actions/api";
import getCurrentDateFormattedYMD from "../../../../helpers/getCurrentDateFormatedYMD";

const itemsPerPage = 7;

const Section2Utilisateurs = ({ clients = [], guests = [], refresh, setRefresh }) => {
  const [data, setData] = useState([...clients, ...guests]);
  const [recherch, setRecherch] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState("all");

  useEffect(() => {
    let filteredData = [...clients, ...guests];

    // Apply the search filter
    if (recherch.trim() !== "") {
      filteredData = filteredData.filter(cl =>
        cl?.first_name.toLowerCase().startsWith(recherch.toLowerCase()) ||
        cl?.last_name.toLowerCase().startsWith(recherch.toLowerCase()) ||
        cl?.email.toLowerCase().startsWith(recherch.toLowerCase())
      );
    }

    // Apply the type filter
    if (filterType === "clients") {
      filteredData = filteredData.filter(cl => cl?.type);
    } else if (filterType === "guests") {
      filteredData = filteredData.filter(cl => !cl?.type);
    }

    // Apply sorting
    filteredData.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });

    setData(filteredData);
  }, [recherch, sortOrder, filterType, clients, guests]);

  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleClients = data.slice(startIndex, startIndex + itemsPerPage);

  const toggleStatus = async (client) => {
    await apiClient().put(`/users/${client?.id}`, { statut: !client?.statut });
    setRefresh(!refresh);
  };

  return (
    <div className="space-y-8 bg-white p-4 rounded-lg shadow-md">
      {/* Filter and title */}
      <div className="flex items-center justify-between flex-wrap md:flex-nowrap gap-4">
        <div className="space-y-1 min-w-fit">
          <h1 className="font-poppins font-semibold text-2xl text-gray-800">
            Tous les utilisateurs
          </h1>
          <p className="text-green-600 text-sm">Membres actifs</p>
        </div>
        <div className="flex items-center gap-x-3 w-full lg:w-auto">
          <div className="flex items-center gap-x-2 py-2 px-3 bg-gray-100 rounded-lg shadow-sm border border-gray-300">
            <i className="bi bi-search text-lg text-gray-500"></i>
            <input
              type="text"
              name="search"
              value={recherch}
              onChange={(e) => setRecherch(e.target.value)}
              className="bg-gray-100 outline-none w-full text-sm text-gray-700"
              placeholder="Recherche"
            />
          </div>
          <select
            name="sortbydate"
            className="py-2 px-3 bg-gray-100 border border-gray-300 rounded-lg text-sm text-gray-700"
            onChange={(e) => setSortOrder(e.target.value)}
            value={sortOrder}
          >
            <option value="desc">Le plus récent</option>
            <option value="asc">Le plus ancien</option>
          </select>
          <select
            name="filtertype"
            className="py-2 px-3 bg-gray-100 border border-gray-300 rounded-lg text-sm text-gray-700"
            onChange={(e) => setFilterType(e.target.value)}
            value={filterType}
          >
            <option value="all">Tous</option>
            <option value="clients">Clients</option>
            <option value="guests">Invités</option>
          </select>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto mt-4">
        <table className="w-full bg-white border border-gray-200 rounded-lg shadow-sm">
          <thead>
            <tr className="text-xs sm:text-sm font-semibold text-gray-600 bg-gray-200 border-b">
              <th className="py-3 px-4 text-left">Nom complet</th>
              <th className="py-3 px-4 text-left">Genre</th>
              <th className="py-3 px-4 text-left">Numéro</th>
              <th className="py-3 px-4 text-left">Email</th>
              <th className="py-3 px-4 text-left">Date de création</th>
              <th className="py-3 px-4 text-left">Type</th>
              <th className="py-3 px-4 text-left">Status</th>
            </tr>
          </thead>
          <tbody className="text-sm text-gray-700">
            {visibleClients?.map((client, index) => (
              <tr key={index} className="border-b hover:bg-gray-50">
                <td className="py-3 px-4 font-medium">{client.first_name} {client.last_name}</td>
                <td className="py-3 px-4 font-medium">{client.gender ? client.gender : "---"}</td>
                <td className="py-3 px-4 font-medium">{client.phone}</td>
                <td className="py-3 px-4 font-medium">{client.email}</td>
                <td className="py-3 px-4 font-medium">{getCurrentDateFormattedYMD(client.created_at)}</td>
                <td className="py-3 px-4 font-medium">
                  {client?.type ? `client${client.gender === 'femme' ? 'e' : ''}` : `invité${client.gender ? client.gender === 'femme' ? 'e' : '' : "(e)"}`}
                </td>
                <td className="py-3 px-4">
                  {client?.type && (
                    <button
                      // onClick={() => toggleStatus(client)}
                      className={`px-3 py-1 rounded-lg w-20 text-sm font-medium ${
                        client.statut ? 'bg-green-500 text-white border-green-500' : 'bg-red-500 text-white border-red-500'
                      } border`}
                    >
                      {client.statut ? 'Active' : 'Inactive'}
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {data.length > itemsPerPage && (
        <div className="flex items-center justify-between mt-4 text-xs sm:text-sm">
          <p className="text-sm text-gray-600">
            Affichage des données {startIndex + 1} à {startIndex + visibleClients.length} sur {data.length} entrées
          </p>
          <nav className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-1 rounded-lg bg-gray-200 text-gray-700 disabled:opacity-50"
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-3 py-1 rounded-lg text-sm ${
                  currentPage === index + 1
                    ? "bg-yellow-400 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-3 py-1 rounded-lg bg-gray-200 text-gray-700 disabled:opacity-50"
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Section2Utilisateurs;