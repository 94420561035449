import { LOGOUT_CLIENT, REMOVE_CLIENT_TOKEN, STORE_CLIENT, STORE_CLIENT_TOKEN } from "../constants/actions";
import { apiClient } from "./api";
import { toast } from 'react-toastify';


export const loginClient = (user, rememberMe = false) => async (dispatch) => {
  try {
    const response = await apiClient().post(`/login`, user);
    
    if (response?.data?.email) {
      toast.error(response?.data?.email, { autoClose: 1000 });
      return;
    }
    
    dispatch({ type: STORE_CLIENT, payload: response?.data?.user });
    if(rememberMe){
      dispatch({ type: STORE_CLIENT_TOKEN, payload: response?.data?.token });
    }
    toast.success("Connexion réussie", { autoClose: 700 });
  } catch (error) {
    toast.error("Adresse e-mail ou mot de passe incorrect", { autoClose: 1000 });
    throw error;
  }
};

export const registerClient = (user, rememberMe = false) => async (dispatch) => {
  try {
    const response = await apiClient().post(`/register`, user);

    dispatch({ type: STORE_CLIENT, payload: response?.data?.user });

    if(rememberMe){
      dispatch({ type: STORE_CLIENT_TOKEN, payload: response?.data?.token });
    }

    await apiClient().post('/sendEmail',{
      "type": 'register',
      "name": `${response?.data?.user?.first_name} ${response?.data?.user?.last_name}`,
      "email": response?.data?.user?.email
    })

    toast.success("Client enregistré avec succès", { autoClose: 700 });
    return response;
  } catch (error) {
    toast.error("L'email existe déjà", { autoClose: 1000 });
    throw error;
  }
};


export const registerGuest = (guest) => async (dispatch) => {
  try {
    const response = await apiClient().post(`/guests`, guest);
    dispatch({ type: STORE_CLIENT, payload: response?.data?.guest });
    dispatch({ type: STORE_CLIENT_TOKEN, payload: response?.data?.token });
    toast.success("Vous êtes enregistré en tant qu'invité avec succès", { autoClose: 1000 });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const logoutClient = () => (dispatch) => {
  dispatch({ type: LOGOUT_CLIENT });
  dispatch({ type: REMOVE_CLIENT_TOKEN });
};
