import { AddButton, SimpleTextEditor } from "../../../..";

const BoPrestationDetailsSection1 = ({
  prestationDetailsData,
  handleChange,
  valider,
}) => {
  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">
        Section Header
      </h1>
      <form onSubmit={valider} className="grid grid-cols-1 gap-3">
        <div className="flex flex-col space-y-1">
          <label htmlFor="titre">Grand titre</label>
          <input
            type="text"
            name="titre"
            id="titre"
            value={prestationDetailsData?.titre}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="description">Description</label>
          <SimpleTextEditor
            value={prestationDetailsData?.description}
            onChange={(value) => handleChange({ target: { name: 'description', value } })}
            styleClassName=""
          />
        </div>
        <div className="flex items-center justify-end">
          <AddButton />
        </div>
      </form>
    </div>
  );
};

export default BoPrestationDetailsSection1;
