import { useNavigate } from 'react-router-dom';
import { BASEIMAGEURL } from '../../constants/actions';

const PrectationDeatailsSection3 = ({ prestation }) => {
  const navigate = useNavigate()
  return (
    <section className="relative py-20 mt-24" style={{ backgroundImage: `url(${BASEIMAGEURL + prestation?.bgimageConcept1})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div 
        className="relative w-[350px] lg:w-[400px] space-y-4 p-12 mx-auto"
        style={{ 
          borderRadius: '70px 70px 70px 10px',
          zIndex: 1,
          backgroundColor: 'rgba(221, 223, 226, 0.8)'
        }}
      >
        <h1 className="text-2xl text-black capitalize font-bold" style={{ fontFamily: 'Playfair Display' }}>{prestation?.titreConcept1}</h1>
        <p className='text-sm text-justify text-black' dangerouslySetInnerHTML={{ __html: prestation?.descriptionConcept1 }}/>
        <button className='button primary uppercase' onClick={()=>navigate(prestation?.lienButton2)}>{prestation?.button2}</button>
      </div>
    </section>
  );
};

export default PrectationDeatailsSection3;