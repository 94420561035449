import { useNavigate } from 'react-router-dom';
import { BASEIMAGEURL } from '../../constants/actions';

const EspaceDetailSection5 = ({ espace }) => {
  const navigate = useNavigate()
  return (
    <section className="relative py-20" style={{ backgroundImage: `url(${BASEIMAGEURL + espace?.bgimageDeatail2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div 
        className="relative w-[350px] lg:w-[400px] space-y-4 p-12 mx-auto"
        style={{ 
          borderRadius: '70px 70px 70px 10px',
          zIndex: 1,
          backgroundColor: 'rgba(221, 223, 226, 0.8)'
        }}
      >
        <h1 className="text-2xl text-black capitalize font-bold" style={{ fontFamily: 'Playfair Display' }}>{espace?.titreDetail2}</h1>
        <p className='text-sm text-justify text-black' dangerouslySetInnerHTML={{ __html: espace?.descriptionDetail2}}/>
        <button className='button primary uppercase' onClick={()=>navigate(espace?.lienButton2)}>{espace?.button2}</button>
      </div>
    </section>
  );
};

export default EspaceDetailSection5;
