import React, { useState } from 'react';

const Question = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`bg-white text-justify ${isExpanded ? '' : 'rounded-full'}`}>
      <div 
        className="flex justify-between items-center p-4 cursor-pointer bg-white rounded-full"
        onClick={toggleExpand}
      >
        <h3 className="text-lg font-medium">{question}</h3>
        <span className={`transform transition-transform duration-200 ${isExpanded ? 'rotate-180' : ''}`}>
            <i className="bi bi-chevron-down text-xl"></i>
        </span>
      </div>
      {isExpanded && (
        <div className="p-4 bg-white">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

export default Question;