import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useDispatch, useSelector } from 'react-redux';
import { SET_DATE } from '../../../constants/actions';

dayjs.extend(isSameOrAfter);

const DatePicker = () => {
  const dispatch = useDispatch();
  const reduxDate = useSelector(state => state?.detailsreservation?.date);
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));

  // Sync selectedDate with reduxDate when reduxDate changes
  useEffect(() => {
    if (reduxDate && reduxDate !== selectedDate) {
      setSelectedDate(reduxDate);
    }
  }, [reduxDate]);

  // Ensure selectedDate is not before today
  useEffect(() => {
    if (selectedDate && dayjs(selectedDate).isBefore(dayjs().startOf('day'))) {
      setSelectedDate(dayjs().format('YYYY-MM-DD'));
    }
  }, [selectedDate]);

  // Dispatch the new selectedDate when it changes
  useEffect(() => {
    if (reduxDate !== selectedDate) {
      dispatch({ type: SET_DATE, payload: selectedDate });
    }
  }, [selectedDate, dispatch, reduxDate]);

  const handlePrevDay = () => {
    const prevDate = dayjs(selectedDate).subtract(1, 'day').format('YYYY-MM-DD');
    if (dayjs(prevDate).isSameOrAfter(dayjs().startOf('day'))) {
      setSelectedDate(prevDate);
    }
  };

  const handleNextDay = () => {
    const nextDate = dayjs(selectedDate).add(1, 'day').format('YYYY-MM-DD');
    setSelectedDate(nextDate);
  };

  const formatDate = (date) => {
    return dayjs(date).format('dddd, MMMM D, YYYY');
  };

  return (
    <div className='w-full'>
      <div className="flex items-center justify-between w-full px-3">
        <button onClick={handlePrevDay} className="bg-gray-50 hover:bg-gray-100 w-8 h-8 min-w-8 min-h-8 border rounded-full flex justify-center items-center">
          <i className="bi bi-chevron-left"></i>
        </button>
        <div className="flex items-center">
          <span className="mx-auto text-base md:text-lg font-semibold capitalize">
            {formatDate(selectedDate)}
          </span>
        </div>
        <button onClick={handleNextDay} className="bg-gray-50 hover:bg-gray-100 w-8 h-8 min-w-8 min-h-8 border rounded-full flex justify-center items-center">
          <i className="bi bi-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default DatePicker;
