import { useDispatch, useSelector } from "react-redux";
import { BASEIMAGEURL } from "../../../../constants/actions";
import { useEffect, useState } from "react";
import AddButton from "../../pagescomponents/AddButton";
import { apiClient } from "../../../../actions/api";
import uploadImage from "../../../../helpers/uploadImages";
import { getData } from "../../../../actions/pages";
import { LoadingGif } from "../../../../assets/images";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const DecosCms = () => {
  const decores = useSelector((state) => state?.pages?.data?.decores);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getData("/decores"));
  }, [dispatch]);

  const [newDecore, setNewDecore] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDecore({ ...newDecore, [name]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      handleChange({
        target: {
          name: "decorephoto",
          value: file,
        },
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const PostData = async () => {
      try {
        const newPath = await uploadImage(
          "decorephoto",
          { decorephoto: newDecore?.decorephoto },
          "",
          "decores-images"
        );
        await apiClient().post(`/decores`, {
          photo: newPath ? newPath : "",
          titre: newDecore?.decoretitre,
          description: newDecore?.decoredescription,
          price: newDecore?.decoreprice,
        });
        toast.success('Décore ajouté avec succès', { autoClose: 600 });
        dispatch(getData("/decores"));
        setNewDecore({
          decoretitre: "",
          decoredescription: "",
          decoreprice: "",
          decorephoto: "",
        });
        setSelectedImage(null);
      } catch (err) {
        toast.error('Erreur lors de l\'ajout du décor', { autoClose: 600 });
        console.error(err);
      }
    };
    PostData();
  };

  return (
    <div className="m-4 p-6 bg-white border border-gray-200 rounded-lg shadow-lg space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-800">Décorations</h1>
        <button
          className="py-2 px-4 font-medium text-white bg-yellow-600 rounded-md hover:bg-yellow-900 transition duration-300"
          onClick={() => setIsFormVisible(!isFormVisible)}
        >
          {isFormVisible ? "Annuler" : "Ajouter"}
        </button>
      </div>
      <div className="flex items-center gap-4 overflow-x-auto overflow-y-hidden h-60 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        {decores ? (
          decores
            ?.sort((a, b) => b.id - a.id)
            .map((decor) => (
              <div
                key={decor?.id}
                className="w-60 h-60 min-w-60 min-h-60 max-h-60 max-w-60 rounded-lg relative group bg-gray-100 overflow-hidden shadow-md"
                id="card"
              >
                <img
                  src={BASEIMAGEURL + decor?.photo}
                  alt={`${decor?.decoretitre} decorphoto`}
                  className="object-cover w-full h-full"
                />
                <h3 className="absolute bottom-0 left-0 text-sm font-semibold text-white bg-gray-800 bg-opacity-70 w-full text-center py-1">
                  {decor?.titre}
                </h3>
                <button
                  onClick={() => navigate(`/gestion/decos-formules/decores/${decor?.id}`)}
                  className="absolute top-2 right-2 bg-white text-gray-800 hover:text-white hover:bg-gray-800 text-xl w-8 h-8 flex items-center justify-center rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                >
                  <i className="bi bi-pencil-square"></i>
                </button>
              </div>
            ))
        ) : (
          <div className="mx-auto flex items-center justify-center h-60">
            <img src={LoadingGif} alt="loading gif" width={40} height={40} />
          </div>
        )}
      </div>
      <form
        onSubmit={handleSubmit}
        className={`transition-all duration-500 ease-in-out ${isFormVisible ? "block" : "hidden"}`}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col gap-3">
            <label htmlFor="decoretitre" className="text-lg font-semibold text-gray-700">Titre</label>
            <input
              type="text"
              name="decoretitre"
              id="decoretitre"
              value={newDecore?.decoretitre}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              required
            />
          </div>
          <div className="flex flex-col gap-3">
            <label htmlFor="decoreprice" className="text-lg font-semibold text-gray-700">Prix</label>
            <input
              type="number"
              name="decoreprice"
              id="decoreprice"
              value={newDecore?.decoreprice}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              required
            />
          </div>
          <div className="flex flex-col gap-3">
            <label htmlFor="decoredescription" className="text-lg font-semibold text-gray-700">Description</label>
            <textarea
              name="decoredescription"
              id="decoredescription"
              value={newDecore?.decoredescription}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              required
              rows={3}
            ></textarea>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-6">
            <label
              htmlFor="decorephoto"
              className="relative cursor-pointer w-32 h-32 bg-gray-200 rounded-lg flex items-center justify-center overflow-hidden"
            >
              <input
                type="file"
                name="decorephoto"
                id="decorephoto"
                className="sr-only"
                onChange={handleImageChange}
                required
              />
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="Selected decor"
                  className="object-cover w-full h-full"
                />
              ) : (
                <i className="bi bi-image text-6xl text-gray-500"></i>
              )}
            </label>
            <button
              type="button"
              className="bg-gray-100 py-2 px-4 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-200 transition duration-300"
            >
              Choisir une photo
            </button>
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <AddButton />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default DecosCms;