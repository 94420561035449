import React from 'react';
import { AddButton } from '../../../..';

const Section2BoContact = ({ contactData, handleChange, valider }) => {

  return (
    <div className="bg-white p-3 rounded-lg">
        <h1 className='font-semibold font-poppins text-xl mb-3'>Section Contact Infos</h1>
        <form onSubmit={valider} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
            <div className="flex flex-col space-y-1">
                <label htmlFor="titreInfos">Grand titre</label>
                <input type="text" name="titreInfos" id="titreInfos" value={contactData?.titreInfos} onChange={handleChange} className='w-full p-2 border rounded-md outline-none focus:outline-none' style={{ border: '1px solid gray' }} />
            </div>
            <div className="flex flex-col space-y-1">
                <label htmlFor="tel">Téléphone</label>
                <input type="text" name="tel" id="tel" value={contactData?.tel} onChange={handleChange} className='w-full p-2 border rounded-md outline-none focus:outline-none' style={{ border: '1px solid gray' }} />
            </div>
            <div className="flex flex-col space-y-1">
                <label htmlFor="email">Email</label>
                <input type="email" name="email" id="email" value={contactData?.email} onChange={handleChange} className='w-full p-2 border rounded-md outline-none focus:outline-none' style={{ border: '1px solid gray' }} />
            </div>
            <div className="flex flex-col space-y-1">
                <label htmlFor="adresse">Adresse</label>
                <input type="text" name="adresse" id="adresse" value={contactData?.adresse} onChange={handleChange} className='w-full p-2 border rounded-md outline-none focus:outline-none' style={{ border: '1px solid gray' }} />
            </div>
            <div className="col-span-1 lg:col-span-2 flex items-center justify-end">
                <AddButton/>
            </div>
        </form>
    </div>
  )
}

export default Section2BoContact