import { Img9 } from "../../../assets/images";
import { Helmet } from "react-helmet";
import { ClientLoginForm } from "../../../components";

const LoginForm = () => {

  return (
    <div
      className="min-h-screen flex justify-center items-center relative"
      style={{
        backgroundImage: `url(${Img9})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Helmet>
        <title>Se connecter</title>
        <meta name="description" content="Connectez-vous à votre compte pour accéder à toutes les fonctionnalités de notre site." />
      </Helmet>
      <div className="absolute inset-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }} />
      <div className="w-11/12 sm:w-10/12 md:max-w-xl mx-auto border rounded-lg p-5 md:p-10 bg-white space-y-4 z-50">
        <h1 className="text-xl md:text-2xl font-semibold">
          Se Connecter
        </h1>
        <ClientLoginForm showLink/>
      </div>     
    </div>
  );
};

export default LoginForm;
