import React, { useEffect, useState } from "react";
import AddButton from "../pagescomponents/AddButton";
import { apiClient } from "../../../actions/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const BoHeaderSection2 = () => {
  const [link, setLink] = useState({name: "", link: ""});
  const [header, setHeader] = useState();
  const [refresh, setRefresh] = useState(false);
  const [mode, setMode] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient().get("/headers");
        setHeader(response.data);
      } catch (err) {
        console.error();
      }
    };
    fetchData();
  }, [refresh]);

  const handlleChange = (e) => {
    const { name, value } = e.target;
    setLink({ ...link, [name]: value });
  };

  const handleUpdate = (id) => {
    setMode(false);
    const editLink = header?.links?.find((link, index)=> index === id);
    setLink({...editLink, index: id});
  };

  const AjouterLink = (e) => {
    e.preventDefault();
    const data = {
      links: [...header.links, link]
    };
    const postData = async () => {
        try {
          await apiClient().post("/headers/1?_method=PUT", data);
          toast.success('Détails de header modifiés avec succès', { autoClose: 600 });
        } catch (err) {
          console.error();
          toast.error('Erreur lors de la modification des détails de header', { autoClose: 600 });
        }
    };
    postData();
    setRefresh(!refresh);
    setLink({ name: "", link: "" });
  };

  const ModifierLink = (e) => {
    e.preventDefault();
    console.log("link", link);
    const newLinks = header?.links?.filter((li, index) => index !== link?.index);
    const data = {
        links: [...newLinks, { name: link?.name, link: link?.link }]
    };
    const updateData = async () => {
        try {
          await apiClient().post("/headers/1?_method=PUT", data);
          toast.success('Détails de header modifiés avec succès', { autoClose: 600 });
        } catch (err) {
          console.error();
          toast.error('Erreur lors de la modification des détails de header', { autoClose: 600 });
        }
    };
    updateData();
    setMode(true);
    setRefresh(!refresh);
    setLink({ name: "", link: "" });
  };

  const ToggleActive = (index) => {
    const updatedLinks = header.links.map((link, i) => {
      if (i === index) {
        return { ...link, status: !link.status };
      }
      return link;
    });
  
    const data = { links: updatedLinks };
  
    const updateStatus = async () => {
      try {
        await apiClient().post("/headers/1?_method=PUT", data);
        toast.success('Statut du lien modifié avec succès', { autoClose: 600 });
        setRefresh(!refresh);
      } catch (err) {
        console.error(err);
        toast.error('Erreur lors de la modification du statut du lien', { autoClose: 600 });
      }
    };
  
    updateStatus();
  };

  return (
    <div className="space-y-4">
      {mode ? (
        <form
          onSubmit={AjouterLink}
          className="bg-white p-3 rounded-lg  grid grid-cols-1 lg:grid-cols-2 gap-3"
        >
          <h1 className="col-span-1 lg:col-span-2 font-semibold font-poppins text-xl mb-3">
            Ajouter un Lien
          </h1>
          <div className="flex flex-col">
            <label htmlFor="name">Titre</label>
            <input
              type="text"
              value={link?.name}
              required
              name="name"
              id="name"
              onChange={handlleChange}
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="link">Lien</label>
            <input
              type="text"
              value={link?.link}
              required
              name="link"
              id="link"
              onChange={handlleChange}
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="col-span-1 lg:col-span-2 flex items-center justify-end">
            <AddButton />
          </div>
        </form>
      ) : (
        <form
          onSubmit={ModifierLink}
          className="bg-white p-3 rounded-lg grid grid-cols-1 lg:grid-cols-2 gap-3"
        >
          <div className="flex items-center justify-between col-span-2">
            <h1 className="col-span-1 lg:col-span-2 font-semibold font-poppins text-xl mb-3">
              Modifier un Lien
            </h1>
            <button type="button" onClick={()=>{setMode(true); setLink({ name: "", link: "" })}} className="px-2 py-1 bg-gray-200 border rounded-lg">Annuler</button>
          </div>
          <div className="flex flex-col">
            <label htmlFor="name">Titre</label>
            <input
              type="text"
              value={link?.name}
              required
              name="name"
              id="name"
              onChange={handlleChange}
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="link">Lien</label>
            <input
              type="text"
              value={link?.link}
              required
              name="link"
              id="link"
              onChange={handlleChange}
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="col-span-1 lg:col-span-2 flex items-center justify-end">
            <AddButton />
          </div>
        </form>
      )}
      <div className="bg-white p-3 rounded-lg space-y-3">
        <h1 className="font-semibold font-poppins text-xl mb-3">Liens</h1>
        <div className="p-2 border rounded-lg">
          <table className="w-full">
            <thead>
              <tr className="text-lightgraybo border-b">
                <th className="text-start p-3">Titre</th>
                <th className="text-start p-3">Lien</th>
                <th className="text-start p-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {header && header.links.map((l, i) => (
                <tr key={i} className="border-b">
                  <td className="p-3 capitalize">{l.name}</td>
                  <td className="p-3">{l.link}</td>
                  <td className="p-3 flex items-center gap-2">
                    <button onClick={() => handleUpdate(i)}>
                      <i className="bi bi-pencil-fill text-medyellow py-1 px-2 border"></i>
                    </button>
                    <button onClick={() => ToggleActive(i)}>
                      {l?.status ? <i class="bi bi-eye-fill text-medyellow py-1 px-2 border"></i> : <i class="bi bi-eye-slash-fill text-medyellow py-1 px-2 border"></i> }
                      {/* <i class="bi bi-eye-slash text-medyellow py-1 px-2 border"></i>
                      <i class="bi bi-eye text-medyellow py-1 px-2 border"></i> */}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default BoHeaderSection2;
