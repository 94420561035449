import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReservationByDate } from "../../../../actions/calendar";
import DatePicker from "../../../reservationComponents/datepicker";
import BasicDateCalendar from "../../../reservationComponents/basicdatecalendar";
import { SET_PERIOD } from "../../../../constants/actions";
import { getData } from "../../../../actions/pages";
import DatePickerBackoffice from "../datepickerbackoffice";
import BasicDateCalendarbackoffice from "../basicdatecalendarbackoffice";
import { useNavigate } from "react-router-dom";

const CalendarComponent = () => {
  const [viewMode, setViewMode] = useState({
    timeStart: "08:30:00",
    timeEnd: "23:00:00",
  });
  const parametres = useSelector((state) => state?.pages?.data?.parametres);
  const reservations = useSelector((state) => state?.pages?.data?.reservations); // get all reservations
  const timeSlots = [];
  const heightCellul = 48;
  let currentTime = new Date(`2000-01-01T${viewMode?.timeStart}`);
  const endTimeDate = new Date(`2000-01-01T${viewMode?.timeEnd}`);
  const reservationDetails = useSelector((state) => state?.detailsreservation);
  const calendarData = useSelector(
    (state) => state?.calendar?.calendarData?.data
  );
  const spaces = useSelector((state) => state?.pages?.data?.espaceCards);
  const [selectedRoom, setSelectedRoom] = useState(spaces?.[0] || null);
  const dispatch = useDispatch();

  const [isPickerOpen, setPickerIsOpen] = useState(false);
  const calendarRef = useRef(null);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [currentReservation, setCurrentReservation] = useState(null);

  const navigate = useNavigate()

  const handleMouseOver = (reservation) => {
    setCurrentReservation(reservation);
    setTooltipVisible(true);
  };

  const handleMouseOut = () => {
    setTooltipVisible(false);
    setCurrentReservation(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setPickerIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(getData("/reservations"));
    dispatch(getData("/parametres"));
  }, [dispatch]);

  useEffect(() => {
    if (reservationDetails?.period) {
      setViewMode(
        reservationDetails?.period === "nuite"
          ? {
              timeStart: parametres?.nuite_start,
              timeEnd: parametres?.nuite_end,
            }
          : {
              timeStart: parametres?.journee_start,
              timeEnd: parametres?.journee_end,
            }
      );
    }
  }, [reservationDetails?.period, parametres]);

  useEffect(() => {
    if (spaces?.length > 0) {
      setSelectedRoom(spaces[0]);
    }
  }, [spaces]);

  useEffect(() => {
    dispatch(getReservationByDate(reservationDetails?.date));
  }, [reservationDetails?.date, dispatch]);

  while (currentTime <= endTimeDate) {
    timeSlots.push(currentTime.toTimeString().substring(0, 5));
    currentTime = new Date(currentTime.getTime() + 30 * 60000);
  }

  const getReservationStatus = (startTime, endTime, date) => {
    const now = new Date();
    const startDateTime = new Date(`${date}T${startTime}:00`);
    const endDateTime = new Date(`${date}T${endTime}:00`);

    if (now < startDateTime) {
      return { status: "En cours de traitement", style: "bg-blue-200" };
    } else if (now >= startDateTime && now <= endDateTime) {
      return { status: "En Cours", style: "bg-green-200" };
    } else {
      return { status: "Terminée", style: "bg-gray-300" };
    }
  };

  const findReservationById = (reservations, id) => {
    const spcres = reservations.find((reservation) => reservation.id === id);

    return (
      spcres?.id && (
        <div className="p-3 bg-white border border-gray-300 rounded-lg shadow-lg text-sm text-gray-700">
          <div className="mb-2">
            <span className="font-semibold">Nombre de personnes:</span>{" "}
            {spcres?.nbr_personne}
          </div>
          <div className="mb-2">
            <span className="font-semibold">Formule:</span>{" "}
            {spcres?.formules[0]?.titre}
          </div>
          <div>
            <span className="font-semibold">Décor:</span> {spcres?.decor?.titre}
          </div>
        </div>
      )
    );
  };

  const getBackgroundColorClass = (id) => {
    const spcres = reservations.find((reservation) => reservation.id === id);

    // reservation?.id ? reservationStatus?.style : 'bg-gray-100'
    switch (spcres?.payments[0]?.mode) {
      case "total":
        return "bg-green-500"; // Tailwind class for green background
      case "50off":
        return "bg-orange-500"; // Tailwind class for orange background
      default:
        return "bg-gray-100"; // Default background color
    }
  };

  const generateReservationCells = (spaceCard) => {
    const cells = [];
    let isReservation = true;
    const room = calendarData?.find((space) => space?.id === spaceCard?.id);

    for (let i = 0; i < timeSlots.length; i++) {
      const reservation = room?.schedule?.find(
        (res) => res.startTime === timeSlots[i]
      );
      const reservationStatus = getReservationStatus(
        reservation?.startTime,
        reservation?.endTime,
        reservation?.date
      );
      if (isReservation && i + 3 < timeSlots.length) {
        cells.push(
          <tr key={`reservation-${i}`} height={`${heightCellul * 4}px`}>
            <td
              onClick={() => navigate(`/gestion/reservations/${reservation?.id}`)}
              className={`reservation-cell border text-center cursor-pointer ${getBackgroundColorClass(
                reservation?.id
              )}`}
              style={{ width: "100px" }}
              onMouseOver={() => handleMouseOver(reservation)}
              onMouseOut={handleMouseOut}
            >
              {reservation?.id ? (
                <div>
                  {reservationStatus?.status}
                  {tooltipVisible &&
                    currentReservation?.id === reservation?.id && (
                      <div className="absolute z-10 bg-white border border-gray-300 rounded-lg shadow-lg p-3 text-sm text-gray-700">
                        {findReservationById(reservations, reservation?.id)}
                      </div>
                    )}
                </div>
              ) : (
                "Libre"
              )}
            </td>
          </tr>
        );
        i += 3;
      } else if (!isReservation) {
        cells.push(
          <tr key={`cleaning-${i}`} height={`${heightCellul}px`}>
            <td
              className="cleaning-cell bg-white"
              style={{ width: "100px" }}
            ></td>
          </tr>
        );
      }
      isReservation = !isReservation;
    }

    return cells;
  };

  const handleChangeRoomSelected = (e) => {
    const room = spaces?.find((space) => space.id === parseInt(e.target.value));
    setSelectedRoom(room);
  };

  return (
    <div className="m-3 p-3 rounded-lg space-y-4">
      <div className="space-y-4">
        {/* nuité journe */}
        <div className="flex rounded-lg border make-shadow mx-auto my-6 w-fit overflow-hidden">
          <div
            onClick={() => dispatch({ type: SET_PERIOD, payload: "journee" })}
            className={`flex justify-center items-center gap-3 py-4 px-7 text-xl md:text-2xl w-1/2 cursor-pointer ${
              reservationDetails?.period === "journee"
                ? "bg-gray-700 text-golddo"
                : "bg-white"
            }`}
          >
            <span className="text-base min-w-fit font-playfairdisplay">
              La Journée
            </span>
            <i className="bi bi-brightness-high-fill"></i>
          </div>
          <div
            onClick={() => dispatch({ type: SET_PERIOD, payload: "nuite" })}
            className={`flex justify-center items-center gap-3 py-4 px-7 text-lg md:text-xl w-1/2 cursor-pointer ${
              reservationDetails?.period === "nuite"
                ? "bg-gray-700 text-golddo"
                : "bg-white"
            }`}
          >
            <span className="text-base min-w-fit font-playfairdisplay">
              La nuité
            </span>
            <i className="bi bi-moon-stars-fill"></i>
          </div>
        </div>

        {/* date picker */}
        <div className="flex flex-col lg:flex-row gap-4">
        <div className="flex-1">
          <DatePickerBackoffice />
        </div>
        <div className="relative flex-1">
          <button
            className="w-full text-center h-12 bg-gray-100 border rounded-lg shadow-sm text-gray-600 font-medium"
            onClick={() => setPickerIsOpen(!isPickerOpen)}
          >
            Choisir une date directement
          </button>
          <div
            className={`absolute z-10 w-full mt-2 bg-white border rounded-lg shadow-lg transition-opacity duration-300 ${
              isPickerOpen ? "opacity-100 visible" : "opacity-0 invisible"
            }`}
          >
            <BasicDateCalendarbackoffice />
          </div>
        </div>
      </div>

        {/* Large screens */}
        <div className="hidden lg:block">
          <table
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            className="tableau"
          >
            <thead>
              <tr height="60" className="text-center relative">
                <td></td>
                {spaces?.map((room) => (
                  <td key={room.id} className="nom_salle font_agenda_strong">
                    {room.titre}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="colonne_heure text-center px-1">
                  <table cellPadding="0" cellSpacing="0">
                    <tbody>
                      {timeSlots.map((time, index) => {
                        const isHour = time.endsWith("00");
                        return (
                          <tr key={index} height={`${heightCellul}px`}>
                            <td
                              className={isHour ? "heure strong" : "demi_heure"}
                            >
                              {time}
                            </td>
                            <td
                              className={
                                isHour
                                  ? "border-t border-black w-8 min-w-5 h-1"
                                  : "border-t border-gray-300"
                              }
                            ></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </td>
                {spaces?.map((spaceCard) => (
                  <td key={spaceCard.id} className="colonne colonne_salle_">
                    <table cellPadding="0" cellSpacing="0" width="100%">
                      <tbody>{generateReservationCells(spaceCard)}</tbody>
                    </table>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        {/* Small screens */}
        <div className="block lg:hidden">
          <div className="mb-3">
            <select
              onChange={handleChangeRoomSelected}
              className="w-full p-2 cursor-pointer border-2 border-black rounded-md"
            >
              {spaces?.map((space) => (
                <option key={space.id} value={space.id} className="py-2">
                  {space.titre}
                </option>
              ))}
            </select>
          </div>
          <table
            width="90%"
            cellPadding="0"
            cellSpacing="0"
            className="tableau"
          >
            <tbody>
              <tr>
                <td className="colonne_heure text-center px-1">
                  <table cellPadding="0" cellSpacing="0">
                    <tbody>
                      {timeSlots.map((time, index) => {
                        const isHour = time.endsWith("00");
                        return (
                          <tr key={index} height={`${heightCellul}px`}>
                            <td
                              className={isHour ? "heure strong" : "demi_heure"}
                            >
                              {time}
                            </td>
                            <td
                              className={
                                isHour
                                  ? "border-t border-black w-8 min-w-5 h-1"
                                  : "border-t border-gray-300"
                              }
                            ></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </td>
                <td className="colonne colonne_salle_">
                  <table cellPadding="0" cellSpacing="0" width="100%">
                    <tbody>
                      <tr>
                        <td className="colonne colonne_salle_">
                          <table cellPadding="0" cellSpacing="0" width="100%">
                            <tbody>
                              {generateReservationCells(selectedRoom)}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CalendarComponent;
