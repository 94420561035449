
const Acces = ({ data }) => {
  return (
    <section id="acces">
      <div className="bg-yelloworange h-auto font-playfairdisplay">
        {/* desktop */}
        <div className="hidden md:flex flex-col justify-center items-center text-black w-10/12 lg:w-9/12 lg:max-w-9/12 mx-auto h-[800px] py-20">
          <div className="md:text-2xl lg:text-3xl mb-6 font-bold capitalize">{data?.titreAcces}</div>
          <div className="grid grid-cols-3 h-full">
            <div className="flex flex-col justify-between">
              <div className="space-y-4">
                <h1 className="md:text-2xl lg:text-3xl font-bold capitalize">
                  {data?.titre1}
                </h1>
                <p className="md:text-base lg:text-lg text-justify" dangerouslySetInnerHTML={{ __html: data?.description1 }}/>
              </div>
              <div className="space-y-4">
                <h1 className="md:text-2xl lg:text-3xl font-bold capitalize">
                  {data?.titre2}
                </h1>
                <p className="md:text-base lg:text-lg text-justify" dangerouslySetInnerHTML={{ __html: data?.description2 }}/>
              </div>
            </div>
            <div className="flex flex-col min-h-full h-full items-center pt-3 pb-20">
              <div className="min-w-5 min-h-5 rounded-full bg-black"></div>
              <div className="h-full" style={{ border: '1px solid black' }}></div>
              <div className="min-w-5 min-h-5 rounded-full bg-black"></div>
              <div className="h-full" style={{ border: '1px solid black' }}></div>
              <div className="min-w-5 min-h-5 rounded-full bg-black"></div>
            </div>
            <div className="space-y-4 flex flex-col justify-center items-center">
              <div className="">
                <h1 className="md:text-2xl lg:text-3xl font-bold capitalize">
                  {data?.titre3}
                </h1>
                <p className="md:text-base lg:text-lg text-justify" dangerouslySetInnerHTML={{ __html: data?.description3 }}/>
              </div>
            </div>
          </div>
        </div>

        {/* mobile */}
        <div className="block md:hidden w-11/12 mx-auto py-10 space-y-9">
          <h1 className="text-3xl text-black text-center font-bold capitalize">{data?.titreAcces}</h1>
          <div className="flex gap-x-6">

            <div className="flex flex-col items-center px-7 pb-16">
              <div className="min-w-5 min-h-5 rounded-full bg-black mt-2"></div>
              <div className="h-full" style={{ border: '1px solid black' }}></div>
              <div className="min-w-5 min-h-5 rounded-full bg-black"></div>
              <div className="h-full" style={{ border: '1px solid black' }}></div>
              <div className="min-w-5 min-h-5 rounded-full bg-black"></div>
            </div>

            <div className="text-black min-h-full">
              <div className="flex flex-col justify-between gap-8 min-h-full">
                <div className="space-y-4">
                  <h1 className="text-xl sm:text-2xl font-bold capitalize">
                    {data?.titre1}
                  </h1>
                  <p className="text-sm sm:text-base text-justify" dangerouslySetInnerHTML={{ __html: data?.description1 }}/>
                </div>
                <div className="">
                  <h1 className="text-xl sm:text-2xl font-bold capitalize">
                    {data?.titre2}
                  </h1>
                  <p className="text-sm sm:text-base font-medium text-justify" dangerouslySetInnerHTML={{ __html: data?.description2 }}/>
                </div>
                <div className="space-y-4">
                  <h1 className="text-xl sm:text-2xl font-bold capitalize">
                    {data?.titre3}
                  </h1>
                  <p className="text-sm sm:text-base text-justify" dangerouslySetInnerHTML={{ __html: data?.description3 }}/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Acces