import React from 'react'
import { BasicSEO, BoLayout, DecosCms, FormulesCms } from '../../../components'
import { ToastContainer } from 'react-toastify';

const DecosFormules = () => {
  return (
    <BoLayout>
      <BasicSEO/>
      <DecosCms/>
      <FormulesCms/>
      <ToastContainer/>
    </BoLayout>
  )
}

export default DecosFormules