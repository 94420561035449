import { STOTE_RESERVATIONS_BY_DATE, STOTE_RESERVATIONS_BY_SEMAINE } from "../constants/actions";

const INITIAL_STATE = {
  calendarData: {},
  semaineData: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STOTE_RESERVATIONS_BY_DATE:
      return { ...state, calendarData: action.payload };
    case STOTE_RESERVATIONS_BY_SEMAINE:
      return { ...state, semaineData: action.payload };
    default:
      return state;
  }
}
