import { useEffect, useState } from 'react';
import { BoLayout } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from '../../../../actions/pages';
import { useNavigate, useParams } from 'react-router-dom';

const ConatctMailDetails = () => {
    const { id } = useParams();
    const [mail, setMail] = useState();
    const contact_mails = useSelector(state => state?.pages?.data?.contact_mails);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getData('/contact-mails'));
    }, [dispatch]);

    useEffect(() => {
        if (contact_mails?.length > 0) {
            const mail = contact_mails?.find(m => m?.id === parseInt(id));
            setMail(mail);
        }
    }, [contact_mails, id]);

    return (
        <BoLayout>
            <div className="p-6 bg-white-100 min-h-screen">
                <div className="my-4">
                    <button
                        onClick={() => navigate('/gestion/contact-mails')}
                        className="font-poppins flex items-center gap-3 text-gray-800 hover:text-gray-600"
                    >
                        <i className="bi bi-arrow-left text-2xl"></i>
                        <span className='text-lg font-medium'>Retour</span>
                    </button>
                </div>
                <div className="border border-gray-300 bg-white rounded-lg shadow-lg p-6">
                    <table className="w-full text-gray-700">
                        <tbody className='text-base'>
                            <tr className='border-b border-gray-200'>
                                <td className='text-start font-medium p-3'>Nom</td>
                                <td className='text-start p-3 font-light capitalize'>{mail?.nom}</td>
                            </tr>
                            <tr className='border-b border-gray-200'>
                                <td className='text-start font-medium p-3'>Prénom</td>
                                <td className='text-start p-3 font-light capitalize'>{mail?.prenom}</td>
                            </tr>
                            <tr className='border-b border-gray-200'>
                                <td className='text-start font-medium p-3'>Email</td>
                                <td className='text-start p-3 font-light'>{mail?.email}</td>
                            </tr>
                            <tr className='border-b border-gray-200'>
                                <td className='text-start font-medium p-3'>Téléphone</td>
                                <td className='text-start p-3 font-light'>{mail?.phone}</td>
                            </tr>
                            <tr>
                                <td className='text-start font-medium p-3'>Message</td>
                                <td className='p-3 text-justify font-light'>{mail?.message}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </BoLayout>
    );
};

export default ConatctMailDetails;