import React from 'react';
import { Helmet } from 'react-helmet';
import { BannerSectionP9, Footer, PolitiqueSection2 } from '../../components';
import { useSelector } from 'react-redux';

const Politique = () => {
  const data = useSelector(state => state?.pages?.data?.politique);

  return (
    <>
      <Helmet>
        <title>{data?.titre}</title>
        <meta name="description" content="Consultez la politique de confidentialité de FIVE SPA PRIVATIVE. Découvrez comment nous protégeons vos informations personnelles et les mesures que nous prenons pour garantir votre vie privée." />
      </Helmet>
      <div className="space-y-20">
        <BannerSectionP9 data={data} />
        <PolitiqueSection2 data={data} />
        <Footer />
      </div>
    </>
  );
}

export default Politique;
