import { BannerImg } from '../../assets/images'
import { BASEIMAGEURL } from '../../constants/actions';
import Question from './Question';


const QuestionReponses = ({ espace }) => {
    const questions = [
        {question: espace?.question1, reponse: espace?.reponse1},
        {question: espace?.question2, reponse: espace?.reponse2},
        {question: espace?.question3, reponse: espace?.reponse3},
        {question: espace?.question4, reponse: espace?.reponse4},
        {question: espace?.question5, reponse: espace?.reponse5},
    ];
    
  return (
    <section className='w-full bg-gray-50 py-5'>
        <div className="w-10/12 mx-auto grid grid-col-1 lg:grid-cols-2 gap-5">
            <div className="space-y-5">
                <h4 className='text-violet-500 uppercase' >QUESTION RÉPONSE</h4>
                <h1 className="text-2xl lg:text-4xl text-gray-800 capitalize" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{espace?.titreQuestions}</h1>
                <img src={BASEIMAGEURL + espace?.imageQuestions} alt="article img" className='rounded-3xl' />
            </div>
            <div className="flex justify-center items-center">
                <div className="flex flex-col gap-y-5">
                    <p className='text-justify font-serif text-gray-500'>{espace?.descriptionQuestions}</p>
                    
                    <div className="space-y-2">
                        {questions?.map((q, i)=> q?.question && <Question key={i} question={q.question} answer={q.reponse} />)}
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default QuestionReponses