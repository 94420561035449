import React from "react";
import { Helmet } from "react-helmet";
import { BannerSectionP11, Footer, RGPDSection2 } from "../../components";
import { useSelector } from "react-redux";

const RGPD = () => {
  const data = useSelector(state => state?.pages?.data?.rgpd);

  return (
    <>
      <Helmet>
        <title>{data?.titre}</title>
        <meta name="description" content="Découvrez la politique de confidentialité et les mesures de conformité au RGPD mises en place pour protéger vos données personnelles sur notre site." />
      </Helmet>
      <div className="space-y-20">
        <BannerSectionP11 data={data} />
        <RGPDSection2 data={data} />
        <Footer />
      </div>
    </>
  );
};

export default RGPD;
