import React from 'react';
import AddButton from '../../../pagescomponents/AddButton';

const CellsDurations = ({ handleSubmit, parametresData, handleChange }) => {
  return (
    <form onSubmit={handleSubmit} className="m-4 p-6 border border-gray-200 rounded-lg shadow-md bg-white space-y-4">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Durée des Entretien et Réservations</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col gap-y-2">
          <label htmlFor="entretien_duration" className="text-lg font-medium text-gray-700">La durée d'entretien en minutes :</label>
          <input
            type="number"
            id="entretien_duration"
            name="entretien_duration"
            value={parametresData?.entretien_duration}
            onChange={handleChange}
            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            placeholder="En minutes"
            required
          />
        </div>
        <div className="flex flex-col gap-y-2">
          <label htmlFor="reservation_duration" className="text-lg font-medium text-gray-700">La durée de chaque réservation en heures :</label>
          <input
            type="number"
            id="reservation_duration"
            name="reservation_duration"
            value={parametresData?.reservation_duration}
            onChange={handleChange}
            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            placeholder="En heures"
            required
          />
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <AddButton />
      </div>
    </form>
  );
};

export default CellsDurations;