import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './style.scss'
import { apiClient } from '../../actions/api';
import { BASEIMAGEURL } from '../../constants/actions';


const NosEspacesSection1 = ({ espaceData }) => {
  
  const navigate = useNavigate();
  
  const [data, setData] = useState({});
  
  useEffect(()=>{
    const fetchData = async() =>{
      const response = await apiClient().get('/espace-cards');
      setData(response.data);
    }
    fetchData();
  },[]);


  return (
    <div className="p-4 my-16 w-11/12 mx-auto">
      <div className="">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2 lg:gap-4">
          {data && [data[0], data[1], data[2]].map((space, index) => (
            <div key={index} onClick={()=>navigate(`/espaces/${space.id}`)} className={`cursor-pointer image-containerES relative rounded-lg overflow-hidden ${index === 2 ? 'col-span-2 md:col-span-1 lg:col-span-1' : ''} group`} >
              <img src={BASEIMAGEURL + space?.image} alt={`${space?.titre} img`} className='w-full h-64 object-cover transition-transform duration-300 ease-in-out group-hover:scale-110' />
              <div className="titreES absolute bottom-0 left-3 text-white font-bold p-2">{space?.titre}</div>
              <div className="descriptionES text-sm text-justify p-4" dangerouslySetInnerHTML={{ __html: space?.description }}/>
            </div>
          ))}
        </div>
        <div className="flex gap-2 lg:gap-4 mt-2 lg:mt-4">
          {data && [data[3], data[4]].map((space, index) => (
            <div
              onClick={()=>navigate(`/espace/${space.id}`)}
              key={index} 
              className={`
                image-containerES relative rounded-lg overflow-hidden group cursor-pointer
                ${index === 0 ? 'basis-1/2 lg:basis-7/12' : ''}
                ${index === 1 ? 'basis-1/2 lg:w-5/12' : ''}
              `}
            >
                <img 
                  src={BASEIMAGEURL + space?.image} 
                  className='
                    w-full h-64 object-cover 
                    transition-transform duration-300 ease-in-out 
                    group-hover:scale-110
                  ' 
                  alt={`${space?.titre} img`} 
                />
                <div className="titreES absolute bottom-0 left-3 text-white font-bold p-2">{space?.titre}</div>
                <div className="descriptionES text-sm text-justify p-4" dangerouslySetInnerHTML={{ __html: space?.description }}/>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 text-center">
        <button className="button primary min-w-60 capitalize" style={{ fontFamily: "verdana"}} onClick={()=>navigate(espaceData?.lienButton1)}>
          {espaceData?.button1}
        </button>
      </div>
    </div>
  );
}

export default NosEspacesSection1