import { useState, useEffect } from "react";
import './style.scss';
import { Link, useNavigate } from "react-router-dom";
import { apiClient } from "../../actions/api";
import { BASEIMAGEURL } from "../../constants/actions";

const SpaceCard = ({ id, imageSrc, title }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/espaces/${id}`)}
      className="space-card hover:text-gray-700 shadow-md group overflow-hidden cursor-pointer"
    >
      <div className="overflow-hidden">
        <img
          src={imageSrc}
          alt={`img of ${title}`}
          className="space-image w-full h-auto transition-transform duration-300 ease-in-out group-hover:scale-110"
        />
      </div>
      <h3 className="text-lg md:text-2xl lg:text-3xl my-4">{title}</h3>
    </div>
  );
};


function SpaceSectionP1({ data }) {
  const [spaces, setSpaces] = useState([]);

  useEffect(()=>{
    const fetchData = async() =>{
      const response = await apiClient().get('/espace-cards');
      setSpaces(response.data);
    }
    fetchData();
  },[]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(3);

  useEffect(() => {
    const updateItemsToShow = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setItemsToShow(1);
      } else if (width < 991) {
        setItemsToShow(2);
      } else {
        setItemsToShow(3);
      }
    };

    window.addEventListener('resize', updateItemsToShow);
    updateItemsToShow();

    return () => window.removeEventListener('resize', updateItemsToShow);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % spaces?.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + spaces?.length) % spaces?.length);
  };

  return (
    <section className="text-center">
      <h2 className="text-4xl lg:text-5xl text-gray-900 mb-5 capitalize" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{data?.titreEspaces}</h2>
      <div className="relative w-8/12 sm:w-8/12 md:w-10/12 lg:w-10/12 mx-auto">
        <button className="absolute top-1/2 -left-12 md:-left-12 transform -translate-y-1/2 z-10" onClick={prevSlide}><i className="bi bi-arrow-left-circle text-3xl text-darckyellow"></i></button>
        <div className="space-grid">
          {spaces && Array.from({ length: itemsToShow }, (_, offset) => {
            const index = (currentIndex + offset) % 5;
            return (
              <SpaceCard
                key={index}
                imageSrc={BASEIMAGEURL + spaces[index]?.image}
                title={spaces[index]?.titre}
                id={spaces[index]?.id}
              />
            );
          })}
        </div>
        <button className="absolute top-1/2 -right-12 md:-right-12 transform -translate-y-1/2 z-10"  onClick={nextSlide}><i className="bi bi-arrow-right-circle text-3xl text-darckyellow"></i></button>
      </div>
      <Link to={data?.lienButtonEspaces} className="button primary mt-5 flex items-center justify-center mx-auto gap-x-2 hover:text-white min-w-fit lg:w-fit w-52">
        <span className="uppercase">{data?.buttonEspaces}</span><i className="bi bi-arrow-right text-lg"></i>
      </Link>
    </section>
  );
}

export default SpaceSectionP1;
