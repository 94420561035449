import { useEffect, useState } from "react";
import { BasicSEO, BoLayout, Section1Blog } from "../../../../components";
import { apiClient } from "../../../../actions/api";

const itemsPerPage = 7;

const BoBlog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [blog, setBlog] = useState([]);
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [plusRecent, setPlusRecent] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchBlog = async () => {
      const response = await apiClient().get('/blogCards');
      setBlog(response.data);
      setFilteredBlog(response.data);
    };
    fetchBlog();
  }, [refresh]);

  useEffect(() => {
    if (search.trim() === "") {
      setFilteredBlog(blog);
      setCurrentPage(1);
    } else {
      const filtered = blog.filter(item =>
        item.title.toLowerCase().includes(search.toLowerCase()) ||
        item.author.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredBlog(filtered);
      setCurrentPage(1);
    }
  }, [search, blog]);

  const totalPages = Math.ceil(filteredBlog.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const sortedBlogs = [...filteredBlog].sort((a, b) => {
    return parseInt(plusRecent) === 2
      ? new Date(b.date) - new Date(a.date)
      : new Date(a.date) - new Date(b.date);
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleBlogs = sortedBlogs.slice(startIndex, startIndex + itemsPerPage);

  return (
    <BoLayout>
      <BasicSEO />
      <div className="bg-white-50 p-3">
        <div className="bg-white p-3 rounded-lg space-y-8">
          <Section1Blog
            plusRecent={plusRecent}
            setPlusRecent={setPlusRecent}
            visibleBlogs={visibleBlogs}
            setRefresh={setRefresh}
            refresh={refresh}
            search={search} setSearch={setSearch}
          />

          {/* pagination */}
          <div className="flex items-center justify-between text-xs sm:text-sm md:text-base">
            <div className="">
              <p className="text-sm font-medium text-extralightgray">
                Affichage des données {1} à {itemsPerPage} sur {blog.length}{" "}
                entrées
              </p>
            </div>
            <nav className="flex items-center space-x-1 md:space-x-2 text-xs">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-2 py-1 rounded-md bg-gray-200 text-gray-700 disabled:opacity-50"
              >
                <i className="bi bi-chevron-left"></i>
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-2 py-1 rounded-md ${
                    currentPage === index + 1
                      ? "bg-medyellow text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-2 py-1 rounded-md bg-gray-200 text-gray-700 disabled:opacity-50"
              >
                <i className="bi bi-chevron-right"></i>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </BoLayout>
  );
};

export default BoBlog;
