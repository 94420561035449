import { useNavigate } from "react-router-dom";
import { Img9 } from "../../../assets/images";
import { Helmet } from "react-helmet";
import CleintRegisterForm from "../../../components/clientRegisterForm";

const RegisterForm = () => {
  const navigate = useNavigate();

  return (
    <div
      className="min-h-screen flex justify-center items-center py-10 relative h-full"
      style={{
        backgroundImage: `url(${Img9})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Helmet>
        <title>Créer un compte</title>
        <meta name="description" content="Inscrivez-vous pour créer un compte et accéder à toutes les fonctionnalités de notre site." />
      </Helmet>
      <div className="absolute inset-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }} />
      <div className="w-11/12 sm:w-10/12 md:max-w-3xl mx-auto bg-white border rounded-2xl p-5 md:p-10 space-y-3 z-50">
        <h1 className="text-xl md:text-2xl font-semibold">
          Créer un compte
        </h1>
        <CleintRegisterForm showLink/>
      </div>
    </div>
  );
};

export default RegisterForm;
