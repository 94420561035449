import React, { useState } from 'react';
import './style.scss';
import { apiClient } from '../../actions/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingGif } from '../../assets/images';

const ContactForm = ({ data }) => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    phone: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    apiClient()
      .post('/contact-mails', formData)
      .then(response => {
        toast.success('L\'email a été envoyé avec succès');
        setFormData({
          nom: '',
          prenom: '',
          email: '',
          phone: '',
          message: '',
        });
        setLoading(false);
      })
      .catch(error => {
        toast.error('Une erreur est survenue lors de l\'envoi de l\'email');
      });
  };

  return (
    <section>
      <div className="grid grid-cols-1 lg:grid-cols-2 my-12 w-10/12 mx-auto border-2 border-black rounded">
        <div className="bg-contactform p-12">
          <div className="text-white space-y-5 lg:space-y-11">
            <h1 className="text-xl md:text-2xl lg:text-3xl text-white font-bold font-playfairdisplay capitalize">{data?.titreInfos}</h1>
            <div className="flex items-center gap-x-8 font-light">
              <span>
                <i className="bi bi-telephone-fill text-lg"></i>
              </span>
              <p className='text-sm'>{data?.tel}</p>
            </div>
            <div className="flex items-center gap-x-8 font-light">
              <span>
                <i className="bi bi-envelope-fill text-lg"></i>
              </span>
              <p className='text-sm'>{data?.email}</p>
            </div>
            <div className="flex items-center gap-x-8 font-light">
              <span>
                <i className="bi bi-geo-alt-fill text-lg"></i>
              </span>
              <p className='text-sm'>{data?.adresse}</p>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className='space-y-7 py-12 px-4'>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-6">
            <div className="flex flex-col">
              <label htmlFor="nom" className='font-serif'>Votre Nom</label>
              <input 
                type="text" 
                name='nom' 
                value={formData.nom}
                onChange={handleChange}
                required 
                className='border-b border-black focus:outline-0' 
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="prenom" className='font-serif'>Votre Prénom</label>
              <input 
                type="text" 
                name='prenom' 
                value={formData.prenom}
                onChange={handleChange}
                required 
                className='border-b border-black focus:outline-0' 
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="email" className='font-serif'>Email</label>
              <input 
                type="email" 
                name='email' 
                value={formData.email}
                onChange={handleChange}
                required 
                className='border-b border-black focus:outline-0' 
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="phone" className='font-serif'>Téléphone</label>
              <input 
                type="text" 
                name='phone' 
                value={formData.phone}
                onChange={handleChange}
                required 
                className='border-b border-black focus:outline-0' 
              />
            </div>
            <div className="flex flex-col col-span-1 sm:col-span-2">
              <label htmlFor="message" className='font-serif'>Message</label>
              <textarea 
                name="message" 
                value={formData.message}
                onChange={handleChange}
                required 
                className='p-3 focus:outline-0 w-full' 
                style={{ border: "1px solid black" }} 
                placeholder='Laissez un message' 
                rows={6}
              ></textarea>
            </div>
          </div>
          <div className="flex items-center w-fit min-w-28 min-h-12 max-h-12 mx-auto justify-center rounded shadow-sm cursor-pointer bg-black text-white">
            {loading ? (
              <img src={LoadingGif} alt='loading gif' width={20} height={20} />
            ) : (
              <input 
                type='submit' 
                value={"Envoyer"}
                className='font-serif text-lg cursor-pointer'
              />
            )}
          </div>
        </form>
      </div>
      <ToastContainer />
    </section>
  );
}

export default ContactForm;
