import {
  CLEAR_RESERVATION_DATA,
  REMOVE_DECOR,
  REMOVE_FORMULE,
  SET_DATE,
  SET_DECOR,
  SET_FORMULE,
  SET_NBR_PERSONNE,
  SET_PERIOD,
  SET_PROMOTION_CODE,
  SET_RESERVATION,
  CLIENT_NAME,
  STORE_GUEST,
  SET_PRICE_NBR_PERSONNE
} from "../constants/actions";

const initialState = {
  isCompleted: false,
  period: "journee",
  date: new Date().toISOString().split('T')[0],
  nbrpersonne: 1,
  price_nbr_personne: 0,
  decor: null,
  formules: [],
  reservation: null,
  promotionCode: null,
  promotionCodeActive: false,
  client_name: "",
  guest: null
};


const isCompleted = (state) => {
  return (
    state.reservation !== null
  );
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PERIOD:
      return {
        ...state,
        period: action.payload,
      };
    case SET_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case SET_NBR_PERSONNE:
      return {
        ...state,
        nbrpersonne: action.payload,
        isCompleted: isCompleted({ ...state, nbrpersonne: action.payload }),
      };
    case SET_PRICE_NBR_PERSONNE:
      return {
        ...state,
        price_nbr_personne: action.payload,
        isCompleted: isCompleted({ ...state, price_nbr_personne: action.payload }),
      };
    case SET_DECOR:
      return {
        ...state,
        decor: action.payload,
        isCompleted: isCompleted({ ...state, decor: action.payload }),
      };
    case SET_FORMULE:
      return {
        ...state,
        formules: [...state.formules, action.payload],
        isCompleted: isCompleted({ ...state, formules: [...state.formules, action.payload] }),
      };
    case REMOVE_FORMULE:
      const filtredFormules = state.formules.filter(formule => formule.id !== action.payload);
      return {
        ...state,
        formules: filtredFormules,
        isCompleted: isCompleted({ ...state, formules: filtredFormules }),
      };
    case SET_RESERVATION:
      return {
        ...state,
        reservation: action.payload,
        isCompleted: isCompleted({ ...state, reservation: action.payload }),
        promotionCodeActive: false,
        promotionCode: null
      };
    case SET_PROMOTION_CODE:
      const decision = action?.payload !== null && true
      return {
        ...state,
        promotionCode: action.payload,
        promotionCodeActive: decision
      };
    // case CLEAR_RESERVATION_DATA:
    //   return {
    //     ...state,
    //     isCompleted: false,
    //     period: "journee",
    //     date: null,
    //     nbrpersonne: 1,
    //     price_nbr_personne: 0,
    //     decor: null,
    //     formules: [],
    //     reservation: null,
    //     promotionCode: null,
    //   };
      case CLEAR_RESERVATION_DATA:
      return initialState;
    case CLIENT_NAME:
      return {
        ...state,
        client_name: action.payload
      };
    case STORE_GUEST:
      return {
        ...state,
        guest: action.payload
      };
    default:
      return state;
  }
};
