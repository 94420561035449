import React, { useState } from 'react';
import { BASEIMAGEURL } from '../../constants/actions';
import './style.scss';

const ImagePopup = ({ image, onClose, onPrev, onNext }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 50, y: 50 });
  const imageRef = React.useRef(null);

  const handleImageClick = (e) => {
    e.stopPropagation();
    if (isZoomed) {
      setIsZoomed(false);
      setZoomLevel(1);
      setPosition({ x: 50, y: 50 });
    } else {
      setIsZoomed(true);
      setZoomLevel(2.5);
      if (imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setPosition({ x, y });
      }
    }
  };

  const handleMouseMove = (e) => {
    if (!isZoomed || !imageRef.current) return;

    const rect = imageRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    setPosition({ x, y });
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-85 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <button
        className="absolute top-7 right-7 text-white text-2xl hover:text-golddo z-20"
        onClick={onClose}
      >
        <i className="bi bi-x-lg"></i>
      </button>
      <div 
        className="relative w-full h-full"
        onClick={(e) => e.stopPropagation()}
        onMouseMove={handleMouseMove}
      >
        <img
          ref={imageRef}
          src={image}
          alt="Popup"
          className="absolute transition-transform duration-200 ease-in-out rounded-2xl w-full max-w-2xl mx-auto"
          style={{
            cursor: isZoomed ? 'zoom-out' : 'zoom-in',
            transformOrigin: `${position.x}% ${position.y}%`,
            height: 'auto',
            objectFit: 'contain',
            left: '50%',
            top: '50%',
            transform: `translate(-50%, -50%) scale(${zoomLevel})`,
          }}
          onClick={handleImageClick}
        />
        <button
          className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white text-4xl hover:text-golddo z-20"
          onClick={(e) => { e.stopPropagation(); onPrev(); }}
        >
          <i className="bi bi-chevron-left"></i>
        </button>
        <button
          className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white text-4xl hover:text-golddo z-20"
          onClick={(e) => { e.stopPropagation(); onNext(); }}
        >
          <i className="bi bi-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

const PrectationDeatailsSection3 = ({ prestation }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    BASEIMAGEURL + prestation?.image1Detail2,
    BASEIMAGEURL + prestation?.image2Detail2,
    BASEIMAGEURL + prestation?.image3Detail2,
    BASEIMAGEURL + prestation?.image4Detail2
  ].filter(Boolean);

  const openPopup = (index) => {
    setSelectedImage(index);
  };

  const closePopup = () => {
    setSelectedImage(null);
  };

  const nextPopupImage = () => {
    setSelectedImage((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevPopupImage = () => {
    setSelectedImage((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <section>
      <div className="w-10/12 h-auto mx-auto rounded-3xl bg-gray-100 grid grid-cols-1 lg:grid-cols-2 overflow-hidden">
        <div className="hidden lg:grid grid-cols-2 overflow-hidden">
          {[prestation?.image1Detail2, prestation?.image2Detail2, prestation?.image3Detail2, prestation?.image4Detail2].map((image, index) => (
            <div key={index} className="">
              <img
                src={BASEIMAGEURL + image}
                alt='img'
                className='w-full h-52 overflow-hidden object-cover cursor-pointer'
                onClick={() => openPopup(index)}
              />
            </div>
          ))}
        </div>

        <div className="p-5 space-y-5 flex flex-col justify-center">
          <h1 className="font-serif text-3xl lg:text-4xl text-gray-900" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>
            {prestation?.titreDetail2}
          </h1>
          <p className='font-serif text-base lg:text-xl text-justify' dangerouslySetInnerHTML={{ __html: prestation?.descriptionDetail2 }} />
        </div>

        <div className="grid lg:hidden grid-cols-2 overflow-hidden">
          {[prestation?.image1Detail2, prestation?.image2Detail2, prestation?.image3Detail2, prestation?.image4Detail2].map((image, index) => (
            <div key={index} className="">
              <img
                src={BASEIMAGEURL + image}
                alt='img'
                className='w-full h-52 overflow-hidden object-cover cursor-pointer'
                onClick={() => openPopup(index)}
              />
            </div>
          ))}
        </div>
      </div>

      {selectedImage !== null && (
        <ImagePopup
          image={images[selectedImage]}
          onClose={closePopup}
          onPrev={prevPopupImage}
          onNext={nextPopupImage}
        />
      )}
    </section>
  );
};

export default PrectationDeatailsSection3;
