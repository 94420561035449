import React, { useEffect, useState } from "react";
import AddButton from "../../AddButton";
import { BASEIMAGEURL } from "../../../../../constants/actions";
import SimpleTextEditor from "../../../../simpleTextEditor";

const BoPrestationDetailsSection5 = ({
  prestationDetailsData,
  handleChange,
  valider,
}) => {
  const [fileName, setFileName] = useState("Choisir une image");
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (!selectedImage && prestationDetailsData?.bgimageConcept2) {
      setSelectedImage(BASEIMAGEURL + prestationDetailsData.bgimageConcept2);
    }
  }, [prestationDetailsData, selectedImage]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      handleChange({
        target:{
          name: "bgimageConcept2",
          value: file
        }
      });
    }
  };

  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">Section 5</h1>
      <form onSubmit={valider} className="grid grid-cols-1 gap-3">
        <div className="flex flex-col space-y-1">
          <label htmlFor="titreConcept2">Grand titre</label>
          <input
            type="text"
            name="titreConcept2"
            value={prestationDetailsData?.titreConcept2}
            onChange={handleChange}
            id="titreConcept2"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="descriptionConcept2">Description</label>
          <SimpleTextEditor
            value={prestationDetailsData?.descriptionConcept2}
            onChange={(value) => handleChange({ target: { name: 'descriptionConcept2', value } })}
            styleClassName=""
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="flex flex-col space-y-1">
            <label htmlFor="button3">Button</label>
            <input
              type="text"
              name="button3"
              value={prestationDetailsData?.button3}
              onChange={handleChange}
              id="button3"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="lienButton3">Lien</label>
            <input
              type="text"
              name="lienButton3"
              value={prestationDetailsData?.lienButton3}
              onChange={handleChange}
              id="lienButton3"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
        </div>

        <div className="space-y-1">
          <p>Background Image</p>
          <div className="border rounded-lg py-2 px-1 flex items-center justify-center flex-wrap gap-x-3">
            <div className="bg-gray-200 rounded-md max-w-40 max-h-40 min-w-40 min-h-40">
              <div className="cursor-pointer">
                {selectedImage ? (
                  <div className="relative">
                    <label
                      htmlFor="bgimageConcept2"
                      className="cursor-pointer"
                    >
                      <img
                        src={selectedImage}
                        alt={`Accueil concept img ${prestationDetailsData.bgimageConcept2}`}
                        className="object-cover max-w-40 max-h-40 min-w-40 min-h-40 "
                      />
                    </label>
                  </div>
                ) : (
                  <label
                    htmlFor="bgimageConcept2"
                    className="h-full min-h-44 max-h-52 w-48 mx-auto bg-gray-200 rounded-md flex items-center justify-center"
                  >
                    <i className="bi bi-card-image text-8xl"></i>
                  </label>
                )}
              </div>
            </div>
            <div className="">
              <p className="italic text-xs md:text-base">
                Veuillez télécharger une image carrée, d'une taille inférieure
                100 Ko.
              </p>
              <div className="bg-blue-50 rounded-md text-center py-3 px-2 sm:px-3">
                <input
                  type="file"
                  name="bgimageConcept2"
                  id="bgimageConcept2"
                  className="sr-only"
                  onChange={handleImageChange}
                />
                <label
                  htmlFor="bgimageConcept2"
                  className="text-sm md:text-base py-2 px-4 rounded-md border-fancygreen text-fancygreen cursor-pointer"
                  style={{ border: "1px solid gray" }}
                >
                  Choisir une image
                </label>
                {fileName && (
                  <span className="ml-3 text-gray-600 text-xs md:text-base">
                    {fileName}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <AddButton/>
        </div>
      </form>
    </div>
  );
};

export default BoPrestationDetailsSection5;
