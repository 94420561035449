import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.scss';
import { Link, useNavigate } from "react-router-dom";
import { BannerImg, LogoOrangePng } from '../../assets/images'
import { useDispatch } from "react-redux";
import { loginUser } from "../../actions/auth";
import { Helmet } from "react-helmet";

const Login = () => {
  const [user, setUser] = useState({email: "", password: ""});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await dispatch(loginUser(user));
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setUser({...user, [name]: value});
  }

  return (
    <section style={{ backgroundImage: `url(${BannerImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>
      <Helmet>
        <title>Se connecter</title>
        <meta name="description" content="Connectez-vous à votre compte pour accéder à toutes les fonctionnalités de notre site." />
      </Helmet>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
        <Link
          to="/"
          className="flex items-center mb-5 text-2xl font-semibold"
        >
          <img
            className="w-32"
            src={LogoOrangePng}
            alt="logo"
          />
        </Link>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
          <form className="space-y-4 md:space-y-6 p-6" onSubmit={handleLogin}>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={user?.email}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="...@..."
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Mot de passe
              </label>
              <input
                type="password"
                name="password"
                id="password"
                value={user?.password}
                onChange={handleChange}
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                required
              />
            </div>
            <p
              className="text-golddo cursor-pointer text-sm text-end"
              onClick={() => navigate("/forgot", {state:{fromLogin: true}})}
            >
              Mot de passe oublié ?
            </p>
            <button
              type="submit"
              className="w-full text-white bg-golddo hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Se Connecter
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Login;
