import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiClient } from "../../../../actions/api";
import { getData } from "../../../../actions/pages";
import { useNavigate } from "react-router-dom";
import FormattingDate from "../../../../helpers/formattingDate";
import DatePickerBackoffice from "../datepickerbackoffice";
import BasicDateCalendarbackoffice from "../basicdatecalendarbackoffice";

const ReservationListComponent = () => {
  const reservations = useSelector(
    (state) => state?.pages?.data?.paiments || []
  );

  const client_name = useSelector(
    (state) => state?.detailsreservation?.client_name
  );

  const [currentItems, setCurrentItems] = useState([]);
  const [fresh, setFresh] = useState(false);

  const date =
    useSelector((state) => state?.detailsreservation?.date) || "hamza";
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formatTime = (time) => {
    return time.substring(0, 5); // Extracts the first 5 characters, e.g., "16:00"
  };

  useEffect(() => {
    dispatch(getData("/payments"));
  }, [dispatch, fresh]);

  useEffect(() => {
    let filteredReservations = reservations;
  
    if (client_name) {
      filteredReservations = filteredReservations.filter(
        (item) => {
          let payer = item?.user || item?.guest;
          return (
            payer?.first_name?.toLowerCase().includes(client_name.toLowerCase()) ||
            payer?.last_name?.toLowerCase().includes(client_name.toLowerCase()) ||
            `${payer?.first_name} ${payer?.last_name}`.toLowerCase().includes(client_name.toLowerCase()) ||
            `${payer?.last_name} ${payer?.first_name}`.toLowerCase().includes(client_name.toLowerCase())
          );
        }
      );
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(filteredReservations.slice(indexOfFirstItem, indexOfLastItem));
    
  }, [reservations, currentPage, client_name]);

  useEffect(() => {
    let filteredReservations = reservations;

    if (date) {
      filteredReservations = filteredReservations.filter(
        (item) => item?.reservation?.date === date
      );
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(filteredReservations.slice(indexOfFirstItem, indexOfLastItem));
    
  }, [date, reservations, currentPage]);
  
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const cancelReservation = async (id) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir annuler cette réservation ? Cette action est irréversible."
    );

    if (confirmed) {
      try {
        await apiClient().post(`/reservations/${id}?_method=PUT`, {
          status: "cancelled",
        });
        toast.success("Réservation annulée avec succès.", { autoClose: 300 });
        setFresh(!fresh);
      } catch (err) {
        toast.error(
          "Une erreur est survenue lors de l'annulation de la réservation."
        );
        console.error(err);
      }
    }
  };

  const [isPickerOpen, setPickerIsOpen] = useState(false);
  const calendarRef = useRef(null);

  const getReservationStatus = (startTime, endTime, date) => {
    const now = new Date();
    const startDateTime = new Date(`${date}T${startTime}`);
    const endDateTime = new Date(`${date}T${endTime}`);

    if (now < startDateTime)
      return {
        status: "En cours de Traitement",
        style: "bg-blue-100 text-blue-700",
      };
    if (now > endDateTime)
      return { status: "Terminée", style: "bg-gray-100 text-gray-700" };
    return { status: "En Cour", style: "bg-green-100 text-green-700" };
  };

  const filteredReservations = date
    ? reservations.filter((item) => item?.reservation?.date === date)
    : reservations;

  const totalPages = Math.ceil(filteredReservations.length / itemsPerPage);

  return (
    <div className="p-4 space-y-6">
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="flex-1">
          <DatePickerBackoffice />
        </div>
        <div className="relative flex-1">
          <button
            className="w-full text-center h-12 bg-gray-100 border rounded-lg shadow-sm text-gray-600 font-medium"
            onClick={() => setPickerIsOpen(!isPickerOpen)}
          >
            Choisir une date directement
          </button>
          <div
            className={`absolute z-10 w-full mt-2 bg-white border rounded-lg shadow-lg transition-opacity duration-300 ${
              isPickerOpen ? "opacity-100 visible" : "opacity-0 invisible"
            }`}
          >
            <BasicDateCalendarbackoffice />
          </div>
        </div>
      </div>

      <div className="overflow-x-auto border border-gray-300 rounded-lg shadow-lg">
        <table className="w-full text-sm md:text-base bg-white border-collapse">
          <thead className="bg-gray-100 text-gray-700">
            <tr>
              <th className="p-3 font-medium text-left border-b">#ID</th>
              <th className="p-3 font-medium text-left border-b">Espace</th>
              <th className="p-3 font-medium text-left border-b">Date de réservation</th>
              <th className="p-3 font-medium text-left border-b">Heure de réservation</th>
              <th className="p-3 font-medium text-left border-b">Équipements</th>
              <th className="p-3 font-medium text-left border-b">Client</th>
              <th className="p-3 font-medium text-center border-b">Status</th>
              <th className="p-3 font-medium text-left border-b">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.length > 0 ? (
              currentItems.map((item) => (
                <tr
                  key={item?.reservation_id}
                  className={`cursor-pointer ${
                    item?.mode === "total" ? "bg-green-100" : "bg-orange-100"
                  } hover:bg-gray-100 border-b`}
                >
                  <td
                    onClick={() =>
                      navigate(`/gestion/reservations/${item?.reservation_id}`)
                    }
                    className="p-3 text-left"
                  >
                    #{item?.reservation_id}
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/gestion/reservations/${item?.reservation_id}`)
                    }
                    className="p-3 capitalize"
                  >
                    {item?.reservation?.espace_card?.titre}
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/gestion/reservations/${item?.reservation_id}`)
                    }
                    className="p-3"
                  >
                    {FormattingDate(item?.reservation?.date)}
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/gestion/reservations/${item?.reservation_id}`)
                    }
                    className="p-3"
                  >
                    {formatTime(item?.reservation?.startTime) + " - " + formatTime(item?.reservation?.endTime)}
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/gestion/reservations/${item?.reservation_id}`)
                    }
                    className="p-3 capitalize"
                  >
                    {[
                      item?.reservation?.decor?.titre || "",
                      ...(Array.isArray(item?.reservation?.formules)
                        ? item.reservation.formules.map(
                            (formule) => formule.titre
                          )
                        : []),
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/gestion/reservations/${item?.reservation_id}`)
                    }
                    className="p-3"
                  >
                    {item?.user?.first_name || item?.guest?.first_name}{" "}
                    {item?.user?.last_name || item?.guest?.last_name}
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/gestion/reservations/${item?.reservation_id}`)
                    }
                    className="p-3 text-center"
                  >
                    <div
                      className={`py-1 px-3 w-fit mx-auto rounded-full font-medium ${
                        item?.reservation?.status === "cancelled"
                          ? "bg-red-100 text-red-700"
                          : getReservationStatus(
                              item?.reservation?.startTime,
                              item?.reservation?.endTime,
                              item?.reservation?.date
                            )?.style
                      }`}
                    >
                      {item?.reservation?.status === "cancelled"
                        ? "Annulée"
                        : getReservationStatus(
                            item?.reservation?.startTime,
                            item?.reservation?.endTime,
                            item?.reservation?.date
                          )?.status}
                    </div>
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/gestion/reservations/${item?.reservation_id}`)
                    }
                    className="p-3"
                  >
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        cancelReservation(item?.reservation_id);
                      }}
                      className="bg-red-100 text-red-600 text-xs font-medium rounded py-1 px-3 hover:bg-red-600 hover:text-white transition-colors duration-300"
                    >
                      Annuler
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="8"
                  className="text-center p-3 text-red-500 font-medium"
                >
                  Aucune réservation trouvée pour cette date.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center mt-4">
        <ul className="flex space-x-2">
          {[...Array(totalPages)].map((_, i) => (
            <li key={i}>
              <button
                className={`px-4 py-2 rounded-lg text-sm ${
                  currentPage === i + 1
                    ? "bg-gray-700 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <ToastContainer />
    </div>
  );
};

export default ReservationListComponent;