import React, { useState } from "react";
import TextEditor from "../texteditor";
import { apiClient } from "../../../../actions/api";
import AddButton from "../../pagescomponents/AddButton";
import getCurrentDateFormattedYMD from '../../../../helpers/getCurrentDateFormatedYMD';
import { useNavigate } from "react-router-dom";
import uploadImage from '../../../../helpers/uploadImages';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleTextEditor from "../../../simpleTextEditor";

const AddBoBlogSection = () => {
  const [fileName, setFileName] = useState('Choisir une image');
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [texteditor, setTexteditor] = useState('');
  const [blog, setBlog] = useState({
    author: "", title: "", description: "", image: "", keys: ""
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBlog({ ...blog, [name]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setFile(file);
    }
  };

  const valider = async (e) => {
    e.preventDefault();

    try {
      const imageUrl = await uploadImage('image', { image: file }, "", 'blog-images');

      const formatedData = {
        ...blog,
        date: getCurrentDateFormattedYMD(),
        body: texteditor,
        image: imageUrl
      };
      const response = await apiClient().post('/blogCards', formatedData);
      toast.success('Blog post ajouté avec succès', { autoClose: 600 });
      navigate('/gestion/blog');
    } catch (err) {
      console.error('Error adding blog post:', err);
      toast.error('Eruer lors l\'ajoute de post', { autoClose: 600 });
    }
  };

  return (
    <div className='bg-white p-3 rounded-lg'>
        <form onSubmit={valider} className="grid grid-cols-1 lg:grid-cols-2 gap-3">
            <div className="flex flex-col space-y-1">
                <label htmlFor="title">Titre</label>
                <input type="text" name="title" id="title" value={blog.title} onChange={handleChange} className='w-full p-2 border rounded-md outline-none focus:outline-none' style={{ border: '1px solid gray' }} />
            </div>
            <div className="flex flex-col space-y-1">
                <label htmlFor="author">Auteur</label>
                <input type="text" name="author" id="author" value={blog.author} onChange={handleChange} className='w-full p-2 border rounded-md outline-none focus:outline-none' style={{ border: '1px solid gray' }} />
            </div>
            <div className="flex flex-col space-y-1">
                <label htmlFor="description">Description</label>
                <SimpleTextEditor
                  value={blog.description}
                  onChange={(value) => handleChange({ target: { name: 'description', value } })}
                  styleClassName=""
                />
            </div>
            <div className="flex flex-col space-y-1">
                <label htmlFor="keys">Tags</label>
                <input type="text" name="keys" id="keys" value={blog.keys} onChange={handleChange} placeholder="séparées par des virgules" className='w-full p-2 border rounded-md outline-none focus:outline-none' style={{ border: '1px solid gray' }} />
            </div>
            <div className="col-span-1 lg:col-span-2 flex items-center justify-center gap-x-3">
                <div className="bg-gray-200 rounded-md">
                    <label htmlFor="image" className='cursor-pointer'>
                        {selectedImage ? (
                            <img src={selectedImage} alt="img selected" className="object-cover max-w-96 max-h-40" />
                        ) : (<i className="bi bi-card-image px-4 py-[12px] text-9xl"></i>)}
                    </label>
                </div>
                <div className="">
                    <p className='italic text-sm lg:text-base'>Veuillez télécharger une image carrée, d'une taille inférieure 100 Ko.</p>
                    <div className="bg-blue-50 rounded-md text-center py-3 px-2 sm:px-3">
                        <input type="file" name="image" id="image" className="sr-only" onChange={handleImageChange} />
                        <label htmlFor="image" className="text-sm md:text-base py-2 px-4 rounded-md border-fancygreen text-fancygreen cursor-pointer" style={{ border: '1px solid gray' }}>
                            Choisir une image
                        </label>
                        {fileName && (
                            <span className="ml-3 text-gray-600 text-xs md:text-base">{fileName}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="col-span-1 lg:col-span-2 space-y-2">
                <label htmlFor="text-editor">Contenu</label>
                <div className="h-96 overflow-hidden border">
                    <TextEditor texteditor={texteditor} setTexteditor={setTexteditor} />
                </div>
            </div>
            <div className="col-span-1 lg:col-span-2 flex items-center justify-end">
                <AddButton/>
            </div>
        </form>
        <ToastContainer/>
    </div>
  )
}

export default AddBoBlogSection