import React, { useEffect, useState } from "react";
import { BannerSectionP13, HeaderDetails } from "../../components";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import { apiClient } from "../../actions/api";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_RESERVATION_DATA } from "../../constants/actions";

function AfterPayment() {
  const [note, setNote] = useState("");
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const reservationId = queryParams.get("id");
  const dispatch = useDispatch();
  const [warning, setWarning] = useState(false);
  const res = useSelector((state) => state?.detailsreservation);

  useEffect(() => {
    if (!res?.reservation?.id) {
      setWarning(true);
    } else {
      dispatch({ type: CLEAR_RESERVATION_DATA });
    }
  }, []);

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleSend = async () => {
    try {
      await apiClient().put(`/reservations/${reservationId}`, {
        client_note: note,
      });
      toast.success("Votre note a été envoyée avec succès !", {
        onClose: () => navigate("/"), // Redirect to home page after toast closes
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleSkip = () => {
    toast.info("Vous avez choisi de passer cette étape.", {
      onClose: () => navigate("/"),
    });
  };

  if (warning) {
    return (
      <div>
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
          <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full text-center">
            <p className="text-lg font-semibold text-gray-800 mb-4">
              Vous devez faire une réservation avant d'accéder à cette page.
            </p>
            <p className="text-gray-600 mb-6">
              Vous serez redirigé vers la page de réservation...
            </p>
            <button
              onClick={() => (window.location.href = "/reservation")}
              className="bg-yellow-500 text-white font-bold py-2 px-4 rounded hover:bg-yellow-600 transition duration-300"
            >
              Aller à la réservation
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="after-payment-page">
      <Helmet>
        <title>Réservation Confirmée</title>
        <meta
          name="description"
          content="Votre réservation a été confirmée avec succès"
        />
      </Helmet>
      <BannerSectionP13 from={"confirmation"} title={"Réservation Confirmée"} />
      {/* <HeaderDetails /> */}
      <div className="my-10 mx-4 md:mx-20 lg:mx-40 text-center">
        <label
          htmlFor="note"
          className="block text-xl md:text-2xl font-bold text-gray-900 py-4"
        >
          Si vous avez des remarques ou des demandes spéciales, veuillez les
          noter ci-dessous :
        </label>
        <textarea
          id="note"
          value={note}
          onChange={handleNoteChange}
          placeholder="Ajoutez vos remarques ici..."
          className="w-full h-28 mt-4 p-4 text-base rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-500"
        ></textarea>
        <div className="flex justify-center gap-4 mt-6">
          <button
            onClick={handleSend}
            className="px-6 py-2 bg-yellow-600 uppercase text-white font-semibold rounded-md hover:bg-yellow-700 transition duration-300"
          >
            Envoyer
          </button>
          <button
            onClick={handleSkip}
            className="px-6 py-2 bg-gray-600 text-white uppercase font-semibold rounded-md hover:bg-gray-700 transition duration-300"
          >
            Ignorer cette étape
          </button>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default AfterPayment;
