import React from "react";
import './style.scss';
import { BASEIMAGEURL } from "../../constants/actions";
import { useNavigate } from "react-router-dom";

const ConceptSection = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-7">
      <div className="flex justify-center lg:justify-end items-center">
        <div className="space-y-6 w-10/12 md:w-9/12">
          <div className="flex justify-center lg:justify-start">
            <h1 className="text-4xl lg:text-5xl text-gray-900" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{data?.titreConcept1}</h1>
          </div>
          <div>
            <p className="text-justify" dangerouslySetInnerHTML={{ __html: data?.descriptionConcept1 }}/>
          </div>
          <div className="flex lg:hidden my-4 h-72 overflow-hidden">
            <img src={ BASEIMAGEURL + data?.imageConcept1 } alt="sectionImg" className="w-full rounded-3xl" />
          </div>
          <div className="flex justify-center lg:justify-start">
            <button className="button primary lg:w-fit w-60 capitalize" onClick={()=>navigate(data?.lienButtonConcept1)}>{data?.buttonConcept1}</button>
          </div>
        </div>
      </div>
      <div className="hidden lg:block">
        <img src={ BASEIMAGEURL + data?.imageConcept1 } alt="sectionImg" className="w-9/12 mx-auto h-[400px] rounded-3xl" />
      </div>
    </div>
  );
};

export default ConceptSection;