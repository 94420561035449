import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useDispatch, useSelector } from "react-redux";
import { SET_DATE } from "../../../constants/actions";

dayjs.locale('fr');

const BasicDateCalendar = () => {
  const dispatch = useDispatch();
  const reduxDate = useSelector(state => state?.detailsreservation?.date);

  const today = dayjs().startOf('day');

  const handleDateSelect = (date) => {
    const selectedDate = date.startOf('day');

    if (selectedDate.isBefore(today)) {
      dispatch({ type: SET_DATE, payload: today.format('YYYY-MM-DD') });
    } else {
      dispatch({ type: SET_DATE, payload: selectedDate.format('YYYY-MM-DD') });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr">
      <DateCalendar
        value={dayjs(reduxDate)}
        onChange={(newDate) => {
          handleDateSelect(newDate);
        }}
        shouldDisableDate={(date) => date.isBefore(today, 'day')}
      />
    </LocalizationProvider>
  );
};

export default BasicDateCalendar;
