import React from 'react';

const Tabs = ({ tab, setTab }) => {
  const tabs = [
    { id: 1, titre: "Calendrier" },
    { id: 2, titre: "Emails" },
    { id: 3, titre: "Codes Promo" },
    { id: 4, titre: "Prix Supplémentaire" },
  ];

  return (
    <div className="overflow-x-auto lg:overflow-x-hidden my-5 px-4 py-2">
      <div className="flex items-center gap-3 lg:justify-center whitespace-nowrap">
        {tabs.map(item => (
          <div
            key={item.id}
            className={`flex justify-center items-center px-4 py-2 cursor-pointer border rounded-md font-medium whitespace-nowrap ${tab === item.id ? "bg-gray-900 text-white" : "bg-white text-black"}`}
            onClick={() => setTab(item.id)}
          >
            {item.titre}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;