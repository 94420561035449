import { useState, useEffect, useRef } from "react";
import "./style.scss";
import { apiClient } from "../../actions/api";
import { BASEIMAGEURL } from "../../constants/actions";

const SpaceCard = ({ imageSrc, onClick }) => (
  <div className="space-card overflow-hidden group rounded-md cursor-pointer" onClick={onClick}>
    <img
      src={imageSrc}
      alt={`Image`}
      className="space-image w-full h-auto transition-transform duration-300 ease-in-out group-hover:scale-110"
    />
  </div>
);

const ImagePopup = ({ image, onClose, onPrev, onNext }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 50, y: 50 });
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  const handleImageClick = (e) => {
    e.stopPropagation();
    if (isZoomed) {
      setIsZoomed(false);
      setZoomLevel(1);
      setPosition({ x: 50, y: 50 });
    } else {
      setIsZoomed(true);
      setZoomLevel(2.5);
      if (imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setPosition({ x, y });
      }
    }
  };

  const handleMouseMove = (e) => {
    if (!isZoomed || !imageRef.current) return;

    const rect = imageRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    setPosition({ x, y });
  };

  return (
    <div 
      ref={containerRef}
      className="fixed inset-0 bg-black bg-opacity-85 flex items-center justify-center z-50"
    >
      <button
        className="absolute top-7 right-7 text-white text-2xl hover:text-golddo z-20"
        onClick={onClose}
      >
        <i className="bi bi-x-lg"></i>
      </button>
      <div 
        className="relative w-full h-full"
        onClick={(e) => e.stopPropagation()}
        onMouseMove={handleMouseMove}
      >
        <img 
          ref={imageRef}
          src={BASEIMAGEURL + image?.image} 
          alt="Popup" 
          className="absolute transition-transform duration-200 ease-in-out rounded-2xl w-full max-w-2xl mx-auto"
          style={{
            cursor: isZoomed ? 'zoom-out' : 'zoom-in',
            transformOrigin: `${position.x}% ${position.y}%`,
            // maxWidth: '80%',
            // maxHeight: '80%',
            // width: 'auto',
            height: 'auto',
            objectFit: 'contain',
            left: '50%',
            top: '50%',
            transform: `translate(-50%, -50%) scale(${zoomLevel})`,
          }}
          onClick={handleImageClick}
        />
        <button
          className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white text-4xl hover:text-golddo z-20"
          onClick={(e) => { e.stopPropagation(); onPrev(); }}
        >
          <i className="bi bi-chevron-left"></i>
        </button>
        <button
          className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white text-4xl hover:text-golddo z-20"
          onClick={(e) => { e.stopPropagation(); onNext(); }}
        >
          <i className="bi bi-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

function SpaceSectionP6({ id }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(3);
  const [album, setAlbum] = useState([]);
  const [size, setSize] = useState(3);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiClient().get(`/album/espace/${id}`);
      setAlbum(response.data);
      setSize(response.data.length);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const updateItemsToShow = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setItemsToShow(1);
      } else if (width < 991) {
        setItemsToShow(2);
      } else {
        setItemsToShow(3);
      }
    };

    window.addEventListener("resize", updateItemsToShow);
    updateItemsToShow(); // Initial call

    return () => window.removeEventListener("resize", updateItemsToShow);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % album?.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + album?.length) % album?.length
    );
  };

  const openPopup = (index) => {
    setSelectedImage(index);
  };

  const closePopup = () => {
    setSelectedImage(null);
  };

  const nextPopupImage = () => {
    setSelectedImage((prevIndex) => (prevIndex + 1) % album?.length);
  };

  const prevPopupImage = () => {
    setSelectedImage((prevIndex) => (prevIndex - 1 + album?.length) % album?.length);
  };

  return (
    <section className="our-spaces">
      <div className="relative w-8/12 sm:w-8/12 md:w-10/12 lg:w-10/12 mx-auto">
        <button
          className="scrolling-btn"
          style={{ left: -50 }}
          onClick={prevSlide}
        >
          <i className="bi bi-arrow-left-circle text-orangemedium text-4xl"></i>
        </button>
        <div className="space-grid">
          {Array.from({ length: itemsToShow }, (_, offset) => {
            const index = (currentIndex + offset) % size;
            return (
              <SpaceCard
                key={index}
                imageSrc={BASEIMAGEURL + album[index]?.image}
                onClick={() => openPopup(index)}
              />
            );
          })}
        </div>
        <button
          className="scrolling-btn"
          style={{ right: -50 }}
          onClick={nextSlide}
        >
          <i className="bi bi-arrow-right-circle text-orangemedium text-4xl"></i>
        </button>
      </div>
      {selectedImage !== null && (
        <ImagePopup
          image={album[selectedImage]}
          onClose={closePopup}
          onPrev={prevPopupImage}
          onNext={nextPopupImage}
        />
      )}
    </section>
  );
}

export default SpaceSectionP6;