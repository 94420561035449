import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BasicSEO, BoLayout, Section1BoEspaces, Section2BoEspaces, Section3BoEspaces, Section4BoEspaces, SeoBoEspaces } from '../../../../components';
import uploadImage from '../../../../helpers/uploadImages';
import { apiClient } from '../../../../actions/api';

const BoEspaces = ({ page_id = 3 }) => {
  const [espacesData, setEspacesData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient().get(`/espaces`);
        setEspacesData(response.data);
        setInitialData(response.data);
      } catch (err) {
        console.error(err);
        toast.error('Erreur lors du chargement des données', { autoClose: 600 });
      }
    };
    fetchData();
  }, [page_id, refresh]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEspacesData({ ...espacesData, [name]: value });
  };

  const valider = async (e) => {
    e.preventDefault();
    try {
      const fieldsToCheck = ['imageAvantages', 'imageConcept', 'imageSeo'];
      const updatedData = { ...espacesData };

      for (const field of fieldsToCheck) {
        if (espacesData[field] !== initialData[field]) {
          const newPath = await uploadImage(field, espacesData, initialData, 'espaces-images');
          updatedData[field] = newPath;
        }
      }

      await apiClient().put(`/espaces/1`, updatedData);
      toast.success('Page d\'espaces modifiée avec succès', { autoClose: 600 });
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
      toast.error('Erreur lors de la modification de la page d\'espaces', { autoClose: 600 });
    }
  };

  return (
    <BoLayout>
      <BasicSEO/>
      <ToastContainer />
      <h1 className='text-xl md:text-2xl font-poppins py-2 px-4 text-lightOrange' style={{ fontWeight: 600 }}>
        Page « Espaces »
      </h1>
      <div className="bg-gray-100">
        <div className="py-3 px-4 space-y-5">
          <Section1BoEspaces espacesData={espacesData} handleChange={handleChange} valider={valider} />
          <Section2BoEspaces page_id={page_id} espacesData={espacesData} handleChangeData={handleChange} valider={valider} />
          <Section3BoEspaces espacesData={espacesData} handleChange={handleChange} valider={valider} />
          <Section4BoEspaces espacesData={espacesData} handleChange={handleChange} valider={valider} />
          <SeoBoEspaces espacesData={espacesData} handleChange={handleChange} valider={valider} />
        </div>
      </div>
    </BoLayout>
  );
};

export default BoEspaces;
