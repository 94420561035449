import * as React from "react";
import './style.scss'
import { Img10 } from "../../assets/images";

const FeatureCard = ({ title, description }) => (
  <div className="p-4 lg:p-6 rounded text-gray-800 bg-gray-100 space-y-3 text-justify">
    <h3 className="font-serif lg:text-xl" style={{ fontWeight: '700' }}>{title}</h3>
    <p className="text-sm lg:text-[17px]" dangerouslySetInnerHTML={{ __html: description }}/>
  </div>
);


function AdvantagesP3({ data }) {

  const avantages = [
    {
      title: data?.titreAvantage1,
      description: data?.descriptionAvantage1,
    },
    {
      title: data?.titreAvantage2,
      description: data?.descriptionAvantage2,
    },
    {
      title: data?.titreAvantage3,
      description: data?.descriptionAvantage3,
    },
    {
      title: data?.titreAvantage4,
      description: data?.descriptionAvantage4,
    },
  ];


  return (
    <section className="w-10/12 mx-auto">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-24">

        <div className="hidden lg:flex justify-center">
          <img src={Img10} alt="img" className="rounded-3xl h-[600px]" />
          {/* <img src={BASEIMAGEURL + data?.imageAvantages} alt="" className="rounded-3xl h-[600px]" /> */}
        </div>

        <div className="space-y-7">
          <div className="flex justify-center lg:justify-start">
            <h2 className="text-4xl lg:text-5xl text-gray-900 capitalize" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{data?.titreAvantages}</h2>
          </div>
          <p className="my-3 lg:my-5 text-justify" dangerouslySetInnerHTML={{ __html: data?.descriptionAvantages }}/>
          <div className="block lg:hidden avantagescover w-full h-72"></div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            {avantages?.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>

      </div>
    </section>
  );
}

export default AdvantagesP3;
