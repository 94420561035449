import { useDispatch, useSelector } from "react-redux";
import { BASEIMAGEURL } from "../../../../constants/actions";
import { useEffect, useState } from "react";
import AddButton from "../../pagescomponents/AddButton";
import { apiClient } from "../../../../actions/api";
import uploadImage from "../../../../helpers/uploadImages";
import { getData } from "../../../../actions/pages";
import { LoadingGif } from "../../../../assets/images";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const FormulesCms = () => {
  const formules = useSelector((state) => state?.pages?.data?.formules);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    dispatch(getData('/formules'));
  },[dispatch]);

  const [newFormule, setNewFormule] = useState({
    formuletitre: '',
    formuledescription: '',
    formuleprice: '',
    formulephoto: '',
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewFormule({ ...newFormule, [name]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setNewFormule({ ...newFormule, formulephoto: file });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newPath = await uploadImage(
        "formulephoto",
        { formulephoto: newFormule.formulephoto },
        "",
        "formules-images"
      );
      await apiClient().post(`/formules`, {
        photo: newPath ? newPath : "",
        titre: newFormule.formuletitre,
        description: newFormule.formuledescription,
        price: newFormule.formuleprice,
      });
      toast.success('Formule ajoutée avec succès', { autoClose: 600 });
      dispatch(getData("/formules"));
      setNewFormule({
        formuletitre: "",
        formuledescription: "",
        formuleprice: "",
        formulephoto: "",
      });
      setSelectedImage(null);
    } catch (err) {
      toast.error('Erreur lors de l\'ajout de la formule', { autoClose: 600 });
      console.error(err);
    }
  };

  return (
    <div className="m-4 p-6 bg-white border border-gray-200 rounded-lg shadow-lg space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-800">
          Formules
        </h1>
        <button 
          className="py-2 px-4 font-medium border rounded-md bg-yellow-600 text-white hover:bg-yellow-900"
          onClick={() => setIsFormVisible(!isFormVisible)}
        >
          {isFormVisible ? 'Annuler' : 'Ajouter'}
        </button>
      </div>
      
      <div className="flex items-center gap-4 overflow-x-auto overflow-y-hidden h-60 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        {formules ? formules
          ?.sort((a, b) => b.id - a.id)
          .map((formule) => (
            <div
              key={formule?.id}
              className="w-60 h-60 min-w-60 min-h-60 max-h-60 max-w-60 rounded-lg relative group bg-gray-100 overflow-hidden shadow-md"
            >
              <img
                src={BASEIMAGEURL + formule?.photo}
                alt={`${formule?.formuletitre} formulephoto`}
                className="object-cover w-full h-full"
              />
              <h3 className="absolute bottom-0 left-0 text-sm font-semibold text-white bg-gray-800 bg-opacity-70 w-full text-center py-1">
                {formule?.titre}
              </h3>
              <button
                onClick={() => navigate(`/gestion/decos-formules/formules/${formule?.id}`)}
                className="absolute top-2 right-2 bg-white text-gray-800 hover:text-white hover:bg-gray-800 text-xl w-8 h-8 flex items-center justify-center rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              >
                <i className="bi bi-pencil-square text-xl"></i>
              </button>
            </div>
          )) : (
            <div className="flex justify-center items-center w-full h-60">
              <img src={LoadingGif} alt="loading gif" width={40} height={40} />
            </div>
          )}
      </div>
      
      {isFormVisible && (
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col gap-2">
              <label htmlFor="formuletitre" className="font-medium text-gray-700">Titre</label>
              <input
                type="text"
                name="formuletitre"
                id="formuletitre"
                value={newFormule?.formuletitre}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="formuleprice" className="font-medium text-gray-700">Prix</label>
              <input
                type="number"
                name="formuleprice"
                id="formuleprice"
                value={newFormule?.formuleprice}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="formuledescription" className="font-medium text-gray-700">Description</label>
              <textarea
                name="formuledescription"
                id="formuledescription"
                value={newFormule?.formuledescription}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-blue-500"
                required
                rows={4}
              ></textarea>
            </div>
            <div className="flex items-center gap-4">
              <label
                htmlFor="formulephoto"
                className="relative cursor-pointer w-36 h-36 bg-gray-200 text-white rounded-lg flex justify-center items-center overflow-hidden"
              >
                <input
                  type="file"
                  name="formulephoto"
                  id="formulephoto"
                  className="sr-only"
                  onChange={handleImageChange}
                  required
                />
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt={`selected img`}
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <i className="bi bi-card-image text-6xl text-gray-400"></i>
                )}
              </label>
              <label
                htmlFor="formulephoto"
                className="cursor-pointer bg-gray-50 py-2 px-4 border rounded-md border-gray-300"
              >
                Choisir une photo
              </label>
            </div>
          </div>
          <div className="flex justify-end mt-6">
            <AddButton />
          </div>
        </form>
      )}
      <ToastContainer />
    </div>
  );
};

export default FormulesCms;