import React from 'react';
import { AddButton } from '../../../..';

const Section3BoContact = ({ contactData, handleChange, valider }) => {

  return (
    <div className="bg-white p-3 rounded-lg">
        <h1 className='font-semibold font-poppins text-xl mb-3'>Section Localisation</h1>
        <form onSubmit={valider} className="space-y-3 grid grid-cols-1 md:grid-cols-2 gap-3">
            <div className="flex flex-col space-y-1 col-span-1 md:col-span-2">
                <label htmlFor="titreLocalisation">Grand titre</label>
                <input type="text" name="titreLocalisation" id="titreLocalisation" value={contactData?.titreLocalisation} onChange={handleChange} className='w-full p-2 border rounded-md outline-none focus:outline-none' style={{ border: '1px solid gray' }} />
            </div>
            <div className="flex flex-col space-y-1">
                <label htmlFor="latitude">Latitude</label>
                <input type="text" name="latitude" id="latitude" value={contactData?.latitude} onChange={handleChange} className='w-full p-2 border rounded-md outline-none focus:outline-none' style={{ border: '1px solid gray' }} />
            </div>
            <div className="flex flex-col space-y-1">
                <label htmlFor="longitude">Longitude</label>
                <input type="text" name="longitude" id="longitude" value={contactData?.longitude} onChange={handleChange} className='w-full p-2 border rounded-md outline-none focus:outline-none' style={{ border: '1px solid gray' }} />
            </div>
            <div className="col-span-1 md:col-span-2 flex items-center justify-end">
                <AddButton/>
            </div>
        </form>
    </div>
  )
}

export default Section3BoContact