import React, { useEffect, useState } from "react";
import AddButton from "../../AddButton";
import { BASEIMAGEURL } from "../../../../../constants/actions";
import SimpleTextEditor from "../../../../simpleTextEditor";

const BoPrestationDetailsSection3 = ({
  prestationDetailsData,
  handleChange,
  valider,
}) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (isInitialLoad && prestationDetailsData.image1Detail2) {
      const initialImages = [
        { id: 1, image: BASEIMAGEURL + prestationDetailsData?.image1Detail2 },
        { id: 2, image: BASEIMAGEURL + prestationDetailsData?.image2Detail2 },
        { id: 3, image: BASEIMAGEURL + prestationDetailsData?.image3Detail2 },
        { id: 4, image: BASEIMAGEURL + prestationDetailsData?.image4Detail2 },
      ];
      setSelectedImages(initialImages);
      setIsInitialLoad(false);
    }
  }, [prestationDetailsData, isInitialLoad]);

  const handleImageChange = (event, id) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);

      setSelectedImages((prevImages) =>
        prevImages.map((img) =>
          img.id === id ? { ...img, image: imageUrl } : img
        )
      );

      handleChange({
        target: {
          name: `image${id}Detail2`,
          value: file,
        },
      });
    }
  };


  return (
    <div className="p-3 bg-white rounded-lg">
      <h2 className="font-semibold text-xl mb-3">Section 2</h2>
      <form onSubmit={valider} className="space-y-3">
        <div className="flex flex-col space-y-1">
          <label htmlFor="titreDetail2">Grand titre</label>
          <input
            type="text"
            name="titreDetail2"
            id="titreDetail2"
            value={prestationDetailsData?.titreDetail2}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="descriptionDetail2">Description</label>
          <SimpleTextEditor
            value={prestationDetailsData?.descriptionDetail2}
            onChange={(value) => handleChange({ target: { name: 'descriptionDetail2', value } })}
            styleClassName=""
          />
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
          {selectedImages?.map((image) => (
            <div key={image.id}>
              <div className="cursor-pointer w-fit mx-auto">
                <input
                  type="file"
                  name={`image${image.id}Detail2`}
                  id={`image${image.id}Detail2`}
                  className="sr-only"
                  onChange={(e) => handleImageChange(e, image.id)}
                />
                <label
                  htmlFor={`image${image.id}Detail2`}
                  className="cursor-pointer"
                >
                  {image.image ? (
                    <img
                      src={image.image}
                      alt={`img ${image.id}`}
                      className="object-cover max-w-40 max-h-40 min-w-40 min-h-40"
                    />
                  ) : (
                    <div className="max-w-40 max-h-40 min-w-40 min-h-40 mx-auto bg-gray-200 rounded-md flex items-center justify-center">
                      <i className="bi bi-card-image text-8xl"></i>
                    </div>
                  )}
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-end">
          <AddButton />
        </div>
      </form>
    </div>
  );
};

export default BoPrestationDetailsSection3;
