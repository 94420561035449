import { REMOVE_CLIENT_TOKEN, REMOVE_TOKEN, STORE_CLIENT_TOKEN, STORE_TOKEN } from "../constants/actions";


const initialState = {
    token: null,
    client_token: null
}

export default (state = initialState, action) => {
    switch(action.type) {
        case STORE_TOKEN:
            return {
                ...state,
                token: action.payload.token,
                token_type: action.payload.token_type,
            };
        case REMOVE_TOKEN:
            return {
                ...state,
                token: null,
                token_type: null,
            };
        case STORE_CLIENT_TOKEN:
            return {
                ...state,
                client_token: action.payload
            };
        case REMOVE_CLIENT_TOKEN:
            return {
                ...state,
                client_token: null,
            };
        default:
            return state;
    }
}
