import { useDispatch, useSelector } from "react-redux";
import {
  SET_FORMULE,
  REMOVE_FORMULE,
  BASEIMAGEURL,
} from "../../../constants/actions";
import { useEffect, useState } from "react";
import { LoadingGif } from "../../../assets/images";

const Formules = () => {
  const dispatch = useDispatch();
  const reservedFormules = useSelector(
    (state) => state?.detailsreservation?.formules
  );
  const formules = useSelector((state) => state?.pages?.data?.formules);
  const [size, setSize] = useState(formules?.length || 0);

  useEffect(() => {
    setSize(formules?.length || 0);
  }, [formules]);

  const toggleFormule = (id) => {
    const formu = formules?.find((f) => f.id === id);
    const isSelected = reservedFormules?.some((f) => f.id === formu.id);

    if (isSelected) {
      dispatch({ type: REMOVE_FORMULE, payload: formu.id });
    } else {
      // Remove previously selected formule if any
      if (reservedFormules.length > 0) {
        dispatch({ type: REMOVE_FORMULE, payload: reservedFormules[0].id });
      }
      dispatch({ type: SET_FORMULE, payload: formu });
    }
  };

  const FormuleCard = ({ formule }) => {
    return (
      <div className="flex flex-col justify-center items-center gap-5 bg-gray-100 rounded-lg p-4 shadow-xl w-full">
        <h1 className="text-2xl font-bold font-outfit capitalize text-gray-900">
          {formule?.titre}
        </h1>
        <img
          src={BASEIMAGEURL + formule.photo}
          alt={`formule ${formule.id}`}
          className="object-cover w-52 h-40 min-w-52 min-h-40 max-w-52 max-h-40"
        />
        <div className="text-center font-outfit max-w-72">
          {formule?.description}
        </div>
        <div className="flex items-center font-outfit font-bold text-xl text-gray-800">
          <span>{formule?.price}</span>
          <i className="bi bi-currency-euro"></i>
        </div>
        <button
          onClick={() => toggleFormule(formule.id)}
          className={`py-2 px-6 font-semibold transition-all duration-300 ${
            reservedFormules?.some((d) => d.id === formule.id)
              ? "bg-red-200 hover:bg-red-300 shadow-lg"
              : "bg-yellow-200 hover:bg-yellow-300 shadow-md"
          }`}
        >
          {reservedFormules?.some((d) => d.id === formule.id)
            ? "Annuler"
            : "Choisir"}
        </button>
      </div>
    );
  };

  return (
    <div className="max-w-screen-xl mx-auto space-y-4" id="services">
      <h1 className="py-10 text-xl md:text-2xl text-center font-bold text-gray-900 font-outfit uppercase">
        Choisissez une formule{" "}
        <span className="lowercase text-yellow-900">(obligatoire)</span>
      </h1>

      <div className="space-y-4 my-20">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 w-[90%] mx-auto pb-[80px]">
          {formules ? (
            formules.map((formule) => (
              <FormuleCard key={formule.id} formule={formule} />
            ))
          ) : (
            <div className="mx-auto">
              <img src={LoadingGif} alt="loading gif" width={40} height={40} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Formules;
