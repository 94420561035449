import SimpleTextEditor from "../../../../simpleTextEditor";
import AddButton from "../../AddButton";

const Section1BoAcceuil = ({ accueilData, handleChange, valider }) => {
  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">
        Section Header
      </h1>
      <form
        onSubmit={valider}
        className="grid grid-cols-1 lg:grid-cols-2 gap-3"
      >
        <div className="flex flex-col space-y-1 col-span-1 lg:col-span-2">
          <label htmlFor="titre">Grand titre</label>
          <input
            type="text"
            name="titre"
            value={accueilData?.titre}
            onChange={handleChange}
            id="titre"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="flex flex-col space-y-1 col-span-1 lg:col-span-2">
          <label htmlFor="description">Description</label>
          <SimpleTextEditor
            value={accueilData?.description}
            onChange={(value) => handleChange({ target: { name: 'description', value } })}
            styleClassName=""
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="button1">Button 1</label>
          <input
            type="text"
            name="button1"
            value={accueilData?.button1}
            onChange={handleChange}
            id="button1"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="lienButton1">Lien button 1</label>
          <input
            type="text"
            name="lienButton1"
            value={accueilData?.lienButton1}
            onChange={handleChange}
            id="lienButton1"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="button2">Button 2</label>
          <input
            type="text"
            name="button2"
            value={accueilData?.button2}
            onChange={handleChange}
            id="button2"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="lienButton2">Lien button 2</label>
          <input
            type="text"
            name="lienButton2"
            value={accueilData?.lienButton2}
            onChange={handleChange}
            id="lienButton2"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="col-span-1 lg:col-span-2 flex items-center justify-end">
          <AddButton />
        </div>
      </form>
    </div>
  );
};

export default Section1BoAcceuil;
