import { useEffect, useState } from 'react';
import getCurrentDateFormattedYMD from '../../../../helpers/getCurrentDateFormatedYMD';

const buttons = [
  { id: 0, text: "Tout" },
  { id: 1, text: "Terminée" },
  { id: 2, text: "En attente" },
  { id: 3, text: "Rejetée" },
];

const ButtonFilter = ({ btn, filterBy, setFilterBy }) => {
  return (
    <button
      className={`px-3 sm:px-4 py-[5px] sm:py-[5px] text-[10px] sm:text-sm md:text-base rounded-full font-outfit ${
        btn.id === filterBy
          ? "text-lightOrange border-lightOrange bg-lightyellow"
          : "bg-white text-gray-800"
      }
      `}
      style={{ border: "1px solid gray" }}
      onClick={() => setFilterBy(btn.id)}
    >
      {btn.text}
    </button>
  );
};

const Section2Paiments = ({ paiments = [] }) => {
  const [filterBy, setFilterBy] = useState(0);
  const [filtredPaiements, setFiltredPaiements] = useState(paiments);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(()=>{
    if(paiments?.length > 0){
      setFiltredPaiements(paiments);
    }
  },[paiments])

  useEffect(() => {
    // let filtered;
    // switch(filterBy) {
    //   case 0:
    //     filtered = paiments;
    //     break;
    //   case 1:
    //     filtered = paiments?.filter(p => p.status === SUCCESS);
    //     break;
    //   case 2:
    //     filtered = paiments?.filter(p => p.status === PENDING);
    //     break;
    //   case 3:
    //     filtered = paiments?.filter(p => p.status === REJECTED);
    //     break;
    //   default:
    //     filtered = paiments;
    // }
    // setFiltredPaiements(filtered);
    setCurrentPage(1);
  }, [filterBy]);

  const totalPages = Math.ceil((filtredPaiements?.length || 0) / itemsPerPage);

  const handlePageChange = (event) => {
    setCurrentPage(Number(event.target.value));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handleFilterChange = (id) => {
    setFilterBy(id);
    setCurrentPage(1);
  };

  const paginatedPaiements = filtredPaiements?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="px-2 py-4 space-y-6">
      <div className="space-y-6">
        <h1 className="font-poppins font-medium text-xl text-darckgraybo">
          Historique de paiments
        </h1>
        <div className="flex items-center gap-x-2 md:gap-x-3 min-w-fit">
          {buttons?.map((btn, index) => (
            <ButtonFilter 
              key={index} 
              btn={btn} 
              filterBy={filterBy} 
              setFilterBy={handleFilterChange}
            />
          ))}
        </div>
      </div>

      <div className="w-full max-w-full border rounded-lg overflow-hidden font-outfit text-[11px] sm:text-base">
          <div className="overflow-x-auto">
              <table className="w-full min-w-max">
                  <thead>
                      <tr className='text-extralightgray'>
                          <th className="py-2 px-1 text-start">Commande ID</th>
                          <th className="py-2 px-1 text-start">Date</th>
                          <th className="py-2 px-1 text-start">Montant</th>
                          <th className="py-2 px-1 text-start">Total ou moitié</th>
                          <th className="py-2 px-1 text-start">Status</th>
                      </tr>
                  </thead>
                  <tbody>
                      {paginatedPaiements?.map((paiement, index) => (
                          <tr key={index} className={`${index === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                              <td className="py-2 px-1">#{paiement.id}</td>
                              <td className="py-2 px-1">{getCurrentDateFormattedYMD(paiement.created_at)}</td>
                              <td className="py-2 px-1">{paiement.price}<i className="bi bi-currency-euro"></i></td>
                              <td className="py-2 px-1">{paiement.mode === "50off" ? "1/2" : '1' }</td>
                              <td className={`py-2 px-1 capitalize font-medium text-ddarckgreen`}>
                                  {/* {paiement.status}                                 // ${
                                //   paiement.status === "succès" ? 'text-ddarckgreen' : 
                                //   (paiement.status === "en attent" ? 'text-blue-600' : 'text-red-600')
                                // } */} succès
                              </td>
                          </tr>
                      ))}
                  </tbody>
              </table>
          </div>
      </div>

      {filtredPaiements.length > itemsPerPage &&
        <div className="flex items-center justify-end space-x-2 text-base font-medium">
          <button 
            onClick={handlePrevPage} 
            disabled={currentPage === 1}
            className="px-2 py-1 border rounded-md disabled:opacity-50"
          >
            <i className="bi bi-chevron-left"></i>
          </button>
          <select 
            value={currentPage}
            onChange={handlePageChange}
            className="px-2 py-1 border rounded-md"
          >
            {[...Array(totalPages)].map((_, index) => (
              <option key={index} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
          <button 
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-2 py-1 border rounded-md disabled:opacity-50"
          >
            <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      }
    </div>
  );
};

export default Section2Paiments;