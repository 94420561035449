import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isClientAuthenticated, setIsClientAuthenticated] = useState(false);

  const token = useSelector((state) => state?.token);
  const client = useSelector((state) => state?.auth?.client);
  const isCompleted = useSelector(
    (state) => state?.detailsreservation?.isCompleted
  );

  useEffect(() => {
    if (!!client && !!client?.type) {
      setIsClientAuthenticated(true);
    } else {
      setIsClientAuthenticated(false);
    }
  }, [token, client]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!token?.token,
        isClientAuthenticated: isClientAuthenticated,
        isClientNotAuthenticated: (client !== null && client !== "") || token?.client_token !== null,
        isCompleted: !!isCompleted,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
