import { useEffect, useState } from "react";
import SimpleTextEditor from "../../../../simpleTextEditor";
import AddButton from "../../AddButton";
import { BASEIMAGEURL } from "../../../../../constants/actions";

const EspaceQuestion = ({ espaceDetailsData, handleChange, valider }) => {
  const [fileName, setFileName] = useState("Choisir une image");
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (!selectedImage && espaceDetailsData?.imageQuestions) {
      setSelectedImage(BASEIMAGEURL + espaceDetailsData.imageQuestions);
    }
  }, [espaceDetailsData, selectedImage]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      handleChange({
        target: {
          name: "imageQuestions",
          value: file,
        },
      });
    }
  };

  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">
      Section des Questions Fréquemment Posées.
      </h1>
      <form onSubmit={valider} className="space-y-2">
        <div className="flex flex-col space-y-1">
          <label htmlFor="titreQuestions">Titre</label>
          <input
            type="text"
            name="titreQuestions"
            id="titreQuestions"
            value={espaceDetailsData?.titreQuestions}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="descriptionQuestions">Description</label>
          <textarea
            name="descriptionQuestions"
            id="descriptionQuestions"
            className="w-full h-40 md:h-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
            onChange={handleChange}
            value={espaceDetailsData?.descriptionQuestions || ""}
          ></textarea>
        </div>
        <div className="border rounded-lg py-2 px-1 flex items-center justify-center flex-wrap gap-x-3">
          <div className="bg-gray-200 rounded-md max-w-40 max-h-40 min-w-40 min-h-40">
            <div className="cursor-pointer">
              {selectedImage ? (
                <div className="relative">
                  <label htmlFor="imageQuestions" className="cursor-pointer">
                    <img
                      src={selectedImage}
                      alt={`Accueil concept img ${espaceDetailsData.imageQuestions}`}
                      className="object-cover max-w-40 max-h-40 min-w-40 min-h-40 "
                    />
                  </label>
                </div>
              ) : (
                <label
                  htmlFor="imageQuestions"
                  className="h-full min-h-44 max-h-52 w-48 mx-auto bg-gray-200 rounded-md flex items-center justify-center"
                >
                  <i className="bi bi-card-image text-8xl"></i>
                </label>
              )}
            </div>
          </div>
          <div className="">
            <p className="italic text-xs md:text-base">
              Veuillez télécharger une image carrée, d'une taille inférieure 100
              Ko.
            </p>
            <div className="bg-blue-50 rounded-md text-center py-3 px-2 sm:px-3">
              <input
                type="file"
                name="imageQuestions"
                id="imageQuestions"
                className="sr-only"
                onChange={handleImageChange}
              />
              <label
                htmlFor="imageQuestions"
                className="text-sm md:text-base py-2 px-4 rounded-md border-fancygreen text-fancygreen cursor-pointer"
                style={{ border: "1px solid gray" }}
              >
                Choisir une image
              </label>
              {fileName && (
                <span className="ml-3 text-gray-600 text-xs md:text-base">
                  {fileName}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-3">
          <div className="flex flex-col space-y-1">
            <label htmlFor="question1">Question 1</label>
            <input
              type="text"
              name="question1"
              id="question1"
              value={espaceDetailsData?.question1}
              onChange={handleChange}
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="reponse1">Reponse 1</label>
            <textarea
              name="reponse1"
              id="reponse1"
              className="w-full h-40 md:h-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
              onChange={handleChange}
              value={espaceDetailsData?.reponse1 || ""}
            ></textarea>
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="question2">Question 2</label>
            <input
              type="text"
              name="question2"
              id="question2"
              value={espaceDetailsData?.question2}
              onChange={handleChange}
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="reponse2">Reponse 2</label>
            <textarea
              name="reponse2"
              id="reponse2"
              className="w-full h-40 md:h-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
              onChange={handleChange}
              value={espaceDetailsData?.reponse2 || ""}
            ></textarea>
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="question3">Question 3</label>
            <input
              type="text"
              name="question3"
              id="question3"
              value={espaceDetailsData?.question3}
              onChange={handleChange}
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="reponse3">Reponse 3</label>
            <textarea
              name="reponse3"
              id="reponse3"
              className="w-full h-40 md:h-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
              onChange={handleChange}
              value={espaceDetailsData?.reponse3 || ""}
            ></textarea>
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="question4">Question 4</label>
            <input
              type="text"
              name="question4"
              id="question4"
              value={espaceDetailsData?.question4}
              onChange={handleChange}
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="reponse4">Reponse 4</label>
            <textarea
              name="reponse4"
              id="reponse4"
              className="w-full h-40 md:h-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
              onChange={handleChange}
              value={espaceDetailsData?.reponse4 || ""}
            ></textarea>
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="question5">Question 5</label>
            <input
              type="text"
              name="question5"
              id="question5"
              value={espaceDetailsData?.question5}
              onChange={handleChange}
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="reponse5">Reponse 5</label>
            <textarea
              name="reponse5"
              id="reponse5"
              className="w-full h-40 md:h-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
              onChange={handleChange}
              value={espaceDetailsData?.reponse5 || ""}
            ></textarea>
          </div>

        </div>
        <div className="flex justify-end">
          <AddButton />
        </div>
      </form>
    </div>
  );
};

export default EspaceQuestion;
