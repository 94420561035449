import React from "react";
import "./style.scss";
import Header from "../header";
import { BannerImg } from "../../assets/images";

function BannerSectionP12() {
  return (
    <div className="relative">
      <section className="h-full hr-sec" style={{ height: '450px', backgroundImage: `url(${BannerImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <Header />
        <div className="absolute inset-0 flex items-center justify-center mt-32">
          <h1 className="text-3xl lg:text-5xl text-white" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }} >Réserver maintenant !</h1>
        </div>
      </section>
    </div>
  );
}

export default BannerSectionP12;