import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { BasicSEO, BoLayout } from "../../../../components";
import { apiClient } from "../../../../actions/api";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const ChangeGestionnaires = () => {
  const [formData, setFormData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiClient().get(`/gestionnaires/${id}`);
        setFormData(response.data);
      } catch (error) {
        toast.error("Erreur lors de la récupération des données de l'utilisateur.");
        console.error("Erreur lors de la récupération de l'utilisateur :", error);
      }
    };
    fetchUser();
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await apiClient().put(`/gestionnaires/${id}`, formData);
      toast.success("Gestionnaire mis à jour avec succès !");
      navigate("/gestion/admin-list");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour du gestionnaire.");
      console.error("Erreur lors de la mise à jour de l'utilisateur :", error);
    }
  };

  return (
    <BoLayout>
      <BasicSEO />
      <div className="p-6 bg-white rounded-lg shadow-lg max-w-md mx-auto">
        <h1 className="text-2xl font-semibold text-center mb-6">Modifier un Gestionnaire</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
              Prénom
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              placeholder="Prénom"
              value={formData.first_name || ""}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
              Nom
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              placeholder="Nom"
              value={formData.last_name || ""}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={formData.email || ""}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Mot de passe (laisser vide pour ne pas changer)
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Mot de passe"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 transition"
          >
            Mettre à jour le Gestionnaire
          </button>
        </form>
        <ToastContainer />
      </div>
    </BoLayout>
  );
};

export default ChangeGestionnaires;