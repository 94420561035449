import React, { useEffect, useState, useCallback } from "react";
import { AddButton } from "../../../..";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../../../../actions/api";
import uploadImage from "../../../../../helpers/uploadImages";
import { BASEIMAGEURL } from "../../../../../constants/actions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleTextEditor from "../../../../simpleTextEditor"; // Import your text editor component

const Section2BoEspaces = ({ espacesData, handleChangeData, valider }) => {
  const navigate = useNavigate();
  const [currentSpace, setCurrentSpace] = useState(0);
  const [selectedImages, setSelectedImages] = useState({});
  const [initialSpaces, setInitialSpaces] = useState([]);
  const [spaces, setSpaces] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient().get(`/espace-cards`);
        setInitialSpaces(response.data);
        setSpaces(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const handleImageChange = useCallback((event, spaceId) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImages((prev) => ({
        ...prev,
        [spaceId]: { file, imageUrl },
      }));
      setSpaces((prevSpaces) =>
        prevSpaces.map((space) =>
          space.id === spaceId ? { ...space, image: file } : space
        )
      );
    }
  }, []);

  const handleChange = useCallback((spaceId, field, value) => {
    setSpaces((prevSpaces) =>
      prevSpaces.map((space) =>
        space.id === spaceId ? { ...space, [field]: value } : space
      )
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    valider(e);
    try {
        // document.forms['buttonForm'].submit();

        const changedSpaces = spaces.filter((space, index) => {
            const initialSpace = initialSpaces[index];
            return (
                space.titre !== initialSpace.titre ||
                space.description !== initialSpace.description ||
                selectedImages[space.id]
            );
        });

        const updatedSpaces = await Promise.all(
            changedSpaces.map(async (space) => {
                const formData = new FormData();
                formData.append('titre', space.titre);
                formData.append('description', space.description);

                let newImagePath = null;
                if (selectedImages[space.id]) {
                    const initialSpace = initialSpaces.find(s => s.id === space.id);
                    newImagePath = await uploadImage('image', {image: selectedImages[space.id].file}, { image: initialSpace.image }, 'espace-cards');
                    formData.append('image', newImagePath);
                }

                const response = await apiClient().post(`/espace-cards/${space.id}?_method=PUT`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                return { ...response.data, newImagePath };
            })
        );

        setSpaces((prevSpaces) =>
            prevSpaces.map((space) => {
                const updatedSpace = updatedSpaces.find((s) => s.id === space.id);
                return updatedSpace ? { ...space, ...updatedSpace, image: updatedSpace.newImagePath || space.image } : space;
            })
        );

        setInitialSpaces((prevInitialSpaces) =>
            prevInitialSpaces.map((space) => {
                const updatedSpace = updatedSpaces.find((s) => s.id === space.id);
                return updatedSpace ? { ...space, ...updatedSpace, image: updatedSpace.newImagePath || space.image } : space;
            })
        );

        setSelectedImages({});
        // toast.success('Page d\'espaces modifiée avec succès', { autoClose: 600 });
        console.log("Spaces updated successfully");
    } catch (error) {
        toast.error('Erreur lors de la modification de la page d\'espaces', { autoClose: 600 });
        console.error("Error updating spaces:", error);
    }
  };

  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">
        Section Espaces
      </h1>
      <form onSubmit={handleSubmit} className="">
        <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="flex flex-col space-y-1">
            <label htmlFor="button1">Button</label>
            <input
              type="text"
              name="button1"
              value={espacesData?.button1}
              onChange={handleChangeData}
              id="button1"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="lienButton1">Lien</label>
            <input
              type="text"
              name="lienButton1"
              value={espacesData?.lienButton1}
              onChange={handleChangeData}
              id="lienButton1"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-3">

          <div className="w-full flex items-center justify-between gap-x-2">
            <button
              type="button"
              onClick={() =>
                setCurrentSpace((prev) => (prev > 0 ? prev - 1 : prev))
              }
            >
              <i className="bi bi-arrow-left-circle text-3xl text-lightOrange"></i>
            </button>

            <div className="overflow-hidden w-full">
              <div
                className="flex transition-transform duration-300 ease-in-out"
                style={{ transform: `translateX(-${currentSpace * 100}%)` }}
              >
                {spaces && spaces?.map((space, index) => (
                  <div key={space.id} className="w-full flex-shrink-0">
                    <div className="space-y-2 w-full border rounded-md p-2 relative">
                      <button
                        type="button"
                        onClick={() =>
                          navigate(`/gestion/pages/espaces/${space.id}`)
                        }
                        className="absolute top-0 right-0 bg-golddo rounded-md px-1 text-white"
                      >
                        <i className="bi bi-arrow-up-right"></i>
                      </button>
                      <h1 className="text-lightOrange text-center font-semibold underline border-b pb-2">
                        Espace {index + 1}
                      </h1>
                      <div className="w-full flex items-start flex-col lg:flex-row gap-y-3 gap-x-5">
                        <div className="text-center h-full mx-auto">
                          <input
                            type="file"
                            name={`s3i${index + 1}`}
                            id={`s3i${index + 1}`}
                            className="sr-only"
                            onChange={(e) => handleImageChange(e, space.id)}
                          />
                          <label
                            htmlFor={`s3i${index + 1}`}
                            className="cursor-pointer"
                          >
                            {selectedImages[space.id] ? (
                              <div className="relative">
                                <img
                                  src={selectedImages[space.id].imageUrl}
                                  alt="selected"
                                  className="object-cover h-44 w-[230px] rounded-md"
                                />
                              </div>
                            ) : (
                              space.image ? (
                                <div className="relative">
                                  <img
                                    src={BASEIMAGEURL + space.image}
                                    alt="selected"
                                    className="object-cover h-44 w-[230px] rounded-md"
                                  />
                                </div>
                              ) : (
                                <div className="h-full min-h-44 max-h-52 w-48 mx-auto bg-gray-200 rounded-md flex items-center justify-center">
                                  <i className="bi bi-card-image text-8xl"></i>
                                </div>
                              )
                            )}
                          </label>
                        </div>
                        <div className="w-full space-y-2">
                          <div className="flex flex-col space-y-1 w-full min-w-fit">
                            <label htmlFor={`titre${space.id}`}>Titre</label>
                            <input
                              type="text"
                              name={`titre${space.id}`}
                              value={space.titre}
                              onChange={(e) =>
                                handleChange(space.id, "titre", e.target.value)
                              }
                              id={`titre${space.id}`}
                              className="w-full p-2 border rounded-md outline-none focus:outline-none"
                              style={{ border: "1px solid gray" }}
                            />
                          </div>
                          <div className="flex flex-col space-y-1 w-full min-w-fit">
                            <label htmlFor={`desc${space.id}`}>Description</label>
                            <SimpleTextEditor
                              value={space.description}
                              onChange={(value) =>
                                handleChange(space.id, "description", value)
                              }
                              id={`desc${space.id}`}
                              styleClassName=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <button
              type="button"
              onClick={() =>
                setCurrentSpace((prev) =>
                  prev < spaces.length - 1 ? prev + 1 : prev
                )
              }
            >
              <i className="bi bi-arrow-right-circle text-3xl text-lightOrange"></i>
            </button>
          </div>

          <div className="text-end col-span-1 lg:col-span-2">
            <AddButton />
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Section2BoEspaces;
