import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BasicSEO, BoEspacesDetailsSection1, BoEspacesDetailsSection2, BoEspacesDetailsSection3, BoEspacesDetailsSection4, BoEspacesDetailsSection5, BoLayout, EspaceOccupation, EspaceQuestion, SeoBoEspacesDeatails } from '../../../../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { apiClient } from '../../../../../actions/api';
import uploadImage from '../../../../../helpers/uploadImages';

const EspacesDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [espaceDetailsData, setEspaceDetailsData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient().get(`/espace-cards/${id}`);
        setEspaceDetailsData(response.data);
        setInitialData(response.data);
      } catch (err) {
        console.error(err);
        toast.error('Erreur lors du chargement des détails de l\'espace', { autoClose: 600 });
      }
    };
    fetchData();
  }, [id, refresh]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEspaceDetailsData({ ...espaceDetailsData, [name]: value });
  };

  const valider = async (e) => {
    e.preventDefault();

    try {
      const fieldsToCheck = ['image', 'bgimageDeatail1', 'imageConcept', 'bgimageDeatail2', 'imageSeo', 'imageQuestions'];
      const updatedData = { ...espaceDetailsData };

      for (const field of fieldsToCheck) {
        if (espaceDetailsData[field] !== initialData[field]) {
          const newPath = await uploadImage(field, espaceDetailsData, initialData, 'espace-cards');
          updatedData[field] = newPath;
        }
      }

      await apiClient().put(`/espace-cards/${id}`, updatedData);
      toast.success('Détails de l\'espace modifiés avec succès', { autoClose: 600 });
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
      toast.error('Erreur lors de la modification des détails de l\'espace', { autoClose: 600 });
    }
  };

  return (
    <BoLayout>
      <BasicSEO/>
      <ToastContainer />
      <div className="flex items-center gap-x-5 py-2 px-4">
        <button onClick={() => navigate('/gestion/pages/espaces')}>
          <i className="bi bi-arrow-left text-2xl cursor-pointer"></i>
        </button>
        <h1 className='text-xl md:text-2xl font-poppins text-lightOrange' style={{ fontWeight: 600 }}>
          Page « Détails d'espace "{id}" »
        </h1>
      </div>
      <div className="bg-gray-100 min-h-screen">
        <div className="py-3 px-4 space-y-5">
          <BoEspacesDetailsSection1 espaceDetailsData={espaceDetailsData} handleChange={handleChange} valider={valider} />
          <BoEspacesDetailsSection2 espaceDetailsData={espaceDetailsData} handleChange={handleChange} valider={valider} space_id={id} />
          <BoEspacesDetailsSection3 espaceDetailsData={espaceDetailsData} handleChange={handleChange} valider={valider} />
          <BoEspacesDetailsSection4 espaceDetailsData={espaceDetailsData} handleChange={handleChange} valider={valider} />
          <BoEspacesDetailsSection5 espaceDetailsData={espaceDetailsData} handleChange={handleChange} valider={valider} />
          <EspaceOccupation espaceDetailsData={espaceDetailsData} handleChange={handleChange} valider={valider} />
          <SeoBoEspacesDeatails espaceDetailsData={espaceDetailsData} handleChange={handleChange} valider={valider} />
          <EspaceQuestion espaceDetailsData={espaceDetailsData} handleChange={handleChange} valider={valider}/>
        </div>
      </div>
    </BoLayout>
  );
};

export default EspacesDetails;
