import { apiClient } from "./api";
import { LOGIN_SUCCESS, LOGOUT, REMOVE_TOKEN, STORE_TOKEN } from "../constants/actions";
import { toast } from 'react-toastify';

export const loginUser = (user) => async (dispatch) => {
  try {
    const response = await apiClient().post(`/login`, user);
    dispatch({ type: LOGIN_SUCCESS, payload: response?.data?.user });
    dispatch({ type: STORE_TOKEN, payload: {
      token: response?.data?.token,
      token_type: response?.data?.token_type
    }});
    toast.success("Connexion réussie", { autoClose: 700 });
  } catch (error) {
    toast.error("Adresse e-mail ou mot de passe incorrect", { autoClose: 1000 });
    console.error("Login Faild !", error);
  }
};

export const logoutUser = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  dispatch({ type: REMOVE_TOKEN });
};
