import { Helmet } from 'react-helmet';
import { BannerSectionP7, BlogSectionp7, Footer } from '../../components';

const Blog = () => {

  return (
    <>
      <Helmet>
        <title>Blog</title>
        <meta name="description" content="Restez à jour avec les derniers articles et publications de notre blog. Explorez des sujets intéressants et des perspectives." />
      </Helmet>
      <BannerSectionP7 />
      <BlogSectionp7 />
      <Footer />
    </>
  )
}

export default Blog;
