import React, { useEffect, useState } from "react";
import { BASEIMAGEURL, STORE_CLIENT } from "../../../constants/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uploadImage from "../../../helpers/uploadImages";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../../actions/api";
import { DefaultAvatar3 } from "../../../assets/images";

const Infos = ({ client, handleChange }) => {
    const [selectedImage, setSelectedImage] = useState(BASEIMAGEURL + (client?.avatar || ""));
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    useEffect(() => {
      setSelectedImage(BASEIMAGEURL + (client?.avatar || ""));
    }, [client]);
  
    const handleChangeAvatar = async (e) => {
      const file = e.target.files[0];
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
  
        try {
          const newAvatarPath = await uploadImage(
            "avatar",
            {avatar: file},
            {avatar: client?.avatar},
            "avatars"
          );
          handleChange({ 
            target: {
              name: "avatar",
              value: newAvatarPath
            }
           })
           const response = await apiClient().post(
            `/users/${client?.id}?_method=PUT`, { avatar: newAvatarPath });
          dispatch({ type: STORE_CLIENT, payload: { ...client, avatar: newAvatarPath } });
        } catch (error) {
          console.error("Error uploading image:", error);
          toast.error("Erreur lors du téléchargement de l'image !", { autoClose: 700 });
        }
      }
    };
  
    return (
      <div className="py-2 min-w-fit">
        <div className="w-32 h-32 min-w-32 min-h-32 mx-auto relative rounded-full">
            <label htmlFor="avatar" className="cursor-pointer overflow-hidden">
                <img
                    src={!client?.avatar ? DefaultAvatar3 : selectedImage}
                    alt="avatar"
                    name="avatar"
                    className="object-cover w-full h-full max-w-full max-h-full rounded-full"
                />
            </label>
            <label htmlFor="avatar">
              <div className="rounded-full border-2 border-white bg-gray-800 text-white w-8 h-8 text-base flex justify-center items-center absolute bottom-2 right-1 cursor-pointer"><i className="bi bi-image-alt"></i></div>
            </label>
        </div>
        <div className="text-center min-w-40 space-y-2">
          <h4 className="font-semibold capitalize text-lg font-poppins">
            {client?.first_name} {client?.last_name}
          </h4>
          <div className="">{client?.phone}</div>
          <div className="min-w-fit">{client?.email}</div>
          <div className="">{client?.birthday}</div>
          <div className="">
            <button onClick={()=>navigate('/reservation')} className="bg-black text-white font-semibold rounded-full py-2 px-4 font-outfit text-sm md:text-xs">Ajouter une réservation</button>
          </div>
          <input type="file" accept="image/*" id="avatar" className="sr-only" onChange={handleChangeAvatar} />
        </div>
      </div>
    );
  };
  
export default Infos