import React from 'react';

const Section1Utilisateurs = ({ clients = [], guests = [] }) => {
  const isActiveNow = (client) => {
    return client?.statut === 1;
  };

  const isCurrentMonth = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
  };

  const totalClients = clients?.length;
  const totalMembers = guests?.length;
  const activeNow = clients?.filter(client => isActiveNow(client)).length;
  
  const currentMonthClients = clients?.filter(client => isCurrentMonth(client.created_at)) || [];
  const currentMonthClientCount = currentMonthClients.length;

  const percentageActiveNow = totalClients > 0 ? ((activeNow / totalClients) * 100).toFixed(1) : '0';
  const membersPercentage = totalClients > 0 ? ((totalMembers / totalClients) * 100).toFixed(1) : '0';
  const clientsPercentage = totalClients > 0 ? ((currentMonthClientCount / totalClients) * 100).toFixed(1) : '0';

  const items = [
    { icon: 'people', title: 'Clients', count: totalClients.toLocaleString(), subicon: 'arrow-up', poursentage: clientsPercentage, color: 'rgb(0, 172, 79)' },
    { icon: 'person-check', title: 'Invités', count: totalMembers.toLocaleString(), subicon: 'arrow-down', poursentage: membersPercentage, color: 'rgb(208, 0, 75)' },
    { icon: 'display', title: 'Actifs maintenant', count: activeNow.toLocaleString(), subicon: 'arrow-up', poursentage: percentageActiveNow, color: 'rgb(0, 172, 79)' },
  ];

  return (
    <div className="bg-white rounded-lg shadow-md p-4 md:p-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {items.map((item, index) => (
          <div key={index} className="flex flex-col items-center p-4 bg-gray-50 rounded-lg shadow-sm">
            <div className="flex items-center justify-center w-20 h-20 bg-lightyellow text-darckyellow rounded-full mb-3">
              <i className={`bi bi-${item.icon} text-3xl md:text-4xl`}></i>
            </div>
            <div className="text-center">
              <p className="text-sm font-medium text-gray-500">{item.title}</p>
              <p className="text-2xl md:text-3xl font-semibold text-gray-800">{item.count}</p>
              {item.poursentage &&
                <div className="flex items-center justify-center gap-x-1 text-xs font-bold mt-1">
                  <span className="text-green-500">{item.poursentage} %</span>
                  <span className="text-gray-600">ce mois</span>
                </div>
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section1Utilisateurs;