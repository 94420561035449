import React, { useEffect, useState } from "react";
import { BASEIMAGEURL } from "../../../../../constants/actions";
import { SimpleTextEditor } from '../../../../../components';

const Section2BoAcceuil = ({ accueilData = {}, handleChange, valider }) => {
  const [fileName, setFileName] = useState("Choisir une image");
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (!selectedImage && accueilData?.imageConcept1) {
      setSelectedImage(BASEIMAGEURL + accueilData.imageConcept1);
    }
  }, [accueilData, selectedImage]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      handleChange({
        target:{
          name: 'imageConcept1',
          value: file
        }
      });
    }
  };

  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">
        Section Concept 1
      </h1>
      <form onSubmit={valider} className="grid grid-cols-1 gap-3">
        <div className="flex flex-col space-y-1">
          <label htmlFor="titreConcept1">Grand titre</label>
          <input
            type="text"
            name="titreConcept1"
            value={accueilData?.titreConcept1}
            onChange={handleChange}
            id="titreConcept1"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="descriptionConcept1">Description</label>
          <SimpleTextEditor
            value={accueilData?.descriptionConcept1}
            onChange={(value) => handleChange({ target: { name: 'descriptionConcept1', value } })}
            styleClassName=""
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="flex flex-col space-y-1">
            <label htmlFor="buttonConcept1">Button</label>
            <input
              type="text"
              name="buttonConcept1"
              value={accueilData?.buttonConcept1}
              onChange={handleChange}
              id="buttonConcept1"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="lienButtonConcept1">Lien</label>
            <input
              type="text"
              name="lienButtonConcept1"
              value={accueilData?.lienButtonConcept1}
              onChange={handleChange}
              id="lienButtonConcept1"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
        </div>

        <div className="border rounded-lg py-2 px-1 flex items-center justify-center flex-wrap gap-x-3">
          <div className="bg-gray-200 rounded-md max-w-40 max-h-40 min-w-40 min-h-40">
            <div className="cursor-pointer">
              {selectedImage ? (
                <div className="relative">
                  <label
                    htmlFor="imageConcept1"
                    className="cursor-pointer"
                  >
                    <img
                      src={selectedImage}
                      alt={`Accueil concept img ${accueilData.imageConcept1}`}
                      className="object-cover max-w-40 max-h-40 min-w-40 min-h-40"
                    />
                  </label>
                </div>
              ) : (
                <label
                  htmlFor="imageConcept1"
                  className="h-full min-h-44 max-h-52 w-48 mx-auto bg-gray-200 rounded-md flex items-center justify-center"
                >
                  <i className="bi bi-card-image text-8xl"></i>
                </label>
              )}
            </div>
          </div>
          <div className="">
            <p className="italic text-xs md:text-base">
              Veuillez télécharger une image carrée, d'une taille inférieure 100
              Ko.
            </p>
            <div className="bg-blue-50 rounded-md text-center py-3 px-2 sm:px-3">
              <input
                type="file"
                name="imageConcept1"
                id="imageConcept1"
                className="sr-only"
                onChange={handleImageChange}
              />
              <label
                htmlFor="imageConcept1"
                className="text-sm md:text-base py-2 px-4 rounded-md border-fancygreen text-fancygreen cursor-pointer"
                style={{ border: "1px solid gray" }}
              >
                Choisir une image
              </label>
              {fileName && (
                <span className="ml-3 text-gray-600 text-xs md:text-base">
                  {fileName}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end">
          <button
            type="submit"
            className="bg-lightyellow text-lightOrange border-lightOrange rounded-md px-4 py-1 hover:border-lightOrange hover:text-lightOrange hover:bg-lightyellow"
            style={{ border: "1px solid gray" }}
          >
            Valider
          </button>
        </div>
      </form>
    </div>
  );
};

export default Section2BoAcceuil;
