import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { registerClient } from "../../actions/client";

const CleintRegisterForm = ({ close, showLink }) => {
  const [registerForm, setRegisterForm] = useState();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();

  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterForm({ ...registerForm, [name]: value });
  };

  const SubmitRegisterForm = async (e) => {
    e.preventDefault();
    if (registerForm?.password === registerForm?.confirmepassword) {
      try{
        await dispatch(registerClient(registerForm, rememberMe));
        close && close();
      }catch(err){
        toast.error("L'email existe déjà", { autoClose: 1000 });
        console.error("Registration failed: ", err);
      }
    } else {
      toast.warning('Les mots de passe ne correspondent pas !', { autoClose: 1000 });
    }
  };


  return (
    <form onSubmit={SubmitRegisterForm} className="space-y-3">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-5">
        <div className="flex flex-col gap-y-2">
          <label
            htmlFor="first_name"
            className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-900 focus-within:ring-1 focus-within:ring-gray-900"
          >
            <span className="text-xs font-medium text-gray-700">Nom</span>
            <input
              type="text"
              name="first_name"
              id="first_name"
              value={registerForm?.first_name || ""}
              onChange={handleChange}
              placeholder="Nom"
              className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm bg-white"
              required
              autoComplete="off"
            />
          </label>
        </div>
        <div className="flex flex-col gap-y-2">
          <label
            htmlFor="last_name"
            className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-900 focus-within:ring-1 focus-within:ring-gray-900"
          >
            <span className="text-xs font-medium text-gray-700">Prénom</span>
            <input
              type="text"
              name="last_name"
              id="last_name"
              value={registerForm?.last_name || ""}
              onChange={handleChange}
              placeholder="Prénom"
              className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
              required
              autoComplete="off"
            />
          </label>
        </div>
        <div className="flex flex-col gap-y-2">
          <label
            htmlFor="email"
            className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-900 focus-within:ring-1 focus-within:ring-gray-900"
          >
            <span className="text-xs font-medium text-gray-700">Email</span>
            <input
              type="email"
              name="email"
              id="email"
              value={registerForm?.email || ""}
              onChange={handleChange}
              placeholder="email@exemple.com"
              className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
              required
              autoComplete="off"
            />
          </label>
        </div>
        <div className="flex flex-col gap-y-2">
          <label
            htmlFor="phone"
            className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-900 focus-within:ring-1 focus-within:ring-gray-900"
          >
            <span className="text-xs font-medium text-gray-700">Téléphone</span>
            <input
              type="tel"
              name="phone"
              id="phone"
              value={registerForm?.phone || ""}
              onChange={handleChange}
              placeholder="Téléphone"
              className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
              required
              autoComplete="off"
            />
          </label>
        </div>
        <div className="flex flex-col gap-y-2">
          <label
            htmlFor="code_postal"
            className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-900 focus-within:ring-1 focus-within:ring-gray-900"
          >
            <span className="text-xs font-medium text-gray-700">
              Code postal
            </span>
            <input
              type="number"
              name="code_postal"
              id="code_postal"
              value={registerForm?.code_postal || ""}
              onChange={handleChange}
              placeholder="Code postal"
              className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
              required
              autoComplete="off"
            />
          </label>
        </div>
        <div className="flex flex-col gap-y-2">
          <label
            htmlFor="birthday"
            className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-900 focus-within:ring-1 focus-within:ring-gray-900"
          >
            <span className="text-xs font-medium text-gray-700">
              Date de naissance
            </span>
            <input
              type="date"
              name="birthday"
              id="birthday"
              value={registerForm?.birthday || ""}
              onChange={handleChange}
              className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
              required
              autoComplete="off"
            />
          </label>
        </div>
        <div className="flex flex-col gap-y-2">
          <label
            htmlFor="password"
            className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-900 focus-within:ring-1 focus-within:ring-gray-900"
          >
            <span className="text-xs font-medium text-gray-700">
              Mot de passe
            </span>
            <input
              type="password"
              name="password"
              id="password"
              value={registerForm?.password || ""}
              onChange={handleChange}
              placeholder="Mot de passe"
              className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
              required
              autoComplete="off"
              minLength={6}
            />
          </label>
        </div>
        <div className="flex flex-col gap-y-2">
          <label
            htmlFor="confirmepassword"
            className={`block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:ring-1 ${
              registerForm?.password &&
              registerForm?.confirmepassword &&
              !registerForm?.password.startsWith(registerForm?.confirmepassword)
                ? "focus-within:border-red-500 focus-within:ring-red-500 text-red-500"
                : "focus-within:border-gray-900 focus-within:ring-gray-900 text-gray-700"
            }`}
          >
            <span className="text-xs font-medium">Répéter mot de passe</span>
            <input
              type="password"
              name="confirmepassword"
              id="confirmepassword"
              value={registerForm?.confirmepassword || ""}
              onChange={handleChange}
              placeholder="Mot de passe"
              className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
              required
              autoComplete="off"
              minLength={6}
            />
          </label>
        </div>
      </div>

      <div className="flex md:items-center justify-between flex-col md:flex-row">
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={handleCheckboxChange}
              className="sr-only peer"
            />
            <div className="relative w-9 h-5 bg-gray-200 rounded-full peer-focus:ring-0 peer-checked:bg-gray-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all"></div>
            <span className="ms-3 text-sm font-medium text-gray-900">
              Se souvenir du mot de passe
            </span>
          </label>
        </div>
      </div>

      <div className="mt-4 flex items-center flex-col md:flex-row gap-4">
        <button
          type="submit"
          className={`w-40 p-3 font-semibold rounded-sm text-white bg-gray-800 hover:bg-gray-700`}
        >
          Continuer
        </button>
        {showLink && (
          <div>
            <p>
              Vous n'avez pas de compte ?{" "}
              <span
                className="text-blue-600 font-medium cursor-pointer"
                onClick={() =>
                  navigate("/login")
                }
              >
                Se Connecter
              </span>
              .
            </p>
          </div>
        )}
      </div>
    </form>
  );
};

export default CleintRegisterForm;
