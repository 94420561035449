import { useSelector } from 'react-redux';
import { AdvantagesP2, BannerSectionP2, ContentTwoP2, Footer, NosEspacesSection1 } from '../../components';
import { Helmet } from 'react-helmet';
import { BASEIMAGEURL } from '../../constants/actions';

const NosEspaces = () => {
  const data = useSelector(state => state?.pages?.data?.espace);
  return (
    <>
      <Helmet>
        <title>{data?.titreSeo}</title>
        <meta name="description" content={data?.descriptionSeo} />
        <meta name="keywords" content={data?.motsClesSeo} />
        <meta property="og:image" content={BASEIMAGEURL + data?.imageSeo} />
      </Helmet>
        <BannerSectionP2 data={data}/>
        <NosEspacesSection1 espaceData={data}/>
        <AdvantagesP2 data={data}/>
        <ContentTwoP2 data={data}/>
        <Footer/>
    </>
  )
}

export default NosEspaces