import { apiClient } from "../actions/api";


const uploadImage = async (imageField, imageData, oldImageData = "", folder) => {

  // console.log(imageField, imageData, oldImageData, folder);

  const formData = new FormData();
  formData.append("image", imageData[imageField]);
  formData.append("folder", folder);
  if (oldImageData[imageField]) {
    formData.append("old_image_path", oldImageData[imageField]);
  }

  try {
    const uploadResponse = await apiClient().post('/upload', formData);
    return uploadResponse.data.path;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export default uploadImage;
