import React, { useEffect, useState } from 'react';
import { apiClient } from '../../../actions/api';
import Informations from './Informations';
import RapidNavigations from './RapidNavigations';
import Legal from './Legal';
import Utilities from './Utilities';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BoFooterSection2 = () => {

    const [footerData, setFooterData] = useState(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(()=>{
        const fetchData = async() =>{
            const response = await apiClient().get('/footers');
            setFooterData(response.data);
        }
        fetchData();
    },[refresh]);

    const handleChange = (e) =>{
        const { name, value } = e.target;
        setFooterData({...footerData, [name]: value});
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        const putData = async() =>{ 
            try{
                const response = await apiClient().post('/footers/1?_method=PUT', footerData);
                toast.success('Détails de footer modifiés avec succès', { autoClose: 600 });
            }catch(err){
                console.error(err);
                toast.error('Erreur lors de la modification des détails de footer', { autoClose: 600 });
            }
        }
        putData();
        setRefresh(!refresh)
    }

  return (
    <div className='space-y-4'>

        <Informations footerData={footerData} handleChange={handleChange} handleSubmit={handleSubmit} />

        <RapidNavigations footerData={footerData} handleChange={handleChange} handleSubmit={handleSubmit} />

        <Legal footerData={footerData} handleChange={handleChange} handleSubmit={handleSubmit} />

        <Utilities footerData={footerData} handleChange={handleChange} handleSubmit={handleSubmit} />

        <ToastContainer/>
    </div>
  )
}

export default BoFooterSection2