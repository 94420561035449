import React, { useState, useEffect } from "react";
import AddButton from "../pagescomponents/AddButton";

const Legal = ({ footerData, handleChange, handleSubmit }) => {
  const [legalLinks, setLegalLinks] = useState([]);
  const [link, setLink] = useState({ name: "", url: "" });

  useEffect(() => {
    if (footerData?.legal_links) {
      const updatedLinks = footerData.legal_links.map(l => ({
        ...l,
        selected: l.selected === 'true' || l.selected === true
      }));
      setLegalLinks(updatedLinks);
    }
  }, [footerData]);

  const handleSelect = (e) => {
    const { value, checked } = e.target;
    setLegalLinks(prevLinks => {
      const updatedLinks = prevLinks.map(link =>
        link.name === value ? { ...link, selected: checked } : link
      );
      handleChange({
        target: { name: "legal_links", value: updatedLinks }
      });
      return updatedLinks;
    });
  };

  const addLink = (e) => {
    e.preventDefault();
    if (link.name && link.url) {
      const newLink = { ...link, selected: true };
      setLegalLinks(prevLinks => {
        const updatedLinks = [...prevLinks, newLink];
        handleChange({
          target: { name: "legal_links", value: updatedLinks }
        });
        return updatedLinks;
      });
      setLink({ name: "", url: "" });
    }
  };

  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">Légales :</h1>
      <form
        onSubmit={addLink}
        className="bg-white p-3 rounded-lg grid grid-cols-1 lg:grid-cols-2 gap-3"
      >
        <div className="flex flex-col">
          <label htmlFor="titrelegal">Titre</label>
          <input
            type="text"
            value={link.name}
            onChange={(e) => setLink({ ...link, name: e.target.value })}
            required
            name="titrelegal"
            id="titrelegal"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="routelegal">Lien</label>
          <input
            type="text"
            value={link.url}
            onChange={(e) => setLink({ ...link, url: e.target.value })}
            required
            name="routelegal"
            id="routelegal"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="col-span-1 lg:col-span-2 flex items-center justify-end">
          <AddButton />
        </div>
      </form>
      <form onSubmit={handleSubmit}>
        <div className="mt-4 grid grid-cols-1 lg:grid-cols-3 gap-4 bg-gray-100 p-4 rounded-lg shadow-md">
          {legalLinks.map((l, index) => (
            <label
              htmlFor={l.name}
              key={index}
              className="flex items-center justify-center cursor-pointer gap-x-4 p-2 bg-white rounded-md shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              <span className="text-gray-700 font-medium capitalize">{l.name}</span>
              <input
                type="checkbox"
                name="selectedlinks"
                value={l.name}
                id={l.name}
                onChange={handleSelect}
                className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                checked={l.selected}
              />
            </label>
          ))}
        </div>
        <div className="flex justify-end py-3">
          <AddButton />
        </div>
      </form>
    </div>
  );
};

export default Legal;
