import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BasicSEO,
  BoLayout,
  Section1BoAcceuil,
  Section2BoAcceuil,
  Section3BoAcceuil,
  Section4BoAcceuil,
  Section5BoAcceuil,
  Section6BoAcceuil,
  SeoBoAcceuil,
} from "../../../../components";
import uploadImage from "../../../../helpers/uploadImages";
import { apiClient } from "../../../../actions/api";


const BoAcceuil = ({ page_id = 1 }) => {
  const [accueilData, setAccueilData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient().get(`/accueil`);
        setAccueilData(response.data);
        setInitialData(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [page_id, refresh]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccueilData({ ...accueilData, [name]: value });
  };

  const valider = async (e) => {
    e.preventDefault();

    try {
      const fieldsToCheck = ['imageConcept1', 'image1Concept2', 'image2Concept2', 'imageSeo'];
      const updatedData = { ...accueilData };

      for (const field of fieldsToCheck) {
        if (accueilData[field] !== initialData[field]) {
          const newPath = await uploadImage(field, accueilData, initialData, 'accueil-images');
          updatedData[field] = newPath;
        }
      }
      // console.log("updated data : ", updatedData);
      await apiClient().put(`/accueil/1`, updatedData);
      toast.success('Page d\'accueil modifiée avec succès', { autoClose: 600 });
      setRefresh(!refresh);
      } catch (err) {
        console.error('Erreur lors de la mise à jour des données d\'accueil :', err);
        toast.error('Erreur lors de la mise à jour des données d\'accueil', { autoClose: 600 });
      }      
  };

  return (
    <BoLayout>
      <BasicSEO/>
      <ToastContainer />
      <h1 className="text-xl md:text-2xl font-poppins py-2 px-4 text-lightOrange" style={{ fontWeight: 600 }}>
        Page « Accueil »
      </h1>
      <div className="bg-gray-100">
        <div className="py-3 px-4 space-y-5">
          <Section1BoAcceuil accueilData={accueilData} handleChange={handleChange} valider={valider} />
          <Section2BoAcceuil accueilData={accueilData} handleChange={handleChange} valider={valider} />
          <Section3BoAcceuil accueilData={accueilData} handleChange={handleChange} valider={valider} />
          <Section4BoAcceuil accueilData={accueilData} handleChange={handleChange} valider={valider} />
          <Section5BoAcceuil accueilData={accueilData} handleChange={handleChange} valider={valider} />
          <Section6BoAcceuil accueilData={accueilData} handleChange={handleChange} valider={valider} />
          <SeoBoAcceuil accueilData={accueilData} handleChange={handleChange} valider={valider} />
        </div>
      </div>
    </BoLayout>
  );
};

export default BoAcceuil;
