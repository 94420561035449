import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { BannerSectionP14 } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { apiClient } from '../../actions/api';
import { getClientByToken } from '../../actions/pages';
import { CLEAR_RESERVATION_DATA } from '../../constants/actions';

const Payment = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const res = useSelector(state => state?.detailsreservation);
    const client = useSelector(state => state?.auth?.client);
    const client_token = useSelector(state => state?.token?.client_token);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!res?.isCompleted || !client_token) {
            navigate('/reservation-details');
        } else {
            if (!client) {
                dispatch(getClientByToken(client_token));
            }
        }
    }, [res?.isCompleted, client_token, client, navigate, dispatch]);

    const handleSubmit = async () => {
        try {
            const response = await apiClient().post('/reservations', {
                espace_card_id: res?.reservation?.roomId,
                startTime: res?.reservation?.startTime,
                endTime: res?.reservation?.endTime,
                decor_id: res?.decor?.id,
                nbr_personne: parseInt(res?.nbrpersonne, 10),
            });

            const reservationId = response?.data?.id;
            if (!reservationId) {
                throw new Error('Reservation ID is missing');
            }

            await apiClient().post('/reservations/attach-formules', {
                reservation_id: reservationId,
                formules: res?.formules?.map(formule => formule.id),
            });

            // console.log('Reservation and formules attached successfully');

            const paymentData = {
                reservation_id: reservationId,
                price: res?.totalPrice,
                user_id: client?.type ? client?.id : null,
                guest_id: client?.type ? null : client?.id,
                mode: location?.state?.mode === "total" ? "total" : "50off",
                transaction_id: "sdfsvcvdsf",
            };

            const response2 = await apiClient().post('/payments', paymentData);

            // console.log('Payment success', response2?.data);

            dispatch({ type: CLEAR_RESERVATION_DATA });
        } catch (error) {
            console.error('Error during payment:', error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Paiement</title>
                <meta name="description" content="Effectuez le paiement pour votre réservation chez FIVE SPA PRIVATIVE. Choisissez le mode de paiement et finalisez votre réservation en toute sécurité." />
            </Helmet>
            {location?.state?.mode && (
                <div>
                    <BannerSectionP14 />
                    <div className="mt-5 text-center text-xl">
                        <p>Vous allez payer avec le mode {location?.state?.mode}</p>
                        <div>
                            <button onClick={handleSubmit}>Créer la réservation</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Payment;
