import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { BasicSEO, BoLayout } from "../../../../components";
import { apiClient } from "../../../../actions/api";
import { useNavigate } from "react-router-dom";

const ListGestionnaires = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, [page]);

  const fetchUsers = async () => {
    try {
      const res = await apiClient().get(`/gestionnaires`);
      setUsers(res?.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await apiClient().delete(`/gestionnaires/${id}`);
      fetchUsers();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'utilisateur :", error);
    }
  };

  const handleAdd = () => {
    navigate('/gestion/add-admin');
  };

  const handleEdit = (id) => {
    navigate(`/gestion/update-admin/${id}`);
  };

  return (
    <BoLayout>
      <BasicSEO />
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold">Liste des Gestionnaires</h1>
          <button
            onClick={handleAdd}
            className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 transition"
          >
            Ajouter un Gestionnaire
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-black tracking-wider">Nom</th>
                <th className="px-6 py-3 text-left text-black tracking-wider">Prénom</th>
                <th className="px-6 py-3 text-left text-black tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-black tracking-wider">Type d'utilisateur</th>
                <th className="px-6 py-3 text-left text-black tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-gray-100">
              {users.map((user) => (
                <tr key={user.id} className="hover:bg-gray-200">
                  <td className="px-6 py-4">{user.last_name}</td>
                  <td className="px-6 py-4">{user.first_name}</td>
                  <td className="px-6 py-4">{user.email}</td>
                  <td className="px-6 py-4">{user.type}</td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => handleEdit(user.id)}
                      className="text-green-500 hover:text-green-700 mr-4"
                    >
                      Modifier
                    </button>
                    <button
                      onClick={() => handleDelete(user.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-6 flex justify-between items-center">
          <button
            disabled={page === 1}
            onClick={() => setPage(page - 1)}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md disabled:opacity-50"
          >
            Précédent
          </button>
          <span className="text-gray-700">
            Page {page} sur {totalPages}
          </span>
          <button
            disabled={page === totalPages}
            onClick={() => setPage(page + 1)}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md disabled:opacity-50"
          >
            Suivant
          </button>
        </div>
      </div>
      <ToastContainer />
    </BoLayout>
  );
};

export default ListGestionnaires;