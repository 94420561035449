import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import {
  BannerSectionP5,
  ContentTwoP4,
  Footer,
  PrectationDeatailsSection3,
  PrectationDeatailsSection4,
  PresentaionDetailsSection2,
} from '../../components';
import { useSelector } from 'react-redux';

const PrestatioDetails = () => {
  const { id } = useParams();
  const prestation = useSelector((state) =>
    state?.pages?.data?.prestationcards?.find((pre) => pre.id == id)
  );

  return (
    <>
      <Helmet>
        <title>Détails de la Prestation</title>
        <meta name="description" content="Explorez les détails de la prestation chez FIVE SPA PRIVATIVE. Découvrez les caractéristiques, les services et les avantages offerts pour une expérience inoubliable." />
      </Helmet>
      <BannerSectionP5 prestation={prestation} />
      <PresentaionDetailsSection2 prestation={prestation} />
      <PrectationDeatailsSection3 prestation={prestation} />
      <PrectationDeatailsSection4 prestation={prestation} />
      <ContentTwoP4 prestation={prestation} />
      <Footer />
    </>
  );
};

export default PrestatioDetails;
