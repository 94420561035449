import { combineReducers } from 'redux'
import pages from './pages'
import global from './global'
import auth from './auth';
import token from './token';
import detailsreservation from './detailsreservation';
import calendar from './calendar'

 

const rootReducer = combineReducers({
    pages,
    global,
    auth,
    token,
    detailsreservation,
    calendar
})

export default rootReducer;