import { BasicSEO, BoLayout, ProfileForm } from '../../../components';

const Profile = () => {
  return (
    <BoLayout>
      <BasicSEO/>
      <div className="p-1 space-y-12">
        <ProfileForm/>
      </div>
    </BoLayout>
  );
};

export default Profile;
