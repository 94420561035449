import React, { useState } from "react";
import { AddButton, SimpleTextEditor } from "../../../..";

const Section3BoEspaces = ({ espacesData = {}, handleChange, valider }) => {

  const [currentAvantage, setCurrentAvantage] = useState(0);

  const avantages = [
    {
      id: 1,
      title: "titreAvantage1",
      description: "descriptionAvantage1",
    },
    {
      id: 2,
      title: "titreAvantage2",
      description: "descriptionAvantage2",
    },
    {
      id: 3,
      title: "titreAvantage3",
      description: "descriptionAvantage3",
    },
    {
      id: 4,
      title: "titreAvantage4",
      description: "descriptionAvantage4",
    }
  ];

  const handleChangeAdvantageCard = (e, index) => {
    const { name, value } = e.target;
    handleChange({
      target: {
        name: avantages[index][name],
        value,
      },
    });
  };

  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">
        Section Avantages
      </h1>
      <form onSubmit={valider} className="space-y-5">
        <div className="grid grid-cols-1 gap-3">
          <div className="space-y-2">
            <div className="col-span-1 lg:col-span-2 flex flex-col space-y-1">
              <label htmlFor="titreAvantages">Grand titre</label>
              <input
                type="text"
                name="titreAvantages"
                value={espacesData?.titreAvantages}
                onChange={handleChange}
                id="titreAvantages"
                className="w-full p-2 border rounded-md outline-none focus:outline-none"
                style={{ border: "1px solid gray" }}
              />
            </div>

            <div className="col-span-1 lg:col-span-2 flex flex-col space-y-1">
              <label htmlFor="descriptionAvantages">Description</label>
              <SimpleTextEditor
                value={espacesData.descriptionAvantages || ""}
                onChange={(value) => handleChange({ target: { name: 'descriptionAvantages', value } })}
                styleClassName="rounded-md h-fit"
              />
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-between gap-x-2">
          <button
            type="button"
            onClick={() =>
              setCurrentAvantage((prev) => (prev > 0 ? prev - 1 : prev))
            }
          >
            <i className="bi bi-arrow-left-circle text-3xl text-lightOrange"></i>
          </button>

          <div className="overflow-hidden w-full">
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${currentAvantage * 100}%)` }}
            >
              {avantages?.map((avantage, index) => (
                <div key={avantage.id} className="w-full flex-shrink-0">
                  <div className="space-y-2 w-full border rounded-md p-2 relative">
                    <h1 className="text-lightOrange text-center font-semibold underline border-b pb-2">
                      Avantage {index + 1}
                    </h1>
                    <div className="w-full space-y-2">
                      <div className="flex flex-col space-y-1 w-full min-w-fit">
                        <label htmlFor={`titreAvantage${index + 1}`}>
                          Titre Avantage {index + 1}
                        </label>
                        <input
                          type="text"
                          value={espacesData[avantage.title] || ""}
                          name="title"
                          id={`titreAvantage${index + 1}`}
                          onChange={(e) => handleChangeAdvantageCard(e, index)}
                          className="w-full p-2 border rounded-md outline-none focus:outline-none"
                          style={{ border: "1px solid gray" }}
                        />
                      </div>
                      <div className="flex flex-col space-y-1 w-full min-w-fit">
                        <label htmlFor={`descriptionAvantage${index + 1}`}>
                          Description Avantage {index + 1}
                        </label>
                        <SimpleTextEditor
                          value={espacesData[`descriptionAvantage${index + 1}`] || ""}
                          onChange={(value) => handleChangeAdvantageCard({ target: { name: 'description', value } }, index)}
                          styleClassName="rounded-md h-fit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <button
          type="button"
            onClick={() =>
              setCurrentAvantage((prev) =>
                prev < avantages?.length - 1 ? prev + 1 : prev
              )
            }
          >
            <i className="bi bi-arrow-right-circle text-3xl text-lightOrange"></i>
          </button>
        </div>

        <div className="text-end col-span-1 lg:col-span-2">
          <AddButton />
        </div>
      </form>
    </div>
  );
};

export default Section3BoEspaces;
