const DeleteConfirmationModal = ({ show, onClose, onConfirm }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-4 max-w-sm w-full">
        <h3 className="text-lg font-semibold">Confirmer la suppression</h3>
        <p className="mt-2">Êtes-vous sûr de vouloir supprimer ce blog?</p>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded"
            onClick={onClose}
          >
            Annuler
          </button>
          <button
            className="bg-red-600 text-white px-4 py-2 rounded"
            onClick={onConfirm}
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
