import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BoLayout } from "../../../components";
import { getData } from "../../../actions/pages";
import FormattingDate from "../../../helpers/formattingDate";
import getCurrentDateFormattedYMD from "../../../helpers/getCurrentDateFormatedYMD";
import { apiClient } from "../../../actions/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BoReservationDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const reservation = useSelector((state) =>
    state?.pages?.data?.reservations?.find((res) => res?.id === parseInt(id))
  );
  const payer = useSelector((state) =>
    state?.pages?.data?.paiments?.find(
      (payment) => payment?.reservation_id === reservation?.id
    )
  );

  useEffect(() => {
    dispatch(getData("/reservations"));
    dispatch(getData("/payments"));
  }, [id, dispatch]);

  const [teamNote, setTeamNote] = useState(reservation?.team_note);

  const handleTeamNoteChange = (e) => {
    setTeamNote(e.target.value);
  };

  const handlePayer = async () => {
    const confirmPayment = window.confirm(
      "Êtes-vous sûr de vouloir payer le reste ?"
    );
    if (confirmPayment) {
      try {
        await apiClient().put(`/payments/${payer?.id}`, {
          mode: "total",
        //   price: parseFloat(payer?.price) * 2,
        });
        toast.success("Le paiement du reste s'est bien passé !");
        dispatch(getData("/reservations"));
        dispatch(getData("/payments"));
      } catch (err) {
        console.error(err);
        toast.error("Une erreur est survenue lors du paiement.");
      }
    }
  };

  const cancelReservation = async (id) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir annuler cette réservation ? Cette action est irréversible."
    );

    if (confirmed) {
      try {
        await apiClient().post(`/reservations/${id}?_method=PUT`, {
          status: "cancelled",
        });
        toast.success("Réservation annulée avec succès.", { autoClose: 300 });
        dispatch(getData("/reservations"));
      } catch (err) {
        toast.error(
          "Une erreur est survenue lors de l'annulation de la réservation."
        );
        console.error(err);
      }
    }
  };

  const handleTeamNoteSubmit = async () => {
    try {
      await apiClient().put(`/reservations/${reservation?.id}`, {
        team_note: teamNote,
      });
      toast.success("Note de l'équipe ajoutée avec succès.");
      dispatch(getData("/reservations"));
    } catch (err) {
      console.error(err);
      toast.error("Une erreur est survenue lors de l'ajout de la note.");
    }
  };

  useEffect(() => {
    dispatch(getData("/reservations"));
    dispatch(getData("/payments"));
  }, [id, dispatch]);

  // const formatTimeWithoutSeconds = (time) => {
  //     if (!time) return "";
  //     const [hours, minutes] = time.split(":");
  //     return `${hours}:${minutes}`;
  // };

  const getReservationStatus = (startTime, endTime, date) => {
    const now = new Date();
    const startDateTime = new Date(`${date}T${startTime}`);
    const endDateTime = new Date(`${date}T${endTime}`);

    if (now < startDateTime)
      return {
        status: "En cours de traitement",
        style: "text-blue-600 bg-blue-50",
      };
    if (now > endDateTime)
      return { status: "Terminée", style: "text-gray-900 bg-gray-200" };
    return {
      status: "En cours de traitement",
      style: "text-green-600 bg-green-100",
    };
  };

  return (
    <BoLayout>
      <div className="p-6">
        <div className="my-4">
          <button
            onClick={() =>
              navigate("/gestion/reservations", { state: { showList: true } })
            }
            className="font-poppins flex items-center gap-4 text-lg text-gray-700"
          >
            <i className="bi bi-arrow-left text-lg md:text-2xl"></i>
            {/* <span>Retour</span> */}
          </button>
        </div>

        {reservation ? (
          <div className="reservation-details space-y-6 font-outfit text-sm md:text-base">
            <div className="flex items-center justify-between">
              <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800">
                Détails de la Réservation
              </h2>
              {reservation?.status !== "cancelled" && (
                <button
                  onClick={() => cancelReservation(reservation?.id)}
                  className="text-red-600 px-4 py-1 bg-red-200"
                >
                  Annuler
                </button>
              )}
            </div>

            <table className="border-collapse w-full">
              <thead>
                <tr>
                  <th className="text-left py-2 px-4 bg-blue-50 border">
                    Champ
                  </th>
                  <th className="text-left py-2 px-4 bg-blue-50 border">
                    Valeur
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-left py-2 px-4 border">ID</td>
                  <td className="text-left py-2 px-4 border">
                    #{reservation?.id}
                  </td>
                </tr>
                <tr>
                  <td className="text-left py-2 px-4 border">Date</td>
                  <td className="text-left py-2 px-4 border">
                    {FormattingDate(reservation?.date)}
                  </td>
                </tr>
                <tr>
                  <td className="text-left py-2 px-4 border">Heure de Début</td>
                  <td className="text-left py-2 px-4 border">
                    {reservation?.startTime}
                  </td>
                </tr>
                <tr>
                  <td className="text-left py-2 px-4 border">Heure de Fin</td>
                  <td className="text-left py-2 px-4 border">
                    {reservation?.endTime}
                  </td>
                </tr>
                <tr>
                  <td className="text-left py-2 px-4 border">Statut</td>
                  <td className="text-left py-2 px-4 border">
                    {reservation?.status === "cancelled"
                      ? "Annullé"
                      : getReservationStatus(
                          reservation?.startTime,
                          reservation?.endTime,
                          reservation?.date
                        )?.status}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="mt-8">
              <h3 className="text-base sm:text-lg md:text-xl font-semibold text-gray-800">
                Formule, Décor, et Nombre de personnes
              </h3>
              <table className="w-full border-collapse mt-4">
                <tbody>
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      Formule choisie
                    </th>
                    <td className="text-left py-2 px-4 border capitalize md:w-2/3">
                      {reservation.formules
                        ?.map((formule) => formule.titre)
                        .join(", ")}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      Décor choisi
                    </th>
                    <td className="text-left py-2 px-4 border capitalize md:w-2/3">
                      {reservation.decor?.titre}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      Nombre de personnes
                    </th>
                    <td className="text-left py-2 px-4 border md:w-2/3">
                      {reservation?.nbr_personne}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      Coût supplémentaire
                    </th>
                    <td className="text-left py-2 px-4 border md:w-2/3">
                      {reservation?.price_personnes}
                      <i className="bi bi-currency-euro"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="mt-8">
              <h3 className="text-base sm:text-lg md:text-xl font-semibold text-gray-800">
                Note de l'équipe
              </h3>
              <textarea
                className="w-full mt-2 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                rows="4"
                placeholder="Ajouter une note ici..."
                value={teamNote}
                onChange={handleTeamNoteChange}
              />
              <button
                onClick={handleTeamNoteSubmit}
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
              >
                {teamNote ? "Modifier Note" : "Ajouter Note"}
              </button>
            </div>

            <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800 mt-8">
              Détails du Client
            </h2>
            {payer ? (
              <table className="w-full border-collapse mt-4">
                <tbody>
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      Nom du Client
                    </th>
                    <td className="text-left py-2 px-4 border capitalize md:w-2/3">
                      {payer?.user?.first_name || payer?.guest?.first_name}{" "}
                      {payer?.user?.last_name || payer?.guest?.last_name}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      Email
                    </th>
                    <td className="text-left py-2 px-4 border md:w-2/3">
                      {payer?.user?.email || payer?.guest?.email}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      Téléphone
                    </th>
                    <td className="text-left py-2 px-4 border md:w-2/3">
                      {payer?.user?.phone || payer?.guest?.phone}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      Note du Client
                    </th>
                    <td className="text-left py-2 px-4 border md:w-2/3">
                      <div className="bg-gray-100 p-4 rounded-md text-gray-700">
                        {reservation?.client_note || "Aucune note disponible."}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <p className="text-gray-500">
                Aucun détail de paiement disponible.
              </p>
            )}

            <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800 mt-8">
              Détails du paiement
            </h2>
            <div className="w-full">
              <table className="border-collapse mt-4 w-full">
                <tbody>
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      Coût total
                    </th>
                    <td className="text-left font-bold py-2 px-4 border capitalize md:w-2/3">
                      {payer?.price}
                      <i className="bi bi-currency-euro"></i>
                    </td>
                  </tr>
                  {payer?.mode !== "total" && (
                    <>
                      <tr>
                        <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                          Montant payé
                        </th>
                        <td className="text-left text-green-600 py-2 px-4 border capitalize md:w-2/3">
                          {parseFloat(payer?.price) / 2}
                          <i className="bi bi-currency-euro"></i>
                        </td>
                      </tr>
                      <tr>
                        <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                          Montant restant à payer
                        </th>
                        <td className="text-left text-red-500 py-2 px-4 border capitalize md:w-2/3">
                          {parseFloat(payer?.price) / 2}
                          <i className="bi bi-currency-euro"></i>
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      Méthode de paiement
                    </th>
                    <td className="text-left py-2 px-4 border capitalize md:w-2/3">
                      {payer?.method}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      ID de la transaction
                    </th>
                    <td className="text-left py-2 px-4 border capitalize md:w-2/3">
                      {payer?.transaction_id}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      Date de la transaction
                    </th>
                    <td className="text-left py-2 px-4 border md:w-2/3">
                      {getCurrentDateFormattedYMD(payer?.updated_at)}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-left py-2 px-4 bg-blue-50 border max-w-fit md:w-1/3">
                      Mode de paiement
                    </th>
                    <td className="text-left py-2 px-4 border md:w-2/3">
                      {payer?.mode === "total" ? "Totalité" : "Moitié"}
                    </td>
                  </tr>
                  {payer?.mode !== "total" && (
                    <tr>
                      <button
                        onClick={handlePayer}
                        className="mt-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-500"
                      >
                        Payer le reste
                      </button>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p>Chargement...</p>
        )}

        <ToastContainer />
      </div>
    </BoLayout>
  );
};

export default BoReservationDetails;
