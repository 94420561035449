import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { BASEIMAGEURL } from "../../constants/actions";
import { useSelector } from "react-redux";

const BlogCard = ({ post }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/blog/${post?.id}`)}
      className="cursor-pointer flex flex-col justify-between gap-y-2 overflow-hidden h-96"
    >
      <div className="overflow-hidden">
        <img
          src={BASEIMAGEURL + post?.image}
          alt={`Blog post by ${post?.author}`}
          className=" transition-transform duration-300 ease-in-out transform hover:scale-105"
        />
      </div>
      <p
        className="text-lightOrange text-sm lg:text-[15px] mt-1"
        style={{ fontFamily: "Archivo", fontWeight: "600" }}
      >
        {post?.author} • {post?.date}
      </p>
      <div className="flex flex-row justify-between items-center text-black font-semibold">
        <h2
          className="text-lg lg:text-[25px]"
          style={{ fontFamily: "Playfair Display" }}
        >
          {post?.title}
        </h2>
        <span>
          <i className="bi bi-arrow-up-right"></i>
        </span>
      </div>
      <p className="text-gray-500 text-sm text-justify" dangerouslySetInnerHTML={{ __html: post?.description }}/>
      <div className="flex items-center flex-wrap lg:flex-nowrap gap-1 mt-1">
        {/* Uncomment and adjust this block if `post.keys` is available and used */}
        {/* {post?.keys.map((key, index) => (
          <div
            key={index}
            className={`py-1 px-2 text-xs sm:text-sm md:text-sm lg:text-sm rounded-xl ${key.color} ${key.bg}`}
          >
            {key.title}
          </div>
        ))} */}
      </div>
    </div>
  );
};

const BlogSection = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const data = useSelector(state => state?.pages?.data?.blogCards);

  useEffect(() => {
    if (data?.length > 0) {
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentItems(data.slice(indexOfFirstItem, indexOfLastItem));
      setTotalPages(Math.ceil(data.length / itemsPerPage));
    }
  }, [data, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <section className="w-10/12 sm:9/12 md:w-10/12 mx-auto my-20">
      <div className="grid gap-x-3 gap-y-9 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {currentItems?.map((post, index) => (
          <BlogCard key={index} post={post} />
        ))}
      </div>
      <div className="flex justify-center mt-8 text-base">
        <nav className="flex items-center space-x-1 md:space-x-2 mt-3">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="disabled:opacity-50 text-xl w-9 h-9 min-w-9 min-h-9 rounded-full border"
          >
            <i className="bi bi-arrow-left-short"></i>
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`w-9 h-9 min-w-9 min-h-9 rounded-full ${
                currentPage === index + 1
                  ? "bg-lightyellow text-medyellow border border-medyellow"
                  : "border"
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="disabled:opacity-50 text-xl w-9 h-9 min-w-9 min-h-9 rounded-full border"
          >
            <i className="bi bi-arrow-right-short"></i>
          </button>
        </nav>
      </div>
    </section>
  );
};

export default BlogSection;
