import React, { useState } from 'react';
import BoHeader from '../boheader';
import BoSidebar from '../bosidebar';

const BoLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="">
      <div className={`fixed inset-0 z-39 bg-gray-200 bg-opacity-50 ${isSidebarOpen ? 'block' : 'hidden'} md:hidden`} onClick={toggleSidebar}></div>
      <BoSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="ml-0 md:ml-[229px]">
        <BoHeader toggleSidebar={toggleSidebar} />
        <div className="">
          {children}
        </div>
      </div>
    </div>
  );
};

export default BoLayout;
