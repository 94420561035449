import React, { useState } from "react";
import './style.scss'

const TestimonialCard = ({ avatarSrc, authorName, ratingSrc, content }) => (
  <article className="bg-white p-6 rounded-lg shadow-md">
    <header className="flex items-center mb-4">
      <img src={avatarSrc} alt={`${authorName}'s avatar`} className="w-12 h-12 rounded-full mr-4" />
      <div>
        <h3 className="font-semibold text-lg">{authorName}</h3>
        <img src={ratingSrc} alt="Rating" className="h-5" />
      </div>
    </header>
    <p className="text-gray-600 italic">{content}</p>
  </article>
);

function TestimonialsSection() {
  const [activeIndex, setActiveIndex] = useState(0);

  const testimonials = [
    {
      avatarSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e899a35e2d909ac481cca789d4107891f176239ba94556d724abce6a06ca396e?apiKey=d4c454cae25e4aff9ddf3a69930fcf94&",
      authorName: "John Doe",
      ratingSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/d9b38b09459e6759636190201c1641cb04b66f0f47b8f418fcd6b46b904186e3?apiKey=d4c454cae25e4aff9ddf3a69930fcf94&",
      content: "\" Expérience exceptionnelle ! Les soins sont de haute qualité et le cadre est magnifique. Je suis ressortie totalement relaxée et revigorée \""
    },
    {
      avatarSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/664441eda79783b5a32bbda34d7c32ef67df01f1f08fa1076aab0666973763fd?apiKey=d4c454cae25e4aff9ddf3a69930fcf94&",
      authorName: "Camile joe",
      ratingSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/807d130c0881b56f7ae995e4d72e597c60514aed2fab8bd21a408f6846fea515?apiKey=d4c454cae25e4aff9ddf3a69930fcf94&",
      content: "\" Un moment de pure détente ! Le personnel est aux petits soins et l'ambiance est vraiment apaisante. Je recommande vivement ce spa \""
    },
    {
      avatarSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e899a35e2d909ac481cca789d4107891f176239ba94556d724abce6a06ca396e?apiKey=d4c454cae25e4aff9ddf3a69930fcf94&",
      authorName: "John Doe",
      ratingSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/d9b38b09459e6759636190201c1641cb04b66f0f47b8f418fcd6b46b904186e3?apiKey=d4c454cae25e4aff9ddf3a69930fcf94&",
      content: "\" Expérience exceptionnelle ! Les soins sont de haute qualité et le cadre est magnifique. Je suis ressortie totalement relaxée et revigorée \""
    },
  ];

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <section className="bg-gray-100 py-5">
      <div className="w-10/12 mx-auto px-4 feed-bg p-5 rounded-2xl relative overflow-hidden">
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 ">
          <h2 className="text-2xl sm:text-3xl lg:ml-10 text-gray-50 mb-4" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>Ils donnaient le meilleur feedback</h2>
          <p className="mb-6 text-gray-50 text-justify lg:ml-10">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div className="relative overflow-hidden">
            <div 
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${activeIndex * 100}%)` }}
            >
              {testimonials.map((testimonial, index) => (
                <div key={index} className="w-full flex-shrink-0">
                  <TestimonialCard {...testimonial} />
                </div>
              ))}
            </div>
          </div>
          <nav className="flex justify-center mt-8" aria-label="Testimonial navigation">
            {testimonials.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full mx-1 focus:outline-none ${
                  index === activeIndex ? 'bg-darckyellow' : 'bg-gray-300'
                }`}
                onClick={() => handleDotClick(index)}
                aria-current={index === activeIndex}
              ></button>
            ))}
          </nav>
        </div>
      </div>
    </section>
  );
}

export default TestimonialsSection;