import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  BannerSectionP12,
  Calendar,
  CalendrierTable,
  ChoosePersons,
  Decos,
  Formules,
  ReservationBarr,
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getReservationByDate } from '../../actions/calendar';
import { getData } from '../../actions/pages';
import { SET_PERIOD } from '../../constants/actions';

const Reservation = () => {
  const reservationDetails = useSelector(state => state?.detailsreservation);
  const parametres = useSelector(state => state?.pages?.data?.parametres);
  const [show, setShow] = useState(reservationDetails?.isCompleted);
  const [viewMode, setViewMode] = useState({ timeStart: '08:30:00', timeEnd: '23:00:00' });
  const [filter, setFilter] = useState('Jour');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData('/formules'));
    dispatch(getData('/decores'));
    dispatch(getData('/parametres'));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getReservationByDate(reservationDetails?.date));
  }, [reservationDetails?.date, dispatch]);

  useEffect(() => {
    if (reservationDetails?.period !== null) {
      reservationDetails?.period === 'nuite'
        ? setViewMode({ timeStart: parametres?.nuite_start, timeEnd: parametres?.nuite_end })
        : setViewMode({ timeStart: parametres?.journee_start, timeEnd: parametres?.journee_end });
    }
  }, [reservationDetails?.period, parametres]);

  useEffect(() => {
    setShow(reservationDetails?.isCompleted);
  }, [reservationDetails?.isCompleted]);

  return (
    <>
      <Helmet>
        <title>Réservation</title>
        <meta name="description" content="Planifiez votre réservation chez FIVE SPA PRIVATIVE. Choisissez les formules, décos, et personnalisez votre expérience en fonction de la période et de vos besoins." />
      </Helmet>
      <div className="reserv-page pb-64">
        <BannerSectionP12 reservationDetails={reservationDetails} />
        <div className="flex rounded-lg border make-shadow mx-auto my-6 w-fit overflow-hidden">
          <div
            onClick={() => dispatch({ type: SET_PERIOD, payload: 'journee' })}
            className={`flex justify-center items-center gap-3 py-4 px-7 text-xl md:text-2xl w-1/2 cursor-pointer ${reservationDetails?.period === 'journee' ? 'bg-gray-700 text-golddo' : 'bg-white'}`}
          >
            <span className="text-base min-w-fit font-playfairdisplay font-bold">La Journée</span>
            <i className="bi bi-brightness-high-fill"></i>
          </div>
          <div
            onClick={() => dispatch({ type: SET_PERIOD, payload: 'nuite' })}
            className={`flex justify-center items-center gap-3 py-4 px-7 text-lg md:text-xl w-1/2 cursor-pointer ${reservationDetails?.period === 'nuite' ? 'bg-gray-700 text-golddo' : 'bg-white'}`}
          >
            <span className="text-base min-w-fit font-playfairdisplay font-bold">La Nuitée</span>
            <i className="bi bi-moon-stars-fill"></i>
          </div>
        </div>
        <Calendar reservationDetails={reservationDetails} filter={filter} setFilter={setFilter} />
        <CalendrierTable
          startTime={viewMode.timeStart}
          endTime={viewMode.timeEnd}
          heightCellul={48}
          cleaningDuration={parametres?.entretien_duration}
          reservation_duration={parametres?.reservation_duration}
          filter={filter}
        />
        <ChoosePersons nbr={reservationDetails?.nbrpersonne} />
        <div className={`${show ? 'h-24 lg:h-10' : 'h-0'} transition-all duration-1000 ease-linear`}></div>
        <div className={`fixed z-50 bottom-0 left-0 w-full transition-all duration-1000 ease-in-out ${show ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}>
          <ReservationBarr from="res" />
        </div>
      </div>
    </>
  );
};

export default Reservation;
