
const ClientForm = ({ client, handleChange, handleSubmit, UpdatePassword }) => {
  
  return (
    <div className="space-y-8 p-5 w-full">
      <form
        className="grid grid-cols-1 md:grid-cols-2 gap-3"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-2">
          <label htmlFor="first_name">Prénom</label>
          <input
            type="text"
            name="first_name"
            id="first_name"
            value={client?.first_name || ""}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="last_name">Nom</label>
          <input
            type="text"
            name="last_name"
            id="last_name"
            value={client?.last_name || ""}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            value={client?.email || ""}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="phone">Téléphone</label>
          <input
            type="text"
            name="phone"
            id="phone"
            value={client?.phone || ""}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="code_postal">Code Postal</label>
          <input
            type="text"
            name="code_postal"
            id="code_postal"
            value={client?.code_postal || ""}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="birthday">Date de Naissance</label>
          <input
            type="date"
            name="birthday"
            id="birthday"
            value={client?.birthday || ""}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
          />
        </div>
        <div className="flex justify-end gap-2 col-span-1 md:col-span-2">
          <button
            type="submit"
            className="text-orange-primary rounded-md px-4 py-1 hover:bg-gray-200"
            style={{ border: "1px solid gray" }}
          >
            Valider
          </button>
        </div>
      </form>
      {/* <h1 className="text-lg font-semibold">Modifier le mot de passe</h1> */}
      <form
        className="grid grid-cols-1 md:grid-cols-2 gap-3"
        onSubmit={UpdatePassword}
      >
        <div className="flex flex-col gap-2">
          <label htmlFor="ancienepassword">Votre ancien mot de passe</label>
          <input
            type="password"
            name="ancienepassword"
            id="ancienepassword"
            onChange={handleChange}
            placeholder="mot de passe"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            required
            minLength={6}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="password">Votre nouveau mot de passe</label>
          <input
            type="password"
            name="password"
            id="password"
            onChange={handleChange}
            placeholder="mot de passe"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            required
            minLength={6}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="confirePassword">Confirmez votre nouveau mot de passe</label>
          <input
            type="password"
            name="confirePassword"
            id="confirePassword"
            onChange={handleChange}
            placeholder="confirmer mot de passe"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            required
            minLength={6}
          />
        </div>
        <div className="flex justify-end gap-2 col-span-1 md:col-span-2">
          <button
            type="submit"
            className="text-orange-primary rounded-md px-4 py-1 hover:bg-gray-200"
            style={{ border: "1px solid gray" }}
          >
            Valider
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientForm;
