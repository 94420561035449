import { useDispatch, useSelector } from 'react-redux';
import { BasicSEO, BoLayout, Section1Utilisateurs, Section2Utilisateurs } from '../../../components'
import { useEffect, useState } from 'react';
import { getData } from '../../../actions/pages';


const BoUtilisateurs = () => {
  const [refresh, setRefresh] = useState(false);
  const data = useSelector(state => state?.pages?.data);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getData('/clients'));
    dispatch(getData('/guests'));
  },[refresh]);

  return (
    <BoLayout>
      <BasicSEO/>
      <div className="bg-white-50 p-3">
        <div className="space-y-6">
          <Section1Utilisateurs clients={data?.clients} guests={data?.guests} />
          <Section2Utilisateurs clients={data?.clients} guests={data?.guests} refresh={refresh} setRefresh={setRefresh} />
        </div>
      </div>
    </BoLayout>
  )
}

export default BoUtilisateurs