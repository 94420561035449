import { useDispatch, useSelector } from 'react-redux';
import { apiClient } from '../../../actions/api';
import { AdditionalPrice, BasicSEO, BoLayout, CellsDurations, CodePromotion, Emails, JourneeNuite, Tabs } from '../../../components';
import { useEffect, useState } from 'react';
import { getData } from '../../../actions/pages';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BoParametre = () => {
  const parametres = useSelector((state) => state?.pages?.data?.parametres);
  const [parametresData, setParametresData] = useState(parametres);
  const dispatch = useDispatch();
  const [tab, setTab] = useState(1);

  useEffect(() => {
    dispatch(getData('/parametres'));
  }, [dispatch]);

  useEffect(() => {
    setParametresData(parametres);
  }, [parametres]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParametresData({ ...parametresData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const postData = async () => {
      try {
        const response = await apiClient().put('/parametres/1', parametresData);
        toast.success("Les paramètres ont été modifiés avec succès", { autoClose: 700 });
        dispatch(getData('/parametres'));
      } catch (err) {
        console.error(err);
        toast.error("Erreur lors de la modification des paramètres", { autoClose: 1000 });
      }
    };
    postData();
  };

  return (
    <BoLayout>
      <ToastContainer />
      <BasicSEO />
      <Tabs tab={tab} setTab={setTab} />
      {tab === 1 && (
        <>
          <JourneeNuite handleSubmit={handleSubmit} parametresData={parametresData} handleChange={handleChange} />
          <CellsDurations handleSubmit={handleSubmit} parametresData={parametresData} handleChange={handleChange} />
        </>
      )}
      {tab === 2 && <Emails />}
      {tab === 3 && <CodePromotion />}
      {tab === 4 && <AdditionalPrice handleSubmit={handleSubmit} parametresData={parametresData} handleChange={handleChange} />}
    </BoLayout>
  );
}

export default BoParametre;