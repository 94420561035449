import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { BannerSectionP6, ContentTwoP6, EspaceDetailList, EspaceDetailSection3, EspaceDetailSection5, Footer, QuestionReponses, SpaceSectionP6 } from '../../components';
import './style.scss';
import { useSelector } from 'react-redux';

const EspaceDetails = () => {
    const { id } = useParams();
    const espace = useSelector(state => state?.pages?.data?.espaceCards?.find(space => space.id == id));

    return (
        <>
            <Helmet>
                <title>Détails de l'Espace</title>
                <meta name="description" content="Découvrez les détails de l'espace choisi dans FIVE SPA PRIVATIVE. Explorez les caractéristiques, les services, et les offres spéciales disponibles pour rendre votre expérience unique." />
            </Helmet>
            <div className="layout-container space-y-20">
                <BannerSectionP6 espace={espace} />
                <div className="question-responses">
                    <QuestionReponses espace={espace} />
                </div>
                <SpaceSectionP6 id={id} />
                <EspaceDetailList />
                <EspaceDetailSection3 espace={espace} />
                <ContentTwoP6 espace={espace} />
                <EspaceDetailSection5 espace={espace} />
                <Footer />
            </div>
        </>
    );
}

export default EspaceDetails;
