import { TOGGLE_LOADING } from "../constants/actions"

const defaultState = {
    loading:false

}

export default (state = defaultState, action) => {
    const { type, payload = {} } = action

    switch (type) {
        case TOGGLE_LOADING:
            return {...state, loading:payload}
        default:
            return state
    }
}
