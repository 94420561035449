import React, { useState, useEffect, useRef } from "react";
import BasicDateCalendar from "../basicdatecalendar";
import DatePicker from "../datepicker";

const Calendar = ({ filter, setFilter }) => {
  const [isPickerOpen, setPickerIsOpen] = useState(false);
  const [isSemaineJourOpen, setIsSemaineJourOpen] = useState(false);
  const calendarRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setPickerIsOpen(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsSemaineJourOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFilterChange = (value) => {
    setFilter(value);
    setIsSemaineJourOpen(false);
  };

  return (
    <div className="flex items-center justify-center gap-3 max-w-7xl mx-auto flex-col lg:flex-row w-11/12 lg:w-full px-1 mt-7">
      <div className={`${filter !== 'Jour' ? 'flex md:hidden' : 'flex'} items-center justify-center flex-col md:flex-row gap-y-2 gap-x-5 py-2 min-h-16 border rounded bg-white w-full md:basis-2/3 make-shadow`}>
        <DatePicker />
        <div className="relative min-w-80 md:min-w-96 calendar-dropdown" ref={calendarRef}>
          <button
            className="w-full text-center h-full text-golddo font-bold text-base"
            onClick={() => setPickerIsOpen(!isPickerOpen)}
          >
            Choisir une date directement
          </button>
          <div
            className={`absolute z-10 w-full mt-2 bg-white border rounded-lg shadow-lg transition-all duration-300 ${
              isPickerOpen ? "opacity-100 visible" : "opacity-0 invisible"
            }`}
          >
            <BasicDateCalendar />
          </div>
        </div>
      </div>
      <div className="relative border rounded h-16 min-h-16 hidden md:flex justify-center items-center cursor-pointer bg-white md:basis-1/3 w-full make-shadow" ref={dropdownRef}>
        <div
          className="flex items-center justify-between w-full h-full px-3"
          onClick={() => setIsSemaineJourOpen(!isSemaineJourOpen)}
        >
          <h4 className="text-black">Affichage du calendrier</h4>
          <b className="capitalize font-bold text-golddo">{filter}</b>
          <div className="flex items-center justify-center">
            <i className={`bi bi-chevron-${
                isSemaineJourOpen ? "up" : "down"
              } text-xl transition-transform duration-400`}
            ></i>
          </div>
        </div>
        <div
          className={`absolute z-10 top-full left-0 bg-white w-full rounded shadow-lg overflow-hidden transition-all duration-300 ${
            isSemaineJourOpen ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <div
            className={`capitalize cursor-pointer p-2 text-center font-bold transition-colors duration-300 ${
              filter === "Jour"
                ? "text-golddo hover:bg-gray-100"
                : "text-gray-900 hover:bg-gray-100"
            }`}
            onClick={() => handleFilterChange("Jour")}
          >
            Calendrier Jour
          </div>
          <div
            className={`capitalize cursor-pointer p-2 text-center font-bold transition-colors duration-300 ${
              filter === "Semaine"
                ? "text-golddo hover:bg-gray-100"
                : "text-gray-900 hover:bg-gray-100"
            }`}
            onClick={() => handleFilterChange("Semaine")}
          >
            Calendrier Semaine
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
