import { useSelector } from 'react-redux';
import { AdvantagesP3, BannerSectionP3, ContentTwoP3, Footer, PrestationsP2 } from '../../components'
import { Helmet } from 'react-helmet';
import { BASEIMAGEURL } from '../../constants/actions';

const NosPrestations = () => {
  const data = useSelector(state => state?.pages?.data?.prestation);
  return (
    <div className='space-y-24'>
      <Helmet>
        <title>{data?.titreSeos}</title>
        <meta name="description" content={data?.descriptionSeos} />
        <meta name="keywords" content={data?.motsClesSeos} />
        <meta property="og:image" content={BASEIMAGEURL + data?.imageSeos} />
      </Helmet>
        <BannerSectionP3 data={data}/>
        <PrestationsP2/>
        <AdvantagesP3 data={data}/>
        <ContentTwoP3 data={data}/>
        <Footer/>
    </div>
  )
}

export default NosPrestations