import "./style.scss";
import Header from "../header";
function BannerSectionP13({ from, title }) {
  return (
    <div className="relative h-[400px]">
      <section className="hr-sec h-full">
        <Header />
        {from !== "confirmation" ? (
          <div className="absolute inset-0 flex items-center justify-center mt-32">
            <h1
              className="text-3xl lg:text-5xl text-white"
              style={{ fontWeight: "bold", fontFamily: "Playfair Display" }}
            >
              Continue la réservation
            </h1>
          </div>
        ) : (
          <div className="absolute inset-0 flex items-center justify-center mt-32 flex flex-col gap-2.5">
            <h2
              style={{ fontWeight: "bold", fontFamily: "Playfair Display" }}
              className="text-white text-2xl md:text-4xl lg:text-5xl"
            >
              {title}
            </h2>
            <div
              style={{ fontWeight: "400", fontFamily: "Playfair Display" }}
              className="mt-4 text-center text-white max-w-xl text-base md:text-lg"
            >
              Votre réservation a été confirmée avec succès, vous recevrez un
              e-mail de confirmation sous peu.
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

export default BannerSectionP13;
