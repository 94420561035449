import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ScrollToTop } from '../components';
import { AuthProvider, useAuth } from '../context/authContext';
import * as Pages from '../pages';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getUserByToken } from '../actions/pages';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/gestion/login" replace />;
};

const RedirectIfAuthenticated = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Navigate to="/gestion" replace /> : children;
};

const RedirectIfClientAuthenticated = ({ children }) => {
  const { isClientAuthenticated } = useAuth();
  return isClientAuthenticated ? <Navigate to="/" replace /> : children
};

const RedirectIfClientNotAuthenticated = ({ children }) => {
  const { isClientNotAuthenticated } = useAuth();
  return !isClientNotAuthenticated ? <Navigate to="/" replace /> : children
};

const RedirectIfNotCompleted = ({ children }) => {
  const { isCompleted } = useAuth();
  return !isCompleted ? <Navigate to="/reservation" replace /> : children;
};

const publicRoutes = [
  { path: "/", element: <Pages.MainPage/> },
  { path: "/espaces", element: <Pages.NosEspaces /> },
  { path: "/prestations", element: <Pages.NosPrestations /> },
  { path: "/contact", element: <Pages.Contact /> },
  { path: "/prestations/:id", element: <Pages.PrestatioDetails /> },
  { path: "/espaces/:id", element: <Pages.EspaceDetails /> },
  { path: "/blog", element: <Pages.Blog /> },
  { path: "/blog/:id", element: <Pages.BlogDetail /> },
  { path: "/politiques", element: <Pages.Politique /> },
  { path: "/conditions", element: <Pages.Conditions /> },
  { path: "/rgpd", element: <Pages.RGPD /> },
  { path: "/reservation", element: <Pages.Reservation /> },
  { path: "/reservation/formules", element: <Pages.FormulesPage /> },
  { path: "/reservation/decos", element: <Pages.DecorPage /> },
  { path: "/reservation/confirmation", element: <Pages.AfterPayment /> },
  { path: "/payment", element: <Pages.Payment /> },
  { path: "/forgot", element: <Pages.ForgotPassword /> },
  { path: "/reset", element: <Pages.ResetPassword /> },
];

const protectedRoutes = [
  { path: "/gestion", element: <Pages.Dashboard /> },
  { path: "/gestion/blog", element: <Pages.BoBlog /> },
  { path: "/gestion/blog/:id", element: <Pages.BlogPost /> },
  { path: "/gestion/blog/ajouteblog", element: <Pages.AddBoBlog /> },
  { path: "/gestion/utilisateurs", element: <Pages.BoUtilisateurs /> },
  { path: "/gestion/reservations", element: <Pages.BoReservations /> },
  { path: "/gestion/reservations/:id", element: <Pages.BoReservationDetails /> },
  { path: "/gestion/paiements", element: <Pages.BoPaiements /> },
  { path: "/gestion/parametres", element: <Pages.BoParametre /> },
  { path: "/gestion/pages/accueil", element: <Pages.BoAcceuil /> },
  { path: "/gestion/pages/espaces", element: <Pages.BoEspaces /> },
  { path: "/gestion/pages/espaces/:id", element: <Pages.EspacesDetails /> },
  { path: "/gestion/pages/prestations", element: <Pages.BoPrestations /> },
  { path: "/gestion/pages/prestations/:id", element: <Pages.PrestationDetails /> },
  { path: "/gestion/pages/contact", element: <Pages.BoContact /> },
  { path: "/gestion/pages/header", element: <Pages.BoNavbar /> },
  { path: "/gestion/pages/footer", element: <Pages.BoFooter /> },
  { path: "/gestion/profil", element: <Pages.Profile /> },
  { path: "/gestion/decos-formules", element: <Pages.DecoFormules /> },
  { path: "/gestion/decos-formules/decores/:id", element: <Pages.EditDecore /> },
  { path: "/gestion/decos-formules/formules/:id", element: <Pages.EditFormule /> },
  { path: "/gestion/contact-mails", element: <Pages.ContactMails /> },
  { path: "/gestion/contact-mails/:id", element: <Pages.ContactMailsDeatils /> },
  { path: "/gestion/pages/conditions", element: <Pages.BoConditions /> },
  { path: "/gestion/pages/politique", element: <Pages.BoPolitique /> },
  { path: "/gestion/pages/rgpd", element: <Pages.BoRgpd /> },
  { path: "/gestion/admin-list", element: <Pages.ListGestionnaires /> },
  { path: "/gestion/add-admin", element: <Pages.AddGestionnaires /> },
  { path: "/gestion/update-admin/:id", element: <Pages.ChangeGestionnaires /> },

];

const MainNavigator = () => {

  const dispatch = useDispatch();
  const token = useSelector(state => state?.token);

  const routes = [
    "headers", "accueil", "activefooters", "espaces", "prestations", "espace-cards", "prestation-cards",
    "contact", "blogCards", "conditions", "politiques", 'rgpd'
  ];

  useEffect(()=>{
    routes.map((route) => dispatch(getData(`/${route}`)));
  },[])

  useEffect(()=>{
    dispatch(getUserByToken(token?.token));
  },[token?.token])

  useEffect(()=>{
    dispatch(getUserByToken(token?.client_token));
  },[token?.client_token])

  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          {publicRoutes.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={element}
            />
          ))}
          <Route
            path="/login"
            element={
              <RedirectIfClientAuthenticated>
                <Pages.LoginForm />
              </RedirectIfClientAuthenticated>
            }
          />
          <Route
            path="/register"
            element={
              <RedirectIfClientAuthenticated>
                <Pages.RegisterForm />
              </RedirectIfClientAuthenticated>
            }
          />
          <Route
            path="/profile"
            element={
              <RedirectIfClientNotAuthenticated>
                <Pages.ClientProfile />
              </RedirectIfClientNotAuthenticated>
            }
          />
          <Route
            path="/reservation-details"
            element={
              <RedirectIfNotCompleted>
                <Pages.ReservationDetails />
              </RedirectIfNotCompleted>
            }
          />
          <Route
            path="/gestion/login"
            element={
              <RedirectIfAuthenticated>
                <Pages.Login />
              </RedirectIfAuthenticated>
            }
          />
          {protectedRoutes.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={<ProtectedRoute>{element}</ProtectedRoute>}
            />
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default MainNavigator;