import React from 'react';
import AddButton from '../../pagescomponents/AddButton';

const AdditionalPrice = ({ handleSubmit, parametresData, handleChange }) => {
  return (
    <div className="m-4">
      <div className="max-w-4xl mx-auto bg-white p-6 border border-gray-200 rounded-lg shadow-md">
        <form onSubmit={handleSubmit} className="space-y-5">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Prix Supplémentaire</h2>
          <div className="flex flex-col gap-y-3">
            <label htmlFor="additional_price" className="text-lg font-medium text-gray-700">Prix Supplémentaire :</label>
            <input
              type="number"
              id="additional_price"
              name="additional_price"
              value={parametresData?.additional_price}
              onChange={handleChange}
              placeholder="0"
              className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              required
            />
          </div>
          <div className="flex justify-end mt-4">
            <AddButton />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdditionalPrice;