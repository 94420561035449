import { useEffect, useState } from 'react';
import { BasicSEO, BoLayout, CalendarComponent } from '../../../components';
import ReservationListComponent from '../../../components/backoffice/reservationscomponents/ReservationListComponent';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BoReservation = () => {
  const [ShowMode, setShowMode] = useState(0);
  const location = useLocation();
  const client_name = useSelector(
    (state) => state?.detailsreservation?.client_name
  );

  useEffect(()=>{
    location?.state?.showList && setShowMode(1);
  },[])

  useEffect(()=>{
    if(client_name !== null && client_name !== ""){
      setShowMode(1);
    }
  },[client_name])

  return (
    <BoLayout>
      <BasicSEO/>
      {/* <AdminCalendar/> */}
      <div className="p-5 space-y-5">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-bold font-poppins text-gray-800">
            Réservations
          </h1>
          <div className="flex items-center gap-3">
            <button
              className={`text-xl w-8 h-8 min-w-8 min-h-8 ${
                ShowMode === 0
                  ? "bg-gray-800 text-white"
                  : "bg-white text-gray-900"
              }`}
              onClick={() => setShowMode(0)}
            >
              <i className="bi bi-calendar-range"></i>
            </button>
            <button
              className={`text-2xl w-8 h-8 min-w-8 min-h-8 ${
                ShowMode === 1
                  ? "bg-gray-800 text-white"
                  : "bg-white text-gray-900"
              }`}
              onClick={() => setShowMode(1)}
            >
              <i className="bi bi-list-task"></i>
            </button>
          </div>
        </div>
        {ShowMode === 0 ? <CalendarComponent /> : <ReservationListComponent /> }
      </div>
      <div className="h-20"></div>
    </BoLayout>
  )
}

export default BoReservation