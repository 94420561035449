import "./style.scss";
import Header from "../header";
function BannerSectionP14() {
  return (
    <div className="relative" style={{ height: "450px" }}>
      <section className="hr-sec h-full">
        <Header />
        <div className="absolute inset-0 flex items-center justify-center mt-32">
          <h1 className="text-3xl lg:text-5xl text-white" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }} >Payment</h1>
        </div>
      </section>
    </div>
  );
}

export default BannerSectionP14;