import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BlogDetail } from '../../assets/images';
import { useSelector } from 'react-redux';
import { BASEIMAGEURL } from '../../constants/actions';

const BlogDetailSection2 = () => {

    const { id } = useParams();
    const [blogItem, setBlogItem] = useState();
    const blogCards = useSelector(state => state?.pages?.data?.blogCards);

    useEffect(()=>{
        const item = blogCards?.find(item => item.id == id);
        setBlogItem(item);
    },[id, blogCards]);

  return (
    <section>
        <div className="max-w-2xl mx-auto my-20 space-y-5 text-justify px-3 lg:px-0 ">
            <h1 className="text-2xl lg:text-3xl text-black" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{blogItem?.title}</h1>
            <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                    <span><i className="bi bi-person-circle"></i></span>
                    <span className='capitalize' style={{ fontFamily: 'Work Sans' }}>{blogItem?.author}</span>
                </div>
                <div className="" style={{ fontFamily: 'Archivo' }}>
                    {blogItem?.date}
                </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: blogItem?.body }} />
        </div>
    </section>
  )
}

export default BlogDetailSection2