import React from "react";
import "./style.scss";
import Header from "../header";


function BannerSectionP9({ data }) {
  return (
    <div className="relative h-[500px]">
      <section className="hr-sec h-full">
        <Header />
        <div className=" absolute inset-0 flex items-center justify-center mt-32">
          <div className="px-4 flex flex-col justify-center items-center max-w-2xl text-center gap-y-7">
            <h1 className="text-[32px] lg:text-5xl text-white" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }} >{data?.titre}</h1>
            {/* <p className="text-gray-300 text-sm lg:text-base max-w-xl">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default BannerSectionP9;