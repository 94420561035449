import { useDispatch, useSelector } from "react-redux";
import FormattingDate from "../../../../helpers/formattingDate";
import { useEffect } from "react";
import { getData } from "../../../../actions/pages";
import { useNavigate } from "react-router-dom";

const LastReservations = () => {
  const reservations = useSelector((state) => state?.pages?.data?.reservations);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    dispatch(getData('/reservations'));
  }, [dispatch]);

  const formatTimeWithoutSeconds = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}`;
  };

  const getReservationStatus = (startTime, endTime, date) => {
    const now = new Date();
    const startDateTime = new Date(`${date}T${startTime}`);
    const endDateTime = new Date(`${date}T${endTime}`);

    if (now < startDateTime)
      return { status: "En cours de Traitement", style: "text-blue-600 bg-blue-100" };
    if (now > endDateTime)
      return { status: "Terminée", style: "text-gray-900 bg-gray-200" };
    return { status: "En Cour", style: "text-green-600 bg-green-100" };
  };

  const calculateTotal = (reservedSpace, formules, decor) => {
    let total = 0;

    if (reservedSpace?.price) {
      total += parseFloat(reservedSpace.price);
    }

    if (Array.isArray(formules)) {
      total += formules.reduce((sum, formule) => sum + (parseFloat(formule.price) || 0), 0);
    }

    if (decor && decor.price) {
      total += parseFloat(decor.price);
    }

    return total.toFixed(1);
  };

  // Sort reservations by date and time in descending order and take the first 3
  const lastThreeReservations = reservations
    ?.slice()
    .sort((a, b) => {
      const dateTimeA = new Date(`${a.date}T${a.startTime}`);
      const dateTimeB = new Date(`${b.date}T${b.startTime}`);
      return dateTimeB - dateTimeA; // Descending order
    })
    .slice(0, 3); // Take only the first 3 reservations

  return (
    <div className="relative rounded-lg bg-white p-4 shadow-md">
      <h2 className="font-poppins font-semibold text-xl text-darkgray mb-4">Nouvelles Réservations</h2>
      
      <div className="space-y-4">
        {lastThreeReservations?.map((reservation, index) => {
          const reservationStatus = getReservationStatus(
            reservation?.startTime,
            reservation?.endTime,
            reservation?.date
          );
          return reservation && (
            <div 
              key={index} 
              className={`flex items-center justify-between py-3 ${index !== 2 ? 'border-b border-gray-200' : ''}`}>
              <div className="space-y-1">
                <h3 className="font-medium text-base text-darkgray capitalize">{reservation?.espace_card?.titre}</h3>
                <p className="text-xs text-gray-500">{FormattingDate(reservation?.date)}</p>
              </div>
              <div className="text-center">
                <div className="text-sm text-gray-600">{formatTimeWithoutSeconds(reservation?.startTime)} - {formatTimeWithoutSeconds(reservation?.endTime)}</div>
                {/* <div className={`py-1 px-3 rounded-full text-xs ${reservationStatus?.style}`}>{reservationStatus?.status}</div> */}
              </div>
              <div className="font-medium text-gray-800">
                {calculateTotal(reservation?.espace_card, reservation?.formules, reservation?.decor)}<i className="bi bi-currency-euro"></i>
              </div>
            </div>
          )
        })}
      </div>
      <button 
        onClick={() => navigate('/gestion/reservations')} 
        className="mt-4 px-4 py-2 bg-yellow-600 text-white mx-auto rounded-lg hover:bg-yellow-700 transition">
        Voir toutes les réservations
      </button>
    </div>
  );
};

export default LastReservations;