import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddButton, BasicSEO, BoLayout, SimpleTextEditor } from "../../../../components";
import { apiClient } from "../../../../actions/api";
import { TextEditor } from "../../../../components";
import getCurrentDateFormattedYMD from "../../../../helpers/getCurrentDateFormatedYMD";
import { BASEIMAGEURL } from "../../../../constants/actions";
import uploadImage from "../../../../helpers/uploadImages";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BlogPost = () => {
  const { id } = useParams();
  const [post, setPost] = useState({});
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Choisir une image");
  const [selectedImage, setSelectedImage] = useState(null);
  const [texteditor, setTexteditor] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await apiClient().get(`/blogCards/${id}`);
        setPost(response.data);
        setFileName(
          response.data.image
            ? response.data.image.split("/").pop()
            : "Choisir une image"
        );
        setSelectedImage(BASEIMAGEURL + response.data.image);
        setTexteditor(response.data.body);
      } catch (err) {
        console.error("Error fetching post:", err);
      }
    };
    fetchPost();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setSelectedImage(URL.createObjectURL(file));
      setFile(file);
    }
  };

  const updatePost = async (e) => {
    e.preventDefault();

    try {
      let imagePath = post?.image;
      if (file) {
        imagePath = await uploadImage(
          "image",
          { image: file },
          { image: imagePath },
          "blog-images"
        );
      }
      const formData = {
        ...post,
        date: getCurrentDateFormattedYMD(),
        image: imagePath,
        body: texteditor,
      };

      await apiClient().post(`/blogCards/${id}?_method=PUT`, formData);
      toast.success("Post mis à jour avec succès", { autoClose: 600 });
      navigate("/gestion/blog");
      } catch (err) {
        console.error("Erreur lors de la mise à jour du post :", err);
        toast.error("Erreur lors de la mise à jour du post", { autoClose: 600 });      
    }
  };

  return (
    <BoLayout>
      <BasicSEO/>
      <div className="flex items-center gap-x-1 px-3">
        <button onClick={()=>navigate('/gestion/blog')}>
          <i className="bi bi-arrow-left text-2xl cursor-pointer"></i>
        </button>
        <h1 className="text-xl md:text-2xl font-semibold py-2 px-4 text-lightOrange">
          Modifier blog
        </h1>
      </div>
      <div className="bg-gray-100 pb-32">
        <div className="py-3 px-4 space-y-5">
          <div className="bg-white p-3 rounded-lg">
            <form
              onSubmit={updatePost}
              className="grid grid-cols-1 lg:grid-cols-2 gap-3"
            >
              <div className="flex flex-col space-y-1">
                <label htmlFor="title">Titre</label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={post?.title || ""}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md outline-none focus:outline-none"
                  style={{ border: "1px solid gray" }}
                />
              </div>
              <div className="flex flex-col space-y-1">
                <label htmlFor="author">Auteur</label>
                <input
                  type="text"
                  name="author"
                  id="author"
                  value={post?.author || ""}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md outline-none focus:outline-none"
                  style={{ border: "1px solid gray" }}
                />
              </div>
              <div className="flex flex-col space-y-1">
                <label htmlFor="description">Description</label>
                <SimpleTextEditor
                  value={post?.description}
                  onChange={(value) => handleChange({ target: { name: 'description', value } })}
                  styleClassName=""
                />
              </div>
              <div className="flex flex-col space-y-1">
                <label htmlFor="keys">Tags</label>
                <input
                  type="text"
                  name="keys"
                  id="keys"
                  value={post?.keys || ""}
                  onChange={handleChange}
                  placeholder="séparées par des virgules"
                  className="w-full p-2 border rounded-md outline-none focus:outline-none"
                  style={{ border: "1px solid gray" }}
                />
              </div>
              <div className="col-span-1 lg:col-span-2 flex items-center justify-center gap-x-3">
                <div className="bg-gray-200 rounded-md">
                  <label htmlFor="image" className="cursor-pointer">
                    {selectedImage ? (
                      <img
                        src={selectedImage}
                        alt="img selected"
                        className="object-cover max-w-96 max-h-40"
                      />
                    ) : (
                      <i className="bi bi-card-image px-4 py-[12px] text-9xl"></i>
                    )}
                  </label>
                </div>
                <div className="">
                  <p className="italic text-sm lg:text-base">
                    Veuillez télécharger une image carrée, d'une taille
                    inférieure à 100 Ko.
                  </p>
                  <div className="bg-blue-50 rounded-md text-center py-3 px-2 sm:px-3">
                    <input
                      type="file"
                      name="image"
                      id="image"
                      className="sr-only"
                      onChange={handleImageChange}
                    />
                    <label
                      htmlFor="image"
                      className="text-sm md:text-base py-2 px-4 rounded-md border-fancygreen text-fancygreen cursor-pointer"
                      style={{ border: "1px solid gray" }}
                    >
                      Choisir une image
                    </label>
                    {fileName && (
                      <span className="ml-3 text-gray-600 text-xs md:text-base">
                        {fileName}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-1 lg:col-span-2 space-y-2">
                <label htmlFor="text-editor">Contenu</label>
                <div className="h-72">
                  <TextEditor
                    texteditor={texteditor}
                    setTexteditor={setTexteditor}
                  />
                </div>
              </div>
              <div className="mt-10 col-span-1 lg:col-span-2 flex items-center justify-end">
                <AddButton />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </BoLayout>
  );
};

export default BlogPost;
