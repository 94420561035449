import React, { useEffect, useRef, useState } from "react";
import "./Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_RESERVATION } from "../../../constants/actions";
import { getReservationBySemaine } from "../../../actions/calendar";
import getCurrentDateFormattedYMD from "../../../helpers/getCurrentDateFormatedYMD";
import { LoadingGif } from "../../../assets/images";

const Calendar = ({ startTime, endTime, heightCellul = 48, cleaningDuration = 30, reservation_duration = 2,  filter }) => {
  const timeSlots = [];
  let currentTime = new Date(`2000-01-01T${startTime}`);
  const endTimeDate = new Date(`2000-01-01T${endTime}`);
  const reservationDetails = useSelector((state) => state?.detailsreservation);
  const calendarData = useSelector(
    (state) => state?.calendar?.calendarData?.data
  );
  const semaineData = useSelector((state) => state?.calendar?.semaineData);
  const spaces = useSelector((state) => state?.pages?.data?.espaceCards);
  const dispatch = useDispatch();
  const [selectedRoom, setSelectedRoom] = useState();
  const [currentWeek, setCurrentWeek] = useState([]);
  const [spaceOfSemaine, setSpaceOfSemaine] = useState();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (spaces) {
      setSelectedRoom(spaces[0]);
      setSpaceOfSemaine(spaces[0]);
    }
  }, [spaces]);

  while (currentTime <= endTimeDate) {
    timeSlots.push(currentTime.toTimeString().substring(0, 5));
    currentTime = new Date(currentTime.getTime() + 30 * 60000);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDownOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const addMinutes = (time, minutes) => {
    const [hours, mins] = time.split(':').map(Number);
    const totalMinutes = hours * 60 + mins + minutes;
    const newHours = Math.floor(totalMinutes / 60) % 24;
    const newMinutes = totalMinutes % 60;
    return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
  };

  const reserverEspace = (roomId, startTime, endTime) => {
    // const isPast = new Date(`${new Date().toDateString()} ${startTime}:00`) < new Date() &&  new Date(reservationDetails?.date) < new Date();
    // if(isPast){
    //   alert('is past ')
    // }else{
    //   alert('ok')
    // }
    const isReserved =
      reservationDetails?.reservation !== null &&
      reservationDetails?.reservation?.id ===
        `${roomId}-${reservationDetails?.period}-${reservationDetails?.date}-${startTime}-${endTime}`;
    dispatch({
      type: SET_RESERVATION,
      payload: isReserved
        ? null
        : {
            id: `${roomId}-${reservationDetails?.period}-${reservationDetails?.date}-${startTime}-${endTime}`,
            startTime: startTime,
            endTime: endTime,
            roomId: roomId,
            date: reservationDetails?.date,
          },
    });
  };

  // const generateReservationCells = (spaceCard) => {
  //   const cells = [];
  //   let isReservation = true;
  //   const room = calendarData?.find((space) => space?.id === spaceCard?.id);
  
  //   for (let i = 0; i < timeSlots.length; i++) {
  //     const currentTime = timeSlots[i];
  //     const reservation = room?.schedule?.find((res) => res.startTime === currentTime);
  
  //     if (isReservation) {
  //       if (reservation?.id) {
  //         cells.push(
  //           <tr key={`reservation-${i}`} height={`${heightCellul * (reservation_duration * 60 / 30)}px`}>
  //             <td className="border cell cell_yellow font-bold text-white">
  //               Réservé
  //             </td>
  //           </tr>
  //         );
  //       } else {
  //         const startTime = currentTime;
  //         const endTime = addMinutes(startTime, reservation_duration * 60);

  //         const isReserved =
  //           reservationDetails?.reservation !== null &&
  //           reservationDetails?.reservation?.id ===
  //             `${spaceCard?.id}-${reservationDetails?.period}-${reservationDetails?.date}-${currentTime}-${endTime}`;
  

  //         cells.push(
  //           <tr key={`reservation-${i}`} height={`${heightCellul * (reservation_duration * 60 / 30)}px`}>
  //             <td className="border cell cell_blue space-y-3 font-bold text-white">
  //               <p>Libre</p>
  //               <button
  //                 className={`btn_reserver ${isReserved ? "reserved" : ""}`}
  //                 onClick={() => reserverEspace(spaceCard?.id, startTime, endTime)}
  //               >
  //                 {isReserved ? "Réservé" : "Réserver"}
  //               </button>
  //             </td>
  //           </tr>
  //         );
  //       }
  //       i += (reservation_duration * 60 / 30) - 1;
  //     } else {
  //       cells.push(
  //         <tr key={`cleaning-${i}`} height={`${cleaningDuration * heightCellul / 30}px`}>
  //           <td className="cleaning-cell bg-white"></td>
  //         </tr>
  //       );
  //       i += (cleaningDuration / 30) - 1;
  //     }
  
  //     isReservation = !isReservation;
  //   }
  
  //   return cells;
  // };

  const generateReservationCells = (spaceCard) => {
    const cells = [];
    let isReservation = true;
    const room = calendarData?.find((space) => space?.id === spaceCard?.id);
  
    for (let i = 0; i < timeSlots.length; i++) {
      const currentTime = timeSlots[i];
      const reservation = room?.schedule?.find((res) => res.startTime === currentTime);
  
      if (isReservation) {
        const remainingSlots = timeSlots.length - i;
        const cellDuration = Math.min(reservation_duration * 2, remainingSlots);
  
        if (reservation?.id) {
          cells.push(
            <tr key={`reservation-${i}`} height={`${heightCellul * cellDuration}px`}>
              <td className="border cell cell_yellow font-bold text-white">
                Réservé
              </td>
            </tr>
          );
        } else {
          const startTime = currentTime;
          const endTime = addMinutes(startTime, cellDuration * 30);
  
          const isReserved =
            reservationDetails?.reservation !== null &&
            reservationDetails?.reservation?.id ===
              `${spaceCard?.id}-${reservationDetails?.period}-${reservationDetails?.date}-${currentTime}-${endTime}`;
  
          // const isPast = new Date(`${new Date().toDateString()} ${startTime}:00`) < new Date();

          // if(isPast){
          //   cells.push(
          //     <tr key={`reservation-${i}`} height={`${heightCellul * cellDuration}px`}>
          //       <td className="border cell cell_past space-y-3 font-bold text-white">
          //         Passé
          //       </td>
          //     </tr>
          //   );
          // }else{
            cells.push(
              <tr key={`reservation-${i}`} height={`${heightCellul * cellDuration}px`}>
                <td className="border cell cell_blue space-y-3 font-bold text-white">
                  <p>Libre</p>
                  <button
                    className={`btn_reserver ${isReserved ? "reserved" : ""}`}
                    onClick={() => reserverEspace(spaceCard?.id, startTime, endTime)}
                  >
                    {isReserved ? "Réservé" : "Réserver"}
                  </button>
                </td>
              </tr>
            );
          // }
        }
        i += (reservation_duration * 60 / 30) - 1;
      } else {
        const remainingSlots = timeSlots.length - i;
        const cleaningSlots = Math.min(cleaningDuration / 30, remainingSlots);
        cells.push(
          <tr key={`cleaning-${i}`} height={`${cleaningSlots * heightCellul}px`}>
            <td className="cleaning-cell bg-white"></td>
          </tr>
        );
        i += (cleaningDuration / 30) - 1;
      }
  
      isReservation = !isReservation;
    }
  
    return cells;
  };

  const handleChangeRoomSelected = (space) => {
    setSelectedRoom(space);
    setIsDropDownOpen(false);
  };

  useEffect(() => {
    updateWeekDays(new Date());
  }, []);

  const updateWeekDays = (date) => {
    const week = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(date);
      day.setDate(date.getDate() + i);
      week.push(day);
    }
    setCurrentWeek(week);
  };

  const formatDate = (date) => {
    const options = { weekday: "long", day: "numeric", month: "numeric" };
    return date.toLocaleDateString("fr-FR", options);
  };

const navigateWeek = (direction) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  
  const newDate = new Date(currentWeek[0]);
  newDate.setDate(newDate.getDate() + (direction === "next" ? 7 : -7));
  if (newDate >= currentDate || (direction === "prev" && currentWeek[0] === currentDate)) {
    updateWeekDays(newDate);
  }
};

  useEffect(() => {
    if (filter === "Semaine") {
      dispatch(
        getReservationBySemaine(
          getCurrentDateFormattedYMD(currentWeek[0]),
          spaceOfSemaine?.id
        )
      );
    }
  }, [filter, currentWeek, spaceOfSemaine]);

  const reserverEspaceFromSemaine = (roomId, startTime, endTime, date) => {
    const isReserved =
      reservationDetails?.reservation !== null &&
      reservationDetails?.reservation?.id === `${roomId}-${reservationDetails?.period}-${date}-${startTime}-${endTime}`;
    
    dispatch({
      type: SET_RESERVATION,
      payload: isReserved
        ? null
        : {
            id: `${roomId}-${reservationDetails?.period}-${date}-${startTime}-${endTime}`,
            startTime: startTime,
            endTime: endTime,
            roomId: roomId,
            date: date,
          },
    });
  };

  const generateSemaineReservationCells = (day) => {
    const cells = [];
    let isReservation = true;
    const dateObj = new Date(day);
    const formattedDate = dateObj.toISOString().split("T")[0];
    const currentDayData = semaineData?.find((data) => data?.date === formattedDate);
    for (let i = 0; i < timeSlots.length; i++) {
      const currentTime = timeSlots[i];
      const reservation = currentDayData?.data.find(
        (res) => res.startTime === currentTime
      );
      
      if (isReservation && i + 3 < timeSlots.length) {
        const remainingSlots = timeSlots.length - i;
        const cellDuration = Math.min(reservation_duration * 2, remainingSlots);
        if (reservation?.id) {
          cells.push(
            <tr
              key={`reservation-${i}`}
              height={`${heightCellul * cellDuration}px`}
              className="flex justify-center items-center"
            >
              <td className="border cell cell_yellow font-bold text-white h-full w-full flex flex-col items-center justify-center">
                Réservé
              </td>
            </tr>
          );
        } else {
          const startTime = currentTime;
          const endTime = addMinutes(startTime, reservation_duration * 60);
          const isReserved = 
            reservationDetails?.reservation !== null &&
            reservationDetails?.reservation?.id === `${spaceOfSemaine?.id}-${reservationDetails?.period}-${formattedDate}-${currentTime}-${endTime}`;
            
          // const isPast = new Date(`${new Date().toDateString()} ${startTime}:00`) < new Date();
          
          // if(isPast){
          //   cells.push(
          //     <tr 
          //       key={`reservation-${i}`}
          //       height={`${heightCellul * cellDuration}px`}
          //       className={`flex justify-center items-center`}
          //     >
          //       <td className={`border cell cell_past font-bold text-white space-y-3 h-full w-full flex flex-col items-center justify-center`}>
          //         Passé
          //       </td>
          //     </tr>
          //   );
          // }else{
            cells.push(
              <tr
              key={`reservation-${i}`}
              height={`${heightCellul * cellDuration}px`}
              className={`flex justify-center items-center`}
            >
              <td
                className={`border cell cell_blue font-bold text-white space-y-3 h-full w-full flex flex-col items-center justify-center`}
              >
                <p>Libre</p>
                <button
                  className={`btn_reserver ${isReserved ? "reserved" : ""}`}
                  onClick={() =>
                    reserverEspaceFromSemaine(
                      spaceOfSemaine?.id,
                      startTime,
                      endTime,
                      formattedDate
                    )
                  }
                >
                  {isReserved ? "Réservé" : "Réserver"}
                </button>
              </td>
            </tr>
            );
          // }
          // cells.push(
            // <tr
            //   key={`reservation-${i}`}
            //   height={`${heightCellul * cellDuration}px`}
            //   className={`flex justify-center items-center`}
            // >
            //   <td
            //     className={`border cell cell_blue font-bold text-white space-y-3 h-full w-full flex flex-col items-center justify-center`}
            //   >
            //     <p>Libre</p>
            //     <button
            //       className={`btn_reserver ${isReserved ? "reserved" : ""}`}
            //       onClick={() =>
            //         reserverEspaceFromSemaine(
            //           spaceOfSemaine?.id,
            //           startTime,
            //           endTime,
            //           formattedDate
            //         )
            //       }
            //     >
            //       {isReserved ? "Réservé" : "Réserver"}
            //     </button>
            //   </td>
            // </tr>
          // );
        }
        i += (reservation_duration * 60 / 30) - 1;
      } else if (!isReservation) {
        const remainingSlots = timeSlots.length - i;
        const cleaningSlots = Math.min(cleaningDuration / 30, remainingSlots);
        cells.push(
          <tr key={`cleaning-${i}`} height={`${cleaningSlots * heightCellul}px`}>
            <td className="cleaning-cell"></td>
          </tr>
        );
        i += (cleaningDuration / 30) - 1;
      }
      isReservation = !isReservation;
    }
    
    return cells;
  };

  // const generateSemaineReservationCells = (day) => {
  //   const cells = [];
  //   let isReservation = true;
  //   const dateObj = new Date(day);
  //   const formattedDate = dateObj.toISOString().split("T")[0];
  //   const currentDayData = semaineData?.find((data) => data?.date === formattedDate);
  //   for (let i = 0; i < timeSlots.length; i++) {
  //     const currentTime = timeSlots[i];
  //     const reservation = currentDayData?.data.find(
  //       (res) => res.startTime === currentTime
  //     );
      
  //     if (isReservation && i + 3 < timeSlots.length) {
  //       if (reservation?.id) {
  //         cells.push(
  //           <tr
  //             key={`reservation-${i}`}
  //             height={`${heightCellul * (reservation_duration * 60 / 30)}px`}
  //             className="flex justify-center items-center"
  //           >
  //             <td className="border cell cell_yellow font-bold text-white h-full w-full flex flex-col items-center justify-center">
  //               Réservé
  //             </td>
  //           </tr>
  //         );
  //       } else {
  //         const startTime = currentTime;
  //         const endTime = addMinutes(startTime, reservation_duration * 60);
  //         const isReserved = 
  //           reservationDetails?.reservation !== null &&
  //           reservationDetails?.reservation?.id === `${spaceOfSemaine?.id}-${reservationDetails?.period}-${formattedDate}-${currentTime}-${endTime}`;
          
  //         cells.push(
  //           <tr
  //             key={`reservation-${i}`}
  //             height={`${heightCellul * (reservation_duration * 60 / 30)}px`}
  //             className={`flex justify-center items-center`}
  //           >
  //             <td
  //               className={`border cell cell_blue font-bold text-white space-y-3 h-full w-full flex flex-col items-center justify-center`}
  //             >
  //               <p>Libre</p>
  //               <button
  //                 className={`btn_reserver ${isReserved ? "reserved" : ""}`}
  //                 onClick={() =>
  //                   reserverEspaceFromSemaine(
  //                     spaceOfSemaine?.id,
  //                     startTime,
  //                     endTime,
  //                     formattedDate
  //                   )
  //                 }
  //               >
  //                 {isReserved ? "Réservé" : "Réserver"}
  //               </button>
  //             </td>
  //           </tr>
  //         );
  //       }
  //       i += (reservation_duration * 60 / 30) - 1;
  //     } else if (!isReservation) {
  //       cells.push(
  //         <tr key={`cleaning-${i}`} height={`${cleaningDuration * heightCellul / 30}px`}>
  //           <td className="cleaning-cell bg-white"></td>
  //         </tr>
  //       );
  //       i += (cleaningDuration / 30) - 1;
  //     }
  //     isReservation = !isReservation;
  //   }
    
  //   return cells;
  // };

  return (
    <div className="px-2">
      {calendarData && timeSlots.length > 0 ? (
        <div>
          <div className="hidden md:block space-y-8 mt-7">
            {/* semaines table */}
            <div
              className={`max-w-4xl mx-auto px-5 rounded border bg-white make-shadow ${
                filter === "Semaine"
                  ? "flex flex-row justify-between gap-x-5 items-center"
                  : "hidden"
              }`}
            >
              {spaces?.map((space) => (
                <div
                  key={space?.id}
                  onClick={() => setSpaceOfSemaine(space)}
                  className={`font-medium cursor-pointer py-2 capitalize ${
                    space?.id === spaceOfSemaine?.id
                      ? "text-golddo underline underline-offset-8"
                      : "text-black"
                  }`}
                >
                  <p>{space?.titre}</p>
                </div>
              ))}
            </div>
            <table
              width="100%"
              cellPadding="0px"
              cellSpacing="0px"
              className={`tableau make-shadow min-w-fit ${
                filter === "Semaine" ? "block" : "hidden"
              }`}
            >
              <thead>
                <tr
                  height="64px"
                  className="text-center relative min-h-16 max-w-screen-2xl mx-auto flex-row items-center"
                >
                  <td>
                    <div
                      className="w-8 h-8 min-w-8 min-h-8 bg-gray-50 hover:bg-gray-100 cursor-pointer border rounded-full flex justify-center items-center float-end"
                      onClick={() => navigateWeek("prev")}
                    >
                      <i className="bi bi-chevron-left text-base"></i>
                    </div>
                  </td>

                  {currentWeek?.map((day, index) => (
                    <td
                      key={index}
                      className="text-center capitalize font-semibold"
                    >
                      <div>{formatDate(day)}</div>
                    </td>
                  ))}

                  <td>
                    <div
                      className="w-8 h-8 min-w-8 min-h-8 bg-gray-50 hover:bg-gray-100 cursor-pointer border rounded-full flex justify-center items-center float-start"
                      onClick={() => navigateWeek("next")}
                    >
                      <i className="bi bi-chevron-right text-base"></i>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="colonne_heure text-center px-1">
                    <table cellPadding="0px" cellSpacing="0px">
                      <tbody>
                        {timeSlots.map((time, index) => {
                          const isHour = time.endsWith("00");
                          return (
                            <tr key={index} height={`${heightCellul}px`}>
                              <td
                                className={
                                  isHour ? "heure strong" : "demi_heure"
                                }
                              >
                                {time}
                              </td>
                              <td
                                className={
                                  isHour
                                    ? "border-t border-black w-8 min-w-5 h-1"
                                    : "border-t border-gray-300"
                                }
                              ></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </td>
                  {currentWeek.map((day) => (
                    <td key={day.toISOString()} className="">
                      {generateSemaineReservationCells(day)}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>

            {/* jours table */}
            <table
              width="100%"
              cellPadding="0px"
              cellSpacing="0px"
              className={`tableau make-shadow ${filter === "Jour" ? "block" : "hidden"}`}
            >
              <thead>
                <tr height="60px" className="text-center relative">
                  <td></td>
                  {filter === "Jour" &&
                    spaces?.map((room) => (
                      <td key={room.id} className="font-bold capitalize">
                        {room.titre}
                      </td>
                    ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="colonne_heure text-center px-1">
                    <table cellPadding="0px" cellSpacing="0px">
                      <tbody>
                        {timeSlots.map((time, index) => {
                          const isHour = time.endsWith("00");
                          return (
                            <tr key={index} height={`${heightCellul}px`}>
                              <td
                                className={
                                  isHour ? "heure strong" : "demi_heure"
                                }
                              >
                                {time}
                              </td>
                              <td
                                className={
                                  isHour
                                    ? "border-t border-black w-8 min-w-5 h-1"
                                    : "border-t border-gray-300"
                                }
                              ></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </td>

                  {spaces?.map((spaceCard) => (
                    <td key={spaceCard.id} className="colonne colonne_salle_">
                      <table cellPadding="0px" cellSpacing="0px" width="100%">
                        <tbody>{generateReservationCells(spaceCard)}</tbody>
                      </table>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>

          {/* small screen */}
          <div className="block md:hidden relative">
            <button
              onClick={() => setIsDropDownOpen(prev => !prev)}
              className="border border-gray-300 text-gray-900 rounded make-shadow p-2 sm:p-2.5 my-5 w-11/12 mx-auto block"
            >
              <span className="text-base md:text-lg font-bold text-golddo">{selectedRoom?.titre}</span>
              <span className="text-xl transition-transform duration-400 float-end">
                <i className={`bi bi-chevron-${isDropDownOpen ? "up" : "down"}`}></i>
              </span>
            </button>
            <div
              ref={dropdownRef}
              className={`absolute z-10 left-1/2 transform -translate-x-1/2 bg-white w-11/12 mx-auto border rounded shadow-xl overflow-hidden overflow-y-auto transition-all duration-300 ease-in-out ${
                isDropDownOpen ? "max-h-60 opacity-100" : "max-h-0 opacity-0"
              }`}
            >
              {spaces?.map((space) => (
                <div
                  key={space?.id}
                  className={`capitalize cursor-pointer p-2 text-center transition-colors duration-300 ${
                    selectedRoom?.id === space?.id ? "bg-gray-100 text-gray-900" : "text-gray-900 hover:bg-gray-100"
                  }`}
                  onClick={() => handleChangeRoomSelected(space)}
                >
                  {space?.titre}
                </div>
              ))}
            </div>
            <table
              width="90%"
              cellPadding="0px"
              cellSpacing="0px"
              className="tableau make-shadow"
            >
              <tbody>
                <tr>
                  <td className="colonne_heure text-center px-1">
                    <table cellPadding="0px" cellSpacing="0px">
                      <tbody>
                        {timeSlots.map((time, index) => {
                          const isHour = time.endsWith("00");
                          return (
                            <tr key={index} height={`${heightCellul}px`}>
                              <td
                                className={
                                  isHour ? "heure strong" : "demi_heure"
                                }
                              >
                                {time}
                              </td>
                              <td
                                className={
                                  isHour
                                    ? "border-t border-black w-8 min-w-5 h-1"
                                    : "border-t border-gray-300"
                                }
                              ></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </td>
                  <td className="colonne colonne_salle_">
                    <table cellPadding="0px" cellSpacing="0px" width="100%">
                      <tbody>
                        <td className="colonne colonne_salle_">
                          <table
                            cellPadding="0px"
                            cellSpacing="0px"
                            width="100%"
                          >
                            <tbody>
                              {generateReservationCells(selectedRoom)}
                            </tbody>
                          </table>
                        </td>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center min-h-[500px]">
          <img src={LoadingGif} alt="loading gif" width={40} height={40} />
        </div>
      )}
    </div>
  );
};

export default Calendar;
