import React, { useState, useRef, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { SET_NBR_PERSONNE, SET_PRICE_NBR_PERSONNE } from "../../../constants/actions";
import { useNavigate } from "react-router-dom";

const ChoosePersons = () => {
  const { resDetails, reservation, nbrpersonne } = useSelector(
    (state) => state?.detailsreservation
  );
  const spaces = useSelector((state) => state?.pages?.data?.espaceCards);
  const additional_price = useSelector(
    (state) => state?.pages?.data?.parametres?.additional_price
  );
  
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState("bottom");
  const [selectedNumber, setSelectedNumber] = useState(nbrpersonne ? nbrpersonne : 1);
  const [specificSpace, setSpecificSpace] = useState(null);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (reservation?.roomId) {
      const sp = spaces?.find(
        (s) => s?.id === parseInt(reservation?.roomId)
      )?.occupation;
      setSpecificSpace(sp);
    }
  }, [reservation, spaces]);


  useEffect(() => {
    const handlePositionDropdown = () => {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current?.offsetHeight || 0;
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;

      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        setDropdownPosition("top");
      } else {
        setDropdownPosition("bottom");
      }
    };

    if (isDropDownOpen) {
      handlePositionDropdown();
    }

    window.addEventListener("resize", handlePositionDropdown);
    return () => {
      window.removeEventListener("resize", handlePositionDropdown);
    };
  }, [isDropDownOpen]);

  const handleNbrPersonneChange = (n) => {
    const space = spaces?.find(
      (s) => s?.id === parseInt(resDetails?.reservation?.roomId)
    );
    if (n === "more") {
      toast.info(
        <div className="text-black">
          Veuillez nous contacter pour une demande spéciale.{" "}
          <span
            onClick={() => navigate("/contact")}
            className="text-blue-600 cursor-pointer underline"
          >
            Cliquez ici
          </span>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
        }
      );
    } 
    // else if (parseInt(n) > space?.occupation) {
    //   toast.warn(
    //     `La salle sélectionnée ne peut pas accueillir plus de ${space?.occupation} personnes.`,
    //     {
    //       position: "top-right",
    //       autoClose: 5000,
    //       className: "text-red-600",
    //     }
    //   );
    //   dispatch({ type: SET_NBR_PERSONNE, payload: 1 });
    // }
     else {
      dispatch({ type: SET_NBR_PERSONNE, payload: parseInt(n) });
      if(n > 2) {
        dispatch({ type: SET_PRICE_NBR_PERSONNE, payload: (parseFloat(additional_price) * parseInt(n - 2)) });
      } else {
        dispatch({ type: SET_PRICE_NBR_PERSONNE, payload: 0 });
      }

      setSelectedNumber(n);
      setIsDropDownOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropDownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      {specificSpace && (
        <div className="relative max-w-xl px-6 mx-auto my-20">
          <h1 className="text-lg sm:text-lg md:text-xl mb-8 font-bold font-serif text-center uppercase">
            Choisissez le nombre de personnes :
          </h1>
          <button
            ref={buttonRef}
            onClick={() => setIsDropDownOpen((prev) => !prev)}
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg p-2 sm:p-2.5 w-full text-center"
          >
            <span className="text-base md:text-lg font-bold text-golddo">
              {selectedNumber
                ? `${selectedNumber} personne(s)${
                    selectedNumber > 2
                      ? ` (+${parseFloat(additional_price) * (selectedNumber - 2)}€)`
                      : ""
                  }`
                : "Choisissez le nombre de personnes"}
            </span>
            <span className="text-xl transition-transform duration-400 float-end">
              <i
                className={`bi bi-chevron-${isDropDownOpen ? "up" : "down"}`}
              ></i>
            </span>
          </button>
          <div
            ref={dropdownRef}
            className={`absolute z-10 ${
              dropdownPosition === "bottom" ? "top-full" : "bottom-full"
            } left-0 right-0 bg-white w-11/12 mx-auto border rounded shadow-xl overflow-hidden overflow-y-auto transition-all duration-300 ease-in-out ${
              isDropDownOpen ? "max-h-60 opacity-100" : "max-h-0 opacity-0"
            }`}
          >
            {Array.from({ length: specificSpace }, (_, i) => i + 1).map((n) => (
              <div
                key={n}
                className={`capitalize cursor-pointer p-2 text-center transition-colors duration-300 ${
                  selectedNumber === n
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-900 hover:bg-gray-100"
                }`}
                onClick={() => handleNbrPersonneChange(n)}
              >
                {n} personne{n > 1 ? "s" : ""}
                {n > 2 && (
                  <span className="ml-4 text-gray-600 font-semibold">
                    +{parseFloat(additional_price) * (n - 2)}€
                  </span>
                )}
              </div>
            ))}
            <div
              className={`cursor-pointer p-2 text-center transition-colors duration-300 text-gray-900 hover:bg-gray-100`}
              onClick={() => handleNbrPersonneChange("more")}
            >
              Plus de {specificSpace} personnes
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default ChoosePersons;