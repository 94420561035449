import React, { useEffect, useState } from "react";
import { BgProfileDetails, DefaultAvatar3 } from "../../../../assets/images";
import uploadImage from "../../../../helpers/uploadImages";
import { apiClient } from "../../../../actions/api";
import { BASEIMAGEURL, STORE_USER } from "../../../../constants/actions";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { getUserByToken } from "../../../../actions/pages";

const ProfileForm = () => {
  const dispatch = useDispatch();

  const storedUser = useSelector(state => state?.auth?.user);
  
  const [user, setUser] = useState(storedUser || {});
  const [selectedImage, setSelectedImage] = useState();
  const token = useSelector(state => state?.token);

  useEffect(()=>{
    dispatch(getUserByToken(token?.token));
  },[token?.token])

  useEffect(()=>{
    setUser(storedUser || {});
    if(storedUser?.avatar){
      setSelectedImage(BASEIMAGEURL + (storedUser?.avatar || ''));
    }
  }, [storedUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleChangeAvatar = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setUser({ ...user, avatar: file });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updateData = async () => {
      try {
        let newPath = user.avatar;
        if (user?.avatar && user?.avatar !== storedUser?.avatar) {
          newPath = await uploadImage(
            "avatar",
            { avatar: user?.avatar },
            { avatar: storedUser?.avatar },
            "avatars"
          );
        }
        const data = { ...user, avatar: newPath };
        const response = await apiClient().post(
          `/users/${storedUser?.id}?_method=PUT`,
          data
        );
        dispatch({ type: STORE_USER, payload: response.data });
        toast.success("Profil mis à jour avec succès !", { autoClose: 700 });
      } catch (err) {
        console.error(err);
        toast.error("Erreur lors de la mise à jour du profil !", { autoClose: 700 });
      }
    };
    updateData();
  };

  const UpdatePassword = async (e) => {
    e.preventDefault();
    if (
      !user?.ancienepassword ||
      !user?.password ||
      !user?.confirePassword
    ) {
      toast.warning("Veuillez remplir tous les champs de mot de passe !");
      return;
    }
    if (user?.password !== user?.confirePassword) {
      toast.warning("Les mots de passe ne sont pas compatibles !");
      return;
    }
    try {
      const response = await apiClient().post(
        `/users/${user?.id}/update-password`,
        {
          oldPassword: user?.ancienepassword,
          newPassword: user?.password,
        }
      );
      if (response.data.success) {
        toast.success("Mot de passe mis à jour avec succès !", {
          autoClose: 700,
        });
        setUser({...user, ancienepassword: "", password: "", confirePassword: ""});
      } else {
        toast.error(
          response.data.error ||
            "Erreur lors de la mise à jour du mot de passe !",
          { autoClose: 1000 }
        );
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.error ||
        "Erreur lors de la mise à jour du mot de passe !";
      console.error(err);
      toast.error(errorMessage, { autoClose: 1000 });
    }
  };

  return (
    <div className="space-y-8 p-3">
      <ToastContainer />
      <div className="w-full h-36 rounded-lg overflow-hidden relative">
        <img
          src={BgProfileDetails}
          alt="bgclient"
          className="w-full h-full max-w-full max-h-full object-cover"
        />
        <div className="absolute w-20 h-20 rounded-full top-3 left-3 bg-white overflow-hidden">
          <input
            type="file"
            name="avatar"
            id="avatar"
            onChange={handleChangeAvatar}
            className="sr-only"
          />
          <label htmlFor="avatar">
            <img
              src={selectedImage ? selectedImage : DefaultAvatar3}
              alt="avatar"
              className="object-cover w-full h-full cursor-pointer"
            />
          </label>
        </div>
      </div>
      <form
        className="grid grid-cols-1 md:grid-cols-2 gap-3"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-2">
          <label htmlFor="first_name">Prénom</label>
          <input
            type="text"
            name="first_name"
            id="first_name"
            value={user?.first_name || ''}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="last_name">Nom</label>
          <input
            type="text"
            name="last_name"
            id="last_name"
            value={user?.last_name || ''}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            value={user?.email || ''}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="phone">Téléphone</label>
          <input
            type="text"
            name="phone"
            id="phone"
            value={user?.phone || ''}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="code_postal">Code Postal</label>
          <input
            type="text"
            name="code_postal"
            id="code_postal"
            value={user?.code_postal || ''}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="birthday">Date de Naissance</label>
          <input
            type="date"
            name="birthday"
            id="birthday"
            value={user?.birthday || ''}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
          />
        </div>
        <div className="flex justify-end gap-2 col-span-1 md:col-span-2">
          <button
            type="submit"
            className="text-orange-primary rounded-md px-4 py-1 hover:bg-gray-200"
            style={{ border: "1px solid gray" }}
          >
            Valider
          </button>
        </div>
      </form>
      <form
        className="grid grid-cols-1 md:grid-cols-2 gap-3"
        onSubmit={UpdatePassword}
      >
        <div className="flex flex-col gap-2">
          <label htmlFor="ancienepassword">Votre ancien mot de passe</label>
          <input
            type="password"
            name="ancienepassword"
            id="ancienepassword"
            onChange={handleChange}
            value={user?.ancienepassword || ""}
            placeholder="mot de passe"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            required
            minLength={6}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="password">Votre nouveau mot de passe</label>
          <input
            type="password"
            name="password"
            id="password"
            onChange={handleChange}
            value={user?.password || ""}
            placeholder="mot de passe"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            required
            minLength={6}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="confirePassword">Confirmez votre nouveau mot de passe</label>
          <input
            type="password"
            name="confirePassword"
            id="confirePassword"
            onChange={handleChange}
            value={user?.confirePassword || ""}
            placeholder="confirmer mot de passe"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            required
            minLength={6}
          />
        </div>
        <div className="flex justify-end gap-2 col-span-1 md:col-span-2">
          <button
            type="submit"
            className="text-orange-primary rounded-md px-4 py-1 hover:bg-gray-200"
            style={{ border: "1px solid gray" }}
          >
            Valider
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileForm;
