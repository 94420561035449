import React, { useRef, useState } from 'react';
import './style.scss';
import { BASEIMAGEURL } from '../../constants/actions';
import { useNavigate } from 'react-router-dom';

const ImagePopup = ({ image, onClose, onPrev, onNext }) => {
    const [isZoomed, setIsZoomed] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [position, setPosition] = useState({ x: 50, y: 50 });
    const containerRef = useRef(null);
    const imageRef = useRef(null);
  
    const handleImageClick = (e) => {
      e.stopPropagation();
      if (isZoomed) {
        setIsZoomed(false);
        setZoomLevel(1);
        setPosition({ x: 50, y: 50 });
      } else {
        setIsZoomed(true);
        setZoomLevel(2.5);
        if (imageRef.current) {
          const rect = imageRef.current.getBoundingClientRect();
          const x = ((e.clientX - rect.left) / rect.width) * 100;
          const y = ((e.clientY - rect.top) / rect.height) * 100;
          setPosition({ x, y });
        }
      }
    };
  
    const handleMouseMove = (e) => {
      if (!isZoomed || !imageRef.current) return;
  
      const rect = imageRef.current.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / rect.width) * 100;
      const y = ((e.clientY - rect.top) / rect.height) * 100;
  
      setPosition({ x, y });
    };
  
    return (
      <div 
        ref={containerRef}
        className="fixed inset-0 bg-black bg-opacity-85 flex items-center justify-center z-50"
      >
        <button
          className="absolute top-7 right-7 text-white text-2xl hover:text-golddo z-20"
          onClick={onClose}
        >
          <i className="bi bi-x-lg"></i>
        </button>
        <div 
          className="relative w-full h-full"
          onClick={(e) => e.stopPropagation()}
          onMouseMove={handleMouseMove}
        >
          <img
            ref={imageRef}
            src={image}
            alt="Popup"
            className="absolute transition-transform duration-200 ease-in-out rounded-2xl w-full max-w-2xl mx-auto"
            style={{
              cursor: isZoomed ? 'zoom-out' : 'zoom-in',
              transformOrigin: `${position.x}% ${position.y}%`,
              // maxWidth: '80%',
              // maxHeight: '80%',
              // width: 'auto',
              height: 'auto',
              objectFit: 'contain',
              left: '50%',
              top: '50%',
              transform: `translate(-50%, -50%) scale(${zoomLevel})`,
            }}
            onClick={handleImageClick}
          />
          <button
            className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white text-4xl hover:text-golddo z-20"
            onClick={(e) => { e.stopPropagation(); onPrev(); }}
          >
            <i className="bi bi-chevron-left"></i>
          </button>
          <button
            className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white text-4xl hover:text-golddo z-20"
            onClick={(e) => { e.stopPropagation(); onNext(); }}
          >
            <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </div>
    );
  };

const PresentaionDetailsSection2 = ({ prestation }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const navigate = useNavigate();

  const images = [
    BASEIMAGEURL + prestation?.image1Detail1,
    BASEIMAGEURL + prestation?.image2Detail1,
    BASEIMAGEURL + prestation?.image3Detail1,
    BASEIMAGEURL + prestation?.image4Detail1
  ].filter(Boolean);

  const openPopup = (index) => {
    setSelectedImage(index);
  };

  const closePopup = () => {
    setSelectedImage(null);
  };

  const nextPopupImage = () => {
    setSelectedImage((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevPopupImage = () => {
    setSelectedImage((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <section className='my-20'>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-0 bg-black max-w-full">
        <div className="s2background col-span-1 py-10 lg:py-0 lg:col-span-2 relative">
          <div className="absolute inset-0 bg-gradient-to-r from-black via-black/50 to-transparent"></div>
          <div className="relative z-10 flex justify-center items-center h-full">
            <div className="text-white w-9/12">
              <h1 className='text-3xl lg:text-4xl capitalize my-4' style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>
                {prestation?.titreDetail1}
              </h1>
              <p className='font-serif text-base lg:text-xl text-justify mb-6' dangerouslySetInnerHTML={{ __html: prestation?.descriptionDetail1 }} />
              <button 
                className='lg:text-xl font-serif py-2 px-3 lg:px-5 bg-darkorange rounded-full capitalize'
                onClick={() => navigate(prestation?.lienButton1)}
              >
                {prestation?.button1}
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 pt-1 pr-1 pb-1 pl-1">
          {images?.map((image, index) => (
            <div key={index} className="">
              <img 
                src={image}
                alt='img' 
                className='w-full h-48 sm:h-52 max-h-fit object-cover rounded-xl cursor-pointer' 
                onClick={() => openPopup(index)}
              />
            </div>
          ))}
        </div>
      </div>

      {selectedImage !== null && (
        <ImagePopup
          image={images[selectedImage]}
          onClose={closePopup}
          onPrev={prevPopupImage}
          onNext={nextPopupImage}
        />
      )}
    </section>
  );
};

export default PresentaionDetailsSection2;
