import React from 'react';
import { Helmet } from 'react-helmet';
import { BannerSectionP8, BlogDetailSection2, Footer } from '../../components';

const BlogDetail = () => {
  return (
    <>
      <Helmet>
        <title>Détail de l'article</title>
        <meta name="description" content="Découvrez les détails de notre article de blog. Plongez dans les informations approfondies et les perspectives sur le sujet abordé." />
      </Helmet>
      <BannerSectionP8 />
      <BlogDetailSection2 />
      <Footer />
    </>
  );
}

export default BlogDetail;
