import { apiClient } from "../actions/api";

const removeImage = async (path) => {
  const formData = new FormData();
  formData.append('path', path);

  try {
    const response = await apiClient().post('/remove', formData);
    return !!response.data;
  } catch (error) {
    console.error('Error removing image:', error);
    throw error;
  }
};

export default removeImage;
