import React from "react";
import "./style.scss";
import Header from "../header";
import { Link, useNavigate } from "react-router-dom";

const Button = ({ text, variant, fun }) => (
  <button className={`button ${variant}`} onClick={fun}>{text}</button>
);

function BannerSectionP1({ data }) {

  const navigate = useNavigate();

  return (
    <div className="relative h-[500px]">
      <section className="hr-sec h-full">
        <Header />
        <div className=" absolute inset-0 flex items-center justify-center mt-32">
          <div className="px-4 flex flex-col justify-center items-center max-w-2xl text-center gap-y-7">
            <h1 className="text-[32px] lg:text-5xl text-white" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }} >{data?.titre}</h1>
            <p className="text-gray-300 text-sm lg:text-base" dangerouslySetInnerHTML={{ __html: data?.description }}/>
            <div className="space-x-4">
              <Button text={data?.button1} variant="primary uppercase" fun={()=>navigate(data?.lienButton1)} />
              <Button text={data?.button2} variant="secondary uppercase" fun={()=>navigate(data?.lienButton2)} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BannerSectionP1;