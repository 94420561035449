import React, { useState, useEffect } from "react";
import { AddButton, SimpleTextEditor } from "../../../..";
import { BASEIMAGEURL } from "../../../../../constants/actions";

const Section6BoAcceuil = ({ accueilData, handleChange, valider }) => {
  const [fileNames, setFileNames] = useState([
    "Choisir une image",
    "Choisir une image",
  ]);
  const [selectedImages, setSelectedImages] = useState([null, null]);

  useEffect(() => {
    if (selectedImages[0] === null && accueilData?.image1Concept2) {
      setSelectedImages((prev) => [
        BASEIMAGEURL + accueilData.image1Concept2,
        prev[1],
      ]);
    }
    if (selectedImages[1] === null && accueilData?.image2Concept2) {
      setSelectedImages((prev) => [
        prev[0],
        BASEIMAGEURL + accueilData.image2Concept2,
      ]);
    }
  }, [accueilData, selectedImages]);

  const handleImageChange = (index) => (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFileNames((prev) =>
        prev.map((name, i) => (i === index ? file.name : name))
      );
      setSelectedImages((prev) =>
        prev.map((img, i) => (i === index ? imageUrl : img))
      );
      handleChange({
        target: {
          name: event.target.name,
          value: file,
        },
      });
    }
  };

  const removeImage = (index) => () => {
    setFileNames((prev) =>
      prev.map((name, i) => (i === index ? "Choisir une image" : name))
    );
    setSelectedImages((prev) =>
      prev.map((img, i) => (i === index ? null : img))
    );
  };

  const renderImageInput = (index) => (
    <div className="border rounded-lg py-2 px-1 flex items-center justify-center flex-wrap gap-x-3">
      <div className="bg-gray-200 rounded-md max-w-40 max-h-40 min-w-40 min-h-40">
        <div className="cursor-pointer">
          {selectedImages[index] ? (
            <div className="relative">
              <label
                htmlFor={`image${index + 1}Concept2`}
                className="cursor-pointer"
              >
                <img
                  src={selectedImages[index]}
                  alt="img selected"
                  className="object-cover h-40 w-40"
                />
              </label>
            </div>
          ) : (
            <label
              htmlFor={`image${index + 1}Concept2`}
              className="h-full min-h-44 max-h-52 w-48 mx-auto bg-gray-200 rounded-md flex items-center justify-center"
            >
              <i className="bi bi-card-image text-8xl"></i>
            </label>
          )}
        </div>
      </div>
      <div>
        <p className="italic text-sm lg:text-base">
          Veuillez télécharger une image carrée, d'une taille inférieure à 100
          Ko.
        </p>
        <div className="bg-blue-50 rounded-md text-center py-3 px-2 sm:px-3">
          <input
            type="file"
            name={`image${index + 1}Concept2`}
            id={`image${index + 1}Concept2`}
            className="sr-only"
            onChange={handleImageChange(index)}
          />
          <label
            htmlFor={`image${index + 1}Concept2`}
            className="text-sm md:text-base py-2 px-4 rounded-md border-fancygreen text-fancygreen cursor-pointer"
            style={{ border: "1px solid gray" }}
          >
            Choisir une image
          </label>
          {fileNames[index] && (
            <span className="ml-3 text-gray-600 text-xs md:text-base">
              {fileNames[index]}
            </span>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">
        Section Concept 2
      </h1>
      <form onSubmit={valider} className="grid grid-cols-1 gap-3">
        <div className="flex flex-col space-y-1">
          <label htmlFor="titreConcept2">Grand titre</label>
          <input
            type="text"
            name="titreConcept2"
            value={accueilData?.titreConcept2 || ""}
            onChange={handleChange}
            id="titreConcept2"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>

        <div className="flex flex-col space-y-1">
          <label htmlFor="descriptionConcept2">Description</label>
          <SimpleTextEditor
            value={accueilData?.descriptionConcept2}
            onChange={(value) => handleChange({ target: { name: 'descriptionConcept2', value } })}
            styleClassName=""
          />
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="flex flex-col space-y-1">
            <label htmlFor="buttonConcept2">Button</label>
            <input
              type="text"
              name="buttonConcept2"
              value={accueilData?.buttonConcept2}
              onChange={handleChange}
              id="buttonConcept2"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="lienButtonConcept2">Lien</label>
            <input
              type="text"
              name="lienButtonConcept2"
              value={accueilData?.lienButtonConcept2}
              onChange={handleChange}
              id="lienButtonConcept2"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
        </div>

        {renderImageInput(0)}
        {renderImageInput(1)}

        <div className="flex items-center justify-end">
          <AddButton />
        </div>
      </form>
    </div>
  );
};

export default Section6BoAcceuil;
