import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginClient } from "../../actions/client";

const CleintLoginForm = ({ close, showLink }) => {

    const [loginForm, setLoginForm] = useState({});
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (e) => {
      const { name, value } = e.target;
      setLoginForm({ ...loginForm, [name]: value });
    };
  
    const handleCheckboxChange = () => {
      setRememberMe(!rememberMe);
    };
  
    const submitLoginForm = async (e) => {
      e.preventDefault();
      try {
        await dispatch(loginClient(loginForm, rememberMe));
        close && close();
      } catch (err) {
        console.error(err);
      }
    };
    
  return (
    <form onSubmit={submitLoginForm} className="space-y-5">
      <div className="grid grid-cols-1 gap-x-5 gap-y-4">
        <div className="flex flex-col gap-y-2">
          <label
            htmlFor="email"
            className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-900 focus-within:ring-1 focus-within:ring-gray-900"
          >
            <span className="text-xs font-medium text-gray-700"> Email </span>

            <input
              type="email"
              name="email"
              id="email"
              value={loginForm.email || ""}
              onChange={handleChange}
              placeholder="email@exemple.com"
              className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
              required
              autoComplete="off"
            />
          </label>
        </div>
        <div className="flex flex-col gap-y-2">
          <label
            htmlFor="password"
            className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-900 focus-within:ring-1 focus-within:ring-gray-900"
          >
            <span className="text-xs font-medium text-gray-700">
              {" "}
              Mot de passe{" "}
            </span>
            <input
              type="password"
              name="password"
              id="password"
              value={loginForm.password || ""}
              onChange={handleChange}
              placeholder="mot de passe"
              className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
              required
              autoComplete="off"
              minLength={6}
            />
          </label>
        </div>
      </div>

      <div className="flex md:items-center justify-between flex-col md:flex-row">
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={handleCheckboxChange}
              className="sr-only peer"
            />
            <div className="relative w-9 h-5 bg-gray-200 rounded-full peer-focus:ring-0 peer-checked:bg-gray-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all"></div>
            <span className="ms-3 text-sm font-medium text-gray-900">
              Se souvenir du mot de passe
            </span>
          </label>
        </div>
        <p
          className="text-blue-800 cursor-pointer text-sm"
          onClick={() => navigate("/forgot", { state: { fromLogin: true } })}
        >
          Mot de passe oublié ?
        </p>
      </div>

      <div className="mt-4 flex items-center flex-col md:flex-row gap-4">
        <button
          type="submit"
          className="w-40 p-3 font-semibold text-white rounded-sm bg-gray-800 hover:bg-gray-700"
        >
          Continuer
        </button>
        {showLink && (
          <div>
            <p>
              Vous n'avez pas de compte ?{" "}
              <span
                className="text-blue-600 font-medium cursor-pointer"
                onClick={() =>
                  navigate("/register")
                }
              >
                S'inscrire
              </span>
              .
            </p>
          </div>
        )}
      </div>
    </form>
  );
};

export default CleintLoginForm;
