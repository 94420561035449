import { useEffect, useState, useCallback } from "react";
import { AddButton } from "../../../..";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../../../../actions/api";
import uploadImage from "../../../../../helpers/uploadImages";
import { BASEIMAGEURL } from "../../../../../constants/actions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleTextEditor from "../../../../simpleTextEditor"; // Import your text editor component

const Section2BoPrestations = () => {
  const navigate = useNavigate();
  const [currentPrestation, setCurrentPrestation] = useState(0);
  const [selectedImages, setSelectedImages] = useState({});
  const [initialPrestations, setInitialPrestations] = useState([]);
  const [prestations, setPrestations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient().get(`/prestation-cards`);
        setInitialPrestations(response.data);
        setPrestations(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const handleImageChange = useCallback((event, prestationId) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImages((prev) => ({
        ...prev,
        [prestationId]: { file, imageUrl },
      }));
      setPrestations((prevPrestations) =>
        prevPrestations.map((prestation) =>
          prestation.id === prestationId ? { ...prestation, image: file } : prestation
        )
      );
    }
  }, []);

  const handleChange = useCallback((prestationId, field, value) => {
    setPrestations((prevPrestations) =>
      prevPrestations.map((prestation) =>
        prestation.id === prestationId ? { ...prestation, [field]: value } : prestation
      )
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const changedPrestations = prestations.filter((prestation, index) => {
        const initialPrestation = initialPrestations[index];
        return (
          prestation.titre !== initialPrestation.titre ||
          prestation.description !== initialPrestation.description ||
          selectedImages[prestation.id]
        );
      });

      const updatedPrestations = await Promise.all(
        changedPrestations.map(async (prestation) => {
          const formData = new FormData();
          formData.append('titre', prestation.titre);
          formData.append('description', prestation.description);
  
          let newImagePath = null;
          if (selectedImages[prestation.id]) {
            const initialPrestation = initialPrestations.find(p => p.id === prestation.id);
            newImagePath = await uploadImage('image', {image: selectedImages[prestation.id].file}, { image: initialPrestation.image }, 'prestation-cards');
            formData.append('image', newImagePath);
          }
  
          const response = await apiClient().post(`/prestation-cards/${prestation.id}?_method=PUT`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
  
          return { ...response.data, newImagePath };
        })
      );
  
      setPrestations((prevPrestations) =>
        prevPrestations.map((prestation) => {
          const updatedPrestation = updatedPrestations.find((p) => p.id === prestation.id);
          return updatedPrestation ? { ...prestation, ...updatedPrestation, image: updatedPrestation.newImagePath || prestation.image } : prestation;
        })
      );

      setInitialPrestations((prevInitialPrestations) =>
        prevInitialPrestations.map((prestation) => {
          const updatedPrestation = updatedPrestations.find((p) => p.id === prestation.id);
          return updatedPrestation ? { ...prestation, ...updatedPrestation, image: updatedPrestation.newImagePath || prestation.image } : prestation;
        })
      );
      toast.success('Détails de prestation modifiés avec succès', { autoClose: 600 });
      setSelectedImages({});
  
    } catch (error) {
      console.error("Error updating prestations:", error);
      toast.error('Erreur lors de la modification des détails de prestation', { autoClose: 600 });
    }
  };

  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">
        Section Prestations
      </h1>
      <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-3">
        <div className="w-full flex items-center justify-between gap-x-2">
          <button
            type="button"
            onClick={() =>
              setCurrentPrestation((prev) => (prev > 0 ? prev - 1 : prev))
            }
          >
            <i className="bi bi-arrow-left-circle text-3xl text-lightOrange"></i>
          </button>

          <div className="overflow-hidden w-full">
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${currentPrestation * 100}%)` }}
            >
              {prestations?.map((prestation, index) => (
                <div key={prestation.id} className="w-full flex-shrink-0">
                  <div className="space-y-2 w-full border rounded-md p-2 relative">
                    <button
                      onClick={() =>
                        navigate(`/gestion/pages/prestations/${prestation.id}`)
                      }
                      className="absolute top-0 right-0 bg-golddo rounded-md px-1 text-white"
                    >
                      <i className="bi bi-arrow-up-right"></i>
                    </button>
                    <h1 className="text-lightOrange text-center font-semibold underline border-b pb-2">
                      Prestation {index + 1}
                    </h1>
                    <div className="w-full flex items-start flex-col lg:flex-row gap-y-3 gap-x-5">
                      <div className="text-center h-full mx-auto">
                        <input
                          type="file"
                          name={`s3i${index + 1}`}
                          id={`s3i${index + 1}`}
                          className="sr-only"
                          onChange={(e) => handleImageChange(e, prestation.id)}
                        />
                        <label
                          htmlFor={`s3i${index + 1}`}
                          className="cursor-pointer"
                        >
                          {selectedImages[prestation.id] ? (
                            <div className="relative">
                              <img
                                src={selectedImages[prestation.id].imageUrl}
                                alt="selected"
                                className="object-cover h-44 w-[230px] rounded-md"
                              />
                            </div>
                          ) : prestation.image ? (
                            <div className="relative">
                              <img
                                src={BASEIMAGEURL + prestation.image}
                                alt="selected"
                                className="object-cover h-44 w-[230px] rounded-md"
                              />
                            </div>
                          ) : (
                            <div className="h-full min-h-44 max-h-52 w-48 mx-auto bg-gray-200 rounded-md flex items-center justify-center">
                              <i className="bi bi-card-image text-8xl"></i>
                            </div>
                          )}
                        </label>
                      </div>
                      <div className="w-full space-y-2">
                        <div className="flex flex-col space-y-1 w-full min-w-fit">
                          <label htmlFor={`titre${prestation.id}`}>Titre</label>
                          <input
                            type="text"
                            name={`titre${prestation.id}`}
                            value={prestation.titre}
                            onChange={(e) =>
                              handleChange(
                                prestation.id,
                                "titre",
                                e.target.value
                              )
                            }
                            id={`titre${prestation.id}`}
                            className="w-full p-2 border rounded-md outline-none focus:outline-none"
                            style={{ border: "1px solid gray" }}
                          />
                        </div>
                        <div className="flex flex-col space-y-1 w-full min-w-fit">
                          <label htmlFor={`desc${prestation.id}`}>
                            Description
                          </label>
                          <SimpleTextEditor
                            value={prestation.description}
                            onChange={(value) =>
                              handleChange(
                                prestation.id,
                                "description",
                                value
                              )
                            }
                            id={`desc${prestation.id}`}
                            styleClassName=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <button
            type="button"
            onClick={() =>
              setCurrentPrestation((prev) =>
                prev < prestations?.length - 1 ? prev + 1 : prev
              )
            }
          >
            <i className="bi bi-arrow-right-circle text-3xl text-lightOrange"></i>
          </button>
        </div>

        <div className="text-end col-span-1 lg:col-span-2">
          <AddButton />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Section2BoPrestations;
