import * as React from "react";
import './style.scss';
import { BASEIMAGEURL } from "../../constants/actions";
import { useNavigate } from "react-router-dom";


const ContentTwoP2 = ({ data }) => {
  const navigate = useNavigate()
  return (
    <section>
      <div className="grid lg:grid-cols-2 gap-x-20 w-10/12 mx-auto my-24">

        <div className="hidden lg:block max-h-[600px]">
          <img src={BASEIMAGEURL + data?.imageConcept} className="rounded-2xl h-full object-cover mx-auto" />
        </div>

        <div className="space-y-5 flex flex-col justify-center">
          <div className="flex justify-center lg:justify-start">
            <div className="text-4xl lg:text-5xl" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{data?.titreConcept}</div>
          </div>
          <div className="text-justify text-gray-600 max-w-2xl lg:max-w-xl">
            <p dangerouslySetInnerHTML={{ __html: data?.descriptionConcept }}/>
          </div>
          <div className="flex lg:hidden my-4 h-72 contentCover"></div>
          <div className="flex justify-center lg:justify-start">
            <button className="button primary w-fit min-w-60 text-center capitalize" onClick={()=>navigate(data?.lienButton2)}>{data?.button2}</button>
          </div>
        </div>

      </div>
    </section>
  );
};

export default ContentTwoP2;
