import * as React from "react";
import './style.scss';
import { BASEIMAGEURL } from "../../constants/actions";
import { useNavigate } from "react-router-dom";

const ContentTwoP2 = ({ prestation }) => {
  const navigate = useNavigate()
  return (
    <section>
      <div className="grid lg:grid-cols-2 gap-x-20 w-10/12 mx-auto my-24">

        <div className="hidden lg:block h-96">
          <img src={BASEIMAGEURL + prestation?.bgimageConcept2} className="rounded-2xl w-full h-full object-cover" />
        </div>

        <div className="space-y-5 flex flex-col justify-center">
          <div className="flex justify-center lg:justify-start">
            <h1 className="text-4xl lg:text-5xl capitalize" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{prestation?.titreConcept2}</h1>
          </div>
          <div className="text-justify text-gray-600 max-w-2xl lg:max-w-xl">
            <p dangerouslySetInnerHTML={{ __html: prestation?.descriptionConcept2 }}/>
          </div>
          <div className="flex lg:hidden my-4 h-72">
            {/* contentCover */}
            <img src={BASEIMAGEURL + prestation?.bgimageConcept2} className="rounded-2xl w-full h-full object-cover" />
          </div>
          <div className="flex justify-center lg:justify-start">
            <div className="button primary text-center w-60 lg:w-fit" onClick={()=>navigate(prestation?.lienButton3)}>{prestation?.button3}</div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default ContentTwoP2;
