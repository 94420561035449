import AddButton from "../../../pagescomponents/AddButton";

const JourneeNuite = ({ handleSubmit, parametresData, handleChange }) => {

  return (
    <form onSubmit={handleSubmit} className="m-4 p-6 border border-gray-200 rounded-lg shadow-md bg-white space-y-4">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Configurations Journée - Nuitée</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="flex flex-col gap-y-2">
          <label htmlFor="journee_start" className="text-lg font-medium text-gray-700">Début de la Journée :</label>
          <input
            type="time"
            id="journee_start"
            name="journee_start"
            value={parametresData?.journee_start}
            onChange={handleChange}
            placeholder="00:00"
            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            required
          />
        </div>
        <div className="flex flex-col gap-y-2">
          <label htmlFor="journee_end" className="text-lg font-medium text-gray-700">Fin de la Journée :</label>
          <input
            type="time"
            id="journee_end"
            name="journee_end"
            value={parametresData?.journee_end}
            onChange={handleChange}
            placeholder="00:00"
            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            required
          />
        </div>
        <div className="flex flex-col gap-y-2">
          <label htmlFor="nuite_start" className="text-lg font-medium text-gray-700">Début de la Nuitée :</label>
          <input
            type="time"
            id="nuite_start"
            name="nuite_start"
            value={parametresData?.nuite_start}
            onChange={handleChange}
            placeholder="00:00"
            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            required
          />
        </div>
        <div className="flex flex-col gap-y-2">
          <label htmlFor="nuite_end" className="text-lg font-medium text-gray-700">Fin de la Nuitée :</label>
          <input
            type="time"
            id="nuite_end"
            name="nuite_end"
            value={parametresData?.nuite_end}
            onChange={handleChange}
            placeholder="00:00"
            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            required
          />
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <AddButton />
      </div>
    </form>
  );
};

export default JourneeNuite;