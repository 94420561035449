import React from "react";
import { AddButton, SimpleTextEditor } from "../../../..";

const Section4BoContact = ({ contactData, handleChange, valider }) => {
  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">Section Accès</h1>
      <form onSubmit={valider} className="space-y-8">
        <div className="flex flex-col space-y-1">
          <label htmlFor="titreAcces">Grand titre</label>
          <input
            type="text"
            name="titreAcces"
            id="titreAcces"
            value={contactData?.titreAcces}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>

        <div>
          <div className="flex gap-x-6 min-h-[500px]">
            <div className="flex flex-col items-center px-7">
              <div className="mt-2 min-w-5 min-h-5 rounded-full bg-black"></div>
              <div
                className="h-full"
                style={{ border: "1px solid black" }}
              ></div>
              <div className="min-w-5 min-h-5 rounded-full bg-black"></div>
              <div
                className="h-full"
                style={{ border: "1px solid black" }}
              ></div>
              <div className="min-w-5 min-h-5 rounded-full bg-black"></div>
            </div>

            <div className="flex flex-col justify-between min-h-full gap-3">
              <div className="space-y-3">
                <input
                  type="text"
                  name="titre1"
                  value={contactData?.titre1}
                  onChange={handleChange}
                  className="p-2 border rounded-md outline-none focus:outline-none"
                  style={{ border: "1px solid gray" }}
                />
                <SimpleTextEditor
                  value={contactData?.description1}
                  onChange={(value) => handleChange({ target: { name: 'description1', value } })}
                  styleClassName=""
                />
              </div>

              <div className="space-y-3">
                <input
                  type="text"
                  name="titre2"
                  value={contactData?.titre2}
                  onChange={handleChange}
                  className="p-2 border rounded-md outline-none focus:outline-none"
                  style={{ border: "1px solid gray" }}
                />
                <SimpleTextEditor
                  value={contactData?.description2}
                  onChange={(value) => handleChange({ target: { name: 'description2', value } })}
                  styleClassName=""
                />
              </div>

              <div className="space-y-3">
                <input
                  type="text"
                  name="titre3"
                  value={contactData?.titre3}
                  onChange={handleChange}
                  className="p-2 border rounded-md outline-none focus:outline-none"
                  style={{ border: "1px solid gray" }}
                />
                <SimpleTextEditor
                  value={contactData?.description3}
                  onChange={(value) => handleChange({ target: { name: 'description3', value } })}
                  styleClassName=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-1 lg:col-span-2 flex items-center justify-end">
          <AddButton />
        </div>
      </form>
    </div>
  );
};

export default Section4BoContact;
