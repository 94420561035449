import * as React from "react";
import './style.scss'
import { Img12 } from "../../assets/images";

function AdvantagesP2({ data }) {

  const avantages = [
    {
      title: data?.titreAvantage1,
      description: data?.descriptionAvantage1,
    },
    {
      title: data?.titreAvantage2,
      description: data?.descriptionAvantage2,
    },
    {
      title: data?.titreAvantage3,
      description: data?.descriptionAvantage3,
    },
    {
      title: data?.titreAvantage4,
      description: data?.descriptionAvantage4,
    },
  ];

  return (
    <section>
      <div className="grid grid-cols-2 gap-4 w-10/12 mx-auto">
        <div className="flex flex-col justify-center items-center col-span-2 lg:col-span-1">
          <div className="space-y-4">
            <div className="text-4xl lg:text-5xl capitalize" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{data?.titreAvantages}</div>
            <div className="text-justify text-gray-600">
              <p dangerouslySetInnerHTML={{ __html: data?.descriptionAvantages }}/>
            </div>
            <div className="flex lg:hidden my-4 h-72 avantageCover"></div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-4">
            {avantages?.map((a, i)=>(
              <div key={i} className="bg-gray-50 p-4 text-justify">
                <div className="space-y-3">
                  <div className="text-2xl font-serif text-gray-600">{a?.title}</div>
                  <div className="text-base text-gray-500 text-justify" dangerouslySetInnerHTML={{ __html: a?.description }}/>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="hidden lg:block">
          <img src={Img12} className="rounded-2xl h-full" />
          {/* <img src={BASEIMAGEURL + data?.imageAvantages} className="rounded-2xl h-full" /> */}
        </div>
      </div>
    </section>
  );
}

export default AdvantagesP2;
