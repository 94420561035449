import { AddButton } from "../../../..";

const Section3BoAcceuil = ({ accueilData, handleChange, valider }) => {
  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">
        Section Espaces
      </h1>
      <form onSubmit={valider} className="space-y-3">
        <div className="flex flex-col space-y-1">
          <label htmlFor="titreEspaces">Grand titre</label>
          <input
            type="text"
            name="titreEspaces"
            id="titreEspaces"
            value={accueilData?.titreEspaces}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="flex flex-col space-y-1">
            <label htmlFor="buttonEspaces">Button</label>
            <input
              type="text"
              name="buttonEspaces"
              value={accueilData?.buttonEspaces}
              onChange={handleChange}
              id="buttonEspaces"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="lienButtonEspaces">Lien</label>
            <input
              type="text"
              name="lienButtonEspaces"
              value={accueilData?.lienButtonEspaces}
              onChange={handleChange}
              id="lienButtonEspaces"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
        </div>

        <div className="text-end">
          <AddButton />
        </div>
      </form>
    </div>
  );
};

export default Section3BoAcceuil;
