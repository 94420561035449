import { BasicSEO, BoLayout } from '../../../../components';
import BoHeaderSection2 from '../../../../components/backoffice/header';


const BoNavbar = () => {
  return (
    <BoLayout>
      <BasicSEO/>
      <h1 className='text-xl md:text-2xl font-poppins py-2 px-4 text-lightOrange' style={{ fontWeight: 600 }}>Header</h1>
      <div className="bg-gray-100 min-h-screen ">
        <div className="py-3 px-4 space-y-5">
          <BoHeaderSection2/>
        </div>
      </div>
    </BoLayout>
  )
}

export default BoNavbar