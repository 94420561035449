import React, { useEffect, useState } from "react";
import {
  AuthOptions,
  BannerSectionP13,
  ClientLoginForm,
  DetailRes,
  Gest,
  HeaderDetails,
} from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { apiClient } from "../../actions/api";
import { LoadingGif } from "../../assets/images";
import { Helmet } from "react-helmet";
import CleintRegisterForm from "../../components/clientRegisterForm";
import { LOGOUT_CLIENT, REMOVE_CLIENT_TOKEN } from "../../constants/actions";

const ReservationDetails = () => {
  const [select, setSelect] = useState(0);
  const [loading50off, setLoading50off] = useState(false);
  const [loadingTotal, setLoadingTotal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const res = useSelector(state => state?.detailsreservation);
  const client_token = useSelector((state) => state?.token?.client_token);
  const [conditionsChecked, setConditionsChecked] = useState({
    condition1: false,
    condition2: false,
  });

  const spaces = useSelector((state) => state?.pages?.data?.espaceCards);
  const res = useSelector((state) => state?.detailsreservation);
  const client = useSelector((state) => state?.auth?.client);
  const additional_price = useSelector(
    (state) => state?.pages?.data?.parametres?.additional_price
  );

  useEffect(() => {
    const checkClient = async () => {
      try {
        let response = null;
        if (!!client?.type) {
          response = await apiClient().get(`/clients/${client?.id}`);
        } else {
          response = await apiClient().get(`/guests/${client?.id}`);
        }
        if (!response?.data) {
          dispatch({ type: LOGOUT_CLIENT });
          dispatch({ type: REMOVE_CLIENT_TOKEN });
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: LOGOUT_CLIENT });
        dispatch({ type: REMOVE_CLIENT_TOKEN });
      }
    };
    checkClient();
  }, []);

  const goToPayment = async (mode) => {
    if (conditionsChecked?.condition1 && conditionsChecked?.condition2) {
      if (client === null || client === "") {
        toast.warning(
          "Vous devez vous connecter ou vous inscrire pour poursuivre le paiement !"
        );
      } else {
        if (mode === "50off") {
          setDisableBtn(true);
          setLoading50off(true);
        } else if (mode === "total") {
          setDisableBtn(true);
          setLoadingTotal(true);
        }
        await handleSubmit(mode);
        if (mode === "50off") {
          setLoading50off(false);
        } else if (mode === "total") {
          setLoadingTotal(false);
        }
      }
    } else {
      toast.warning("Vous devez accepter toutes les conditions.");
      setDisableBtn(false);
    }
  };

  const handleSubmit = async (mode) => {
    const space = spaces?.find(
      (sp) => sp?.id === parseInt(res?.reservation?.roomId)
    );

    const calculateTotal = () => {
      let total = 0;

      // if (space?.price) {
      //   total += parseFloat(space.price);
      // }

      if (Array.isArray(res?.formules)) {
        total += res.formules.reduce(
          (sum, formule) => sum + (parseFloat(formule.price) || 0),
          0
        );
      }

      if (res?.decor && res.decor.price) {
        total += parseFloat(res.decor.price);
      }

      if (res?.nbrpersonne > 2) {
        total += parseFloat(additional_price) * (res?.nbrpersonne - 2);
      }

      if (!!res?.promotionCode) {
        total = total * (parseFloat(res?.promotionCode) / 100);
      }

      return total.toFixed(2);
    };

    const response = await apiClient().post("/reservations", {
      espace_card_id: res?.reservation?.roomId,
      startTime: res?.reservation?.startTime,
      endTime: res?.reservation?.endTime,
      decor_id: res?.decor?.id,
      nbr_personne: parseInt(res?.nbrpersonne),
      price_personnes: res?.price_nbr_personne,
      date: res?.reservation?.date,
    });

    const reservationId = response?.data?.id;
    if (!reservationId) {
      throw new Error("Reservation ID is missing");
    }

    await apiClient().post("/reservations/attach-formules", {
      reservation_id: reservationId,
      formules: res?.formules?.map((formule) => formule.id),
    });

    const paymentData = {
      reservation_id: reservationId,
      price: calculateTotal(),
      user_id: client?.type ? client?.id : null,
      guest_id: client?.type ? null : client?.id,
      mode: mode === "total" ? "total" : "50off",
      transaction_id: "sdfsvcvdsf",
    };

    // console.log(paymentData);

    await apiClient().post("/payments", paymentData);

    const mailData = {
      type: "reservation",
      name: `${client?.first_name} ${client?.last_name}`,
      email: client?.email,
      service: space?.titre,
      date: res?.date,
      debut: res?.reservation?.startTime,
      fin: res?.reservation?.endTime,
      totalPrice: calculateTotal(),
    };

    await apiClient().post("/sendEmail", mailData);
    toast.success("La réservation est bien passée", {
      onClose: () => navigate(`/reservation/confirmation?id=${reservationId}`), // Redirect after toast closes
    });
  };

  return (
    <div className="">
      <Helmet>
        <title>Détails de la Réservation</title>
        <meta
          name="description"
          content="Consultez les détails de votre réservation et procédez au paiement. Acceptez les conditions générales et recevez des offres promotionnelles pour finaliser votre réservation chez FIVE SPA PRIVATIVE."
        />
      </Helmet>
      <BannerSectionP13 />
      <HeaderDetails />
      {!client_token && !client && (
        <AuthOptions select={select} setSelect={setSelect} />
      )}
      <div className="text-xl mt-7">
        {select === 1 && <Gest select={select} setSelect={setSelect} />}
        {select === 2 && (
          <div className="flex justify-center items-center mb-20 my-10">
            <div className="w-11/12 sm:w-10/12 md:max-w-3xl mx-auto border make-shadow rounded-2xl p-5 md:p-10 space-y-3">
              <h1 className="text-xl md:text-2xl font-semibold">
                Se Connecter
              </h1>
              <ClientLoginForm close={() => setSelect(0)} />
            </div>
          </div>
        )}
        {select === 3 && (
          <div className="flex justify-center items-center mb-20 my-10">
            <div className="w-11/12 sm:w-10/12 md:max-w-3xl mx-auto border make-shadow rounded-2xl p-5 md:p-10 space-y-3">
              <h1 className="text-xl md:text-2xl font-semibold">
                Se Connecter
              </h1>
              <CleintRegisterForm close={() => setSelect(0)} />
            </div>
          </div>
        )}
      </div>
      <DetailRes />
      <div className="space-y-6 max-w-7xl mx-auto px-4 mt-7">
        <div className="font-bold flex items-center gap-x-4">
          <input
            type="checkbox"
            name="condition1"
            id="condition1"
            onChange={(e) =>
              setConditionsChecked({
                ...conditionsChecked,
                condition1: e.target.checked,
              })
            }
            className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded focus:ring-red-500"
          />
          <label htmlFor="condition1">
            <span>J'ai lu et j'accepte les </span>
            <span
              className="text-golddo cursor-pointer"
              onClick={() => navigate("/conditions")}
            >
              conditions générales du site.
            </span>
          </label>
        </div>
        <div className="font-bold flex items-center gap-x-4">
          <input
            type="checkbox"
            name="condition2"
            id="condition2"
            onChange={(e) =>
              setConditionsChecked({
                ...conditionsChecked,
                condition2: e.target.checked,
              })
            }
            className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded focus:ring-red-500"
          />
          <label htmlFor="condition2">
            J'accepte de recevoir les offres promotionnelles.
          </label>
        </div>
        <div className="flex items-center flex-wrap sm:flex-nowrap gap-3 font-semibold">
          <button
            className={`${
              disableBtn ? "opacity-20" : "opacity-100"
            } h-10 w-56 min-w-full sm:min-w-fit border border-black bg-gray-800 text-white hover:bg-gray-700`}
            onClick={() => goToPayment("50off")}
            disabled={disableBtn}
          >
            {loading50off ? (
              <img
                src={LoadingGif}
                alt="loading"
                width={"20px"}
                height={"20px"}
                className="mx-auto"
              />
            ) : (
              "Payer un acompte de 50 %"
            )}
          </button>
          <button
            className={`${
              disableBtn ? "opacity-20" : "opacity-100"
            } h-10 w-36 min-w-full sm:min-w-fit border border-black bg-gray-800 text-white hover:bg-gray-700`}
            onClick={() => goToPayment("total")}
            disabled={disableBtn}
          >
            {loadingTotal ? (
              <img
                src={LoadingGif}
                alt="loading"
                width={"20px"}
                height={"20px"}
                className="mx-auto"
              />
            ) : (
              "Payer la totalité"
            )}
          </button>
        </div>
      </div>
      <div className="h-20"></div>
      <ToastContainer />
    </div>
  );
};

export default ReservationDetails;
