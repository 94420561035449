import React, { useEffect, useState } from 'react';
import { apiClient } from '../../../../../actions/api';
import uploadImage from '../../../../../helpers/uploadImages';
import AddButton from '../../AddButton';
import { BASEIMAGEURL } from '../../../../../constants/actions';
import removeImage from '../../../../../helpers/removeImage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BoEspacesDetailsSection2 = ({ space_id }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [album, setAlbum] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient().get(`/album/espace/${space_id}`);
        setAlbum(response.data);
      } catch (err) {
        console.error('Error fetching album:', err);
      }
    };
    fetchData();
  }, [space_id, refresh]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }))]);
  };

  const handleRemoveSelectedImage = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      for (const { file } of selectedFiles) {
        const newPath = await uploadImage('image', {image:file}, "", 'espace-albums');
        
        await apiClient().post('/espace-albums', {
          image: newPath,
          espace_card_id: space_id,
        });
      }
      toast.success('Détails de l\'espace modifiés avec succès', { autoClose: 600 });
      setSelectedFiles([]);
      setRefresh(!refresh);
    } catch (err) {
      console.error('Error uploading files:', err);
      toast.error('Erreur lors de la modification des détails de l\'espace', { autoClose: 600 });
    }
  };

  const handleRemoveImage = async (imageId) => {
    const image = album.find(i => i.id === imageId);
    if (!image) return;

    try {
      await removeImage(image.image);
      await apiClient().delete(`/espace-albums/${imageId}`);
      setAlbum(album.filter((image) => image.id !== imageId));
    } catch (err) {
      console.error('Error removing image:', err);
    }
  };

  const renderCarousel = (images, onRemove) => (
    <div className="overflow-x-auto whitespace-nowrap">
      <div className="inline-flex space-x-4">
        {images.map((image, index) => (
          <div key={image.id || index} className="relative inline-block">
            <img
              src={image.image ? BASEIMAGEURL + image.image : image.preview}
              alt={`img ${image.id || index}`}
              className="w-64 h-48 min-w-64 min-h-48 max-w-64 max-h-48 object-cover rounded"
            />
            <button
              onClick={() => onRemove(image.id || index)}
              className="absolute top-2 right-2 text-red-600 rounded-full bg-white w-5 h-5 flex justify-center items-center"
            >
              <i className="bi bi-x text-xl"></i>
            </button>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="p-3 bg-white rounded-lg">
      <h2 className="font-semibold text-xl mb-4">Section Images</h2>
      {album.length > 0 && (
        <div className='overflow-x-auto whitespace-nowrap'>
          <h3 className="font-semibold text-lg mb-2">Album Images</h3>
          {renderCarousel(album, handleRemoveImage)}
        </div>
      )}
      <form onSubmit={handleFormSubmit} className="mt-4">
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="hidden"
          id='album'
        />
        <label htmlFor='album' className="bg-lightgray font-bold py-2 px-4 rounded cursor-pointer">
          Sélectionner les images
        </label>
        {selectedFiles.length > 0 && (
          <>
            <h3 className="font-semibold text-lg mt-4 mb-2">Selected Images</h3>
            {renderCarousel(selectedFiles, handleRemoveSelectedImage)}
          </>
        )}
        <div className="flex justify-end mt-4">
          <AddButton />
        </div>
      </form>
    </div>
  );
};

export default BoEspacesDetailsSection2;
