import React from "react";

const PolitiqueSection2 = ({ data }) => {
  return (
    <div className="text-justify max-w-3xl mx-auto p-3 space-y-3">
      <div dangerouslySetInnerHTML={{ __html: data?.contenue }}/>
    </div>
  );
};

export default PolitiqueSection2;
