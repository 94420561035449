import React, { useState } from "react";
import { registerGuest } from '../../../actions/client'
import { useDispatch } from "react-redux";

const Gest = ({ select, setSelect }) => {
  const [guestForm, setGuestForm] = useState();
  const dispatch = useDispatch();
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setGuestForm({ ...guestForm, [name]: value });
  };

  const SubmitGuestForm = async(e) => {
    e.preventDefault();
      try {
        await dispatch(registerGuest(guestForm));
        setSelect(!select);
      } catch (err) {
        console.error("Registration failed: ", err);
      }
  };
  
  return (
    <div className="flex justify-center items-center mb-20 my-10">
      <div className="w-11/12 sm:w-10/12 md:max-w-3xl mx-auto border make-shadow rounded-2xl p-5 md:p-10 space-y-3">
        <h1 className="text-xl md:text-2xl font-semibold">
          Continuer en tant qu'invité
        </h1>
        <form onSubmit={SubmitGuestForm} className="space-y-3">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-5">
            <div className="flex flex-col gap-y-2">
              <label
                htmlFor="first_name"
                className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-800 focus-within:ring-1 focus-within:ring-gray-800"
              >
                <span className="text-xs font-medium text-gray-700">Votre nom</span>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={guestForm?.first_name || ""}
                  onChange={handleChange}
                  placeholder="Nom"
                  className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
                  required
                  autoComplete="off"
                />
              </label>
            </div>
            <div className="flex flex-col gap-y-2">
              <label
                htmlFor="last_name"
                className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-800 focus-within:ring-1 focus-within:ring-gray-800"
              >
                <span className="text-xs font-medium text-gray-700">Votre prénom</span>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={guestForm?.last_name || ""}
                  onChange={handleChange}
                  placeholder="Prénom"
                  className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
                  required
                  autoComplete="off"
                />
              </label>
            </div>
            <div className="flex flex-col gap-y-2">
              <label
                htmlFor="email"
                className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-800 focus-within:ring-1 focus-within:ring-gray-800"
              >
                <span className="text-xs font-medium text-gray-700">Votre email</span>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={guestForm?.email || ""}
                  onChange={handleChange}
                  placeholder="Email"
                  className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
                  required
                  autoComplete="off"
                />
              </label>
            </div>
            <div className="flex flex-col gap-y-2">
              <label
                htmlFor="phone"
                className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-800 focus-within:ring-1 focus-within:ring-gray-800"
              >
                <span className="text-xs font-medium text-gray-700">Votre téléphone</span>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  value={guestForm?.phone || ""}
                  onChange={handleChange}
                  placeholder="Téléphone"
                  className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
                  required
                  autoComplete="off"
                />
              </label>
            </div>
            <div className="flex flex-col gap-y-2">
              <label
                htmlFor="code_postal"
                className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-800 focus-within:ring-1 focus-within:ring-gray-800"
              >
                <span className="text-xs font-medium text-gray-700">
                  Code postal
                </span>
                <input
                  type="number"
                  name="code_postal"
                  id="code_postal"
                  value={guestForm?.code_postal || ""}
                  onChange={handleChange}
                  placeholder="Code postal"
                  className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
                  required
                  autoComplete="off"
                />
              </label>
            </div>
            <div className="flex flex-col gap-y-2">
              <label
                htmlFor="birthday"
                className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-800 focus-within:ring-1 focus-within:ring-gray-800"
              >
                <span className="text-xs font-medium text-gray-700">
                  Date de naissance
                </span>
                <input
                  type="date"
                  name="birthday"
                  id="birthday"
                  value={guestForm?.birthday || ""}
                  onChange={handleChange}
                  className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 text-sm"
                  required
                  autoComplete="off"
                />
              </label>
            </div>
          </div>
          <div className="mt-4 flex items-center flex-col md:flex-row gap-4">
            <button
              type="submit"
              className="w-40 p-3 font-semibold rounded-sm text-white bg-gray-800 hover:bg-gray-700"
            >
              Continuer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Gest;
