import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiClient } from "../../../../actions/api";
import { getData } from "../../../../actions/pages";

const CodePromo = () => {
  const promo_codes = useSelector((state) => state?.pages?.data?.promo_codes) || [];
  const [currentPage, setCurrentPage] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [newPromoCode, setNewPromoCode] = useState({
    code: "",
    discount: "",
    expires_at: "",
    expire_nbr: 0, // Initialize with default value
  });
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = promo_codes.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(promo_codes.length / itemsPerPage);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData("/promo-codes"));
  }, [dispatch]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const ToggleCodePromo = async (code) => {
    try {
      await apiClient().put(`/promo-codes/${code?.id}`, {
        status: !code?.status,
      });
      dispatch(getData("/promo-codes"));
    } catch (err) {
      console.error(err);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setNewPromoCode((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [editingPromoCode, setEditingPromoCode] = useState(null);

  const handleEdit = (code) => {
    setEditingPromoCode(code);
    setNewPromoCode({
      code: code.code,
      discount: code.discount,
      expires_at: new Date(code.expires_at).toISOString().slice(0, 16),
      expire_nbr: code.expire_nbr, // Include this field
    });
    setShowForm(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await apiClient().put(`/promo-codes/${editingPromoCode.id}`, newPromoCode);
      dispatch(getData("/promo-codes"));
      setNewPromoCode({ code: "", discount: "", expires_at: "", expire_nbr: 0 });
      setEditingPromoCode(null);
      setShowForm(false);
      toast.success("Code promo modifié avec succès");
    } catch (err) {
      console.error(err);
      toast.error("Erreur lors de la mise à jour du code promo");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (editingPromoCode) {
      handleUpdate(e);
    } else {
      apiClient().post("/promo-codes", newPromoCode)
        .then(() => {
          dispatch(getData("/promo-codes"));
          setNewPromoCode({ code: "", discount: "", expires_at: "", expire_nbr: 0 });
          setShowForm(false);
          toast.success("Code promo ajouté avec succès");
        })
        .catch(err => {
          console.error(err);
          toast.error("Erreur lors de l'ajout du code promo");
        });
    }
  };

  const handleDelete = async (id) => {
    try {
      await apiClient().delete(`/promo-codes/${id}`);
      dispatch(getData("/promo-codes"));
      toast.success("Code promo supprimé avec succès");
    } catch (err) {
      console.error(err);
      toast.error("Erreur lors de la suppression du code promo");
    }
  };

  return (
    <div className="m-3 font-poppins bg-white rounded-lg p-4">
      <div className="flex justify-end mb-4">
        <button
          className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-all duration-300"
          onClick={() => setShowForm(!showForm)}
        >
          {showForm ? "Annuler" : "Ajouter un code promo"}
        </button>
      </div>

      {showForm && (
        <form
          onSubmit={handleFormSubmit}
          className="mb-4 p-6 border rounded-md shadow-md bg-gray-50"
        >
          <h2 className="text-lg font-semibold mb-4 text-gray-700">
            {editingPromoCode ? "Modifier le code promo" : "Ajouter un nouveau code promo"}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="mb-4">
              <label htmlFor="code" className="block text-sm font-medium mb-2">
                Code
              </label>
              <input
                type="text"
                id="code"
                name="code"
                value={newPromoCode.code}
                onChange={handleFormChange}
                className="border border-gray-300 p-3 rounded w-full focus:outline-none focus:ring-2 focus:ring-gray-500"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="discount"
                className="block text-sm font-medium mb-2"
              >
                Remise (%)
              </label>
              <input
                type="number"
                id="discount"
                name="discount"
                value={newPromoCode.discount}
                onChange={handleFormChange}
                className="border border-gray-300 p-3 rounded w-full focus:outline-none focus:ring-2 focus:ring-gray-500"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="expires_at"
                className="block text-sm font-medium mb-2"
              >
                Expire le
              </label>
              <input
                type="datetime-local"
                id="expires_at"
                name="expires_at"
                value={newPromoCode.expires_at}
                onChange={handleFormChange}
                className="border border-gray-300 p-3 rounded w-full focus:outline-none focus:ring-2 focus:ring-gray-500"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="expire_nbr"
                className="block text-sm font-medium mb-2"
              >
                Nombre d'utilisations
              </label>
              <input
                type="number"
                id="expire_nbr"
                name="expire_nbr"
                value={newPromoCode.expire_nbr}
                onChange={handleFormChange}
                className="border border-gray-300 p-3 rounded w-full focus:outline-none focus:ring-2 focus:ring-gray-500"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className={`px-4 py-2 rounded-md font-medium ${
                editingPromoCode ? "bg-yellow-500 hover:bg-yellow-600" : "bg-green-500 hover:bg-green-600"
              } text-white transition-all duration-300`}
            >
              {editingPromoCode ? "Modifier" : "Ajouter"}
            </button>
          </div>
        </form>
      )}

      <div className="border rounded-tr-xl rounded-tl-xl overflow-x-auto shadow-md">
        <table width="100%" className="text-xs md:text-sm lg:text-base">
          <thead>
            <tr className="bg-gray-100 text-gray-800">
              <th className="text-center p-3">ID</th>
              <th className="text-center p-3">Code</th>
              <th className="text-center p-3">Remise</th>
              <th className="text-center p-3">Nombre d'utilisations</th>
              <th className="text-center p-3">Expire le</th>
              <th className="text-center p-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center p-4 text-gray-500">
                  Aucun code promo disponible
                </td>
              </tr>
            ) : (
              currentItems.map((code) => (
                <tr
                  key={code?.id}
                  className="hover:bg-gray-50 transition-colors"
                >
                  <td className="text-center p-3">{code?.id}</td>
                  <td className="text-center p-3">{code?.code}</td>
                  <td className="text-center p-3">{code?.discount} %</td>
                  <td className="text-center p-3">{code?.expire_nbr}</td>
                  <td className="text-center p-3">
                    {new Date(code?.expires_at).toLocaleDateString()}{" "}
                    {new Date(code?.expires_at).toLocaleTimeString()}
                  </td>
                  <td className="text-center p-3 gap-2">
                    <button
                      onClick={() => handleEdit(code)}
                      className="mr-2 px-3 py-1 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-all duration-300"
                    >
                      Modifier
                    </button>
                    <button
                      onClick={() => handleDelete(code.id)}
                      className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-all duration-300"
                    >
                      Supprimer
                    </button>
                    {/* <button
                      onClick={() => ToggleCodePromo(code)}
                      className={`px-3 py-1 rounded-md ${
                        code?.status ? "bg-red-500 hover:bg-red-600" : "bg-green-500 hover:bg-green-600"
                      } text-white transition-all duration-300`}
                    >
                      {code?.status ? "Désactiver" : "Activer"}
                    </button> */}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500 transition-all duration-300"
        >
          Précédent
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500 transition-all duration-300"
        >
          Suivant
        </button>
      </div>

      <ToastContainer />
    </div>
  );
};

export default CodePromo;