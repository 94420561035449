import { useEffect } from 'react'
import { BasicSEO, BoLayout, Section1Paiments, Section2Paiments } from '../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { getData } from '../../../actions/pages';

const BoPaiements = () => {

  const paiments = useSelector(state => state?.pages?.data?.paiments);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getData('/payments'));
  },[])

  return (
    <BoLayout>
      <BasicSEO/>
      <div className="p-3 mb-32">
        <Section1Paiments paiments={paiments} />
        <Section2Paiments paiments={paiments} />
      </div>
    </BoLayout>
  )
}

export default BoPaiements