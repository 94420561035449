import React, { useEffect, useState } from "react";
import AddButton from "../../AddButton";
import { getCurrentDateFormatted } from "../../../../../helpers/getCurrentDate";
import { BASEIMAGEURL } from "../../../../../constants/actions";

const SeoBoAcceuil = ({ accueilData, handleChange, valider }) => {
  const [fileName, setFileName] = useState("Choisir une image");
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (!selectedImage && accueilData?.imageSeo) {
      setSelectedImage(BASEIMAGEURL + accueilData.imageSeo);
    }
  }, [accueilData, selectedImage]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      handleChange({
        target: {
          name: "imageSeo",
          value: file
        }
      });
    }
  };

  return (
    <div>
      <div className="bg-white p-3 rounded-lg space-y-6">
        <h1 className="font-semibold font-poppins text-xl">
          Optimisation pour les moteurs de recherche (SEO)
        </h1>
        <div className="p-3 border rounded-md">
          <h2 className="text-blue-700 font-semibold capitalize font-poppins text-lg">
            {accueilData?.titreSeo || "seo title"}
          </h2>
          <p className="text-green-700 text-sm">
            https://five-spa-privatif.fr
          </p>
          <p className="text-base font-poppins">
            <span className="text-gray-400 float-left">
              {getCurrentDateFormatted()} -{" "}
            </span>
            <span>{accueilData?.descriptionSeo || "seo description"}</span>
          </p>
        </div>
        <form onSubmit={valider} className="grid grid-cols-1 gap-3">
          <div className="flex flex-col space-y-1">
            <label htmlFor="titreSeo">Titre SEO</label>
            <input
              type="text"
              name="titreSeo"
              id="titreSeo"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
              onChange={handleChange}
              value={accueilData?.titreSeo || ""}
            />
          </div>

          <div className="flex flex-col space-y-1">
            <label htmlFor="descriptionSeo">Description SEO</label>
            <textarea
              name="descriptionSeo"
              id="descriptionSeo"
              className="w-full h-40 md:h-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
              onChange={handleChange}
              value={accueilData?.descriptionSeo || ""}
            ></textarea>
          </div>

          <div className="flex flex-col space-y-1">
            <label htmlFor="motsClesSeo">Mots Clés SEO</label>
            <textarea
              name="motsClesSeo"
              id="motsClesSeo"
              placeholder="mots clés seo (séparées par des virgules)"
              className="w-full h-40 md:h-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
              onChange={handleChange}
              value={accueilData?.motsClesSeo || ""}
            ></textarea>
          </div>

          <div className="flex items-center flex-wrap gap-x-3">
            <div className="bg-gray-200 rounded-md">
              <div className="cursor-pointer">
                {selectedImage ? (
                  <div className="relative">
                    <label htmlFor="seoimage" className="cursor-pointer">
                      <img
                        src={selectedImage}
                        alt="img sélectionnée"
                        className="object-cover max-w-32 max-h-32 min-w-32 min-h-32"
                      />
                    </label>
                  </div>
                ) : (
                  <label
                    htmlFor="seoimage"
                    className="w-32 h-38 mx-auto bg-white border rounded-md flex items-center justify-center"
                  >
                    <i className="bi bi-card-image text-8xl text-gray-200"></i>
                  </label>
                )}
              </div>
            </div>
            <div className="">
              <div className="rounded-md text-center py-3 px-2 sm:px-3">
                <input
                  type="file"
                  name="imageSeo"
                  id="seoimage"
                  className="sr-only"
                  onChange={handleImageChange}
                />
                <label
                  htmlFor="seoimage"
                  className="text-sm md:text-base py-2 px-4 rounded-md border-fancygreen text-fancygreen cursor-pointer"
                  style={{ border: "1px solid gray" }}
                >
                  Choisir une image
                </label>
                {fileName && (
                  <span className="ml-3 text-gray-600 text-xs md:text-base">
                    {fileName}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label htmlFor="index">Index</label>
            <div className="flex items-center gap-3">
              <label
                htmlFor="isindex"
                className="flex items-center gap-3 px-4 py-2 border border-gray-200 rounded"
              >
                <input
                  type="radio"
                  name="indexSeo"
                  id="isindex"
                  value="1"
                  checked={parseInt(accueilData?.indexSeo) === 1}
                  onChange={handleChange}
                />
                Index
              </label>
              <label
                htmlFor="notindex"
                className="flex items-center gap-3 px-4 py-2 border border-gray-200 rounded"
              >
                <input
                  type="radio"
                  name="indexSeo"
                  id="notindex"
                  value="0"
                  checked={parseInt(accueilData?.indexSeo) === 0}
                  onChange={handleChange}
                />
                No Index
              </label>
            </div>
          </div>

          <div className="flex items-center justify-end">
            <AddButton />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SeoBoAcceuil;
