import * as React from "react";
import { useState, useEffect } from "react";
import './style.scss'
import { useNavigate } from "react-router-dom";
import { BASEIMAGEURL } from "../../constants/actions";
import { useSelector } from "react-redux";

const ImageCard = ({ id, src, title, desc }) => {
  const navigate = useNavigate();
  return(
    <div onClick={() => navigate(`/prestations/${id}`)} className="image-cardpre">
      <div className="image-containerpre">
        <img src={src} alt={title} className="card-image" />
        <h3 className="card-titlepre text-lg sm:text-xl md:text-2xl">{title}</h3>
        <div className="card-descriptionpre text-justify" dangerouslySetInnerHTML={{ __html: desc }}/>
      </div>
    </div>
  );
}

function PrestationsSection() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(3);
  const prestations = useSelector(state => state?.pages?.data?.prestationcards);
  const [size, setSize] = useState(3);

  useEffect(()=>{
    setSize(prestations?.length)
  },[]);

  useEffect(() => {
    const updateItemsToShow = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setItemsToShow(1);
      } else if (width < 991) {
        setItemsToShow(2);
      } else {
        setItemsToShow(3);
      }
    };

    window.addEventListener('resize', updateItemsToShow);
    updateItemsToShow(); // Initial call

    return () => window.removeEventListener('resize', updateItemsToShow);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % prestations?.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + prestations?.length) % prestations?.length);
  };

  return (
    <section className="text-center">
      <div className="relative w-8/12 sm:w-8/12 md:w-10/12 lg:w-10/12 mx-auto">
        <button className="block lg:hidden absolute top-1/2 -left-12 md:-left-12 transform -translate-y-1/2 z-10" onClick={prevSlide}><i className="bi bi-arrow-left-circle text-3xl text-darckyellow"></i></button>
        <div className="image-grid-pre">
          {prestations?.length && Array.from({ length: itemsToShow }, (_, offset) => {
            const index = (currentIndex + offset) % size;
            return (
              <ImageCard
                key={index}
                id={prestations[index]?.id}
                src={BASEIMAGEURL + prestations[index]?.image}
                alt={prestations[index]?.titre}
                desc={prestations[index]?.description}
              />
            );
          })}
        </div>
        <button className="block lg:hidden absolute top-1/2 -right-12 md:-right-12 transform -translate-y-1/2 z-10"  onClick={nextSlide}><i className="bi bi-arrow-right-circle text-3xl text-darckyellow"></i></button>
      </div>
    </section>
  );
}

export default function PrestationsP1() {
  return (
    <>
      <PrestationsSection />
    </>
  );
}
