import React from 'react';
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import './style.scss';

// const containerStyle = {
//   width: '100%',
//   height: '450px',
// };


const Localisation = ({ data }) => {
  // const center = {
  //   lat: parseFloat(data?.latitude),
  //   lng: parseFloat(data?.longitude),
  // };
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: "YOUR_API_KEY",
  // });

  // const [map, setMap] = React.useState(null);

  // const onLoad = React.useCallback(function callback(map) {
  //   setMap(map);
  // }, []);

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null);
  // }, []);

  return (
    <section id='location'>
      <div className="py-10 w-10/12 mx-auto mb-20">
        <h1 className='text-4xl font-serif font-bold text-center my-5 capitalize'>{data?.titreLocalisation}</h1>
        <div className="h-[400px] sm:[470px] md:[660px] lg:h-[600px]">
          {/* {isLoaded && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                disableDefaultUI: true,
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
            >
            </GoogleMap>
          )} */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.9994458845613!2d2.292292250774187!3d48.85884497928738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66f9e41f7e2d1%3A0x9b51f36b8dc0925a!2sEiffel%20Tower%2C%20Champ%20de%20Mars%2C%205%20Avenue%20Anatole%20France%2C%2075007%20Paris%2C%20France!5e0!3m2!1sen!2sus!4v1594735871748!5m2!1sen!2sus"
            className='w-full h-full'
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            title="Paris Map"
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default Localisation;