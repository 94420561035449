import {
  ACCUEIL_DATA,
  ESPACE_DATA,
  PRESTATION_DATA,
  HEADER_DATA,
  FOOTER_DATA,
  PRESTATION_CARDS_DATA,
  CONATCT_DATA,
  ACTIVE_FOOTER_DATA,
  ESPACE_CARDS_DATA,
  BLOG_DATA,
  BLOG_CARDS_DATA,
  PARAMETRES_DATA,
  DECORES_DATA,
  FORMULES_DATA,
  RESERVATIONS_DATA,
  EMAILS_DATA,
  CLIENT_RESERVATIONS,
  CLIENT_DATA,
  GUESTS_DATA,
  PAIMENTS_DATA,
  PROMO_CODES,
  CONTACT_MAILS,
  POLITIQUE_DATA,
  CONDITIONS_DATA,
  RGPD_DATA,
} from "../constants/actions";

const INITIAL_STATE = {
  data: {},
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HEADER_DATA:
      return { ...state, data: {...state.data, header: action.payload} };
    case FOOTER_DATA:
      return { ...state, data: {...state.data, footer: action.payload} };
    case ACCUEIL_DATA:
      return { ...state, data: {...state.data, accueil: action.payload} };
    case ESPACE_DATA:
      return { ...state, data: {...state.data, espace: action.payload} };
    case PRESTATION_DATA:
      return { ...state, data: {...state.data, prestation: action.payload} };
    case ESPACE_CARDS_DATA:
      return { ...state, data: {...state.data, espaceCards: action.payload} };
    case PRESTATION_CARDS_DATA:
      return { ...state, data: {...state.data, prestationcards: action.payload} };
    case CONATCT_DATA:
      return { ...state, data: {...state.data, contact: action.payload} };
    case ACTIVE_FOOTER_DATA:
      return { ...state, data: {...state.data, activeFooters: action.payload} };
    case BLOG_DATA:
      return { ...state, data: {...state.data, blog: action.payload} };
    case BLOG_CARDS_DATA:
      return { ...state, data: {...state.data, blogCards: action.payload} };
    case PARAMETRES_DATA:
      return { ...state, data: {...state.data, parametres: action.payload} };
    case DECORES_DATA:
      return { ...state, data: {...state.data, decores: action.payload} };
    case FORMULES_DATA:
      return { ...state, data: {...state.data, formules: action.payload} };
    case RESERVATIONS_DATA:
      return { ...state, data: {...state.data, reservations: action.payload} };
    case EMAILS_DATA:
      return { ...state, data: {...state.data, emails: action.payload} };
    case CLIENT_RESERVATIONS:
      return { ...state, data: {...state.data, client_reservations: action.payload} };
    case CLIENT_DATA:
      return { ...state, data: {...state.data, clients: action.payload} };
    case GUESTS_DATA:
      return { ...state, data: {...state.data, guests: action.payload} };
    case PAIMENTS_DATA:
      return { ...state, data: {...state.data, paiments: action.payload} };
    case PROMO_CODES:
      return { ...state, data: {...state.data, promo_codes: action.payload} };
    case CONTACT_MAILS:
      return { ...state, data: {...state.data, contact_mails: action.payload} };
    case POLITIQUE_DATA:
      return { ...state, data: {...state.data, politique: action.payload} };
    case CONDITIONS_DATA:
      return { ...state, data: {...state.data, conditions: action.payload} };
    case RGPD_DATA:
      return { ...state, data: {...state.data, rgpd: action.payload} };
      
    default:
      return state;
  }
}
export default reducer;
