import { AddButton } from "../../../..";
const Section4BoAcceuil = ({ accueilData, handleChange, valider }) => {
  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">
        Section Prestations
      </h1>
      <form onSubmit={valider} className="space-y-3">
        <div className="col-span-1 lg:col-span-2 flex flex-col space-y-1">
          <label htmlFor="titrePrestations">Grand titre</label>
          <input
            type="text"
            name="titrePrestations"
            id="titrePrestations"
            value={accueilData?.titrePrestations}
            onChange={handleChange}
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="flex flex-col space-y-1">
            <label htmlFor="buttonPrestations">Button</label>
            <input
              type="text"
              name="buttonPrestations"
              value={accueilData?.buttonPrestations}
              onChange={handleChange}
              id="buttonPrestations"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="lienButtonPrestations">Lien</label>
            <input
              type="text"
              name="lienButtonPrestations"
              value={accueilData?.lienButtonPrestations}
              onChange={handleChange}
              id="lienButtonPrestations"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
        </div>

        <div className="text-end col-span-1 lg:col-span-2">
          <AddButton />
        </div>
      </form>
    </div>
  );
};

export default Section4BoAcceuil;
