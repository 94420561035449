import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./header.scss";
import { LogoOrangePng } from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { BASEIMAGEURL } from "../../constants/actions";
import { logoutClient } from "../../actions/client";

const NavItem = ({ title, style }) => (
  <span className={`text-lg ${style} capitalize`} style={{ fontFamily: 'Playfair Display', fontWeight: '700' }}>
    {title}
  </span>
);

const Button = ({ text, variant, fun }) => (
  <button className={`button ${variant}`} style={{ fontFamily: "verdana" }} onClick={() => fun()}>
    {text}
  </button>
);

const socialIcons = [
  { icon: "telephone", path: "/profile", tab: 2 },
  { icon: "basket", path: "/profile", tab: 3 },
];

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  
  const navItems = useSelector(state => state?.pages?.data?.header?.links)?.filter(l => l?.status);
  const client_token = useSelector(state => state?.client_token);
  const client = useSelector(state => state?.auth?.client);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const navigate = useNavigate();

  const SocialIcon = ({ icon }) => (
    <i className={`bi bi-${icon?.icon}-fill text-gray-100 hover:text-gray-300 cursor-pointer`} onClick={() => navigate(icon?.path, { state: { tab: icon?.tab } })}></i>
  );
  
  const logout = () =>{
    dispatch(logoutClient());
  }

  return (
    <header className="bg-transparent absolute w-full">
      <div className="w-11/12 mx-auto py-2">
        <div className="flex justify-end items-center gap-3 text-xl text-white p-2">
        <div className="">
          {client !== null && client !== '' && !!client?.type ? (
            <div className="flex gap-2">
              {socialIcons?.map((icon, index) => (
                <SocialIcon key={index} icon={icon} />
              ))}
              <div className="flex items-center gap-1 cursor-pointer relative" onClick={toggleDropdown} ref={dropdownRef}>
                <div className="">
                  <div className="capitalize text-sm flex items-center justify-between gap-3">
                    <div className="">{client?.first_name} {client?.last_name}</div>
                    <div className=""><i className={`bi bi-chevron-${isDropdownOpen ? 'up' : 'down'}`}></i></div>
                  </div>
                  {isDropdownOpen && (
                    <div className="absolute top-full right-0 mt-2 w-40 bg-white text-sm z-50 text-gray-900 rounded shadow-lg py-2 space-y-1">
                      <div className="px-4 py-2 cursor-pointer text-center hover:bg-gray-100" onClick={() => navigate('/profile', { state: { tab: 1 } })}>Tableau de bord</div>
                      <div className="px-4 py-2 cursor-pointer text-center hover:bg-gray-100" onClick={() => navigate('/profile', { state: { tab: 2 } })}>Profil</div>
                      <div className="px-4 py-2 cursor-pointer text-center hover:bg-gray-100" onClick={() => navigate('/profile', { state: { tab: 3 } })}>Réservations</div>
                      <div className="px-4 py-2 bg-golddo text-white font-medium w-fit mx-auto" onClick={logout}>Se Déconnecter</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-1 font-semibold text-sm">
              <span className="cursor-pointer" onClick={() => navigate('/login')}>Se Connecter</span>
              <span>/</span>
              <span className="cursor-pointer" onClick={() => navigate('/register')}>S'inscrire</span>
            </div>
          )}
        </div>

        </div>
        <div className="flex items-center justify-between py-2">
          <img
            src={LogoOrangePng}
            className="w-40 cursor-pointer"
            alt="Company Logo"
            onClick={() => navigate('/')}
          />
          <button className="hamburger" onClick={toggleMenu}>
            <div className="relative w-8 h-8">
              <i className={`bi bi-list absolute inset-0 transition-opacity duration-300 ${isMenuOpen ? 'opacity-0' : 'opacity-100'} text-white text-4xl cursor-pointer`}></i>
              <i className={`bi bi-x-lg absolute inset-0 transition-opacity duration-300 ${isMenuOpen ? 'opacity-100' : 'opacity-0'} text-white text-4xl cursor-pointer`}></i>
            </div>
          </button>
          {windowWidth <= 991 ? (
            <>
              <nav
                className="nav nav-with-btn bg-gray-50"
                style={{ display: `${isMenuOpen ? "flex" : "none"}` }}
              >
                {navItems?.map((item, index) => (
                  <Link to={item.link} key={index}>
                    <NavItem title={item.name} style={"hover:text-black"} />
                  </Link>
                ))}
                <Button text="Réservez en ligne" variant="primary" fun={() => navigate('/reservation')} />
              </nav>
            </>
          ) : (
            <>
              <nav
                className="flex items-center justify-center gap-6"
                style={{ fontFamily: "verdana" }}
              >
                {navItems?.map((item, index) => (
                  <Link to={item.link} key={index}>
                    <NavItem title={item.name} style={"text-gray-300 hover:text-white"} />
                  </Link>
                ))}
              </nav>
              <Button
                text="Réservez en ligne"
                variant="primary"
                fun={() => navigate('/reservation')}
              />
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
