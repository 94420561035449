import { useEffect, useState } from "react";
import { BoLayout } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../../actions/pages";
import { useNavigate } from "react-router-dom";

const ContactMailList = () => {
  const dispatch = useDispatch();
  const contact_mails = useSelector(
    (state) => state?.pages?.data?.contact_mails
  );
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getData("/contact-mails"));
  }, [dispatch]);

  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(contact_mails?.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleMails = contact_mails?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <BoLayout>
      <div className="p-4 font-poppins">
        <div className="border rounded-tr-xl rounded-tl-xl overflow-x-auto shadow-md">
          <table className="w-full min-w-max text-sm">
            <thead>
              <tr className="text-gray-800 bg-gray-200 border-b border-gray-300">
                <th className="p-3 text-start">ID</th>
                <th className="p-3 text-start">Nom</th>
                <th className="p-3 text-start">Prénom</th>
                <th className="p-3 text-start">Email</th>
                <th className="p-3 text-start">Téléphone</th>
              </tr>
            </thead>
            <tbody>
              {visibleMails?.length > 0 ? 
                visibleMails?.map((mail, index) => (
                  <tr
                    key={index}
                    className="cursor-pointer hover:bg-gray-50"
                    onClick={() => navigate(`/gestion/contact-mails/${mail?.id}`)}
                  >
                    <td className="p-3 text-gray-700">#{mail.id}</td>
                    <td className="p-3 text-gray-700">{mail?.nom}</td>
                    <td className="p-3 text-gray-700">{mail?.prenom}</td>
                    <td className="p-3 text-gray-700">{mail?.email}</td>
                    <td className="p-3 text-gray-700">{mail?.phone}</td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={5} className="p-3 text-center text-gray-500">Aucun email disponible</td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
        {contact_mails?.length > itemsPerPage && (
          <div className="p-3 flex items-center justify-between text-xs">
            <div>
              <p className="text-gray-500">
                Affichage des données {startIndex + 1} à {Math.min(startIndex + itemsPerPage, contact_mails?.length)} sur {contact_mails?.length} entrées
              </p>
            </div>
            <nav className="flex items-center space-x-1">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-2 py-1 rounded-md bg-gray-200 text-gray-600 disabled:opacity-50"
              >
                <i className="bi bi-chevron-left"></i>
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-2 py-1 rounded-md ${
                    currentPage === index + 1
                      ? "bg-gray-600 text-white"
                      : "bg-gray-200 text-gray-600"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-2 py-1 rounded-md bg-gray-200 text-gray-600 disabled:opacity-50"
              >
                <i className="bi bi-chevron-right"></i>
              </button>
            </nav>
          </div>
        )}
      </div>
    </BoLayout>
  );
};

export default ContactMailList;