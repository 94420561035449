import { ACCUEIL_DATA, ESPACE_DATA, FOOTER_DATA, HEADER_DATA, PRESTATION_DATA, PRESTATION_CARDS_DATA, CONATCT_DATA, ACTIVE_FOOTER_DATA, ESPACE_CARDS_DATA, BLOG_DATA, BLOG_CARDS_DATA, STORE_USER, PARAMETRES_DATA, DECORES_DATA, FORMULES_DATA, STORE_CLIENT, RESERVATIONS_DATA, EMAILS_DATA, CLIENT_RESERVATIONS, CLIENT_DATA, GUESTS_DATA, PAIMENTS_DATA, PROMO_CODES, CONTACT_MAILS, POLITIQUE_DATA, CONDITIONS_DATA, RGPD_DATA } from "../constants/actions";
import { apiClient } from "./api";

export const getData = (uri) => async (dispatch) => {
  try {
    const res = await apiClient().get(uri);
    switch (uri) {
      case "/headers":
        dispatch({ type: HEADER_DATA, payload: res.data });
        break;
      case "/footers":
        dispatch({ type: FOOTER_DATA, payload: res.data });
        break;
      case "/accueil":
        dispatch({ type: ACCUEIL_DATA, payload: res.data });
        break;
      case "/espaces":
        dispatch({ type: ESPACE_DATA, payload: res.data });
        break;
      case "/prestations":
        dispatch({ type: PRESTATION_DATA, payload: res.data });
        break;
      case "/espace-cards":
        dispatch({ type: ESPACE_CARDS_DATA, payload: res.data });
        break;
      case "/prestation-cards":
        dispatch({ type: PRESTATION_CARDS_DATA, payload: res.data });
        break;
      case "/contact":
        dispatch({ type: CONATCT_DATA, payload: res.data });
        break;
      case "/activefooters":
        dispatch({ type: ACTIVE_FOOTER_DATA, payload: res.data });
        break;
      case "/blog":
        dispatch({ type: BLOG_DATA, payload: res.data });
        break;
      case "/blogCards":
        dispatch({ type: BLOG_CARDS_DATA, payload: res.data });
        break;
      case "/parametres":
        dispatch({ type: PARAMETRES_DATA, payload: res.data });
        break;
      case "/decores":
        dispatch({ type: DECORES_DATA, payload: res.data });
        break;
      case "/formules":
        dispatch({ type: FORMULES_DATA, payload: res.data });
        break;
      case "/reservations":
        dispatch({ type: RESERVATIONS_DATA, payload: res.data });
        break;
      case "/emails":
        dispatch({ type: EMAILS_DATA, payload: res.data });
        break;
      case "/clients":
        dispatch({ type: CLIENT_DATA, payload: res.data });
        break;
      case "/guests":
        dispatch({ type: GUESTS_DATA, payload: res.data });
        break;
      case "/payments":
        dispatch({ type: PAIMENTS_DATA, payload: res.data });
        break;
      case "/promo-codes":
        dispatch({ type: PROMO_CODES, payload: res.data });
        break;
      case "/contact-mails":
        dispatch({ type: CONTACT_MAILS, payload: res.data });
        break;
      case "/politiques":
        dispatch({ type: POLITIQUE_DATA, payload: res.data });
        break;
      case "/conditions":
        dispatch({ type: CONDITIONS_DATA, payload: res.data });
        break;
      case "/rgpd":
        dispatch({ type: RGPD_DATA, payload: res.data });
        break;
        
      default:
        console.warn("Unhandled URI:", uri);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }

};


export const getUserByToken = (token) => async (dispatch) => {
  try{
    const res = await apiClient().get(`/user-by-token/${token}`);
    if(res?.data?.type !== 'client'){
      dispatch({ type: STORE_USER, payload: res.data })
    };
  }catch(err){
    console.error('error fetching user by token data ', err);
  }
}

export const getClientByToken = (token) => async (dispatch) => {
  try{
    const res = await apiClient().get(`/user-by-token/${token}`);
    if(res?.data && res?.data?.type === "client"){
      dispatch({ type: STORE_CLIENT, payload: res.data });
    }else{
      const res = await apiClient().get(`/guest-by-token/${token}`);
      dispatch({ type: STORE_CLIENT, payload: res.data });
    }
  }catch(err){
    console.error('error fetching guest by token data ', err);
  }
}

export const getClientReservations = (id) => async (dispatch) => {
  try{
    const res = await apiClient().get(`/user-reservations/${id}`);
    dispatch({ type: CLIENT_RESERVATIONS, payload: res.data });
  }catch(err){
    console.error('error fetching user Reservations ', err);
  }
}



// export const pageDateGet = (params) => (dispatch) => {
//   const data = {
//     ...params,
//   };
//   return apiClient()
//     .post(`/membre/` + params.action, data)
//     .then((res) => {
//       if (res.data.success == false) {
//         alert("Une erreur est survenue !");
//       } else {
//         switch (params.action) {
//           case "getcontacts":
//             dispatch({ type: CONTACTS, payload: res.data?.contacts });

//             break;

//           case "getnoteapels":
//             dispatch({ type: NOTES_APL, payload: res.data?.notes });

//             break;
//           case "getnotes":
//             dispatch({ type: NOTES, payload: res.data?.notes });

//             break;
//           case "gettasks":
//             dispatch({ type: TASKS, payload: res.data?.tasks });
//             break;
//           case "getspam":
//             let spamsobj = { mails: res.data?.mails, spams: res.data?.spams };
//             if (params.type == 1) {
//               dispatch({ type: SPAMS, payload: spamsobj });
//             } else if (params.type == 2) {
//               dispatch({ type: NDD, payload: spamsobj });
//             } else {
//               dispatch({ type: TLD, payload: spamsobj });
//             }

//             break;
//           case "getdepartements":
//             dispatch({ type: DEPARTEMENT, payload: res.data?.departements });
//             break;
//           case "getmembres":
//             dispatch({ type: MEMBRES, payload: res.data?.users });
//             break;
//           case "getchatusers":
//             dispatch({ type: MEMBRESCHAT, payload: res.data?.users });
//             break;
//           case "getchatcontact":
//             dispatch({ type: CONTACTCHAT, payload: res.data?.users });
//             break;
//           case "getgroupes":
//             dispatch({ type: GROUPES, payload: res.data?.groupes });
//             break;
//           case "getmembresgroup":
//             dispatch({ type: MEMBRESGROUP, payload: res.data?.membres });
//             break;
//           case "getmessages":
//             dispatch({ type: DISCUSSIONS, payload: res.data?.messages });
//             if (params.senddiscuton === true) {
//               dispatch({ type: ID_DISUCSSION, payload: params.item });
//             }

//             break;

//           default:
//             dispatch({ type: PAGE_DATA, payload: res.data?.object });
//         }
//       }
//       //dispatch({ type: IS_SUBMITED, payload: true })
//     });
// };

// export const pageDatePost = (params) => (dispatch) => {
//   const data = {
//     ...params,
//   };

//   return apiClient()
//     .post(`/membre/` + params.action, data)
//     .then((res) => {
//       if (res.data.success == false) {
//         alert("Une erreur est survenue !");
//       } else {
//         switch (params.action) {
//           case "updatephoto":
//             dispatch({ type: AUTHENTICATE_USER, payload: res.data?.user });

//             break;
//           case "updateprofil":
//             dispatch({ type: AUTHENTICATE_USER, payload: res.data?.user });

//             break;

//           case "addcontact":
//             break;
//           case "updatecontact":
//             break;
//           case "addgroupe":
//             //dispatch({ type: CONTACTS, payload:res.data?.contacts})

//             break;
//           default:
//         }
//       }

//       dispatch({ type: IS_SUBMITED, payload: true });
//     });
// };

// export const uploadPhotoPost = (params, action) => (dispatch) => {
//   return apiClient()
//     .post(`/membre/` + action, params)
//     .then((res) => {
//       if (res.data.success == false) {
//         alert("Une erreur est survenue !");
//       } else {
//         dispatch({ type: AUTHENTICATE_USER, payload: res.data?.user });
//       }

//       dispatch({ type: IS_SUBMITED, payload: true });
//     });
// };

// export const uploadFilePost = (params, action) => (dispatch) => {
//   return apiClient()
//     .post(`/membre/` + action, params)
//     .then((res) => {
//       if (res.data.success == false) {
//         alert("Une erreur est survenue !");
//       }

//       dispatch({ type: IS_SUBMITED, payload: true });
//     });
// };

// export const projectData = (params) => (dispatch) => {
//   dispatch({ type: PROJECT_DATA, payload: params });
// };

// export const taskData = (params) => (dispatch) => {
//   dispatch({ type: TASK_DATA, payload: params });
// };

// export const deleteTask = (id, updatedObjects) => dispatch => {
//     dispatch({type: DELETE_TASK, payload: { id, updatedObjects }})
// }
