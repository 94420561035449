import React, { useEffect, useState } from "react";
import AddButton from "../pagescomponents/AddButton";

const Informations = ({ footerData, handleChange, handleSubmit }) => {
  const [socialLinks, setSocialLinks] = useState([]);

  useEffect(() => {
    if (footerData?.social_links) {
      setSocialLinks(footerData.social_links);
    }
  }, [footerData]);

  const handleSocialLinkChange = (index, value) => {
    const updatedLinks = [...socialLinks];
    updatedLinks[index].url = value;
    setSocialLinks(updatedLinks);
    handleChange({
      target: {
        name: 'social_links',
        value: socialLinks
      }
    });
  };

  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">Informations :</h1>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <div className="flex flex-col">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={footerData?.email || ''}
              onChange={handleChange}
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="phone">Téléphone</label>
            <input
              type="text"
              name="phone"
              id="phone"
              value={footerData?.phone || ''}
              onChange={handleChange}
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="col-span-1 lg:col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
            {['facebook', 'twitter', 'instagram', 'linkedin'].map((platform, index) => (
              <div className="flex flex-col" key={platform}>
                <label htmlFor={platform} className="capitalize">{platform}</label>
                <input
                  type="text"
                  name={platform}
                  id={platform}
                  value={socialLinks[index]?.url || ''}
                  onChange={(e) => handleSocialLinkChange(index, e.target.value)}
                  className="w-full p-2 border rounded-md outline-none focus:outline-none"
                  style={{ border: "1px solid gray" }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="pt-3 flex justify-end">
          <AddButton />
        </div>
      </form>
    </div>
  );
};

export default Informations;
