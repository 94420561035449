import { useEffect, useState } from "react";
import {
  BannerSectionP15,
  CleintProfileTabs,
  // FuturReservations,
  // OldReservations,
  ProfileInfos,
} from "../../components";
import ClientForm from "../../components/client_profile_components/profileForm";
import { useDispatch, useSelector } from "react-redux";
import { getClientReservations } from "../../actions/pages";
import { useLocation } from "react-router-dom";
import { BASEIMAGEURL, STORE_CLIENT } from "../../constants/actions";
import uploadImage from "../../helpers/uploadImages";
import { apiClient } from "../../actions/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormattingDate from "../../helpers/formattingDate";
import { Helmet } from "react-helmet";

const ClientProfile = () => {
  const [tab, setTab] = useState(1);
  const [refreshData, setRefreshData] = useState(false);
  const [expandedReservationId, setExpandedReservationId] = useState(null);

  const client = useSelector((state) => state?.auth?.client);
  const client_reservations = useSelector(
    (state) => state?.pages?.data?.client_reservations
  );
  const dispatch = useDispatch();
  const location = useLocation();

  const [clientForm, setClientForm] = useState(client || {});

  useEffect(() => {
    location?.state?.tab && setTab(location?.state?.tab);
  }, [location]);

  useEffect(() => {
    if (client) {
      dispatch(getClientReservations(client?.id));
      setClientForm(client);
    }
  }, [dispatch, client, refreshData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientForm({ ...clientForm, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let newPath = clientForm.avatar;
      if (clientForm?.avatar && clientForm?.avatar !== client?.avatar) {
        newPath = await uploadImage(
          "avatar",
          { avatar: clientForm?.avatar },
          { avatar: client?.avatar },
          "avatars"
        );
      }
      const data = { ...clientForm, avatar: newPath };
      const response = await apiClient().post(
        `/users/${client?.id}?_method=PUT`,
        data
      );
      dispatch({ type: STORE_CLIENT, payload: response.data });
      toast.success("Profil mis à jour avec succès !", { autoClose: 700 });
    } catch (err) {
      console.error(err);
      toast.error("Erreur lors de la mise à jour du profil !", {
        autoClose: 700,
      });
    }
  };

  const UpdatePassword = async (e) => {
    e.preventDefault();
    if (
      !clientForm?.ancienepassword ||
      !clientForm?.password ||
      !clientForm?.confirePassword
    ) {
      toast.warning("Veuillez remplir tous les champs de mot de passe !");
      return;
    }
    if (clientForm?.password !== clientForm?.confirePassword) {
      toast.warning("Les mots de passe ne sont pas compatibles !");
      return;
    }
    try {
      const response = await apiClient().post(
        `/users/${clientForm?.id}/update-password`,
        {
          oldPassword: clientForm?.ancienepassword,
          newPassword: clientForm?.password,
        }
      );
      if (response.data.success) {
        toast.success("Mot de passe mis à jour avec succès !", {
          autoClose: 700,
        });
      } else {
        toast.error(
          response.data.error ||
            "Erreur lors de la mise à jour du mot de passe !",
          { autoClose: 1000 }
        );
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.error ||
        "Erreur lors de la mise à jour du mot de passe !";
      console.error(err);
      toast.error(errorMessage, { autoClose: 1000 });
    }
  };

  const calculateTotalPrice = (reservation) => {
    const spacePrice = parseFloat(reservation.espace_card.price) || 0;
    const decorPrice = parseFloat(reservation.decor.price) || 0;
    const formulesPrice = reservation.formules.reduce(
      (total, formule) => total + parseFloat(formule.price),
      0
    );
    return spacePrice + decorPrice + formulesPrice;
  };

  const formatTimeWithoutSeconds = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}`;
  };

  const getReservationStatus = (startTime, endTime, date) => {
    const now = new Date();
    const startDateTime = new Date(`${date}T${startTime}`);
    const endDateTime = new Date(`${date}T${endTime}`);

    if (now < startDateTime)
      return { status: "En cours de Traitement", style: "text-blue-600 bg-blue-100" };
    if (now > endDateTime)
      return { status: "Terminée", style: "text-gray-900 bg-gray-200" };
    return { status: "En Cour", style: "text-green-600 bg-green-100" };
  };

  const handleReservationClick = (reservationId) => {
    setExpandedReservationId(
      expandedReservationId === reservationId ? null : reservationId
    );
  };

  const handleCancelReservation = async (id) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir annuler cette réservation ? Cette action est irréversible."
    );

    if (confirmed) {
      try {
        await apiClient().post(`/reservations/${id}?_method=PUT`, {
          status: "cancelled",
        });
        // toast.success("Réservation annulée avec succès.");
        setRefreshData(!refreshData);
      } catch (err) {
        // toast.error(
        //   "Une erreur est survenue lors de l'annulation de la réservation."
        // );
        console.error(err);
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>FIVE SPA PRIVATIVE</title>
        <meta
          name="description"
          content="Découvrez FIVE SPA PRIVATIVE, votre refuge de bien-être exclusif. Profitez de soins spa personnalisés dans un cadre luxueux, conçu pour votre détente ultime et votre relaxation. Réservez dès aujourd'hui et offrez-vous une expérience spa inoubliable."
        />
      </Helmet>

      <BannerSectionP15 />
      <div className="flex flex-col gap-3 md:flex-row md:gap-7 p-2 mt-5 max-w-6xl mx-auto">
        <div className="">
          <CleintProfileTabs tab={tab} setTab={setTab} />
        </div>
        <div className="w-full px-2">
          {tab === 1 && (
            <div className="md:px-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                <div className="p-5 space-y-5 rounded-xl bg-blue-50 text-blue-400 relative">
                  <h1 className="text-4xl font-poppins font-extrabold">
                    {client_reservations?.length || 0}
                  </h1>
                  <h4 className="text-xl font-semibold">
                    Toutes les réservations
                  </h4>
                  <div className="w-5 h-5 min-w-5 min-h-5 rounded-full bg-blue-300 absolute -top-2 right-2"></div>
                </div>

                <div className="p-5 space-y-5 rounded-xl bg-green-50 text-green-400 relative">
                  <h1 className="text-4xl font-poppins font-extrabold">
                    {client_reservations?.filter(
                      (res) =>
                        res?.status == "notYet" &&
                        getReservationStatus(
                          res?.startTime,
                          res?.endTime,
                          res?.date
                        ).status === "En cours de Traitement"
                    )?.length || 0}
                  </h1>
                  <h4 className="text-xl font-semibold">
                    Réservations en attente
                  </h4>
                  <div className="w-5 h-5 min-w-5 min-h-5 rounded-full bg-green-300 absolute -top-2 right-2"></div>
                </div>

                <div className="p-5 space-y-5 rounded-xl bg-gray-50 text-gray-400 relative">
                  <h1 className="text-4xl font-poppins font-extrabold">
                    {client_reservations?.filter(
                      (res) =>
                        res?.status == "notYet" &&
                        getReservationStatus(
                          res?.startTime,
                          res?.endTime,
                          res?.date
                        ).status === "Terminée"
                    )?.length || 0}
                  </h1>
                  <h4 className="text-xl font-semibold">
                    Réservations complètes
                  </h4>
                  <div className="w-5 h-5 min-w-5 min-h-5 rounded-full bg-gray-300 absolute -top-2 right-2"></div>
                </div>

                <div className="p-5 space-y-5 rounded-xl bg-red-50 text-red-400 relative">
                  <h1 className="text-4xl font-poppins font-extrabold">
                    {client_reservations?.filter(
                      (res) => res?.status === "cancelled"
                    )?.length || 0}
                  </h1>
                  <h4 className="text-xl font-semibold">
                    Réservations annulées
                  </h4>
                  <div className="w-5 h-5 min-w-5 min-h-5 rounded-full bg-red-300 absolute -top-2 right-2"></div>
                </div>
              </div>
            </div>
          )}
          {tab === 2 && (
            <div className="relative px-3">
              <div className="h-32 bg-gray-600 absolute top-0 left-0 right-0 -z-10" />
              <div className="flex flex-col lg:flex-row gap-3 mx-auto pt-16 text-xs font-poppins">
                <div className="p-2 basis-3/12 bg-gray-50 border border-gray-800 h-fit rounded">
                  <ProfileInfos
                    client={clientForm}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                  />
                </div>
                <div className="basis-9/12 bg-gray-50 border border-gray-800 rounded">
                  <ClientForm
                    client={clientForm}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    UpdatePassword={UpdatePassword}
                  />
                </div>
              </div>
              <ToastContainer />
            </div>
          )}
          {tab === 3 && (
            <div className="space-y-4">
              {client_reservations?.map((res) => {
                const reservationStatus = getReservationStatus(
                  res?.startTime,
                  res?.endTime,
                  res?.date
                );
                const isExpanded = expandedReservationId === res?.id;
                return (
                  <div
                    key={res?.id}
                    className="bg-white shadow-md rounded-lg overflow-hidden font-outfit"
                  >
                    <div
                      className="text-sm md:text-base flex items-center justify-between gap-2 p-4 cursor-pointer hover:bg-gray-50 transition-colors duration-300 ease-in-out"
                      onClick={() => handleReservationClick(res?.id)}
                    >
                      <div className="font-bold text-base md:text-lg text-gray-800 capitalize">
                        {res?.espace_card?.titre}
                      </div>
                      <div className="text-gray-600">
                        {formatTimeWithoutSeconds(res?.startTime)} -{" "}
                        {formatTimeWithoutSeconds(res?.endTime)}
                      </div>
                      <div className="text-gray-600">
                        {FormattingDate(res?.date)}
                      </div>
                      <div className="font-bold text-green-600">
                        {calculateTotalPrice(res)}
                        <i className="bi bi-currency-euro"></i>
                      </div>
                      <div
                        className={`py-1 px-3 rounded-full text-sm ${
                          res?.status === "cancelled"
                            ? "text-red-500 bg-red-200"
                            : reservationStatus.style
                        }`}
                      >
                        {res?.status === "cancelled"
                          ? "Annullé"
                          : reservationStatus.status}
                      </div>
                      <div className="text-2xl text-gray-400">
                        <i
                          className={`bi bi-chevron-${
                            isExpanded ? "up" : "down"
                          }`}
                        ></i>
                      </div>
                    </div>
                    <div
                      className={`p-4 bg-gray-50 border-t border-gray-200 ${
                        isExpanded ? "block" : "hidden"
                      } transition-all duration-300 ease-in-out`}
                    >
                      <div className="flex justify-between items-center">
                        <h4 className="font-semibold text-lg text-gray-700 mb-2">
                          Détails de l'Espace
                        </h4>
                        {reservationStatus.status === "En cours de Traitement" &&
                          res?.status == "notYet" && (
                            <span
                              onClick={() => handleCancelReservation(res?.id)}
                              className="text-red-500 underline cursor-pointer"
                            >
                              Annuler la réservation
                            </span>
                          )}
                      </div>
                      <div className="flex flex-col sm:flex-row items-start gap-3">
                        <div className="w-fit float-left">
                          <img
                            src={BASEIMAGEURL + res?.espace_card?.image}
                            alt="espace img"
                            className="w-40 h-40 max-w-40 max-h-40 min-w-32 min-h-32 object-cover rounded"
                          />
                        </div>
                        <div className="space-y-2">
                          <div className="font-bold text-lg text-gray-800 capitalize">
                            {res?.espace_card?.titre}
                          </div>
                          <p
                            className="text-gray-600"
                            dangerouslySetInnerHTML={{
                              __html: res?.espace_card.description,
                            }}
                          />
                          <p className="text-gray-600">
                            <span className="font-medium">Occupation:</span>{" "}
                            {res?.espace_card.occupation} personnes
                          </p>
                          <p className="text-gray-600">
                            <span className="font-medium">Prix:</span>{" "}
                            {res?.espace_card.price}€
                          </p>
                        </div>
                      </div>

                      <div className="mt-6">
                        <h4 className="font-semibold text-lg text-gray-700 mb-2">
                          Décor
                        </h4>
                        <div className="bg-white p-4 rounded shadow-sm flex items-center space-x-4 w-fit max-w-3xl">
                          <img
                            src={BASEIMAGEURL + res?.decor.photo}
                            alt={res?.decor.titre}
                            className="w-16 h-16 object-cover rounded"
                          />
                          <div>
                            <p className="font-medium text-gray-800 capitalize">
                              {res?.decor.titre}
                            </p>
                            <p
                              className="text-gray-600 text-sm"
                              dangerouslySetInnerHTML={{
                                __html: res?.decor.description,
                              }}
                            />
                            <p className="text-green-600 font-medium mt-1">
                              {res?.decor.price}€
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="mt-6">
                        <h4 className="font-semibold text-lg text-gray-700 mb-2">
                          Formules
                        </h4>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {res?.formules.map((formule) => (
                            <div
                              key={formule.id}
                              className="bg-white p-4 rounded shadow-sm flex items-center space-x-4"
                            >
                              <img
                                src={BASEIMAGEURL + formule.photo}
                                alt={formule.titre}
                                className="w-16 h-16 object-cover rounded"
                              />
                              <div>
                                <p className="font-medium text-gray-800 capitalize">
                                  {formule.titre}
                                </p>
                                <p className="text-sm text-gray-600">
                                  {formule.description}
                                </p>
                                <p className="text-green-600 font-medium mt-1">
                                  {formule.price}€
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="h-20"></div>
      <ToastContainer />
    </div>
  );
};

export default ClientProfile;
