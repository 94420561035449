import * as React from "react";
import './style.scss';
import { BASEIMAGEURL } from "../../constants/actions";
import { useNavigate } from "react-router-dom";

const ContentTwoP2 = ({ data }) => {
  const navigate = useNavigate()
  return (
    <section className="">
      <div className="grid grid-cols-1 lg:grid-cols-2 w-10/12 mx-auto">
        <div className="hidden lg:block">
          <img src={BASEIMAGEURL + data?.imageConcept} alt="sectionImg" className="w-9/12 mx-auto h-[400px] rounded-3xl" />
        </div>
        <div className="items-center">
          <div className="space-y-6">
            <div className="flex justify-center lg:justify-start">
              <h1 className="text-4xl lg:text-5xl text-gray-900 mt-10 lg:mb-10 capitalize" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{data?.titreConcept}</h1>
            </div>
            <p className="text-justify" dangerouslySetInnerHTML={{ __html: data?.descriptionConcept }}/>
            <div className="flex lg:hidden my-4 h-72 conceptCoverp1"></div>
            <div className="flex justify-center lg:justify-start">
              <button className="button primary w-60 lg:w-fit capitalize" onClick={()=>navigate(data?.lienButton)}>{data?.button}</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentTwoP2;