import { STOTE_RESERVATIONS_BY_DATE, STOTE_RESERVATIONS_BY_SEMAINE } from "../constants/actions";
import { apiClient } from "./api";

export const getReservationByDate = (date) => async (dispatch) => {
  try {
    const res = await apiClient().get(`/reservations-date/${date}`);
    dispatch({
      type: STOTE_RESERVATIONS_BY_DATE,
      payload: res?.data
    })
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getReservationBySemaine = (firstDayOfWeek, spaceId) => async (dispatch) => {
  try {
    const res = await apiClient().get(`/reservations-semaine/${firstDayOfWeek}/${spaceId}`);
    dispatch({
      type: STOTE_RESERVATIONS_BY_SEMAINE,
      payload: res?.data
    })
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
