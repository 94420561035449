import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiClient } from '../../../actions/api';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Img9 } from "../../../assets/images";
import { Helmet } from 'react-helmet';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state?.email) {
      navigate('/forgot');
    }
  }, [location, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.warning("Les mots de passe ne sont pas compatibles !");
      return;
    }

    try {
      const response = await apiClient().post('/reset-password', {
        email: location?.state?.email,
        password: password
      });

      if (response.data.success) {
        toast.success("Mot de passe réinitialisé avec succès !");
        navigate('/login', { state: { success: "Mot de passe réinitialisé avec succès !" } });
      } else {
        toast.error(response.data.error || "Erreur lors de la réinitialisation du mot de passe !");
      }
    } catch (err) {
      console.error(err);
      toast.error("Erreur lors de la réinitialisation du mot de passe !");
    }
  };

  return (
    <div>
      <div
        className="min-h-screen flex justify-center items-center relative"
        style={{
          backgroundImage: `url(${Img9})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
      <Helmet>
        <title>Réinitialiser le mot de passe</title>
        <meta name="description" content="Entrez votre adresse e-mail pour recevoir les instructions de réinitialisation de votre mot de passe." />
      </Helmet>
        <div className="absolute inset-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }} />
        <div className="w-11/12 sm:w-10/12 md:max-w-xl mx-auto border rounded-lg p-5 md:p-10 bg-white space-y-4 z-50">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="text-xl md:text-2xl font-semibold mb-5">
              Réinitialiser votre mot de passe
            </div>
            <div className="flex flex-col gap-y-2">
              <label
                htmlFor="password"
                className="block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:border-gray-900 focus-within:ring-1 focus-within:ring-gray-900"
              >
                <span className="text-xs font-medium text-gray-700">Nouveau mot de passe</span>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Nouveau mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                  required
                  autoComplete="off"
                  minLength={6}
                />
              </label>
            </div>
            <div className="flex flex-col gap-y-2">
              <label
                htmlFor="confirmPassword"
                className={`block overflow-hidden rounded-md border border-gray-200 px-3 py-2 shadow-sm focus-within:ring-1 ${
                  password && confirmPassword && !password.startsWith(confirmPassword)
                    ? "focus-within:border-red-500 focus-within:ring-red-500 text-red-500"
                    : "focus-within:border-gray-900 focus-within:ring-gray-900 text-gray-700"
                }`}              >
                <span className="text-xs font-medium">Confirmer le mot de passe</span>
                <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirmer le mot de passe"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="mt-1 w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                  required
                  autoComplete="off"
                  minLength={6}
                />
              </label>
            </div>
            <div className="flex justify-end">
              <button type="submit" className="bg-gray-800 hover:bg-gray-700 py-1 px-3 text-white font-medium">
                Réinitialiser le mot de passe
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
