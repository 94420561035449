

const AddButton = () => {
  return (
    <button
      type="submit"
      className="bg-lightyellow text-lightOrange border-lightOrange border rounded-md px-4 py-1 hover:border-lightOrange hover:text-orange-400 hover:bg-white"
      // style={{ border: "1px solid gray" }}
    >
      Valider
    </button>
  );
};

export default AddButton;