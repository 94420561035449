import { useSelector } from 'react-redux';
import { Acces, BannerSectionP4, ContactForm, Footer, Localisation } from '../../components';
import { Helmet } from 'react-helmet';
import { BASEIMAGEURL } from '../../constants/actions';

const Contact = () => {
  const data = useSelector(state => state?.pages?.data?.contact);

  return (
    <>
      <Helmet>
        <title>{data?.titreSeo}</title>
        <meta name="description" content={data?.descriptionSeo} />
        <meta name="keywords" content={data?.motsClesSeo} />
        <meta property="og:image" content={BASEIMAGEURL + data?.imageSeo} />
      </Helmet>
        <BannerSectionP4 data={data}/>
        <ContactForm data={data}/>
        <Localisation data={data}/>
        <Acces data={data}/>
        <Footer/>
    </>
  )
}

export default Contact