import { useEffect, useState } from "react";
import './style.scss'
import { Link, useNavigate } from "react-router-dom";
import { apiClient } from "../../actions/api";
import { BASEIMAGEURL } from "../../constants/actions";

const ImageCard = ({ src, alt, title }) => (
  <div className="relative overflow-hidden group">
    <img 
      className="w-full h-[284px] object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
      src={src} 
      alt={alt} 
    />
    <h3 className="absolute bottom-0 left-0 w-full text-white text-center bg-black bg-opacity-50 py-3">
      {title}
    </h3>
  </div>
);

function PrestationsSection({ data }) {

  const [prestations, setPrestations] = useState([]);
  const [size, setSize] = useState(3);
  useEffect(()=>{
    const fetchData = async() =>{
      const response = await apiClient().get('/prestation-cards');
      setPrestations(response.data);
      setSize(response.data.length);
    }
    fetchData();
  },[]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % prestations?.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + prestations?.length) % prestations?.length);
  };

  const navigate = useNavigate();

  return (
    <section className="space-y-14 text-center">
      <h2 className="text-4xl lg:text-5xl text-gray-900 capitalize" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>{data?.titrePrestations}</h2>
      <div className="relative w-8/12 sm:w-8/12 md:w-10/12 lg:w-10/12 mx-auto">
        <button className="absolute top-1/2 -left-12 md:-left-12 transform -translate-y-1/2 z-10" onClick={prevSlide}>
          <i className="bi bi-arrow-left-circle text-3xl text-darckyellow"></i>
        </button>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {[0, 1, 2].map((offset) => {
            const index = (currentIndex + offset) % size;
            return (
              <div onClick={()=>navigate(`/prestations/${index+1}`)} key={index} className={`rounded-md overflow-hidden cursor-pointer ${offset === 2 ? 'hidden sm:hidden lg:block' : offset === 1 ? 'hidden sm:block' : ''}`}>
                <ImageCard
                  src={BASEIMAGEURL + prestations[index]?.image}
                  alt={prestations[index]?.titre}
                  title={prestations[index]?.titre}
                />
              </div>
            );
          })}
        </div>
        <button className="absolute top-1/2 -right-12 md:-right-12 transform -translate-y-1/2 z-10" onClick={nextSlide}>
          <i className="bi bi-arrow-right-circle text-3xl text-darckyellow"></i>
        </button>
      </div>
      <div className="flex justify-center">
      <Link to={data?.lienButtonPrestations} className="button primary flex items-center justify-center mx-auto gap-x-2 hover:text-white min-w-fit lg:w-fit w-52">
        <span className="uppercase">{data?.buttonPrestations}</span><i className="bi bi-arrow-right text-lg"></i>
      </Link>
      </div>
    </section>
  );
}

export default function PrestationsP1({ data }) {
  return (
    <>
      <PrestationsSection data={data} />
    </>
  );
}