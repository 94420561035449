import Header from "../header";
import "./style.scss";

function BannerSectionP6({ espace }) {

  return (
    <div className="relative h-[500px]">
      <section className="hr-sec h-full">
        <Header />
        <div className=" absolute inset-0 flex items-center justify-center mt-32">
          <div className="px-4 flex flex-col justify-center items-center max-w-2xl text-center gap-y-7">
            <h1 className="text-4xl lg:text-5xl text-white capitalize" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }} >{espace?.titre}</h1>
            <p className="text-gray-300 text-sm lg:text-base" dangerouslySetInnerHTML={{ __html: espace?.description }}/>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BannerSectionP6;
