import { useEffect, useState } from "react";
import { AddButton, BasicSEO, BoLayout, TextEditor } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../../actions/pages";
import { apiClient } from "../../../../actions/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BoPolitique = () => {
  const reduxData = useSelector((state) => state?.pages?.data?.politique);
  const [politiqueData, setPolitiqueData] = useState(reduxData);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData("/politiques"));
  }, [refresh, reduxData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPolitiqueData({ ...politiqueData, [name]: value });
  };

  const valider = async (e) => {
    e.preventDefault();

    try {
      await apiClient().put(`/politiques/1`, politiqueData);
      toast.success(
        "Page de Politique de Confidentialité est modifiée avec succès",
        { autoClose: 600 }
      );
      setRefresh(!refresh);
    } catch (err) {
      console.error(
        "Erreur lors de la mise à jour des données de Page de Politique de Confidentialité :",
        err
      );
      toast.error(
        "Erreur lors de la mise à jour des données de Page de Politique de Confidentialité",
        { autoClose: 600 }
      );
    }
  };

  return (
    <BoLayout>
      <BasicSEO />
      <h1
        className="text-xl md:text-2xl font-poppins py-2 px-4 text-lightOrange"
        style={{ fontWeight: 600 }}
      >
        Page « Politique de Confidentialité »
      </h1>
      <div className="bg-gray-100 min-h-screen py-3 px-4 space-y-5">
        <form onSubmit={valider} className=" bg-white p-3 rounded-lg space-y-5">
          <div className="flex flex-col space-y-1 col-span-1 lg:col-span-2">
            <label htmlFor="titre">Titre</label>
            <input
              type="text"
              name="titre"
              value={politiqueData?.titre}
              onChange={handleChange}
              id="titre"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1 col-span-1 lg:col-span-2">
            <label htmlFor="contenue">Contenu</label>
            <div className="min-h-40 h-40 overflow-hidden border-b">
                <TextEditor
                    texteditor={politiqueData?.contenue}
                    setTexteditor={(value) =>
                        handleChange({ target: { name: "contenue", value } })
                    }
                />
            </div>
          </div>
          <div className="flex justify-end">
            <AddButton/>
          </div>
        </form>
      </div>
      <ToastContainer/>
    </BoLayout>
  );
};

export default BoPolitique;
