import './style.scss'

const EspaceDetailList = ({ espace }) => {
  return (
    <div className='w-11/12 lg:w-9/12 mx-auto'>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-y-4">
            <div className="flex items-center justify-center gap-x-1 text-black font-extrabold">
                <span><i className="bi bi-geo-alt-fill text-xl"></i></span>
                <span>Massage Relaxant</span>
            </div>
            <div className="flex items-center justify-center gap-x-1 text-black font-extrabold">
                <span><i className="bi bi-building text-xl"></i></span>
                <span>Sauna et Hammam</span>
            </div>
            <div className="flex items-center justify-center gap-x-1 text-black font-extrabold">
                <span><i className="bi bi-person-fill text-xl"></i></span>
                <span>Bain d Remous</span>
            </div>
            <div className="flex items-center justify-center gap-x-1 text-black font-extrabold">
                <span><i className="bi bi-backpack4-fill text-xl"></i></span>
                <span>piscine privée</span>
            </div>
        </div>
    </div>
  )
}

export default EspaceDetailList