import React from 'react';
import './style.scss';

const Section1Paiments = ({ paiments = [] }) => {

  const totalGains = paiments
    .filter(payment => payment.mode === 'total')
    .reduce((sum, payment) => {
      const price = parseFloat(payment.price);
      if (!isNaN(price)) {
        return sum + price;
      }
      return sum;
    }, 0)
    .toFixed(2);

  const pendingPayments = paiments
    .filter(payment => payment.mode === '50off')
    .reduce((sum, payment) => {
      const price = parseFloat(payment.price);
      if (!isNaN(price)) {
        return sum + price / 2;
      }
      return sum;
    }, 0)
    .toFixed(2);

  const data = [
    { title: 'Total des gains', price: totalGains, bg: 'bg-extralightblue', colorprice: 'text-totalgreen' },
    { title: 'Paiements en attente', price: pendingPayments, bg: 'bg-extralightgray2', colorprice: 'gradient-text' },
  ];

  return (
    <div className="">
      <div className="grid grid-cols-2 gap-x-2 sm:gap-x-3 md:gap-x-4">
        {data.map((d, i) => (
          <div key={i} className={`rounded-md ${d.bg} p-3 sm:p-4 flex flex-col justify-between`}>
            <h3 className="text-[15px] sm:text-base font-outfit text-black" style={{ fontWeight: 400 }}>{d.title}</h3>
            <p className={`${d.colorprice} text-[22px] font-medium`}>
              <span>{d.price}</span>
              <i className="bi bi-currency-euro"></i>
            </p>
            <p className="text-[11px] text-darckgraybo" style={{ fontWeight: 400 }}>au {new Date().toLocaleDateString('fr-FR', { day: '2-digit', month: 'long', year: 'numeric' })}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section1Paiments;
