

const index = ({ tab, setTab }) => {
    const tabsArray = [
        { id: 1, titre: "Tableau de bord" },
        { id: 2, titre: "Informations Personnelles" },
        { id: 3, titre: "Réservations" },
    ];
    
  return (
    <div className='flex flex-col items-center gap-2'>
        {tabsArray?.map(t=>(
            <div key={t?.id} onClick={()=>setTab(t?.id)} className={`flex flex-col justify-center items-center shadow-xl h-10 md:h-16 cursor-pointer font-semibold font-playfairdisplay border border-black transition-colors duration-400 ease-linear min-w-52 w-full md:w-fit ${tab === t?.id ? "bg-gray-800 text-white" : "bg-white text-black"} hover:bg-gray-800 hover:text-white`}>
                {t?.titre}
            </div>
        ))}
    </div>
  )
}

export default index