import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiClient } from '../../../../actions/api';
import uploadImage from '../../../../helpers/uploadImages';
import { BasicSEO, BoLayout, Section1BoPrestations, Section2BoPrestations, Section3BoPrestations, Section4BoPrestations, SeoBoPrestations } from '../../../../components';

const BoPrestations = ({ page_id = 4 }) => {
  const [prestationsData, setPrestationsData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient().get(`/prestations`);
        setPrestationsData(response.data);
        setInitialData(response.data);
      } catch (err) {
        console.error(err);
        toast.error('Erreur lors du chargement des données', { autoClose: 600 });
      }
    };
    fetchData();
  }, [page_id, refresh]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrestationsData({ ...prestationsData, [name]: value });
  };

  const valider = async (e) => {
    e.preventDefault();

    try {
      const fieldsToCheck = ['imageAvantages', 'imageConcept', 'imageSeos'];
      const updatedData = { ...prestationsData };

      for (const field of fieldsToCheck) {
        if (prestationsData[field] !== initialData[field]) {
          const newPath = await uploadImage(field, prestationsData, initialData, 'prestations-images');
          updatedData[field] = newPath;
        }
      }

      await apiClient().put(`/prestations/1`, updatedData);
      toast.success('Page de prestations modifiée avec succès', { autoClose: 600 });
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
      toast.error('Erreur lors de la modification de la page de prestations', { autoClose: 600 });
    }
  };

  return (
    <BoLayout>
      <BasicSEO/>
      <ToastContainer />
      <h1 className='text-xl md:text-2xl font-poppins py-2 px-4 text-lightOrange' style={{ fontWeight: 600 }}>
        Page « Prestations »
      </h1>
      <div className="bg-gray-100">
        <div className="py-3 px-4 space-y-5">
          <Section1BoPrestations prestationsData={prestationsData} handleChange={handleChange} valider={valider} />
          <Section2BoPrestations page_id={page_id} />
          <Section3BoPrestations prestationsData={prestationsData} handleChange={handleChange} valider={valider} />
          <Section4BoPrestations prestationsData={prestationsData} handleChange={handleChange} valider={valider} />
          <SeoBoPrestations prestationsData={prestationsData} handleChange={handleChange} valider={valider} />
        </div>
      </div>
    </BoLayout>
  );
};

export default BoPrestations;
