import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useDispatch, useSelector } from "react-redux";
import { SET_DATE } from "../../../../constants/actions";

dayjs.locale('fr');

const BasicDateCalendarbackoffice = () => {
  const dispatch = useDispatch();
  const reduxDate = useSelector(state => state?.detailsreservation?.date);

  const handleDateSelect = (date) => {
    // Update the state with the selected date, no restriction
    dispatch({ type: SET_DATE, payload: date.format('YYYY-MM-DD') });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr">
      <DateCalendar
        value={dayjs(reduxDate)}
        onChange={(newDate) => {
          handleDateSelect(newDate);
        }}
      />
    </LocalizationProvider>
  );
};

export default BasicDateCalendarbackoffice;
