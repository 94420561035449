import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASEIMAGEURL } from '../../../constants/actions';
import { logoutUser } from '../../../actions/auth';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../../context/authContext';
import { DefaultAvatar3 } from '../../../assets/images';

const ProfileIcon = ({ user }) => { 
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleDropdown = () => {
    setIsProfileVisible(!isProfileVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsProfileVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/');
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button 
        onClick={toggleDropdown}
        className="rounded-full p-2 hover:bg-gray-200 transition-colors duration-200"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
        </svg>
      </button>
      {isProfileVisible && (
        <div className="absolute top-12 right-0 bg-white shadow-lg rounded-lg w-64 py-4 px-6 z-10">
          <div className="flex items-center mb-4">
            <div className="w-12 h-12 rounded-full mr-3 overflow-hidden">
              <img src={user?.avatar ? BASEIMAGEURL + user?.avatar : DefaultAvatar3} alt="avatar" className='w-full h-full object-cover' />
            </div>
            <div>
              <h3 className="font-semibold text-gray-800">{user?.first_name} {user?.last_name}</h3>
              <p className="text-sm text-gray-600">{user?.email}</p>
            </div>
          </div>
          <hr className="my-2 border-gray-200" />
          <button className="flex items-end gap-2 text-gray-900 hover:text-gray-700" onClick={()=>navigate('/gestion/profil')}>
            <i className="bi bi-person-fill text-xl"></i> Mon profil
          </button>
          <hr className="my-2 border-gray-200"/>
          <button 
            onClick={handleLogout} 
            className="w-full mt-2 bg-lightOrange text-white py-2 px-4 rounded transition-colors duration-200"
          >
            Se déconnecter
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileIcon;
