import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_PROMOTION_CODE } from "../../../constants/actions";
import { toast } from "react-toastify";
import { apiClient } from "../../../actions/api";
import { LoadingGif } from "../../../assets/images";

const DetailRes = () => {
  const resData = useSelector((state) => state.detailsreservation);
  const additional_price = useSelector(
    (state) => state?.pages?.data?.parametres?.additional_price
  );
  const [oldPrice, setOldPrice] = useState("0.00");
  const [promotedPrice, setPromotedPrice] = useState(null);
  const [code, setPromotionCode] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const spaces = useSelector((state) => state.pages.data.espaceCards);
  const [reservedSpace, setReservedSpace] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (resData?.reservation?.roomId) {
      const reservedSpace = spaces?.find(
        (space) => space.id === parseInt(resData?.reservation?.roomId)
      );
      setReservedSpace(reservedSpace);
    }
  }, [resData?.reservation, spaces]);

  const handlePromotionCode = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient().post("/isCodePromoExpired", { code });
      if (data?.message) {
        toast.warning(data.message);
        dispatch({ type: SET_PROMOTION_CODE, payload: null });
      } else {
        toast.success("Code promo est activé");
        dispatch({ type: SET_PROMOTION_CODE, payload: data });
      }
    } catch (err) {
      console.error(err);
      toast.error(
        "Une erreur est survenue lors de l'activation du code promo."
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    const calculateTotal = () => {
      let total = 0;

      // if (reservedSpace?.price) {
      //   total += parseFloat(reservedSpace.price);
      // }

      if (Array.isArray(resData?.formules)) {
        total += resData.formules.reduce(
          (sum, formule) => sum + (parseFloat(formule.price) || 0),
          0
        );
      }

      if (resData?.decor && resData.decor.price) {
        total += parseFloat(resData.decor.price);
      }

      if (resData?.nbrpersonne > 2) {
        total += parseFloat(additional_price) * (resData?.nbrpersonne - 2);
      }

      // if(!!resData?.promotionCode){
      //   total = total * ( parseFloat(resData?.promotionCode) / 100 )
      // }

      return {
        oldTotal: total,
        promotedTotal: !!resData?.promotionCode
          ? total * (parseFloat(resData?.promotionCode) / 100)
          : null,
      };
    };

    setOldPrice(calculateTotal().oldTotal);
    setPromotedPrice(calculateTotal().promotedTotal);
  }, [reservedSpace, resData, dispatch]);

  return (
    <div className="max-w-7xl mx-auto px-4 my-10">
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="make-shadow lg:basis-2/3 border rounded-lg flex flex-col overflow-hidden flex-grow">
          <div className="flex items-center justify-between px-4 py-6 text-xl">
            <b>Détail</b>
            <b>Prix</b>
          </div>
          <div className={`flex items-center justify-between px-4 font-medium pb-6 ${resData?.nbrpersonne < 2 && "m-auto"}`}>
            <div className="capitalize">
              Espace : {reservedSpace?.titre}{" "}
              <span className="text-sm font-outfit uppercase font-bold text-yellow-900">
                ( {resData?.nbrpersonne === 1 ? resData?.nbrpersonne + " personne" : resData?.nbrpersonne + " personnes"} )
              </span>
            </div>
            {resData?.nbrpersonne > 2 && <div>
              {parseFloat(additional_price) * (resData?.nbrpersonne - 2)}
              <i className="bi bi-currency-euro"></i>
            </div>}
          </div>
          {[...resData?.formules, resData?.decor]?.map((service, i) =>
            service ? (
              <div
                key={i}
                className="flex items-center justify-between px-4 pb-6"
              >
                <div className="font-medium capitalize">{service.titre}</div>
                <div className="font-medium">
                  {service.price}
                  <i className="bi bi-currency-euro"></i>
                </div>
              </div>
            ) : null
          )}
          {!!resData?.promotionCode && (
            <div className="flex items-center justify-between px-4 font-medium pb-6">
              <div className="capitalize">Code Promo</div>
              <div>{parseFloat(resData?.promotionCode).toFixed(0)} %</div>
            </div>
          )}
          <div
            className="flex items-center justify-center gap-x-6 py-3 border-y cursor-pointer hover:bg-gray-50"
            onClick={() => navigate("/reservation#services")}
          >
            <p>Choisir d'autres services que vous voulez</p>
            <i className="bi bi-plus-circle-fill text-xl"></i>
          </div>
          <div className="flex items-center justify-between bg-gray-800 text-base sm:text-xl text-white p-4">
            <b className="font-bold">Total de la réservation</b>
            <b className="font-bold font-poppins text-golddo">
              <span className={`${promotedPrice ? "line-through mr-3" : ""}`}>
                {oldPrice && oldPrice}
              </span>
              <span>{promotedPrice && promotedPrice}</span>
              <i className="bi bi-currency-euro"></i>
            </b>
          </div>
        </div>
        <div className="make-shadow lg:basis-1/3 border rounded-lg p-4 flex justify-center items-center h-fit min-h-60">
          <div className="w-full mx-auto flex flex-col items-center justify-center gap-y-8">
            <h1 className="text-xl font-bold">CODE PROMOTION ?</h1>
            <div className="w-full text-center">
              <input
                type="text"
                name="code"
                id="code"
                value={code}
                onChange={(e) => setPromotionCode(e.target.value)}
                placeholder="Rentrez votre code ici"
                className="border-b p-2 w-full focus:outline-none text-center max-w-sm"
                required
                readOnly={resData?.promotionCodeActive}
              />
            </div>
            <div className="w-full text-center">
              <button
                disabled={resData?.promotionCodeActive}
                onClick={handlePromotionCode}
                className={`px-4 py-2 border text-white ${
                  resData?.promotionCodeActive ? "opacity-20" : "opacity-100"
                } bg-gray-800 hover:bg-gray-700 w-fit text-center`}
              >
                {loading ? (
                  <img
                    src={LoadingGif}
                    alt="loading gif"
                    width={20}
                    height={20}
                    className="mx-auto"
                  />
                ) : (
                  "Activer"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailRes;
