import React, { useEffect, useState } from 'react';
import TextEditor from '../../blogcomponents/texteditor';
import { apiClient } from '../../../../actions/api';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from '../../../../actions/pages';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddButton from '../../pagescomponents/AddButton';

const ToastContainer = () => <div />;

const Emails = () => {
    const [emailsData, setEmailsData] = useState([]);
    const emails = useSelector(state => state?.pages?.data?.emails);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getData('/emails'));
    }, [dispatch]);

    useEffect(() => {
        setEmailsData(emails);
    }, [emails]);

    const handleTextChange = (id) => (text) => {
        setEmailsData((prevState) =>
            prevState.map((mail) =>
                mail.id === id ? { ...mail, content: text } : mail
            )
        );
    };

    const handleSubjectChange = (id) => (e) => {
        const newSubject = e.target.value;
        setEmailsData((prevState) =>
            prevState.map((mail) =>
                mail.id === id ? { ...mail, subject: newSubject } : mail
            )
        );
    };

    const handleSubmit = async (id, e) => {
        e.preventDefault();
        const mail = emailsData.find((mail) => mail.id === id);
        if (mail) {
            try {
                await apiClient().put(`/emails/${id}`, mail);
                toast.success(`Email ${mail.type === "register" ? "d'inscription" : "de réservation"} mis à jour avec succès`);
            } catch (err) {
                toast.error(`Échec de la mise à jour de l'email ${mail.type === "register" ? "d'inscription" : "de réservation"}`);
                console.error(`Failed to update email ${mail.type === "register" ? "d'inscription" : "de réservation"}:`, err);
            }
        }
    };

    return (
        <div className='m-4 space-y-6'>
            <ToastContainer />
            {emailsData?.map(mail => (
                <form key={mail.id} onSubmit={(e) => handleSubmit(mail.id, e)} className="bg-white border border-gray-300 rounded-lg shadow-md p-6 space-y-4">
                    <h3 className='text-xl font-bold text-gray-800'>
                        {mail?.titre} :
                    </h3>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor={`subject-${mail.id}`} className="font-medium text-gray-700">Sujet :</label>
                        <input
                            type="text"
                            id={`subject-${mail.id}`}
                            name={`subject-${mail.id}`}
                            value={mail.subject}
                            onChange={handleSubjectChange(mail.id)}
                            className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            placeholder='Sujet'
                            required
                        />
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor={`content-${mail.id}`} className="font-medium text-gray-700">Message :</label>
                        <TextEditor
                            texteditor={mail.content}
                            setTexteditor={handleTextChange(mail.id)}
                            className="border border-gray-300 rounded-lg"
                        />
                    </div>
                    <div className="flex justify-end">
                        <AddButton />
                    </div>
                </form>
            ))}
        </div>
    );
};

export default Emails;