import { useSelector } from "react-redux";
import FormattingDate from "../../../helpers/formattingDate";


const HeaderDetails = () => {
    const resDetails = useSelector(state => state?.detailsreservation);
    const spaces = useSelector(state => state?.pages?.data?.espaceCards);

  return (
    <div className="bg-gray-100 py-14 px-3 flex justify-center items-center">
      <h1 className="text-xl md:text-2xl text-center font-bold text-gray-900 font-outfit">{FormattingDate(resDetails?.reservation?.date)} <span className="font-normal">de</span> {resDetails?.reservation?.startTime} <span className="font-normal">à</span> {resDetails?.reservation?.endTime} <span className="font-normal">Pour l'Espace</span> <span className="capitalize">{spaces?.find(space=>space?.id === parseInt(resDetails?.reservation?.roomId))?.titre}</span></h1>
    </div>
  )
}

export default HeaderDetails