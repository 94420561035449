import { useDispatch, useSelector } from "react-redux";
import { BASEIMAGEURL, SET_DECOR } from "../../../constants/actions";
import { useCallback, useEffect, useState } from "react";
import { LoadingGif } from "../../../assets/images";

const Decos = () => {
  const dispatch = useDispatch();
  const decor = useSelector((state) => state?.detailsreservation?.decor);
  const decors = useSelector((state) => state?.pages?.data?.decores);
  const [size, setSize] = useState(decors?.length || 0);

  useEffect(() => {
    setSize(decors?.length || 0);
  }, [decors]);

  const toggleDeco = useCallback(
    (deco) => {
      dispatch({
        type: SET_DECOR,
        payload: decor?.id === deco.id ? null : deco,
      });
    },
    [decor, dispatch]
  );

  const DecoCard = ({ deco }) => {
    return (
      <div className="flex flex-col justify-center items-center gap-5 bg-gray-100 rounded-lg p-4 shadow-xl w-full">
        <h1 className="text-2xl font-bold font-outfit capitalize text-gray-900">
          {deco?.titre}
        </h1>
        <img
          src={BASEIMAGEURL + deco?.photo}
          alt={`deco ${deco?.id}`}
          className="object-cover w-52 h-40 min-w-52 min-h-40 max-w-52 max-h-40"
        />
        <div className="text-center font-outfit max-w-72">
          {deco?.description}
        </div>
        <div className="flex items-center font-outfit font-bold text-xl text-gray-800">
          <span>{deco?.price}</span>
          <i className="bi bi-currency-euro"></i>
        </div>
        <button
          onClick={() => toggleDeco(deco)}
          className={`py-2 px-6 font-semibold transition-all duration-300 ${
            decor !== null && decor?.id === deco.id
              ? "bg-red-200 hover:bg-red-300 shadow-lg"
              : "bg-yellow-200 hover:bg-yellow-300 shadow-md"
          }`}
        >
          {decor !== null && decor?.id === deco.id ? "Annuler" : "Choisir"}
        </button>
      </div>
    );
  };

  return (
    <div className="w-[90%] mx-auto pb-[80px]">
      
      <div className="space-y-4 mb-20">
      <h1 className="py-10 text-xl md:text-2xl text-center font-bold text-gray-900 font-outfit uppercase">
        Choisissez un décor{" "}
        <span className="lowercase text-yellow-900">(optionnel)</span>
      </h1>
        <div className="min-h-48 flex flex-wrap gap-4 justify-center">
          {decors ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 w-[90%] mx-auto pb-[80px]">
              {decors.map((deco) => (
                <DecoCard key={deco.id} deco={deco} />
              ))}
            </div>
          ) : (
            <div className="mx-auto">
              <img src={LoadingGif} alt="loading gif" width={40} height={40} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Decos;
