import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../actions/api";
import './style.scss';
import { BASEIMAGEURL } from "../../constants/actions";
import { useSelector } from "react-redux";

const BlogCard = ({ id, image, author, date, title, description }) => {
  const navigate = useNavigate();
  return (
    <div className="blog-card group overflow-hidden cursor-pointer" onClick={() => navigate(`/blog/${id}`)}>
      <div className="overflow-hidden h-72">
        <img 
          loading="lazy" 
          src={BASEIMAGEURL + image} 
          alt={`Blog post by ${author}`} 
          className="blog-image w-full h-full transition-transform duration-300 ease-in-out group-hover:scale-110 object-cover" 
        />
      </div>
      <p className="text-lightOrange text-[15px] mt-4" style={{ fontFamily: 'Archivo', fontWeight: '600' }}>{author} • {date}</p>
      <div className="flex justify-between items-center text-black font-semibold space-y-6">
        <h2 className="text-[25px]" style={{ fontFamily: 'Playfair Display' }}>{title}</h2>
        <span className="">
          <i className="bi bi-arrow-up-right"></i>
        </span>
      </div>
      <p className="text-gray-500" dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
}

function BlogSection() {
  const blogPosts = useSelector(state => state?.pages?.data?.blogCards);
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [activeDot, setActiveDot] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (blogPosts?.length > 0) {
      const selectRandomPosts = () => {
        const shuffled = blogPosts.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 3);
      };
      setDisplayedPosts(selectRandomPosts());
    }
  }, [blogPosts]);

  const scrollToPage = (index) => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      containerRef.current.scrollTo({
        left: containerWidth * index,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const scrollPosition = containerRef.current.scrollLeft;
      const containerWidth = containerRef.current.offsetWidth;
      const newActiveDot = Math.round(scrollPosition / containerWidth);
      setActiveDot(newActiveDot);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <section className="w-9/12 lg:w-10/12 mx-auto space-y-8">
      <h1 className="text-3xl lg:text-4xl text-gray-900" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display' }}>Les derniers blogs</h1>
      <div className="blog-container" ref={containerRef}>
        <div className="blog-grid">
          {displayedPosts?.map((post, index) => (
            <BlogCard key={index} id={post.id} {...post} />
          ))}
        </div>
      </div>
      <div className="pagination-dots">
        {displayedPosts?.map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full mx-1 focus:outline-none ${index === activeDot ? 'active bg-darckyellow' : 'bg-gray-300'}`}
            onClick={() => scrollToPage(index)}
          />
        ))}
      </div>
    </section>
  );
}

export default BlogSection;
