import React, { useState, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LogoOrangePng } from '../../../assets/images';

const links = [
  { title: 'Dashboard', link: '/gestion', icon: 'columns-gap' },
  { title: 'Pages', icon: 'ui-checks-grid', sublinks: [
    { title: 'Accueil', link: '/gestion/pages/accueil' },
    { title: 'Nos espaces', link: '/gestion/pages/espaces' },
    { title: 'Nos prestations', link: '/gestion/pages/prestations' },
    { title: 'Contactez-nous', link: '/gestion/pages/contact' },
    { title: 'Header', link: '/gestion/pages/header' },
    { title: 'Footer', link: '/gestion/pages/footer' },
    { title: 'Politique', link: '/gestion/pages/politique' },
    { title: 'Conditions', link: '/gestion/pages/conditions' },
    { title: 'RGPD', link: '/gestion/pages/rgpd' },
  ]},
  { title: 'Blog', link: '/gestion/blog', icon: 'boxes' },
  { title: 'Utilisateurs', link: '/gestion/utilisateurs', icon: 'people' },
  { title: 'Réservations', link: '/gestion/reservations', icon: 'bookmark' },
  { title: 'Liste des paiements', link: '/gestion/paiements', icon: 'tag' },
  { title: 'Messages Reçus', link: '/gestion/contact-mails', icon: 'envelope-arrow-down' },
  { title: 'Décors et Formules', link: '/gestion/decos-formules', icon: 'ui-checks' },
  { title: 'Gestion des Gestionnaires', link: '/gestion/admin-list', icon: 'shield-check' },
  { title: 'Paramètres', link: '/gestion/parametres', icon: 'gear' },
];

const LinkItem = ({ item }) => {
  const location = useLocation();
  return (
    <Link 
      to={item.link} 
      className={`flex items-center gap-x-3 py-2 px-3 rounded-lg transition-all duration-200 ${
        location.pathname === item.link 
          ? 'bg-lightyellow text-darckyellow font-semibold' 
          : 'text-darckgraybo hover:bg-lightyellow hover:text-darckyellow'
      }`}
    >
      <i className={`bi bi-${item.icon} text-xl`}></i>
      <span>{item.title}</span>
    </Link>
  );
};

const PagesItem = ({ item }) => {
  const location = useLocation();

  const isCurrentPageInDropdown = useCallback(() => {
    return item.sublinks?.some(sublink => 
      location.pathname === sublink.link ||
      (location.pathname.startsWith('/gestion/pages/espaces') && sublink.link.startsWith('/gestion/pages/espaces')) ||
      (location.pathname.startsWith('/gestion/pages/prestations') && sublink.link.startsWith('/gestion/pages/prestations'))
    );
  }, [item.sublinks, location.pathname]);

  const [isOpen, setIsOpen] = useState(isCurrentPageInDropdown());

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        type="button"
        className="flex items-center justify-between w-full group gap-x-3 py-2 px-3 rounded-lg transition-all duration-200 hover:bg-lightyellow hover:text-darckyellow"
        aria-controls="dropdown-example"
        onClick={toggleDropdown}
        aria-expanded={isOpen}
      >
        <div className="flex items-center gap-x-3">
          <i className={`bi bi-ui-checks-grid text-xl`}></i>
          <span className="whitespace-nowrap">{item.title}</span>
        </div>
        <i className={`bi bi-chevron-down text-lg transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}></i>
      </button>
      <ul id="dropdown-example" className={`flex flex-col ml-9 max-w-full space-y-2 transition-all duration-200 ${isOpen ? 'block' : 'hidden'}`}>
        {item.sublinks?.map((sublink, index) => (
          <Link 
            to={sublink.link} 
            key={index} 
            className={`py-2 px-3 rounded-lg transition-all duration-200 ${
              location.pathname === sublink.link || 
              (location.pathname.startsWith('/gestion/pages/espaces') && sublink.link.startsWith('/gestion/pages/espaces')) ||
              (location.pathname.startsWith('/gestion/pages/prestations') && sublink.link.startsWith('/gestion/pages/prestations'))
                ? 'bg-lightyellow text-darckyellow font-semibold' 
                : 'text-darckgraybo hover:bg-lightyellow hover:text-darckyellow'
            }`}
          >
            {sublink.title}
          </Link>
        ))}
      </ul>
    </div>
  );
};

const BoSidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();

  return (
    <div className={`fixed top-0 left-0 z-40 h-full border-r border-gray-200 bg-white shadow-md overflow-y-auto transition-transform transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 scrollbar-thin scrollbar-thumb-lightOrange scrollbar-track-white`}>
      <div className="w-[225px] mx-auto relative">
        <button 
          className="fixed top-0 right-0 text-xl text-gray-500 rounded-full md:hidden hover:bg-gray-200 p-2 transition-all duration-200" 
          onClick={toggleSidebar}
        >
          <i className="bi bi-x-lg"></i>
        </button>
        <br />
        <div onClick={() => navigate('/gestion')} className="flex justify-center pt-4 mb-5 cursor-pointer">
          <img src={LogoOrangePng} alt="logo" className="h-12 w-auto" />
        </div>
        <div className="p-4 space-y-2">
          {links.map((l, i) => {
            if (l.title === "Pages") return <PagesItem key={i} item={l} />;
            else return <LinkItem key={i} item={l} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default BoSidebar;