
const AuthOptions = ({ select, setSelect }) => {

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 p-5 max-w-6xl mx-auto">
      <div
        className={`flex flex-col justify-center items-center shadow-xl h-16 md:h-48 cursor-pointer font-semibold font-playfairdisplay border border-black transition-colors duration-500 ease-linear ${select === 1 ? "bg-gray-800 text-white" : "bg-white text-black"} hover:bg-gray-800 hover:text-white`}
        onClick={() => setSelect(1)}
      >
        <h1 className="text-lg sm:text-xl md:text-2xl">Payer sans compte ?</h1>
      </div>
      <div
        className={`flex flex-col justify-center items-center shadow-xl h-16 md:h-48 cursor-pointer font-semibold font-playfairdisplay border border-black transition-colors duration-500 ease-linear ${select === 2 ? "bg-gray-800 text-white" : "bg-white text-black"} hover:bg-gray-800 hover:text-white`}
        onClick={() => setSelect(2)}
      >
        <h1 className="text-lg sm:text-xl md:text-2xl">
          Vous avez un compte ?
        </h1>
      </div>
      <div
        className={`flex flex-col justify-center items-center shadow-xl h-16 md:h-48 cursor-pointer font-semibold font-playfairdisplay border border-black transition-colors duration-500 ease-linear ${select === 3 ? "bg-gray-800 text-white" : "bg-white text-black"} hover:bg-gray-800 hover:text-white`}
        onClick={() => setSelect(3)}
      >
        <h1 className="text-lg sm:text-xl md:text-2xl">Pas de compte ?</h1>
      </div>
    </div>
  );
};

export default AuthOptions;
