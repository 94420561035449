import React, { useEffect, useState } from "react";
import { BASEIMAGEURL } from "../../../../../constants/actions";
import SimpleTextEditor from "../../../../simpleTextEditor";

const BoEspacesDetailsSection3 = ({ espaceDetailsData, handleChange, valider }) => {
  const [fileName, setFileName] = useState("Choisir une image");
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (!selectedImage && espaceDetailsData?.bgimageDeatail1) {
      setSelectedImage(BASEIMAGEURL + espaceDetailsData.bgimageDeatail1);
    }
  }, [espaceDetailsData, selectedImage]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      handleChange({
        target:{
          name: "bgimageDeatail1",
          value: file
        }
      });
    }
  };

  return (
    <div className="bg-white p-3 rounded-lg">
      <h1 className="font-semibold font-poppins text-xl mb-3">
        Section Espace Détails 1
      </h1>
      <form onSubmit={valider} className="grid grid-cols-1 gap-3">
        <div className="flex flex-col space-y-1">
          <label htmlFor="titreDetail1">Grand titre</label>
          <input
            type="text"
            name="titreDetail1"
            value={espaceDetailsData?.titreDetail1}
            onChange={handleChange}
            id="titreDetail1"
            className="w-full p-2 border rounded-md outline-none focus:outline-none"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="descriptionDetail1">Description</label>
          <SimpleTextEditor
            value={espaceDetailsData?.descriptionDetail1}
            onChange={(value) => handleChange({ target: { name: 'descriptionDetail1', value } })}
            styleClassName=""
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="flex flex-col space-y-1">
            <label htmlFor="button1">Button</label>
            <input
              type="text"
              name="button1"
              value={espaceDetailsData?.button1}
              onChange={handleChange}
              id="button1"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="lienButton1">Lien</label>
            <input
              type="text"
              name="lienButton1"
              value={espaceDetailsData?.lienButton1}
              onChange={handleChange}
              id="lienButton1"
              className="w-full p-2 border rounded-md outline-none focus:outline-none"
              style={{ border: "1px solid gray" }}
            />
          </div>
        </div>

        <div className="space-y-1">
          <p>Background Image</p>   
          <div className="border rounded-lg py-2 px-1 flex items-center justify-center flex-wrap gap-x-3">
            <div className="bg-gray-200 rounded-md max-w-40 max-h-40 min-w-40 min-h-40">
              <div className="cursor-pointer">
                {selectedImage ? (
                  <div className="relative">
                    <label
                      htmlFor="bgimageDeatail1"
                      className="cursor-pointer"
                    >
                      <img
                        src={selectedImage}
                        alt={`Accueil concept img ${espaceDetailsData.bgimageDeatail1}`}
                        className="object-cover max-w-40 max-h-40 min-w-40 min-h-40 "
                      />
                    </label>
                  </div>
                ) : (
                  <label
                    htmlFor="bgimageDeatail1"
                    className="h-full min-h-44 max-h-52 w-48 mx-auto bg-gray-200 rounded-md flex items-center justify-center"
                  >
                    <i className="bi bi-card-image text-8xl"></i>
                  </label>
                )}
              </div>
            </div>
            <div className="">
              <p className="italic text-xs md:text-base">
                Veuillez télécharger une image carrée, d'une taille inférieure 100
                Ko.
              </p>
              <div className="bg-blue-50 rounded-md text-center py-3 px-2 sm:px-3">
                <input
                  type="file"
                  name="bgimageDeatail1"
                  id="bgimageDeatail1"
                  className="sr-only"
                  onChange={handleImageChange}
                />
                <label
                  htmlFor="bgimageDeatail1"
                  className="text-sm md:text-base py-2 px-4 rounded-md border-fancygreen text-fancygreen cursor-pointer"
                  style={{ border: "1px solid gray" }}
                >
                  Choisir une image
                </label>
                {fileName && (
                  <span className="ml-3 text-gray-600 text-xs md:text-base">
                    {fileName}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <button
            type="submit"
            className="bg-lightyellow text-lightOrange border-lightOrange rounded-md px-4 py-1 hover:border-lightOrange hover:text-lightOrange hover:bg-lightyellow"
            style={{ border: "1px solid gray" }}
          >
            Valider
          </button>
        </div>
      </form>
    </div>
  );
};

export default BoEspacesDetailsSection3;
