import React, { useEffect } from 'react';
import ProfileIcon from './ProfileIcon';
// import NotificationIcon from './NotificationIcon';
import { getCurrentDateFormatted } from '../../../helpers/getCurrentDate';
import { useDispatch, useSelector } from 'react-redux';
import { getUserByToken } from '../../../actions/pages';
import { CLIENT_NAME } from '../../../constants/actions';
import { useLocation } from 'react-router-dom';

const BoHeader = ({ toggleSidebar }) => {
  const user = useSelector(state => state?.auth?.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector(state => state?.token);

  useEffect(()=>{
    dispatch(getUserByToken(token?.token));
  },[token?.token])

  const handleChange = (e) =>{
    dispatch({
      type: CLIENT_NAME,
      payload: e.target.value
    })
  }

  return (
    <div className='py-1 bg-white'>
      <div className="flex items-center justify-between gap-x-2 py-2 px-4">
        <button 
          onClick={toggleSidebar} 
          aria-controls="default-sidebar" 
          type="button" 
          className="text-xl px-2 py-1 text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <i className="bi bi-text-left"></i>
        </button>
        {location?.pathname === '/gestion/reservations' ? (
          <div className="bg-gray-50 py-1 px-2 flex items-center gap-x-3 rounded-md w-72 sm:w-96">
            <i className="bi bi-search"></i>
            <input type="text" name='search' className='bg-gray-50 outline-none w-full' onChange={handleChange} />
          </div>
        ) : (<div></div>)}
        <div className="flex items-center gap-x-3">
          {/* <NotificationIcon/> */}
          <ProfileIcon user={user} />
        </div>
      </div>
      <div className="flex justify-center py-2">
      <h3 className="text-gray-800 font-semibold text-sm sm:text-base">
      Aujourd'hui, le {getCurrentDateFormatted()}</h3>
      </div>
    </div>
  );
};

export default BoHeader;
