import React, { useEffect, useState } from 'react'
import { Decos, BannerSectionP13, ReservationBarr, HeaderDetails } from '../../components'
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';

function DecorPage() {
  const reservationDetails = useSelector(state => state?.detailsreservation);
  const [show, setShow] = useState(reservationDetails?.isCompleted);
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    if (!reservationDetails?.reservation?.id) {
      setWarning(true);
    }
  }, []);

  if (warning) {
    return (
      <div>
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
          <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full text-center">
            <p className="text-lg font-semibold text-gray-800 mb-4">
              Vous devez faire une réservation avant d'accéder à cette page.
            </p>
            <p className="text-gray-600 mb-6">
              Vous serez redirigé vers la page de réservation...
            </p>
            <button
              onClick={() => (window.location.href = "/reservation")}
              className="bg-yellow-500 text-white font-bold py-2 px-4 rounded hover:bg-yellow-600 transition duration-300"
            >
              Aller à la réservation
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div>
      <Helmet>
        <title>Choisissez la formule</title>
        <meta name="description" content="Découvrez notre sélection de formules adaptées à vos besoins sur notre page dédiée. Choisissez parmi diverses options pour personnaliser votre réservation et profiter de nos services exclusifs." />
      </Helmet>
      <BannerSectionP13 />
      <HeaderDetails />
        <Decos /> 
        <div className={`${show ? 'h-24 lg:h-10' : 'h-0'} transition-all duration-1000 ease-linear`}></div>
        <div className={`fixed z-50 bottom-0 left-0 w-full transition-all duration-1000 ease-in-out ${show ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}>
          <ReservationBarr from="deco" />
        </div>
    </div>
  )
}

export default DecorPage