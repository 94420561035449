import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DeleteConfirmationModal from "../popups/DeleteConfirmationModal";
import { apiClient } from "../../../../actions/api";

const Section1Blog = ({ visibleBlogs, refresh, setRefresh, plusRecent, setPlusRecent, search, setSearch }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [blogToDelete, setBlogToDelete] = useState(null);

  const handleDeleteClick = (postId) => {
    setBlogToDelete(postId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setBlogToDelete(null);
  };

  const deletePost = async (id) => {
    try {
      await apiClient().delete(`/blogCards/${id}`);
      // handle success if needed
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleConfirmDelete = () => {
    deletePost(blogToDelete);
    setShowModal(false);
    setBlogToDelete(null);
    setRefresh(!refresh);
  };

  return (
    <div className="space-y-8 py-4">
      <DeleteConfirmationModal
        show={showModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
      />
      <div className="flex items-center justify-between flex-wrap md:flex-nowrap gap-4">
        <div className="min-w-fit">
          <h2 className="font-poppins font-semibold text-2xl text-gray-800">
            Tous les blogs
          </h2>
        </div>
        <div className="flex items-center gap-x-4 w-full lg:w-auto">
          <div className="flex items-center gap-x-2 py-2 px-3 bg-gray-100 rounded-lg shadow-sm border border-gray-300">
            <i className="bi bi-search text-lg text-gray-500"></i>
            <input
              type="text"
              name="searchblog"
              className="bg-gray-100 outline-none w-full text-sm text-gray-700"
              placeholder="Recherche"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <select
            name="sortbydate"
            className="py-2 px-3 bg-gray-100 border border-gray-300 rounded-lg text-sm text-gray-700"
            onChange={(e) => setPlusRecent(e.target.value)}
            defaultValue="1"
          >
            <option value="1">Plus ancien</option>
            <option value="2">Plus récent</option>
          </select>
          <button
            onClick={() => navigate('/gestion/blog/ajouteblog')}
            className="bg-yellow-400 text-yellow-800 border border-yellow-800 rounded-md px-4 py-2 text-sm font-medium hover:bg-yellow-500 hover:text-yellow-900"
          >
            Ajouter
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full min-w-max bg-white shadow-md border border-gray-200 rounded-lg">
          <thead>
            <tr className="text-xs sm:text-sm font-semibold text-gray-600 bg-gray-200 border-b">
              <th className="px-3 py-2 text-left">ID</th>
              <th className="px-3 py-2 text-left">Titre</th>
              <th className="px-3 py-2 text-left">Auteur</th>
              <th className="px-3 py-2 text-left">Date de création</th>
              <th className="px-3 py-2 text-left">Status</th>
            </tr>
          </thead>
          <tbody className="text-sm text-gray-700">
            {visibleBlogs?.map((post, index) => (
              <tr
                key={index}
                className="border-b hover:bg-gray-50 cursor-pointer"
              >
                <td className="py-3 px-3">#{post.id}</td>
                <td className="py-3 px-3 font-medium">{post.title}</td>
                <td className="py-3 px-3">{post.author}</td>
                <td className="py-3 px-3">{post.date}</td>
                <td className="py-3 px-3 flex items-center gap-x-2">
                  <button onClick={() => navigate(`/gestion/blog/${post.id}`)}>
                    <i className="bi bi-pencil-fill text-yellow-500 p-1 border border-yellow-500 rounded-full hover:bg-yellow-100"></i>
                  </button>
                  <button onClick={() => handleDeleteClick(post.id)}>
                    <i className="bi bi-trash3-fill text-red-500 p-1 border border-red-500 rounded-full hover:bg-red-100"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Section1Blog;